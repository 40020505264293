import React, { useCallback, useEffect, useState } from "react";

import OnboardingContent from "./OnboardingContent";
import { updateFirstTimeCampaignOnboardingUserList } from "../../utils/utils";

const MAX_WIDTH = 424; // px

function OnboardingContainer({ config, isShownOnboarding }) {
  const { parentRef, text, offset = {}, userId, name } = config;
  const [styles, setStyles] = useState({});
  const [isShown, setIsShown] = useState(isShownOnboarding);

  const updateStyles = useCallback(() => {
    if (!parentRef.current) return;

    const { top, left, right, bottom } = offset || {};
    const parentRect = parentRef.current.getBoundingClientRect();

    let width = Math.min(parentRect.width, MAX_WIDTH);

    const styleProps = [
      ["top", top ? `${top}` : undefined],
      ["left", left ? `${left}` : undefined],
      ["right", right ? `${right}` : undefined],
      ["bottom", bottom ? `${bottom}` : undefined],
      ["width", width === MAX_WIDTH ? `${MAX_WIDTH}px` : `${width}px`],
    ].filter(Boolean);

    setStyles(Object.fromEntries(styleProps));
  }, [offset, parentRef]);

  useEffect(() => {
    updateStyles();

    const currentRef = parentRef.current;
    const resizeObserver = new ResizeObserver(updateStyles);

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [offset, updateStyles, parentRef]);

  const handleClick = () => {
    setIsShown(false);
    updateFirstTimeCampaignOnboardingUserList({
      userId,
      name,
    });
  };

  return (
    isShown && (
      <OnboardingContent
        styles={styles}
        text={text}
        handleClick={handleClick}
      />
    )
  );
}

export default OnboardingContainer;
