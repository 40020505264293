/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";

import CampaignOverview from "./CampaignOverview";
import CampaignDetails from "./CampaignDetails";
import CampaignType from "./CampaignType";
import SalesStrategy from "./SalesStrategy";
import SelectRecipients from "./SelectRecipients";
import ConfigureOptions from "./ConfigureOptions";
import PreviewMessage from "./PreviewMessage";
import JobDescription from "./JobDescription";

import {
  GOALS,
  ATTEMPTS_OPTIONS,
  BEGIN_OPTIONS,
  DURATION_OPTIONS,
  TONE_OPTIONS,
  BULLHORN_CAMPAIGN_TYPES,
} from "../constants";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
  saveObjectToLocalStorage,
  saveDataToLocalStorage,
} from "../../../api/localStorage";
import {
  buildMarketingCampaignName,
  getWizardTabs,
  isBullhornMarketingCampaignType,
} from "../utils";
import {
  createCampaign,
  updateCampaign,
  postTempCampaign,
} from "../../../services/campaigns";
import { getOptedOutCandidates } from "../../../services/candidates";
import { uniq } from "lodash";
import { CRMS } from "../../../utils/constants";

function ContentSteps({
  activeTab,
  setActiveTab,
  setCampaignType,
  isRecreatingCampaignIframe,
  crm,
  bullhornCampaignData,
  isEmailIntegration,
  isSmsIntegration,
}) {
  const [campaign, setCampaign] = useState({
    attempts: ATTEMPTS_OPTIONS[0].value + 1,
    begin: BEGIN_OPTIONS[0].value,
    beginDate: "",
    duration: DURATION_OPTIONS[0].value,
    goal: "",
    isCustomGoal: false,
    name: crm === CRMS.BULLHORN ? "" : buildMarketingCampaignName(),
    jobDescriptionKey: "",
    jobDescription: {},
    type: "",
    details: "",
    strategy: "",
    useSMS: false,
    candidatesLength: 0,
    aiModel: "",
    tone: TONE_OPTIONS[0].value,
    additionalInstructions: "",
    link: "",
    smsTiming: 1,
    autoReply: false,
    isFollowUpsAsReplies: false,
    useContactNotesInCommunications: false,
    isFullSequenceCampaign: false,
    sequenceTemplate: null,
    crmType: crm,
  });

  const tabs = getWizardTabs(crm, campaign.type);

  const [tempCampaignId, setTempCampaignId] = useState(null);

  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [isCampaignChanged, setIsCampaignChanged] = useState(false);
  const [isCampaignCreated, setIsCampaignCreated] = useState(false);

  const changeCampaignValues = (campaign) => {
    if (isCampaignCreated) {
      setIsCampaignChanged(true);
    }
    setCampaign(campaign);
  };

  const finishCreation = async (e, isNeedToLaunch) => {
    e.preventDefault();

    saveDataToLocalStorage("isCampaignChanged", isCampaignChanged);

    const preparedFields = prepareCreateCampaignFields();
    saveObjectToLocalStorage("preparedFields", preparedFields);

    sendMessageToExtension({
      message: "finish-create-campaign",
      data: {
        isFinishedCreation: true,
        isNeedToLaunch,
        crm,
      },
    });
  };

  const prepareCreateCampaignFields = () => {
    const {
      attempts,
      begin,
      beginDate,
      duration,
      goal,
      name,
      link,
      smsTiming,
      strategy,
      useSMS,
      aiModel,
      tone,
      additionalInstructions,
      autoReply,
      candidates,
      crmType,
      jobDescription,
      jobDescriptionKey,
      isFollowUpsAsReplies,
      useContactNotesInCommunications,
      isFullSequenceCampaign,
      sequenceTemplate,
    } = campaign;

    const filteredCandidates = candidates.filter(
      (candidate) => !candidate.isInvalid
    );

    const durationCount = parseInt(duration);
    const durationUnit = duration.charAt(duration.length - 1);

    const createCampaignFields = {
      attempts: Number(attempts),
      begin: beginDate || begin,
      duration: durationCount,
      durationUnit,
      goal,
      name,
      strategy,
      useSMS,
      aiModel,
      tone,
      additionalInstructions,
      autoReply,
      contacts: filteredCandidates,
      crmType,
      isFollowUpsAsReplies,
      useContactNotesInCommunications,
      isFullSequenceCampaign,
      sequenceTemplate,
    };

    if (goal === GOALS.visitLink) {
      createCampaignFields.link = link;
    }

    if (useSMS) {
      createCampaignFields.smsTiming = Number(smsTiming);
    }

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description = jobDescription[jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      createCampaignFields.crmData = {
        job_description,
        job_pay_rate,
      };
    } else {
      createCampaignFields.crmData = {
        campaign_type: campaign.type,
        campaign_details: campaign.details,
      };
    }

    if (isFullSequenceCampaign) {
      delete createCampaignFields.attempts;
      delete createCampaignFields.useSMS;
      delete createCampaignFields.smsTiming;
      delete createCampaignFields.begin;
      delete createCampaignFields.duration;
    } else {
      delete createCampaignFields.sequenceTemplate;
    }

    return createCampaignFields;
  };

  const fetchCreateTempCampaign = async (data) => {
    let currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    if (currentDraftCampaign && !isCampaignChanged) {
      return currentDraftCampaign;
    }

    const createCampaignFields = prepareCreateCampaignFields();

    const result = isCampaignChanged
      ? await updateCampaign({
          campaign: createCampaignFields,
          campaignId: currentDraftCampaign.id,
          isNeedToStartCampaign: data?.isNeedToStartCampaign,
        })
      : await createCampaign({
          campaign: { ...createCampaignFields, tempCampaignId },
          isNeedToStartCampaign: data?.isNeedToStartCampaign,
        });

    if (result.success !== undefined && !result.success) {
      console.log("create temp campaign error", result.message);
      removeDataFromLocalStorage("currentDraftCampaign", true);

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    setIsCampaignCreated(true);

    saveObjectToLocalStorage(
      "currentDraftCampaign",
      result.result.campaign,
      true
    );
    return result.result.campaign;
  };

  const handleIncreaseStep = async (event) => {
    event?.preventDefault();

    if (activeTab === Object.keys(tabs).length) {
      return;
    }

    const nextTab = activeTab + 1;
    setActiveTab(nextTab);
    if (nextTab === tabs.REVIEW_MESSAGES) {
      setIsCreatingCampaign(true);
      const campaign = await fetchCreateTempCampaign();
      if (!campaign) {
        setActiveTab(activeTab);
      }
      setIsCreatingCampaign(false);
    }
  };

  const handleDecreaseStep = (event) => {
    event?.preventDefault();
    if (activeTab > 1) {
      setActiveTab((activeTab) => activeTab - 1);
    }
  };

  useEffect(() => {
    if (isRecreatingCampaignIframe) {
      const currentCampaign = getObjectFromLocalStorage("campaignInfo");
      setCampaign(currentCampaign);
      setActiveTab(tabs.SELECT_RECIPIENTS);
    }
  }, [isRecreatingCampaignIframe]);

  useEffect(() => {
    if (campaign.candidatesExternalIdList?.length) {
      const calcOptedOut = async () => {
        const optedOutCandidates = await getOptedOutCandidates({
          campaignType: campaign.type,
          externalIdList: campaign.candidatesExternalIdList,
          crmType: campaign.crmType,
        });
        const optedOutCandidatesList = uniq(
          optedOutCandidates.result?.result || []
        );
        const optedOutCandidatesLength = optedOutCandidatesList.length;
        const candidates = campaign.candidates.map((c) => {
          if (optedOutCandidatesList.includes(c.id)) {
            return { ...c, isInvalid: true };
          }
          return c;
        });
        changeCampaignValues({
          ...campaign,
          optedOutCandidatesLength,
          incorrectCandidatesLength:
            (campaign.invalidEmailCandidatesLength || 0) +
            optedOutCandidatesLength,
          candidatesLength: candidates.length,
          candidates,
        });
      };
      calcOptedOut();
    }
  }, [campaign.candidatesExternalIdList]);

  useEffect(() => {
    const currentTempCampaign = getObjectFromLocalStorage(
      "currentTempCampaign"
    );
    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );
    if (currentTempCampaign) {
      setTempCampaignId(currentTempCampaign.tempCampaignId);
      saveDataToLocalStorage(
        "tempCampaignId",
        currentTempCampaign.tempCampaignId
      );
      delete currentTempCampaign.activeTab;
      delete currentTempCampaign.createdAt;
      if (crm === CRMS.BULLHORN && !currentTempCampaign.type) {
        currentTempCampaign.type = BULLHORN_CAMPAIGN_TYPES.recruitForJob;
      }
      setCampaign({ ...currentTempCampaign });
    } else if (bullhornCampaignData) {
      const addData = {
        localBullhornData: { ...bullhornCampaignData },
      };
      if (bullhornCampaignData.isMarketingCampaign) {
        addData.type = BULLHORN_CAMPAIGN_TYPES.marketToCandidates;
        addData.name = buildMarketingCampaignName();
      } else {
        addData.type = BULLHORN_CAMPAIGN_TYPES.recruitForJob;
        addData.name = bullhornCampaignData.jobTitle;
        addData.payRate = bullhornCampaignData.payRate;
        addData.jobDescription = { ...bullhornCampaignData.jobDescription };
      }
      setCampaign({
        ...campaign,
        ...addData,
      });
      setCampaignType(addData.type);
    }
    if (currentDraftCampaign) {
      setIsCampaignCreated(true);
    }
  }, []);

  useEffect(() => {
    if (campaign.name && campaign.goal) {
      const saveTemp = async () => {
        const currentTempCampaign = getObjectFromLocalStorage(
          "currentTempCampaign"
        );
        const currentDraftCampaign = getObjectFromLocalStorage(
          "currentDraftCampaign"
        );
        const newCurrentTempCampaignStr = JSON.stringify({
          ...campaign,
          activeTab,
        });
        if (JSON.stringify(currentTempCampaign) !== newCurrentTempCampaignStr) {
          saveDataToLocalStorage(
            "currentTempCampaign",
            newCurrentTempCampaignStr,
            true
          );
          const res = await postTempCampaign({
            crm,
            campaign: newCurrentTempCampaignStr,
          });
          if (!tempCampaignId && res.success && res.result.tempCampaign?.id) {
            setTempCampaignId(res.result.tempCampaign.id);
            saveDataToLocalStorage(
              "tempCampaignId",
              res.result.tempCampaign.id
            );
          }
          if (currentDraftCampaign?.id) {
            const createCampaignFields = prepareCreateCampaignFields();
            await updateCampaign({
              campaign: createCampaignFields,
              campaignId: currentDraftCampaign.id,
            });
          }
        }
      };
      saveTemp();
    }
  }, [campaign, activeTab]);

  switch (activeTab) {
    case tabs.CAMPAIGN_OVERVIEW:
      return (
        <Form>
          <CampaignOverview
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
            isEmailIntegration={isEmailIntegration}
          />
        </Form>
      );
    case tabs.CAMPAIGN_TYPE:
      return (
        <Form onSubmit={handleIncreaseStep}>
          <CampaignType
            setCampaignType={setCampaignType}
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        </Form>
      );
    case tabs.CAMPAIGN_DETAILS:
      return (
        <Form onSubmit={handleIncreaseStep}>
          <CampaignDetails
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        </Form>
      );
    case tabs.JOB_DESCRIPTION:
      return (
        <Form onSubmit={handleIncreaseStep}>
          <JobDescription
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        </Form>
      );
    case tabs.SALES_STRATEGY:
      return (
        <Form onSubmit={handleIncreaseStep}>
          <SalesStrategy
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
          />
        </Form>
      );
    case tabs.SELECT_RECIPIENTS:
      return (
        <Form
          onSubmit={handleIncreaseStep}
          $margin="0"
          $height={
            campaign.candidatesLength !== 0 ||
            campaign.incorrectCandidatesLength > 0
              ? "calc(100vh - 70px)"
              : undefined
          }
        >
          <SelectRecipients
            campaign={campaign}
            setCampaign={setCampaign}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
            isRecreatingCampaignIframe={isRecreatingCampaignIframe}
          />
        </Form>
      );
    case tabs.CONFIGURE_OPTIONS:
      return (
        <Form>
          <ConfigureOptions
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            isCreatingCampaign={isCreatingCampaign}
            finishCreateCampaign={(e) => finishCreation(e, true)}
            generateAll={(e) => finishCreation(e, false)}
            crm={crm}
            isEmailIntegration={isEmailIntegration}
            isSmsIntegration={isSmsIntegration}
          />
        </Form>
      );
    case tabs.REVIEW_MESSAGES:
      return (
        <Form $margin="0" $height="100vh">
          <PreviewMessage
            campaign={campaign}
            candidates={campaign.candidates ? campaign.candidates : []}
            isCreatedDraftCampaign={false}
            handleDecreaseStep={handleDecreaseStep}
            isCreatingCampaign={isCreatingCampaign}
            finishCreateCampaign={(e) => finishCreation(e, true)}
            generateAll={(e) => finishCreation(e, false)}
          />
        </Form>
      );

    default:
      return <div>GOT ERROR IN STEPS</div>;
  }
}

export default ContentSteps;

const Form = styled.form`
  width: 100%;
  margin: ${({ $margin }) => ($margin ? $margin : "60px 30px 30px 60px")};
  height: ${({ $height }) => ($height ? $height : "calc(100vh - 100px)")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
