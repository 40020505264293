import {
  fetchChangeSubscriptionPlan,
  fetchCreateSetupIntent,
  fetchSubscriptionPlans,
  fetchUpdateBillingDetails,
} from "./queries";

const getSubscriptionPlans = async () => {
  try {
    const result = await fetchSubscriptionPlans();

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get subscription plans failed" };
  }
};

const createSetupIntent = async () => {
  try {
    const result = await fetchCreateSetupIntent();

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Create setup intent failed" };
  }
};

const changeSubscriptionPlan = async (data) => {
  try {
    const result = await fetchChangeSubscriptionPlan(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Change subscription plan failed" };
  }
};

const updateBillingDetails = async (data) => {
  try {
    const result = await fetchUpdateBillingDetails(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Update billing details failed" };
  }
};

export {
  getSubscriptionPlans,
  createSetupIntent,
  changeSubscriptionPlan,
  updateBillingDetails,
};
