import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { getCompany, updateCompany } from "../../../services/company";
import Loader from "../../Loader";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { useNavigate } from "react-router-dom";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { Tooltip } from "react-tooltip";

function GreetingAndSignature() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const [
    isAllowManualGreetingAndSignature,
    setIsAllowManualGreetingAndSignature,
  ] = useState(false);
  const [company, setCompany] = useState(null);

  const fetchUpdateCompany = async (e) => {
    e.preventDefault();

    if (!isCompanyAdmin()) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Available only for company admins",
        },
      });
      setIsAllowManualGreetingAndSignature(
        company.manualGreetingAndSignature !== undefined
          ? company.manualGreetingAndSignature
          : false
      );
      return;
    }

    setIsUpdating(true);

    const response = await updateCompany({
      manualGreetingAndSignature: isAllowManualGreetingAndSignature,
    });

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      setIsAllowManualGreetingAndSignature(
        company.manualGreetingAndSignature !== undefined
          ? company.manualGreetingAndSignature
          : false
      );
      setIsUpdating(false);
      return;
    }

    setCompany({
      ...company,
      manualGreetingAndSignature: isAllowManualGreetingAndSignature,
    });

    setIsUpdating(false);
  };

  const isDataNotChanged = () => {
    return (
      company.manualGreetingAndSignature === isAllowManualGreetingAndSignature
    );
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const response = await getCompany();

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: response.message,
          },
        });

        navigate("/campaigns");
        setIsLoading(false);
        return;
      }

      setCompany(response.company);
      setIsAllowManualGreetingAndSignature(
        response.company.manualGreetingAndSignature !== undefined
          ? response.company.manualGreetingAndSignature
          : false
      );

      setIsLoading(false);
    };

    fetchCompany();
  }, []);

  return (
    <Content>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <Section>
          <Title>Manual Greeting & Signature</Title>
          <Inputs>
            <InputBlock>
              <CheckboxBlock
                data-tooltip-id={!isCompanyAdmin() ? `no-admin-tooltip` : null}
                data-tooltip-content={
                  !isCompanyAdmin()
                    ? "Please contact your company admin to change this setting"
                    : null
                }
              >
                <input
                  id="isAllowManualGreetingAndSignatureInput"
                  type="checkbox"
                  checked={isAllowManualGreetingAndSignature}
                  value={isAllowManualGreetingAndSignature}
                  disabled={!isCompanyAdmin()}
                  onChange={(event) =>
                    setIsAllowManualGreetingAndSignature(event.target.checked)
                  }
                />
                <label
                  htmlFor="isAllowManualGreetingAndSignatureInput"
                  className="toggle"
                ></label>
                <CheckboxLabel $checked={isAllowManualGreetingAndSignature}>
                  Allow manual greeting and signature
                </CheckboxLabel>
              </CheckboxBlock>
              {!isCompanyAdmin() ? (
                <Tooltip
                  id={`no-admin-tooltip`}
                  className="custom-tooltip"
                  place="left"
                />
              ) : null}
            </InputBlock>
          </Inputs>

          <Actions>
            <UpdateUnsubscribeBtn
              onClick={(e) => fetchUpdateCompany(e)}
              $disabled={isDataNotChanged() || isUpdating}
            >
              Update
            </UpdateUnsubscribeBtn>
          </Actions>
        </Section>
      )}
    </Content>
  );
}

export default GreetingAndSignature;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Section = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const UpdateUnsubscribeBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ $disabled }) => ($disabled ? "0.5" : "1")};
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 50%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
