import React from "react";
import { styled } from "styled-components";

function StatCard({ title, value, isLoading }) {
  const disabled = isNaN(parseInt(value));

  const getRoundedValue = (numberValue) => {
    return getFormattedCreditValue(Math.round(numberValue)).toLocaleString("en-US");
  };

  const getFormattedCreditValue = (value) => {
    let formattedValue = value;
    if (value > 1000 && value < 1000000) {
      formattedValue = value / 1000 + `k`
    } else if (value > 1000000) {
      formattedValue = value /1000000 + 'm'
    }
    return formattedValue;
  };

  return (
    <CardWrapper $disabled={disabled}>
      <CardTitle $disabled={disabled}>{title}</CardTitle>
      <CardContent>
        {disabled ? (
          <DisabledText>No data</DisabledText>
        ) : isLoading ? (
          <img src="/images/ellipsis.png" alt="loader" />
        ) : (
          <CardValue viewBox="0 0 205 33">
            <text x="0" y="30">
              {Array.isArray(value)
                ? `${getRoundedValue(value[0])} of ${getRoundedValue(value[1])}`
                : getRoundedValue(value)}
            </text>
          </CardValue>
        )}
      </CardContent>
    </CardWrapper>
  );
}

export default StatCard;

const DisabledText = styled.span`
  color: #aeaca9;
  font-size: 12px;
  font-weight: 300;
`;

const CardWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.rateCard_border_color};
  max-width: 330px;
  height: 130px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? "faf8f6" : theme.colors.white};
  box-shadow: 0px 0px 30px 0px #0000001a;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardValue = styled.svg`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 29px;
    color: ${({ theme }) => theme.colors.black};
    font-weight: bold;
  }
  width: 100%;
`;

const CardTitle = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme, $disabled }) =>
    $disabled ? "#c9c6c4" : theme.colors.gray};
  width: 73%;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
