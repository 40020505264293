import React, { useEffect, useRef, useState } from "react";
import { styled } from "styled-components";
import SelectedContactsTable from "./SelectedContactsTable";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import CandidateTableFilters from "./CandidateTableFilters";
import { BULLHORN_CAMPAIGN_TYPES } from "../constants";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

function SelectedContacts({
  contactsData,
  campaignType,
  crm,
  onClickEdit,
  checkedContacts,
  setCheckedContacts,
  relevantStatuses,
}) {
  const [contactsList, setContactsList] = useState(contactsData);
  const parentRef = useRef(null);
  const [sort, setSort] = useState({ sort: null, order: null });
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState("");

  const filterCandidatesBySort = async ({ newSort, newOrder }) => {
    setSort({ sort: newSort, order: newOrder });

    const sortMultiplier = newOrder === "desc" ? -1 : 1;
    const sortedContactsList = contactsList.sort(
      (a, b) => sortMultiplier * (a[newSort] < b[newSort] ? 1 : -1)
    );

    setContactsList(sortedContactsList);

    return sortedContactsList;
  };

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.selectRecipients,
    parentRef,
  };

  const handleCheckContact = (isChecked, contactId) => {
    if (isChecked === "all") {
      setCheckedContacts(contactsList.map((c) => c.id));
      return;
    }
    if (isChecked === "none") {
      setCheckedContacts([]);
      return;
    }

    setCheckedContacts(
      isChecked
        ? [...checkedContacts, contactId]
        : checkedContacts.filter((c) => c !== contactId)
    );
  };

  const filterCandidatesByFilters = (newFilters) => {
    const filteredContacts = contactsData.filter((c) => {
      return Object.keys(newFilters).every(
        (filter) => c[filter] === newFilters[filter]
      );
    });

    setContactsList(filteredContacts);
    return filteredContacts;
  };

  const filterCandidatesByQuery = (currentQuery, contacts) => {
    if (currentQuery === "") {
      setContactsList(contacts);
      return;
    }
    const filteredContacts = contacts.filter((c) => {
      return (
        c.full_name?.toLowerCase().includes(currentQuery.toLowerCase()) ||
        c.email?.toLowerCase().includes(currentQuery.toLowerCase()) ||
        c.company?.toLowerCase().includes(currentQuery.toLowerCase()) ||
        c.jobTitle?.toLowerCase().includes(currentQuery.toLowerCase())
      );
    });

    setContactsList(filteredContacts);
  };

  useEffect(() => {
    setContactsList(contactsData);
    const filteredContacts = filterCandidatesByFilters(filters);
    filterCandidatesByQuery(query, filteredContacts);
  }, [contactsData]);

  useEffect(() => {
    const filteredContacts = filterCandidatesByFilters(filters);
    filterCandidatesByQuery(query, filteredContacts);
  }, [query, filters]);

  return (
    <SelectedContactsContainer>
      <Header>
        <TitleBlock>
          <Title>
            {contactsData.length}{" "}
            {crm === CRMS.BULLHORN &&
            campaignType !== BULLHORN_CAMPAIGN_TYPES.marketToClients
              ? "candidate"
              : "contact"}
            {contactsData.length > 1 && "s"} selected
          </Title>
          <EditLink onClick={onClickEdit}>Edit in CRM</EditLink>
        </TitleBlock>
        <FiltersBlock>
          <CandidateTableFilters
            load={setFilters}
            relevantStatuses={relevantStatuses}
          />
          <Search>
            <SearchInput
              type="text"
              onInput={(event) => setQuery(event.target.value)}
              placeholder="Search"
              value={query}
            />
            <SearchIcon src="/images/search-icon.png" />
          </Search>
        </FiltersBlock>
      </Header>
      <TableContainer ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <SelectedContactsTable
          candidateList={contactsList}
          setSort={(sort) => filterCandidatesBySort(sort)}
          sort={sort}
          handleCheckContact={handleCheckContact}
          checkedContacts={checkedContacts}
          crm={crm}
          campaignType={campaignType}
        ></SelectedContactsTable>
      </TableContainer>
    </SelectedContactsContainer>
  );
}

export default SelectedContacts;

const SelectedContactsContainer = styled.div`
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-left: 3px;
  align-items: center;
  justify-content: space-between;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 60px;
`;

const EditLink = styled.div`
  font-size: 13px;
  color: #4640de;
  cursor: pointer;
  border-bottom: 1px dashed #4640de;
  margin-left: 15px;
`;

const TableContainer = styled.div`
  height: calc(100% - 60px);
  overflow: auto;
  position: relative;
`;

const Search = styled.div`
  position: relative;
  margin-left: 10px;
`;

const SearchInput = styled.input`
  margin: 0;
  height: 38px;
  width: 256px;
  padding-left: 30px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  &:focus-visible {
    margin: 0;
    height: 38px;
    width: 256px;
    padding-left: 30px;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 12px;
  left: 10px;
  width: 13px;
  height: 13px;
`;
