import React, { useCallback, useState } from "react";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { useDropzone } from "react-dropzone";
import { styled } from "styled-components";

const ProfilePhotoUploader = ({ onLoadFile }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const user = getUserFromLocalStorage();
  const avatar = user.avatar?.current || user.avatar;

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    onLoadFile(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  return (
    <UploaderContainer>
      <UploadedPhoto>
        {(selectedFile || avatar) && (
          <img
            src={selectedFile ? URL.createObjectURL(selectedFile) : avatar}
            alt="avatar"
          />
        )}
      </UploadedPhoto>
      <DragDropArea {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <DragDropAreaText>Upload photo...</DragDropAreaText>
        ) : (
          <>
            <DragDropAreaText>
              Drag and Drop your photo here, or
            </DragDropAreaText>
            <UploadBlock>
              <div>Upload...</div>
            </UploadBlock>
          </>
        )}
      </DragDropArea>
    </UploaderContainer>
  );
};

export default ProfilePhotoUploader;

const UploaderContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const UploadedPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 3px;
  background-color: #f6f6f6;
  border: 1px solid #d6ddeb;
  margin-right: 10px;
  background-image: url(/images/avatar-uploader-bg-icon.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  img {
    max-width: 71px;
    max-height: 71px;
  }
`;

const DragDropArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 82px);
  height: 72px;
  border-radius: 3px;
  border: 1px dashed #d6ddeb;
  background-color: #f6f6f6;
`;

const DragDropAreaText = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #757678;
`;

const UploadBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #646464;
  border: 1px solid #646464;
  border-radius: 6px;
  width: 76px;
  height: 30px;
  font-family: "AlbertSansExtraBold";
  background-color: #f6f6f6;
  margin-left: 10px;
  cursor: pointer;
`;
