import { sendMessageToExtension } from "../utils/postToExtension.js";
import {
  fetchCreateIntegration,
  fetchGetIntegrations,
  fetchHubspotFields,
  fetchUpdateHubspotFields,
  fetchUpdateOpenAiModels,
  fetchSendEmail,
  fetchDisconnectIntegration,
} from "./queries.js";

const createIntegration = async (data) => {
  const result = await fetchCreateIntegration(data);

  if (result.message) {
    return { success: false, message: result.message };
  }

  const integrations = await fetchGetIntegrations();

  if (!integrations.message) {
    sendMessageToExtension({
      message: "save-integrations",
      data: {
        integrations,
      },
    });
  }

  return { success: true, integrations };
};

const getIntegrations = async () => {
  const result = await fetchGetIntegrations();

  if (result.message) {
    return { success: false, message: result.message };
  }

  sendMessageToExtension({
    message: "save-integrations",
    data: {
      integrations: result,
    },
  });

  return result;
};

const getHubspotFields = async () => {
  const result = await fetchHubspotFields();

  if (result.message) {
    return { success: false, message: result.message };
  }

  return result;
};

const updateHubspotFields = async (data) => {
  const result = await fetchUpdateHubspotFields(data);
  return result.success;
};

const updateOpenAiModels = async (data) => {
  const result = await fetchUpdateOpenAiModels(data);
  return result.success;
};

const sendEmail = async (data) => {
  return await fetchSendEmail(data);
};

const disconnectIntegration = async (data) => {
  return await fetchDisconnectIntegration(data);
};

export {
  createIntegration,
  getIntegrations,
  getHubspotFields,
  updateHubspotFields,
  updateOpenAiModels,
  sendEmail,
  disconnectIntegration,
};
