import React, { useEffect, useState } from "react";
import { AccountProvider } from "../context";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import Navbar from "../components/Navbar";
import { Usage } from "../components/Settings/TabContent";
import Loader from "../components/Loader";
import UserActivity from "../components/Settings/TabContent/Reports/UserActivity";
import CampaignsSummary from "../components/Settings/TabContent/Reports/CampaignsSummary";
import CampaignDetails from "../components/Settings/TabContent/Reports/CampaignDetails";
import UndeliverableEmails from "../components/Settings/TabContent/Reports/UndeliverableEmails";

import { loadUserData } from "../services/user";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import {
  ENVs,
  POST_INSTALL_EXTENSION_SRC_COOKIE_NAME,
} from "../utils/constants";
import { getCookie } from "../utils/utils";

const GENERAL_TAB_KEY = "general";
const USAGE_TAB_KEY = "usage";
const UNDELIVERABLE_EMAILS_TAB_KEY = "undeliverableEmails";

const MULTI_LEVEL_TABS_LIST = [GENERAL_TAB_KEY];
const SINGLE_LEVEL_TABS_LIST = [USAGE_TAB_KEY, UNDELIVERABLE_EMAILS_TAB_KEY];

const Reports = () => {
  const [isLoading, setIsLoading] = useState(true);

  const SIDEBAR_TABS = {
    [GENERAL_TAB_KEY]: {
      title: "General Reports",
      tabs: {
        userActivity: {
          title: "User Activity",
          component: () => <UserActivity />,
        },
        campaignsSummary: {
          title: "Campaigns Summary",
          component: () => <CampaignsSummary />,
        },
        campaignDetails: {
          title: "Campaign Details",
          component: () => <CampaignDetails />,
        },
      },
    },
    [USAGE_TAB_KEY]: {
      title: "Usage",
      component: () => <Usage />,
    },
    [UNDELIVERABLE_EMAILS_TAB_KEY]: {
      title: "Undeliverable Emails",
      component: () => <UndeliverableEmails />,
    },
  };

  const [styledTab, setStyledTab] = useState(GENERAL_TAB_KEY);
  const [activeTab, setActiveTab] = useState(
    Object.keys(SIDEBAR_TABS[GENERAL_TAB_KEY].tabs)[0]
  );

  const userInfo = getUserFromLocalStorage();
  const isAdmin = userInfo ? userInfo.role === "admin" : false;

  const handleTabClick = (key) => {
    if (MULTI_LEVEL_TABS_LIST.includes(key)) {
      setStyledTab(key);
      setActiveTab(Object.keys(SIDEBAR_TABS[key].tabs)[0]);
      return;
    }
    if (SINGLE_LEVEL_TABS_LIST.includes(key)) {
      setStyledTab(key);
      setActiveTab(key);
      return;
    }
    const topKey = MULTI_LEVEL_TABS_LIST.find((tk) =>
      Object.keys(SIDEBAR_TABS[tk]?.tabs || {}).includes(key)
    );
    setStyledTab(topKey);
    setActiveTab(key);
  };

  useEffect(() => {
    const postInstallExtensionCookieValue = getCookie(
      POST_INSTALL_EXTENSION_SRC_COOKIE_NAME
    );
    const isNotForceToShowPostSignUpOnboarding = Boolean(
      postInstallExtensionCookieValue
    );

    loadUserData(
      userInfo,
      () => {
        setIsLoading(false);
      },
      isNotForceToShowPostSignUpOnboarding
    );
  }, []);

  const currentEnv = () => {
    const envName = Object.keys(ENVs).find(
      (key) => ENVs[key] === window.location.origin
    );
    return envName;
  };

  if (isLoading) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  return (
    <AccountProvider>
      <CheckExtensionVersion />
      <GlobalLayout>
        <Navbar />
        <Layout>
          <SideBarTitle>Reports</SideBarTitle>
          <ContentContainer>
            <SideBar>
              <TabList>
                {Object.keys(SIDEBAR_TABS).map((key) => {
                  return (
                    <div key={key}>
                      <TabItem
                        $flexDirection="column"
                        $alignItems="flex-start"
                        data-active={styledTab === key}
                      >
                        <SidebarItemTitle
                          onClick={() => handleTabClick(key)}
                          data-active={styledTab === key}
                          $styledTab={styledTab}
                        >
                          {SIDEBAR_TABS[key].title}
                        </SidebarItemTitle>
                        {MULTI_LEVEL_TABS_LIST.includes(key) &&
                          styledTab === key && (
                            <TabListIntegration>
                              {Object.keys(SIDEBAR_TABS[key].tabs).map(
                                (subKey) => (
                                  <div key={subKey}>
                                    <TabItemIntegration
                                      disabled={
                                        SIDEBAR_TABS[key].tabs[subKey]
                                          .disabledContent
                                      }
                                      data-active={activeTab === subKey}
                                    >
                                      <div
                                        data-tooltip-id={
                                          SIDEBAR_TABS[key].tabs[subKey]
                                            .disabledContent
                                            ? `${subKey}-tooltip`
                                            : null
                                        }
                                        data-tooltip-content={
                                          SIDEBAR_TABS[key].tabs[subKey]
                                            .disabledContent
                                            ? "Coming soon"
                                            : null
                                        }
                                        onClick={() =>
                                          !SIDEBAR_TABS[key].tabs[subKey]
                                            .disabledContent
                                            ? handleTabClick(subKey)
                                            : null
                                        }
                                      >
                                        {SIDEBAR_TABS[key].tabs[subKey].title}
                                      </div>
                                    </TabItemIntegration>
                                    {SIDEBAR_TABS[key].tabs[subKey]
                                      .disabledContent ? (
                                      <Tooltip
                                        id={`${subKey}-tooltip`}
                                        place="right"
                                        className="custom-tooltip"
                                      />
                                    ) : null}
                                  </div>
                                )
                              )}
                            </TabListIntegration>
                          )}
                      </TabItem>
                    </div>
                  );
                })}
              </TabList>
              {isAdmin && <EnvName>env: {currentEnv()}</EnvName>}
            </SideBar>
            <Content $activeTab={activeTab}>
              {isLoading ? (
                <Loader parentSize={true} />
              ) : SINGLE_LEVEL_TABS_LIST.includes(activeTab) ? (
                SIDEBAR_TABS[activeTab].component()
              ) : (
                MULTI_LEVEL_TABS_LIST.map((key) => (
                  <React.Fragment key={key}>
                    {SIDEBAR_TABS[key]?.tabs[activeTab]?.component()}
                  </React.Fragment>
                ))
              )}
            </Content>
          </ContentContainer>
        </Layout>
      </GlobalLayout>
    </AccountProvider>
  );
};

export default Reports;

const GlobalLayout = styled.main`
  height: 100%;
`;

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: calc(100vh - 70px);
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 1.7% 0 1.8% 4.8%;
`;

const SideBarTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  font-family: "AlbertSansExtraBold", sans-serif;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 7px;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 97%;
`;

const SideBar = styled.div`
  width: 176px;
  margin-top: 13px;
`;

const SidebarItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 10px;
  line-height: 13px;
  cursor: pointer;
  color: ${({ theme, $styledTab }) =>
    !MULTI_LEVEL_TABS_LIST.includes($styledTab)
      ? theme.colors.saturated_purple
      : theme.colors.gray};
  &::before {
    ${(props) =>
      props["data-active"]
        ? `
          content: "";
          position: absolute;
          width: 2px;
          height: 10px;
          border-radius:10px;
          left: 3px;
          `
        : "content: none"};
    background-color: ${({ theme, $styledTab }) =>
      !MULTI_LEVEL_TABS_LIST.includes($styledTab)
        ? theme.colors.saturated_purple
        : theme.colors.gray};
  }
`;

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
`;

const TabItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: ${({ $flexDirection }) =>
    $flexDirection ? $flexDirection : "row"};
  justify-content: flex-start;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : "center")};
  color: ${(props) => (props.disabled ? "#ccc" : props.theme.colors.gray)};
  font-weight: ${(props) => (props["data-active"] ? "700" : "400")};
`;

const TabListIntegration = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  font-size: 11px;
  /* margin-top: 8px; */
`;

const TabItemIntegration = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${(props) =>
    props.disabled ? "#ccc" : props.theme.colors.saturated_purple};
  font-weight: ${(props) => (props["data-active"] ? "700" : "400")};

  div {
    cursor: pointer;
    padding: 10px 10px 10px 24px;
  }
`;

const Content = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.divider_color};
  width: 87.5%;
  background-color: white;
  margin: 30px 0 0 23px;
  padding: ${({ $activeTab }) =>
    SINGLE_LEVEL_TABS_LIST.includes($activeTab) ? "0" : "43px 0 43px 63px"};
  overflow: auto;
`;

const EnvName = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 12px;
  color: grey;
`;
