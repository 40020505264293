import { useState } from "react";
import { resetPassword } from "../services/auth";
import ConnectBtn from "./ConnectBtn";

import styled from "styled-components";
import { sendMessageToExtension } from "../utils/postToExtension";

const PWD_REGEX = /^(?=.*\d).{6,}$/;

const ResetPassword = ({ resetToken, isInvited }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [pwdErrorMessage, setPwdErrorMessage] = useState("");
  const [confirmPwdErrorMessage, setConfirmPwdErrorMessage] = useState("");

  const toggleShowPassword = (isConfirmPwd) => {
    if (isConfirmPwd) {
      setShowConfirmPassword(!showConfirmPassword);
      return;
    }
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setPwdErrorMessage("");
    setConfirmPwdErrorMessage("");

    setIsLoading(true);

    const result = await resetPassword({ password: pwd, rt: resetToken });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    setPwd("");
    setConfirmPwd("");

    setIsLoading(false);
  };

  const isFormValid = () => {
    let isPwdValid = true;
    let isConfirmPwdValid = true;

    if (!pwd || !PWD_REGEX.test(pwd) || pwd === "") {
      setPwdErrorMessage(
        "Password must be at least 6 characters long and have at least one number"
      );
      isPwdValid = false;
    }

    if (confirmPwd !== pwd) {
      setConfirmPwdErrorMessage("Passwords do not match");
      isConfirmPwdValid = false;
    }

    if (!isPwdValid || !isConfirmPwdValid) {
      return false;
    }

    return true;
  };

  return (
    <section>
      <Title>{isInvited ? "Create a password" : "Reset Password"}</Title>

      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="password">Password</Label>
            <Relative>
              <Input
                type={showPassword ? "text" : "password"}
                id="password"
                placeholder={
                  isInvited
                    ? "Give your account a strong password..."
                    : "Enter new password..."
                }
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                onInput={(e) => setPwdErrorMessage("")}
                autoComplete="off"
              />
              {pwdErrorMessage ? (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              ) : (
                <ShowPwdIcon
                  src="/images/show-pwd-icon.svg"
                  alt="show password"
                  onClick={() => toggleShowPassword(false)}
                />
              )}
            </Relative>
            {pwdErrorMessage && <ErrorMessage>{pwdErrorMessage}</ErrorMessage>}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="confirm-password">Confirm Password</Label>
            <Relative>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                id="confirm-password"
                placeholder="Repeat your password..."
                value={confirmPwd}
                onChange={(e) => setConfirmPwd(e.target.value)}
                onInput={(e) => setConfirmPwdErrorMessage("")}
                autoComplete="off"
              />
              {confirmPwdErrorMessage ? (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              ) : (
                <ShowPwdIcon
                  src="/images/show-pwd-icon.svg"
                  alt="show password"
                  onClick={() => toggleShowPassword(true)}
                />
              )}
            </Relative>
            {confirmPwdErrorMessage && (
              <ErrorMessage>{confirmPwdErrorMessage}</ErrorMessage>
            )}
          </InputBlock>
        </Inputs>
        <Actions>
          <ConnectBtn
            type="submit"
            label={`Save ${isInvited ? "" : "New "}Password...`}
            disabled={isLoading}
          />
        </Actions>
      </Form>
    </section>
  );
};

export default ResetPassword;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10%;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ShowPwdIcon = styled.img`
  position: absolute;
  top: 24%;
  right: 12%;
  cursor: pointer;
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;
