import React from "react";

import ThumbnailList from "./ThumbnailList";

function Thumbnails({ listData, previewItem, setPreview }) {
  return (
    <ThumbnailList
      listData={listData}
      previewItem={previewItem}
      setPreview={setPreview}
    />
  );
}

export default Thumbnails;
