import React from "react";
import styled from "styled-components";

import StepLoader from "./StepLoader";

function StepLoaders({ wizardSteps, stepIndex }) {
  return (
    <Container>
      {wizardSteps.map((step, index) => (
        <StepLoader key={step.id} index={index} stepIndex={stepIndex} />
      ))}
    </Container>
  );
}

export default StepLoaders;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 13px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;
