import React from "react";
import { styled } from "styled-components";
import { Tooltip } from "react-tooltip";

function Toggle({
  id,
  label,
  value,
  onChange,
  withNoAdminTooltip,
  marginBottom,
  width,
}) {
  return (
    <Content
      className="custom-toggle-cont"
      $marginBottom={marginBottom}
      $width={width}
    >
      <CheckboxBlock
        data-tooltip-id={withNoAdminTooltip ? `no-admin-tooltip` : null}
        data-tooltip-content={
          withNoAdminTooltip
            ? "Please contact your company admin to change this setting"
            : null
        }
      >
        <input
          id={id}
          type="checkbox"
          checked={value}
          value={value}
          disabled={withNoAdminTooltip}
          onChange={(event) => onChange(event.target.checked)}
        />
        <label htmlFor={id} className="toggle" />
        {label ? <CheckboxLabel $checked={value}>{label}</CheckboxLabel> : null}
      </CheckboxBlock>
      {withNoAdminTooltip ? (
        <Tooltip
          id={`no-admin-tooltip`}
          className="custom-tooltip"
          place="left"
        />
      ) : null}
    </Content>
  );
}

export default Toggle;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${({ $marginBottom }) =>
    $marginBottom ? `margin-bottom: ${$marginBottom}` : ""}
  ${({ $width }) => ($width ? `width: ${$width}` : "")}
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;
