import React, { useEffect, useState } from "react";
import { AccountProvider } from "../context";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import Navbar from "../components/Navbar";
import { FAQs } from "../components/FAQs";
import CheckExtensionVersion from "../components/CheckExtensionVersion";
import { loadUserData } from "../services/user";
import { getCookie } from "../utils/utils";
import { POST_INSTALL_EXTENSION_SRC_COOKIE_NAME } from "../utils/constants";

const Support = () => {
  const SIDEBAR_TABS = {
    FAQs: {
      title: "FAQs",
      component: () => <FAQs />,
    },
    supportTicket: {
      title: "Log a Support Ticket",
      disabledContent: true,
    },
  };
  const [activeTab, setActiveTab] = useState(Object.keys(SIDEBAR_TABS)[0]);
  const [styledTab, setStyledTab] = useState(Object.keys(SIDEBAR_TABS)[0]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const postInstallExtensionCookieValue = getCookie(
      POST_INSTALL_EXTENSION_SRC_COOKIE_NAME
    );
    const isNotForceToShowPostSignUpOnboarding = Boolean(
      postInstallExtensionCookieValue
    );

    loadUserData(
      null,
      () => {
        setIsLoading(false);
      },
      isNotForceToShowPostSignUpOnboarding
    );
  }, []);

  const handleTabClick = (key) => {
    setStyledTab(key);
    setActiveTab(key);
  };

  if (isLoading) {
    return (
      <AccountProvider>
        <CheckExtensionVersion />
      </AccountProvider>
    );
  }

  return (
    <AccountProvider>
      <CheckExtensionVersion />
      <GlobalLayout>
        <Navbar />
        <Layout>
          <SideBarTitle>Support</SideBarTitle>
          <ContentContainer>
            <SideBar>
              <TabList>
                {Object.keys(SIDEBAR_TABS).map((key) => {
                  return (
                    <div key={key}>
                      <TabItem
                        $flexDirection="column"
                        $alignItems="flex-start"
                        data-active={styledTab === key}
                        data-tooltip-id={
                          SIDEBAR_TABS[key].disabledContent
                            ? `${key}-tooltip`
                            : null
                        }
                        data-tooltip-content={
                          SIDEBAR_TABS[key].disabledContent
                            ? "Coming soon"
                            : null
                        }
                      >
                        <SidebarItemTitle
                          onClick={() =>
                            !SIDEBAR_TABS[key].disabledContent
                              ? handleTabClick(key)
                              : null
                          }
                          data-active={styledTab === key}
                          $disabled={SIDEBAR_TABS[key].disabledContent}
                          $styledTab={styledTab}
                        >
                          {SIDEBAR_TABS[key].title}
                        </SidebarItemTitle>
                      </TabItem>
                      {SIDEBAR_TABS[key].disabledContent ? (
                        <Tooltip
                          id={`${key}-tooltip`}
                          place="right"
                          className="custom-tooltip"
                        />
                      ) : null}
                    </div>
                  );
                })}
              </TabList>
            </SideBar>
            <Content $activeTab={activeTab}>
              {SIDEBAR_TABS[activeTab].component()}
            </Content>
          </ContentContainer>
        </Layout>
      </GlobalLayout>
    </AccountProvider>
  );
};

export default Support;

const GlobalLayout = styled.main`
  height: 100%;
`;

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: calc(100vh - 70px);
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 1.7% 0 1.8% 4.8%;
`;

const SideBarTitle = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 24px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.gray};
  margin-bottom: 7px;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 97%;
`;

const SideBar = styled.div`
  width: 176px;
  margin-top: 13px;
`;

const SidebarItemTitle = styled.div`
  position: relative;
  padding: 10px;
  cursor: pointer;
  color: ${({ theme, $disabled }) =>
    !$disabled ? theme.colors.saturated_purple : theme.colors.gray};
  &::before {
    ${(props) =>
      props["data-active"]
        ? `
          content: "";
          position: absolute;
          width: 2px;
          height: 10px;
          border-radius:10px;
          left: 3px;
          `
        : "content: none"};
    background-color: ${({ theme, $styledTab }) =>
      $styledTab !== "integrations"
        ? theme.colors.saturated_purple
        : theme.colors.gray};
  }
`;

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  font-size: 11px;
  font-weight: 700;
`;

const TabItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: ${({ $flexDirection }) =>
    $flexDirection ? $flexDirection : "row"};
  justify-content: flex-start;
  align-items: ${({ $alignItems }) => ($alignItems ? $alignItems : "center")};
  color: ${(props) => (props.disabled ? "#ccc" : props.theme.colors.gray)};
  font-weight: ${(props) => (props["data-active"] ? "700" : "400")};
`;

const Content = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.divider_color};
  width: 87.5%;
  background-color: white;
  margin: 30px 0 0 23px;
  padding: ${({ $activeTab }) =>
    $activeTab === "account" ? "0" : "43px 0 43px 63px"};
  overflow: auto;
`;
