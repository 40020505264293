import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import styled from "styled-components";

function PhoneNumberInput({ value, onChange, placeholder, disabled }) {
  return (
    <Input
      mask="+1 999 999 9999"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
    ></Input>
  );
}

PhoneNumberInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default PhoneNumberInput;

const Input = styled(InputMask)`
  margin-top: 4px !important;
  margin-bottom: 20px;
  border: 1px solid #d6ddeb;
  padding: 12px 0px 12px 16px;
  height: 38px;
  max-width: 600px;
  &:focus-visible {
    margin-top: 4px;
    margin-bottom: 20px;
    border: 1px solid #d6ddeb;
    padding: 12px 0px 12px 16px;
    height: 38px;
    max-width: 600px;
  }
  &:disabled {
    background-color: white;
  }
`;
