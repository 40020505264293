import React, { useEffect, useState } from "react";
import styled from "styled-components";

import StepButton from "../../StepButton";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { getObjectFromLocalStorage } from "../../../api/localStorage";
import ConfirmPopup from "../../ConfirmPopup";

function JobDescription({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const jobDescriptionFromStorage = {
    ...campaign.localBullhornData?.jobDescription,
  };

  const [jobDescription, setJobDescription] = useState("");
  const [publishedDescription, setPublishedDescription] = useState("");
  const [jobDescriptionKey, setJobDescriptionKey] = useState("");
  const [isCheckboxActive, setIsCheckboxActive] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);

  const [isJobDescriptionCleared, setIsJobDescriptionCleared] = useState(true);
  const [isPublishedDescriptionCleared, setIsPublishedDescriptionCleared] =
    useState(true);

  const [isJobDescriptionChanged, setIsJobDescriptionChanged] = useState(false);
  const [isPublishedDescriptionChanged, setIsPublishedDescriptionChanged] =
    useState(false);

  const handleClickBack = (event) => {
    event.preventDefault();

    const jobDescriptionChanged = isChanged();

    setCampaign((campaign) => ({
      ...campaign,
      jobDescriptionKey,
      jobDescription: {
        jobDescription,
        publishedDescription,
      },
      jobDescriptionChanged,
    }));

    handleDecreaseStep();
  };

  const handleClickNext = async (event) => {
    event.preventDefault();

    if (
      (jobDescriptionKey === "jobDescription" && jobDescription === "") ||
      (jobDescriptionKey === "publishedDescription" &&
        publishedDescription === "")
    ) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Please enter job description",
        },
      });
      handleIncreaseStep();
      return;
    }

    const jobDescriptionChanged = isChanged();

    setCampaign((campaign) => ({
      ...campaign,
      jobDescriptionKey,
      jobDescription: {
        jobDescription,
        publishedDescription,
      },
      jobDescriptionChanged,
    }));

    handleIncreaseStep();
  };

  const isChanged = () => {
    const currentTempCampaign = getObjectFromLocalStorage(
      "currentTempCampaign"
    );

    return (
      currentTempCampaign.jobDescriptionKey !== jobDescriptionKey ||
      (jobDescriptionKey === "jobDescription" && isJobDescriptionChanged) ||
      (jobDescriptionKey === "publishedDescription" &&
        isPublishedDescriptionChanged)
    );
  };

  const toggleCheckbox = (isChecked) => {
    if (
      (jobDescriptionKey === "jobDescription" && isJobDescriptionChanged) ||
      (jobDescriptionKey === "publishedDescription" &&
        isPublishedDescriptionChanged)
    ) {
      setIsConfirmPopupOpen(true);
      return;
    }

    changeDescriptionKey(isChecked);
  };

  const changeDescriptionKey = (isChecked, confirmed = false) => {
    setIsCheckboxChecked(isChecked);
    setJobDescriptionKey(isChecked ? "publishedDescription" : "jobDescription");

    if (isChecked && confirmed) {
      setJobDescription(jobDescriptionFromStorage.jobDescription);
      setIsJobDescriptionCleared(true);
    }

    if (!isChecked && confirmed) {
      setPublishedDescription(jobDescriptionFromStorage.publishedDescription);
      setIsPublishedDescriptionCleared(true);
    }

    setIsJobDescriptionChanged(false);
    setIsPublishedDescriptionChanged(false);
  };

  const confirmToggle = () => {
    changeDescriptionKey(!isCheckboxChecked, true);
    cancelToggle();
  };

  const cancelToggle = () => {
    setIsConfirmPopupOpen(false);
  };

  const handleChangeJobDescription = (value) => {
    if (isJobDescriptionCleared) {
      setIsJobDescriptionChanged(false);
      setIsJobDescriptionCleared(false);
      setJobDescription(value);
      return;
    }
    setIsJobDescriptionChanged(true);
    setJobDescription(value);
  };

  const handleChangePublishedDescription = (value) => {
    if (isPublishedDescriptionCleared) {
      setIsPublishedDescriptionChanged(false);
      setIsPublishedDescriptionCleared(false);
      setPublishedDescription(value);
      return;
    }
    setIsPublishedDescriptionChanged(true);
    setPublishedDescription(value);
  };

  useEffect(() => {
    if (campaign.jobDescriptionKey !== "") {
      setJobDescriptionKey(campaign.jobDescriptionKey);
      setJobDescription(campaign.jobDescription.jobDescription);
      setPublishedDescription(campaign.jobDescription.publishedDescription);

      const isJobDescriptionFull =
        campaign.jobDescription.jobDescription !== "" &&
        campaign.jobDescription.publishedDescription !== "";

      setIsCheckboxActive(isJobDescriptionFull);
      if (campaign.jobDescriptionKey === "jobDescription") {
        setIsCheckboxChecked(false);
      }

      return;
    }

    const isJobDescriptionFull =
      jobDescriptionFromStorage.jobDescription !== "" &&
      jobDescriptionFromStorage.publishedDescription !== "";

    setIsCheckboxActive(isJobDescriptionFull);

    const key =
      jobDescriptionFromStorage &&
      jobDescriptionFromStorage.publishedDescription !== ""
        ? "publishedDescription"
        : "jobDescription";

    if (key === "jobDescription") {
      setIsCheckboxChecked(false);
    }

    setJobDescriptionKey(key);
    setJobDescription(jobDescriptionFromStorage.jobDescription);
    setPublishedDescription(jobDescriptionFromStorage.publishedDescription);

    setCampaign((campaign) => ({
      ...campaign,
      jobDescriptionKey: key,
      jobDescription: jobDescriptionFromStorage,
      jobDescriptionChanged: false,
    }));
  }, []);

  return (
    <>
      <Section>
        {isConfirmPopupOpen && (
          <ConfirmPopup
            title="Overwrite Changes?"
            description={`You’re about to overwrite your changes with the ${
              jobDescriptionKey === "jobDescription" ? "Job" : "Published"
            } Description. Are you sure?`}
            onConfirm={confirmToggle}
            onCancel={cancelToggle}
            confirmLabel="Overwrite"
          />
        )}

        <Title>Job Description</Title>
        <InputBlock>
          <Label fontWeight="bold" htmlFor="job-description">
            Enter or modify the job description you plan to promote in the
            campaign
          </Label>

          <CheckboxBlock>
            <input
              id="jobDescriptionCheckbox"
              type="checkbox"
              checked={isCheckboxChecked}
              value={isCheckboxChecked}
              disabled={!isCheckboxActive}
              onChange={(event) => toggleCheckbox(event.target.checked)}
            />
            <NativeLabel
              htmlFor="jobDescriptionCheckbox"
              className="toggle"
              $disabled={!isCheckboxActive}
            ></NativeLabel>
            <CheckboxLabel>
              {jobDescriptionKey === "jobDescription" ? (
                <div>
                  Use <span>Job Description</span>
                </div>
              ) : (
                <div>
                  Use <span>Published Description</span>
                </div>
              )}
            </CheckboxLabel>
          </CheckboxBlock>

          <InputTextarea
            $show={jobDescriptionKey === "jobDescription"}
            id="job-description"
            placeholder="Job Description"
            value={jobDescription}
            onChange={handleChangeJobDescription}
          />

          <InputTextarea
            $show={jobDescriptionKey === "publishedDescription"}
            id="job-description"
            placeholder="Job Description"
            value={publishedDescription}
            onChange={handleChangePublishedDescription}
          />
        </InputBlock>
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton
          onClick={handleClickNext}
          disabled={
            (jobDescriptionKey === "jobDescription" && jobDescription === "") ||
            (jobDescriptionKey === "publishedDescription" &&
              publishedDescription === "")
          }
          isNext={true}
        />
      </ButtonGroup>
    </>
  );
}

export default JobDescription;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 100px);
`;

const Label = styled.label`
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 600;
  font-size: 15px;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
`;

const InputTextarea = styled(ReactQuill)`
  margin-top: 15px;
  width: 100%;
  height: calc(100vh - 315px);
  * {
    background-color: white;
  }
  ${({ $show }) => ($show ? "" : "display: none")}
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin: 10px 0 5px 0;
`;

const NativeLabel = styled.label`
  background-color: transparent !important;
  border: 1px solid ${({ $disabled }) => ($disabled ? "#c9c9c9" : "#66952a")} !important;
  &::after {
    background-color: ${({ $disabled }) =>
      $disabled ? "#c9c9c9" : "#66952a"} !important;
  }
  cursor: ${({ $disabled }) =>
    $disabled ? "not-allowed" : "pointer"} !important;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  margin-left: 10px;
  span {
    font-weight: 800;
  }
`;
