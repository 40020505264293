import React from "react";
import ConfirmPopup from "../../ConfirmPopup";

function ConfirmLaunchPopup({
  confirmLaunch,
  cancelLaunch,
  isFullSequenceCampaign,
}) {
  const description = isFullSequenceCampaign
    ? "You’re about to launch a full sequence campaign.  Are you sure you want to proceed?"
    : "You’re about to launch your campaign and begin sending messages to your contacts.  You will not have an opportunity to review these messages.  Are you sure you want to proceed?";
  return (
    <ConfirmPopup
      title="Ready to Launch?"
      description={description}
      onConfirm={confirmLaunch}
      onCancel={cancelLaunch}
    />
  );
}

export default ConfirmLaunchPopup;
