/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  fetchGetPostSignUpOnboarding,
  fetchSetPostSignUpOnboarding,
} from "../services/queries";
import Loader from "../components/Loader";
import { saveObjectToLocalStorage } from "../api/localStorage";
import Avatars from "../components/Avatars";

const PostSignUpOnboarding = () => {
  const userInfo = getUserFromLocalStorage();
  const userName = userInfo.name.split(" ")[0];
  const startStepIdx = (userInfo.postSignUpOnboarding?.steps || []).length;

  const [isShowStartScreen, setIsShowStartScreen] = useState(!startStepIdx);
  const [curStepIdx, setCurStepIdx] = useState(startStepIdx);
  const [onboardingSteps, setOnboardingSteps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userInfo?.postSignUpOnboarding?.completed !== false) {
      sendMessageToExtension({
        message: "open-campaigns",
      });
      return;
    }

    const loadPostSignUpOnboarding = async () => {
      const res = await fetchGetPostSignUpOnboarding();
      const { postSignUpOnboarding } = res;

      if (!postSignUpOnboarding) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: res.message || "Failed to get Post sign up onboarding",
          },
        });
        return;
      }

      if (!postSignUpOnboarding[curStepIdx]) {
        setCurStepIdx(postSignUpOnboarding.length - 1);
      }
      setOnboardingSteps(postSignUpOnboarding);
    };
    loadPostSignUpOnboarding();
  }, []);

  const handleClickNext = async (event) => {
    event?.preventDefault();

    setIsLoading(true);

    const res = await fetchSetPostSignUpOnboarding(
      onboardingSteps[curStepIdx].question,
      onboardingSteps[curStepIdx].answer.trim()
    );

    setIsLoading(false);

    if (!res.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.message || "Failed to set Post sign up onboarding",
        },
      });
      return;
    }

    if (res.postSignUpOnboarding?.completed !== false) {
      delete userInfo.postSignUpOnboarding;
      saveObjectToLocalStorage("user", userInfo);
      sendMessageToExtension({
        message: "open-campaigns",
      });
      return;
    }

    setCurStepIdx(curStepIdx + 1);
  };

  return (
    <main className="app">
      <Layout className="layout">
        {isShowStartScreen ? (
          <StartContent>
            <Avatars />
            <Logo src="/images/logos/full-logo-purple.png" alt="Logo" />
            <StartWelcome>Welcome {userName}!</StartWelcome>
            <StartText>Lets get started with Reach</StartText>
            <StartBtn
              onClick={() => {
                setIsShowStartScreen(false);
              }}
            >
              Get started
            </StartBtn>
            <StartInfo>
              Before you begin, we'd like to ask you a few questions about the
              way you currently run email campaigns. This will help Reach better
              serve you as you become more experienced with the product
            </StartInfo>
          </StartContent>
        ) : (
          <Content>
            {isLoading && <Loader />}
            <LogoAndHelp>
              <Logo src="/images/logos/full-logo-purple.png" alt="Logo" />
              <Link to="https://salesstream.ai" target="_blank">
                <HelpBtn>Help</HelpBtn>
              </Link>
            </LogoAndHelp>
            <Welcome>Welcome {userName}! first things first...</Welcome>
            {!!onboardingSteps?.[curStepIdx] && (
              <>
                <QuestionContent>
                  <Question>{onboardingSteps[curStepIdx].question}</Question>
                  <Answers>
                    {onboardingSteps[curStepIdx].options.map((a, i) => {
                      const withInput = a === "";
                      return (
                        <Answer
                          key={i}
                          $current={i === onboardingSteps[curStepIdx].answerIdx}
                          $withInput={withInput}
                          onClick={() => {
                            if (onboardingSteps[curStepIdx].answerIdx !== i) {
                              const newData = [...onboardingSteps];
                              if (withInput) {
                                delete newData[curStepIdx].answerIdx;
                              } else {
                                newData[curStepIdx].answerIdx = i;
                              }
                              newData[curStepIdx].answer = a;
                              setOnboardingSteps(newData);
                            }
                          }}
                        >
                          {!withInput ? (
                            a
                          ) : (
                            <input
                              placeholder="Enter"
                              value={
                                (i === onboardingSteps[curStepIdx].answerIdx
                                  ? onboardingSteps[curStepIdx].answer
                                  : null) || ""
                              }
                              onChange={(e) => {
                                const newData = [...onboardingSteps];
                                newData[curStepIdx].answerIdx = i;
                                newData[curStepIdx].answer = e.target.value;
                                setOnboardingSteps(newData);
                              }}
                              onBlur={(e) => {
                                const newData = [...onboardingSteps];
                                newData[curStepIdx].answer =
                                  e.target.value.trim();
                                if (!newData[curStepIdx].answer) {
                                  delete newData[curStepIdx].answerIdx;
                                } else {
                                  newData[curStepIdx].answerIdx = i;
                                }
                                setOnboardingSteps(newData);
                              }}
                              autoComplete="off"
                            />
                          )}
                        </Answer>
                      );
                    })}
                  </Answers>
                  <NextBtn
                    $disabled={!onboardingSteps[curStepIdx].answer?.trim()}
                    onClick={handleClickNext}
                  >
                    {curStepIdx === onboardingSteps.length - 1
                      ? "Completed"
                      : "Next"}
                  </NextBtn>
                </QuestionContent>
                <ProgressCont>
                  {onboardingSteps.map((s, i) => (
                    <ProgressItem key={i} $current={i === curStepIdx} />
                  ))}
                </ProgressCont>
              </>
            )}
          </Content>
        )}
      </Layout>
    </main>
  );
};

export default PostSignUpOnboarding;

const Layout = styled.div`
  max-width: 1160px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
`;

const StartContent = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: white;
  padding: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: relative;
`;

const StartWelcome = styled.div`
  margin-top: 43px;
  font-size: 47px;
  font-weight: 700;
  line-height: 75.2px;
  color: #5a5a5a;
`;

const StartText = styled.div`
  margin-top: 3px;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  color: #5a5a5a;
`;

const StartBtn = styled.div`
  margin-top: 34px;
  margin-bottom: 20px;
  min-height: 40px;
  height: 40px;
  width: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19.2px;
  background-color: #471168;
  color: #fff;
  ${({ $disabled }) =>
    $disabled ? "pointer-events: none; opacity: 0.75;" : "cursor: pointer;"};
`;

const StartInfo = styled.div`
  margin-top: 25px;
  max-width: 456px;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #5a5a5a;
  text-align: center;
`;

const Content = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: white;
  padding: 80px 68px 0 72px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
`;

const LogoAndHelp = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 163px;
`;

const HelpBtn = styled.div`
  padding: 9px 32px;
  border: 1px solid #696969;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  color: #696969;
  cursor: pointer;
`;

const Welcome = styled.div`
  margin-top: 20px;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
  text-align: left;
  color: #5a5a5a;
`;

const QuestionContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  overflow: auto;
`;

const Question = styled.div`
  margin-top: 80px;
  font-size: 33px;
  font-weight: 400;
  line-height: 52.8px;
  text-align: left;
  color: #5a5a5a;
`;

const Answers = styled.div`
  margin-top: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 210px);
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 25px;
  row-gap: 15px;
`;

const Answer = styled.div`
  padding: ${({ $withInput }) => ($withInput ? "0" : "27px 30px")};
  border-radius: 3px;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
  text-align: ${({ $withInput }) => ($withInput ? "left" : "center")};
  background-color: ${({ $current }) => ($current ? "#471168" : "#f9efff")};
  color: ${({ $current }) => ($current ? "#fff" : "#000")};
  cursor: pointer;
  input {
    margin: 0;
    min-width: 150px;
    width: 100%;
    height: auto;
    padding: 27px 30px;
    border-radius: 3px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    background: transparent !important;
    color: ${({ $current }) => ($current ? "#fff" : "#000")};
    &::placeholder {
      color: #a8adb7;
    }
  }
`;

const NextBtn = styled.div`
  margin-top: 102px;
  padding: 8px 19px;
  min-width: 90px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  background-color: #471168;
  color: #fff;
  ${({ $disabled }) =>
    $disabled ? "pointer-events: none; opacity: 0.75;" : "cursor: pointer;"};
`;

const ProgressCont = styled.div`
  margin-top: 20px;
  margin-left: -72px;
  margin-right: -68px;
  width: calc(100% + 72px + 68px);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  column-gap: 7px;
`;

const ProgressItem = styled.div`
  flex: 1;
  height: 10px;
  background-color: #471168;
  ${({ $current }) => ($current ? "" : "opacity: 0.4;")}
`;
