import React, { useState } from "react";
import styled from "styled-components";

import { scrollAnchors } from "../../constants";
import TabItem from "./TabItem";

const tabsList = [
  { id: 1, name: "Overview" },
  { id: 2, name: "Features" },
];

function TabList() {
  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <List>
      {tabsList.map((tab) => (
        <TabItem
          key={tab.id}
          activeItem={selectedTab === tab.id}
          href={`#${scrollAnchors[tab.name]}`}
          onClick={() => setSelectedTab(tab.id)}
          name={tab.name}
        />
      ))}
    </List>
  );
}

export default TabList;

const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;
