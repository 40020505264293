import React from "react";
import { styled } from "styled-components";

function RateCard({
  disabled,
  currentRate,
  changeRate,
  title,
  text,
  isLoading,
  $width,
  $padding,
  $withFit,
}) {
  const isCurrentRateHigher = Number(changeRate) > 0;

  return (
    <CardWrapper
      $disabled={disabled}
      $width={$width ? $width : ""}
      $padding={$padding ? $padding : ""}
      $withFit={$withFit ? $withFit : false}
    >
      <CardTitle $disabled={disabled}>{title}</CardTitle>
      <CardContent>
        {disabled ? (
          <Text $disabled={disabled}>Available after the campaign starts</Text>
        ) : isLoading ? (
          <img src="/images/ellipsis.png" alt="loader" />
        ) : text !== undefined ? (
          <Text>{text !== "" ? text : "No data"}</Text>
        ) : (
          <>
            <CurrentRate>{currentRate}%</CurrentRate>
            {changeRate !== 0 && (
              <PreviousRate $color={isCurrentRateHigher}>
                <Icon
                  src={
                    isCurrentRateHigher
                      ? "/images/rate-up-icon.png"
                      : "/images/rate-down-icon.png"
                  }
                  alt="rate-icon"
                />
                <span>{changeRate}%</span>
              </PreviousRate>
            )}
          </>
        )}
      </CardContent>
    </CardWrapper>
  );
}

export default RateCard;

const Text = styled.span`
  color: ${({ theme, $disabled }) =>
    $disabled ? "#aeaca9" : theme.colors.purple};
  font-size: ${({ $disabled }) => ($disabled ? "12px" : "16px")};
  font-weight: 300;
`;

const CardWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.rateCard_border_color};
  max-width: 330px;
  height: 130px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? "faf8f6" : theme.colors.white};
  box-shadow: 0px 0px 30px 0px #0000001a;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 10px;
  @media (min-width: ${({ $withFit }) => ($withFit ? "1210px" : "0")}) {
    min-width: 206px;
  }
`;

const Icon = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 6px;
`;

const CurrentRate = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 29px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
`;

const PreviousRate = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 400;
  color: ${({ $color, theme }) =>
    $color ? theme.colors.green : theme.colors.red};
  font-size: 11px;
`;

const CardTitle = styled.span`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 12px;
  color: ${({ theme, $disabled }) =>
    $disabled ? "#c9c6c4" : theme.colors.gray};
  width: 73%;
`;

const CardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
