import React from "react";
import styled from "styled-components";

import Tabs from "./Tabs";
import LinkButton from "./LinkButton";

function Header() {
  return (
    <Container>
      <HeaderInner>
        <ExtensionInfo>
          <ExtensionLogo
            src="images/minimal-logo-purple.png"
            alt="Item logo image for SalesStream.ai Reach"
          />
          <ExtensionName>SalesStream.ai Reach</ExtensionName>
        </ExtensionInfo>
        <LinkButton />
      </HeaderInner>
      <Tabs />
    </Container>
  );
}

export default Header;

const Container = styled.div`
  padding-top: 16px;

  @media (max-width: 768px) {
    padding-bottom: 12px;
  }
`;

const HeaderInner = styled.div`
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  display: flex;
  flex-grow: 1;
  max-width: 100%;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

const ExtensionName = styled.h1`
  color: #33475b;
  font-weight: 700;
  font-size: 32px;
`;

const ExtensionInfo = styled.div`
  align-items: center;
  display: flex;
`;

const ExtensionLogo = styled.img`
  margin-right: 12px;
  width: 85px;
`;
