import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Register from "../components/Register";
import CloseBtn from "../components/CloseBtn";

const RegisterPage = () => {
  return (
    <main className="app">
      <CloseBtn />
      <div className="layout">
        <SidePanel>
          <LogoBlock>
            <Logo src="/images/logos/full-logo-white.png" alt="Logo" />
          </LogoBlock>
          <SidePanelContent>
            <SidePanelContentTitle>
              AI superpowers for your sales team
            </SidePanelContentTitle>
            <SidePanelContentDescription>
              Seamless integration with your CRM platform empowering you to
              supercharge your outreach initiatives. Streamline your workflow
              and automatically carry out personalized outreach at scale.
            </SidePanelContentDescription>
            <SidePanelContentDescription>
              Already have an account?
            </SidePanelContentDescription>
            <Link to="/login">
              <SignUpBtn>Sign In</SignUpBtn>
            </Link>
          </SidePanelContent>
        </SidePanel>
        <Content>
          <Register />
        </Content>
      </div>
    </main>
  );
};

export default RegisterPage;

const SidePanel = styled.div`
  width: 55%;
  background: no-repeat url(log_screens_bg.png);
  background-size: cover;
  border-right: 2px solid #e6e6e6;
  padding: 15px;
`;

const LogoBlock = styled.div`
  height: 20%;
`;

const Logo = styled.img`
  width: 161px;
  margin: 40px 0 0 40px;
`;

const SidePanelContent = styled.div`
  margin: 10% 20% 0 20%;
`;

const SidePanelContentTitle = styled.div`
  font-size: 25pt;
  font-weight: 800;
  color: white;
  margin-bottom: 20px;
`;

const SidePanelContentDescription = styled.div`
  color: white;
  margin-bottom: 20px;
  font-size: 12px;
`;

const SignUpBtn = styled.button`
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  height: 50px;
  width: 170px;
  background-color: transparent;
  cursor: pointer;
`;

const Content = styled.div`
  width: 45%;
  background-color: white;
  padding: 40px;
  display: flex;
  align-items: center;
`;
