const WIZARD_SCHEME = [
  {
    id: 1,
    title: "Creating Project in Google Cloud",
    description:
      "First, Reach is creating a new Project in your Google Cloud account.",
    imageSrc: "/images/automate-gsuite-key-gen/step1.png",
  },
  {
    id: 2,
    title: "Enabling Gmail API",
    description:
      "Now, Reach is enabling the project to use the Gmail API.",
    imageSrc: "/images/automate-gsuite-key-gen/step2.png",
  },
  {
    id: 3,
    title: "Creating a service account",
    description:
      "Now, Reach is creating a service account that will have access to the new project.",
    imageSrc: "/images/automate-gsuite-key-gen/step3.png",
  },
  {
    id: 4,
    title: "Creating credentials",
    description:
      "Now that Reach has created a service account, it can create a set of credentials that can be used to interact with the Gmail API.",
    imageSrc: "/images/automate-gsuite-key-gen/step4.png",
  },
  {
    id: 5,
    title: "Setting up Domain Delegation",
    description:
      "Now, Reach is setting up Domain Delegation within your Google Workspace account.",
    imageSrc: "/images/automate-gsuite-key-gen/step5.png",
  },
  {
    id: 6,
    title: "You're almost done!",
    description:
      `You can now click "Finish" and use the 'client_email' and 'private_key' values from the file you just saved to complete your configuration.`,
    imageSrc: "/images/automate-gsuite-key-gen/step6.png",
  },
];

export { WIZARD_SCHEME };
