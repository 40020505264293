/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { AccountProvider } from "../context";
import { styled } from "styled-components";
import { sendMessageToExtension } from "../utils/postToExtension";
import { fetchGetPrepCampaigns } from "../services/queries";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  calcCampaignProgress,
  calcCampaignRemainingMinutes,
} from "../utils/utils";

const MinimizedCampaignPrep = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const campaignToMinimize = location.state?.campaignToMinimize;

  const [campaigns, setCampaigns] = useState(
    campaignToMinimize?.id
      ? [
          {
            ...campaignToMinimize,
            progress: calcCampaignProgress(campaignToMinimize),
            remainingMinutes: calcCampaignRemainingMinutes(campaignToMinimize),
          },
        ]
      : []
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const timerRef = useRef();

  const handleToggleExpand = () => {
    const expanded = !isExpanded;
    sendMessageToExtension({
      message: "minimize-campaign-prep",
      data: { expand: expanded },
    });
    setIsExpanded(expanded);
  };

  const handleMaximize = () => {
    navigate("/campaigns", {
      state: { campaignToSelect: campaigns[0] },
    });
    sendMessageToExtension({
      message: "change-iframe-window-type",
      data: {
        iframeWindowType: "drawer",
      },
    });
  };

  const loadCampaigns = async (prevCampaigns) => {
    const res = await fetchGetPrepCampaigns();
    const prepCampaigns = res?.campaigns;

    if (!prepCampaigns) {
      sendMessageToExtension({
        message: "close-iframe",
      });
      return;
    }

    const updCampaigns = [...prevCampaigns];

    updCampaigns.forEach((campaign) => {
      const prepCampaignIdx = prepCampaigns.findIndex(
        ({ id }) => id === campaign.id
      );
      const prepCampaign = prepCampaigns[prepCampaignIdx];
      if (prepCampaignIdx !== -1) {
        campaign.progress = calcCampaignProgress(prepCampaign);
        campaign.remainingMinutes = calcCampaignRemainingMinutes(prepCampaign);
        campaign.generationDurationMs = prepCampaign.generationDurationMs;
        prepCampaigns.splice(prepCampaignIdx, 1);
      } else if (campaign.progress !== 100) {
        campaign.progress = 100;
        campaign.remainingMinutes = 0;
        if (campaign.generationDurationMs < 0) {
          campaign.generationDurationMs =
            new Date().getTime() + campaign.generationDurationMs;
        }
      }
    });

    updCampaigns.push(
      ...prepCampaigns.map((campaign) => ({
        ...campaign,
        progress: calcCampaignProgress(campaign),
        remainingMinutes: calcCampaignRemainingMinutes(campaign),
      }))
    );

    setCampaigns(updCampaigns);

    timerRef.current = setTimeout(() => loadCampaigns(updCampaigns), 2000);
  };

  useEffect(() => {
    loadCampaigns(campaigns);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const isLoading = Boolean(campaignToMinimize && !campaigns?.length);
  const isAllPrepared = isLoading
    ? false
    : campaigns?.every((c) => c.progress === 100) || false;
  const avgProgress = (function () {
    if (!campaigns?.length) {
      return 0;
    }
    if (isAllPrepared) {
      return 100;
    }
    const prepCampaigns = campaigns.filter((c) => c.progress !== 100);
    return Math.round(
      prepCampaigns.reduce((a, c) => a + c.progress, 0) / prepCampaigns.length
    );
  })();
  const isSingleCampaign = campaigns?.length === 1;

  const displayRemainingMinutes = (campaign) => {
    const { remainingMinutes } = campaign;
    const text = `${
      remainingMinutes !== null ? remainingMinutes : "∞"
    } minutes`;
    return (
      <>
        {isSingleCampaign && <RemainingMinutesSep />}
        <RemainingMinutes $isSingleCampaign={isSingleCampaign}>
          <img src="/images/clock-white.svg" alt="clock" />
          {isSingleCampaign ? <span>{text}</span> : text} remaining
        </RemainingMinutes>
      </>
    );
  };

  const displayGenerationMinutes = (campaign) => {
    const { generationDurationMs } = campaign;
    if (!generationDurationMs || generationDurationMs < 0) {
      return null;
    }

    const minutes = Math.round(generationDurationMs / 60000) || 1;
    const text = `${minutes} minute${minutes === 1 ? "" : "s"}`;
    return (
      <>
        {isSingleCampaign && <RemainingMinutesSep />}
        <RemainingMinutes
          $isGenerationMinutes={true}
          $isSingleCampaign={isSingleCampaign}
        >
          {!isSingleCampaign && (
            <img src="/images/clock-white.svg" alt="clock" />
          )}
          Generated in{isSingleCampaign ? <br /> : " "}under{" "}
          {isSingleCampaign ? <span>{text}</span> : text}
        </RemainingMinutes>
      </>
    );
  };

  return (
    <AccountProvider>
      <Container>
        {!isExpanded ? (
          <CircleProgressBlock
            title="View Details"
            onClick={isLoading ? undefined : handleToggleExpand}
            $isLoading={isLoading}
          >
            <ReachLogoBlock $isAllPrepared={isAllPrepared}>
              <img
                src="/images/minimal-logo-white.png"
                alt="reach logo"
                width={isAllPrepared ? 24 : 17}
              />
            </ReachLogoBlock>
            {isAllPrepared ? (
              <CheckIcon src="/images/circle-checked-green.svg" />
            ) : (
              <CircleProgress $value={avgProgress} />
            )}
          </CircleProgressBlock>
        ) : (
          <Details
            $isAllPrepared={isAllPrepared}
            $isSingleCampaign={isSingleCampaign}
          >
            <RightLeftIcon
              src={`/images/maximize-${isAllPrepared ? "white" : "black"}.svg`}
              title="View Campaign"
              onClick={handleMaximize}
            />
            <CircleProgressBlock
              title="Hide Details"
              onClick={handleToggleExpand}
              $isExpanded={true}
              $isSingleCampaign={isSingleCampaign}
              $isAllPrepared={isAllPrepared}
            >
              <ReachLogoBlock $isAllPrepared={isAllPrepared} $isExpanded={true}>
                <img
                  src={`/images/minimal-logo-${
                    isAllPrepared ? "white" : "pink"
                  }.png`}
                  alt="reach logo"
                  width={isAllPrepared ? 24 : 31}
                />
              </ReachLogoBlock>
              {isAllPrepared ? (
                <CheckIcon src="/images/circle-checked-white.svg" />
              ) : (
                <CircleProgress $value={avgProgress} />
              )}
            </CircleProgressBlock>
            {!isAllPrepared && (
              <AvgProgress>
                <span>{avgProgress}</span>%
              </AvgProgress>
            )}
            {isSingleCampaign ? (
              <SingleCampaign $isAllPrepared={isAllPrepared}>
                <div className="sc-name">
                  <span>{campaigns[0].name}</span>{" "}
                  {isAllPrepared ? "ready to send" : "generating"}
                </div>
                {isAllPrepared
                  ? displayGenerationMinutes(campaigns[0])
                  : displayRemainingMinutes(campaigns[0])}
              </SingleCampaign>
            ) : (
              <MultiCampaigns>
                {campaigns.map((campaign, idx) => {
                  const isPrepared = campaign.progress === 100;
                  return (
                    <MultiCampaign key={idx}>
                      <MultiCampaignInfo>
                        <MultiCampaignName title={campaign.name}>
                          {campaign.name}
                        </MultiCampaignName>
                        {isPrepared
                          ? displayGenerationMinutes(campaign)
                          : displayRemainingMinutes(campaign)}
                      </MultiCampaignInfo>
                      <MultiCampaignProgress
                        $isPrepared={isPrepared}
                        $value={campaign.progress}
                      >
                        {isPrepared && (
                          <img
                            src="/images/check-white.svg"
                            alt="check"
                            width="12"
                          />
                        )}
                      </MultiCampaignProgress>
                    </MultiCampaign>
                  );
                })}
              </MultiCampaigns>
            )}
          </Details>
        )}
      </Container>
    </AccountProvider>
  );
};

export default MinimizedCampaignPrep;

const ContBoxShadow = "0px 4px 20px 0px #00000033";

const Container = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
`;

const CircleProgressBlock = styled.div`
  position: relative;
  width: ${({ $isExpanded, $isAllPrepared }) =>
    $isExpanded && !$isAllPrepared ? "114px" : "64px"};
  height: ${({ $isExpanded, $isAllPrepared }) =>
    $isExpanded && !$isAllPrepared ? "114px" : "64px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: ${({ $isLoading }) => ($isLoading ? "wait" : "pointer")};
  ${({ $isExpanded, $isAllPrepared, $isSingleCampaign }) =>
    $isExpanded
      ? `margin-top: ${$isAllPrepared ? "36px" : "28px"};
         margin-bottom: ${
           $isAllPrepared ? ($isSingleCampaign ? 0 : "25px") : "13px"
         };}
        `
      : `box-shadow: ${ContBoxShadow};`};
`;

const ReachLogoBlock = styled.div`
  position: absolute;
  width: ${({ $isAllPrepared }) => ($isAllPrepared ? "100%" : "70%")};
  height: ${({ $isAllPrepared }) => ($isAllPrepared ? "100%" : "70%")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $isExpanded, $isAllPrepared }) =>
    $isExpanded && !$isAllPrepared ? "#f5f5f5" : "#471168"};
  border-radius: 50%;
`;

const CircleProgress = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 94%, transparent 100% 100%),
    conic-gradient(#f95959 ${({ $value }) => $value}%, #e8e8e8 0);
`;

const CheckIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
`;

const RightLeftIcon = styled.img`
  position: absolute;
  top: 11px;
  right: 10px;
  width: 21px;
  cursor: pointer;
`;

const AvgProgress = styled.div`
  position: absolute;
  top: 126px;
  right: 10px;
  font-size: 17.31px;
  font-weight: 400;
  line-height: 22.97px;
  text-align: center;
  color: #471168;
  span {
    font-weight: 700;
  }
`;

const Details = styled.div`
  height: fit-content;
  width: 224px;
  border-radius: 6px;
  background-color: ${({ $isAllPrepared }) =>
    $isAllPrepared ? "#6c8a2c" : "#fff"};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  box-shadow: ${({ $isAllPrepared }) =>
      $isAllPrepared ? "" : "0 0 0 1px #d9d9d9, "}${ContBoxShadow};
`;

const RemainingMinutesSep = styled.div`
  margin: 11px auto 13px;
  padding-top: 3px;
  width: 26px;
  border-radius: 3px;
  background-color: #fff;
  opacity: 0.5;
`;

const RemainingMinutes = styled.div`
  ${({ $isGenerationMinutes, $isSingleCampaign }) =>
    $isGenerationMinutes && $isSingleCampaign
      ? ""
      : `
          display: flex;
          align-items: flex-start;
          justify-content: ${$isSingleCampaign ? "center" : "flex-start"};
          column-gap: ${$isSingleCampaign ? "5px" : "2px"};
        `};
  margin: 0 ${({ $isSingleCampaign }) => ($isSingleCampaign ? "30px" : "0")};
  font-size: ${({ $isSingleCampaign }) => ($isSingleCampaign ? "13px" : "9px")};
  font-weight: ${({ $isSingleCampaign }) =>
    $isSingleCampaign ? "400" : "300"};
  line-height: ${({ $isSingleCampaign }) =>
    $isSingleCampaign ? "15.23px" : "10.55px"};
  img {
    width: ${({ $isSingleCampaign }) => ($isSingleCampaign ? "13px" : "9px")};
  }
  ${({ $isSingleCampaign }) =>
    $isSingleCampaign
      ? `
          span {
            font-weight: 700;
            white-space: nowrap;
          }
        `
      : ""};
`;

const SingleCampaign = styled.div`
  padding-bottom: 26px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ $isAllPrepared }) =>
    $isAllPrepared ? "#6c8a2c" : "#471168"};
  border-radius: 0 0 6px 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  span {
    font-weight: 700;
  }
  * {
    text-align: center;
  }
  .sc-name {
    margin: 20px auto 0;
    width: calc(100% - 48px);
    span {
      word-wrap: break-word;
    }
  }
`;

const MultiCampaigns = styled.div`
  flex: 1;
  width: 100%;
  max-height: 140px;
  border-radius: 0 0 6px 6px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MultiCampaign = styled.div`
  padding: 10px 17px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 10px;
  color: #fff;
  &:nth-child(3n-2) {
    background-color: #471168;
  }
  &:nth-child(3n-1) {
    background-color: #7932a4;
  }
  &:nth-child(3n) {
    background-color: #9a50c7;
  }
`;

const MultiCampaignInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 3px;
  max-width: calc(100% - 35px - 18px);
`;

const MultiCampaignName = styled.div`
  max-width: 100%;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const MultiCampaignProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  ${({ $isPrepared, $value }) =>
    $isPrepared
      ? ""
      : `border: 1px solid #fff;
         background: conic-gradient(#fff ${$value}%, transparent 0);
        `}
`;
