import React from "react";
import styled from "styled-components";

import TabList from "./TabList";

function Tabs() {
  return (
    <Container>
      <TabList />
    </Container>
  );
}

export default Tabs;

const Container = styled.div`
  margin-top: 12px;

  @media (max-width: 768px) {
    display: none;
  }
`;
