import React, { useCallback, useState } from "react";
import { styled } from "styled-components";

import ConnectBtn from "../../ConnectBtn";
import SampleList from "./Samples/SampleList";

const SampleFormPopup = ({ sample, promptsTokens, handleClose, onSave }) => {
  const [name, setName] = useState(sample.name);
  const [fields, setSequence] = useState(sample.fields);

  const [nameErrorMessage, setNameErrorMessage] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const handleUpdateSample = async (e) => {
    e.preventDefault();

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setNameErrorMessage("");
    onSave({ id: sample.id, name, fields });
  };

  const isFormValid = () => {
    let isNameValid = true;
    let isFieldsChanged = false;

    if (!name || name === "") {
      setNameErrorMessage("Please enter sample name");
      isNameValid = false;
    }

    if (!fields?.length !== sample.fields) {
      isFieldsChanged = true;
    }

    if (isFieldsChanged === false && fields?.length && sample.fields) {
      for (let i = 0; i < fields?.length; i++) {
        if (fields[i].role !== sample.fields[i].role) {
          isFieldsChanged = false;
          break;
        }

        if (fields[i].content !== sample.fields[i].content) {
          isFieldsChanged = false;
          break;
        }
      }
    }

    if (name && name === sample.name && !isFieldsChanged) {
      setErrorMessage("No changes made");
      return false;
    }

    if (!isNameValid) {
      return false;
    }

    return true;
  };

  const onChangeList = useCallback((items) => {
    setSequence(items);
  }, []);

  return (
    <UpdateSampleContainer>
      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          x
        </CloseIcon>

        <Form onSubmit={handleUpdateSample}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="email">Name</Label>
              <Relative>
                <Input
                  id="name"
                  placeholder="Sample name..."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onInput={(e) => setNameErrorMessage("")}
                  autoComplete="off"
                />
                {nameErrorMessage && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {nameErrorMessage && (
                <ErrorMessage>{nameErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
            <SampleList
              items={sample.fields}
              promptsTokens={promptsTokens}
              onChange={onChangeList}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </Inputs>

          <Actions>
            <ConnectBtn type="submit" label={sample.id ? "Update" : "Create"} />
          </Actions>
        </Form>
      </Content>
    </UpdateSampleContainer>
  );
};

export default SampleFormPopup;

const UpdateSampleContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;
const Content = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
`;

const CloseIcon = styled.span`
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;
