import React from "react";

import AutomateHubspotAPIWizard from "../components/AutomateHubspotAPI";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

function AutomateHubspotAPI() {
  return (
    <>
      <CheckExtensionVersion />
      <AutomateHubspotAPIWizard />
    </>
  );
}

export default AutomateHubspotAPI;
