import React, { useState, useEffect, useRef } from "react";
import { styled } from "styled-components";
import {
  getCampaign,
  updateCampaignStatus,
  getPreviewsGenerationStatus,
  stopGenerateMessagePreviews,
} from "../../../services/campaigns";
import { getCandidates } from "../../../services/candidates";
import TitleBlock from "./TitleBlock";
import RateCard from "../RateCard";
import TableFilters from "../TableFilter";
import CandidateTable from "./CandidateTable";
import Loader from "../../Loader";
import { debounce } from "lodash";
import InfiniteScroll from "../../InfiniteScroll";
import Spinner from "../../Spinner";
import PreviewMessage from "../../CreateCampaign/ContentSteps/PreviewMessage";
import { Tooltip } from "react-tooltip";

import { CAMPAIGN_UPDATE_STATUSES } from "../constants";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  isObjectEmpty,
} from "../../../utils/constants";

import { getObjectFromLocalStorage } from "../../../api/localStorage";
import PreparingScreen from "./PreparingScreen";
import ConfirmPopup from "../../ConfirmPopup";
import {
  calcCampaignProgress,
  calcCampaignRemainingMinutes,
} from "../../../utils/utils";
import { getIntegrations } from "../../../services/integrations";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

const FilterBarStatuses = {
  read: { value: "read", label: "Open" },
  multiRead: { value: "multiRead", label: "Multiple Reads" },
  completed: { value: "completed", label: "Completed" },
  answered: { value: "answered", label: "Responded" },
  unsubscribed: { value: "unsubscribed", label: "Unsubscribed" },
  bounced: { value: "bounced", label: "Bounced" },
};

const CampaignDescription = ({
  campaignId,
  isCreatingCampaign,
  onClose,
  setCampaignBeingPrepared,
}) => {
  const parentRef = useRef(null);
  const [campaign, setCampaign] = useState({});
  const [campaignStatisticList, setCampaignStatisticList] = useState({});
  const [campaignMessageStats, setCampaignMessageStats] = useState(null);
  const [
    fullSequenceCampaignStepStatuses,
    setFullSequenceCampaignStepStatuses,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(false);
  const [isCandidatesLoading, setIsCandidatesLoading] = useState(true);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isPreparing, setIsPreparing] = useState(false);
  const [progress, setProgress] = useState(2);
  const [remainingMinutes, setRemainingMinutes] = useState(null);
  const [isArchivePopupOpened, setIsArchivePopupOpened] = useState(false);

  const [isPauseStatusLoading, setIsPauseStatusLoading] = useState(false);
  const [isArchiveStatusLoading, setIsArchiveStatusLoading] = useState(false);
  const [isMarkCompletedLoading, setIsMarkCompletedLoading] = useState(false);
  const [isBeginCampaignLoading, setIsBeginCampaignLoading] = useState(false);

  const [candidatesList, setCandidatesList] = useState([]);
  const [candidatesForReview, setCandidatesForReview] = useState([]);
  const [relevantStatuses, setRelevantStatuses] = useState([]);

  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState({ sort: null, order: null });
  const [messageStatsFilters, setMessageStatsFilters] = useState([]);

  const timerRef = useRef();

  let prepareStart = 0;

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.preparedCampaignDetails,
    parentRef,
  };

  const checkIsPreparedCampaign = async () => {
    const previewsGenerationStatus = await getPreviewsGenerationStatus({
      campaignId,
    });

    if (
      previewsGenerationStatus.success &&
      !previewsGenerationStatus.result.inProgress
    ) {
      refreshCandidates();
      setCampaignBeingPrepared(null);
      setIsPreparing(false);
      setCampaign((c) => ({ ...c, isNeedToGenerateMessagePreviews: false }));
      console.log(
        `Campaign preparation time: ${Math.round(
          (new Date() - prepareStart) / 1000
        )}secs`
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsPreparing(true);
      setProgress(calcCampaignProgress(previewsGenerationStatus.result));
      setRemainingMinutes(
        calcCampaignRemainingMinutes(previewsGenerationStatus.result)
      );
      timerRef.current = setTimeout(checkIsPreparedCampaign, 2000);
    }
  };

  const fetchCampaign = async () => {
    const data = await getCampaign(campaignId);

    if (data.success !== undefined && !data.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch campaign",
        },
      });
      onClose();
      return;
    }

    setCampaign(data.result.campaign);
    setCampaignStatisticList(data.result.rates);
    setCampaignMessageStats(data.result.messageStats);
    setFullSequenceCampaignStepStatuses(data.result.fscStepStatuses || []);

    setIsPauseStatusLoading(false);
    setIsMarkCompletedLoading(false);

    const { status, isNeedToGenerateMessagePreviews } = data.result.campaign;
    if (
      [
        CAMPAIGN_UPDATE_STATUSES.DRAFT,
        CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH,
      ].includes(status) &&
      isNeedToGenerateMessagePreviews
    ) {
      prepareStart = new Date();
      timerRef.current = setTimeout(checkIsPreparedCampaign, 2000);
      setCampaignBeingPrepared(data.result.campaign);
      return;
    }
    setIsLoading(false);
    setCampaignBeingPrepared(null);
  };

  const fetchAllCandidatesForReview = async () => {
    if (isCandidatesLoading || !!campaign.isNeedToGenerateMessagePreviews) {
      return;
    }

    const result = await fetchCandidates({ fetchAll: true, forReview: true });

    if (!result.success) {
      return;
    }

    setCandidatesForReview(result.result.contacts);
    setIsPreviewMode(true);
  };

  const fetchCandidates = async (params) => {
    const data = {
      campaignId,
      forReview: params?.forReview,
      offset:
        params?.clearAfterSearch || params?.isSort || params?.fetchAll
          ? 0
          : offset,
      query:
        params?.currentSearchQuery !== undefined
          ? params.currentSearchQuery
          : query,
    };

    if (params?.fetchAll) {
      data.limit = campaign.contactCount;
    }

    if ((params?.isSort || sort.sort) && !params?.fetchAll) {
      data.sort = params?.isSort ? params?.newSort : sort.sort;
      data.order = params?.isSort ? params?.newOrder : sort.order;
    }

    if (params?.filters || Object.keys(filters).length > 0) {
      data.filters = params?.filters ? params?.filters : filters;
    }

    if (params?.messageStatsFilters?.length || messageStatsFilters?.length) {
      data.filters = {
        ...data.filters,
        messageStatuses: params?.messageStatsFilters || messageStatsFilters,
      };
    }

    if (!data.limit) {
      data.limit = Math.floor(window.innerHeight / 50);
    }

    const result = await getCandidates(data);

    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch candidates",
        },
      });
      onClose();
      setIsCandidatesLoading(false);
      return result;
    }

    setRelevantStatuses(result.result.meta.statuses);

    if (params?.fetchAll) {
      setIsCandidatesLoading(false);
      return result;
    }

    setDataLength(
      params?.clearAfterSearch
        ? result.result.contacts.length
        : dataLength + result.result.contacts.length
    );

    setHasMore(result.result.meta.hasMore);

    if (result.result.meta.hasMore) {
      setOffset(
        params?.clearAfterSearch || params?.isSort
          ? data.limit
          : offset + data.limit
      );
    }

    setIsCandidatesLoading(false);

    return result;
  };

  const fetchCandidatesOnMount = async () => {
    const result = await fetchCandidates();

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchCandidatesByQuery = async (currentSearchQuery) => {
    const result = await fetchCandidates({
      currentSearchQuery,
      clearAfterSearch: true,
    });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchCandidatesByScroll = async () => {
    const result = await fetchCandidates();

    if (result.success) {
      setCandidatesList([...candidatesList, ...result.result.contacts]);
    }
  };

  const fetchCandidatesBySort = async ({ newSort, newOrder }) => {
    setSort({ sort: newSort, order: newOrder });

    const result = await fetchCandidates({ isSort: true, newSort, newOrder });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchCandidatesByFilters = async (selectedFilters) => {
    setFilters(selectedFilters);
    const result = await fetchCandidates({
      filters: selectedFilters,
      clearAfterSearch: true,
    });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchUpdateCampaignStatus = async (status) => {
    const result = await updateCampaignStatus({ campaignId, status });
    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to change status",
        },
      });
      setIsLoading(false);

      setIsPauseStatusLoading(false);
      setIsMarkCompletedLoading(false);
      return;
    }

    const response = await fetchCandidates({ isSort: true });

    if (response.success) {
      setCandidatesList(response.result.contacts);
    }

    fetchCampaign();
  };

  const onCancelPrepareCampaign = async () => {
    setIsLoading(true);
    const result = await stopGenerateMessagePreviews({ campaignId });

    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to stop generation",
        },
      });
    }
  };

  const handleSearch = debounce((event) => {
    setQuery(event.target.value);
    fetchCandidatesByQuery(event.target.value);
  }, 500);

  const refreshCandidates = async () => {
    const result = await fetchCandidates({ clearAfterSearch: true });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }

    fetchCampaign();
  };

  const togglePauseStatus = async () => {
    if (isPauseStatusLoading) {
      return;
    }
    setIsPauseStatusLoading(true);
    await fetchUpdateCampaignStatus(
      campaign.status === CAMPAIGN_UPDATE_STATUSES.PAUSED
        ? CAMPAIGN_UPDATE_STATUSES.RESUME
        : CAMPAIGN_UPDATE_STATUSES.PAUSED
    );
  };

  const archiveCampaign = async () => {
    if (isArchiveStatusLoading) {
      return;
    }
    setIsArchiveStatusLoading(true);
    await fetchUpdateCampaignStatus(CAMPAIGN_UPDATE_STATUSES.ARCHIVED);
    onClose();
  };

  const markCampaignCompleted = async () => {
    if (isMarkCompletedLoading) {
      return;
    }
    setIsMarkCompletedLoading(true);
    await fetchUpdateCampaignStatus(CAMPAIGN_UPDATE_STATUSES.COMPLETED);
  };

  const beginCampaign = async () => {
    if (isBeginCampaignLoading) {
      return;
    }
    setIsBeginCampaignLoading(true);

    const message = await validateIntegrations();

    if (message) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message,
        },
      });
      setIsBeginCampaignLoading(false);

      return;
    }

    await fetchUpdateCampaignStatus(CAMPAIGN_UPDATE_STATUSES.START);
  };

  const validateIntegrations = async () => {
    setIsLoadingIntegration(true);
    let errorMessage = "";
    const isBullhornCrm = campaign.crmType === CRMS.BULLHORN;
    const isHubspotCrm = campaign.crmType === CRMS.HUBSPOT;
    const integrations = await getIntegrations();

    if (!integrations.integration || !integrations.integration.crm) {
      errorMessage = "No integration found";
    } else {
      const integrationNames = [];

      const isNotBullhornIntegrationApplicable =
        (!integrations.integration.crm.bullhorn ||
          isObjectEmpty(integrations.integration.crm.bullhorn) ||
          integrations.integration.crm.bullhorn.expired) &&
        isBullhornCrm;

      if (isNotBullhornIntegrationApplicable) {
        integrationNames.push("Bullhorn");
      }

      const isNotHubspotIntegrationApplicable =
        (!integrations.integration.crm.hubspot ||
          isObjectEmpty(integrations.integration.crm.hubspot) ||
          integrations.integration.crm.hubspot.expired) &&
        isHubspotCrm;

      if (isNotHubspotIntegrationApplicable) {
        integrationNames.push("HubSpot");
      }

      const isNotEmailIntegrationApplicable =
        !integrations.integration.email ||
        isObjectEmpty(integrations.integration.email) ||
        integrations.integration.email.expired;

      if (isNotEmailIntegrationApplicable) {
        integrationNames.push("email");
      }

      if (integrationNames.length) {
        errorMessage = `Could not create campaign due to invalid ${integrationNames.join(
          ", "
        )} integration.  Please update integration settings and try again`;
      }
    }
    setIsLoadingIntegration(false);
    return errorMessage;
  };

  const handleClickFilterBarItem = async (e, k) => {
    e?.preventDefault();

    const selectedFilters = messageStatsFilters.includes(k)
      ? messageStatsFilters.filter((f) => f !== k)
      : [k]; // [...messageStatsFilters, k];
    setMessageStatsFilters(selectedFilters);

    const result = await fetchCandidates({
      messageStatsFilters: selectedFilters,
      clearAfterSearch: true,
    });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  useEffect(() => {
    if (isCreatingCampaign) {
      const preparedCampaign = getObjectFromLocalStorage("preparedFields");
      if (!preparedCampaign.isFullSequenceCampaign) {
        preparedCampaign.status = CAMPAIGN_UPDATE_STATUSES.GENERATING;
        preparedCampaign.isNeedToGenerateMessagePreviews = true;
        preparedCampaign.createdAt = new Date().toISOString();
        if (!preparedCampaign.id) {
          preparedCampaign.id = campaignId;
        }
        setCampaignBeingPrepared(preparedCampaign);
        setIsLoading(false);
        setIsPreparing(true);
        setCampaign(preparedCampaign);
        setRemainingMinutes(calcCampaignRemainingMinutes(preparedCampaign));
      }
    }
    if (campaignId && !isCreatingCampaign) {
      if (!isPreparing) {
        setIsLoading(true);
      }
      fetchCampaign();
      fetchCandidatesOnMount();
    }
  }, [campaignId, isCreatingCampaign]);

  return (
    <>
      {isLoadingIntegration && <Loader zIndex={11} />}
      {isArchivePopupOpened && (
        <ConfirmPopup
          title="Archive Campaign?"
          description={`Are you sure you want to archive this campaign?`}
          onConfirm={archiveCampaign}
          onCancel={() => setIsArchivePopupOpened(false)}
          confirmLabel="Archive"
        />
      )}
      {isLoading ? (
        <Loader
          parentSize={true}
          bgColor="#F5F2EE"
          height="calc(100% - 70px)"
        />
      ) : !isCreatingCampaign && isPreviewMode ? (
        <PreviewMessage
          campaign={campaign}
          candidates={candidatesForReview}
          isCreatedDraftCampaign={true}
          handleClose={() => setIsPreviewMode(false)}
        />
      ) : isPreparing ? (
        <Layout>
          <BlankContainer>
            <HeaderTitleBlock>
              <ArrowBackIconBlock
                onClick={() => (!isCreatingCampaign ? onClose() : null)}
                $disabled={isCreatingCampaign}
              >
                <img
                  src="/images/arrow-campaign-description.svg"
                  alt="arrow-left-icon"
                />
              </ArrowBackIconBlock>
              <TitleBlock campaign={campaign} />
            </HeaderTitleBlock>
            <PreparingScreen
              crm={campaign.crmType}
              isCancelBtnDisabled={isCreatingCampaign}
              progress={progress || 2}
              remainingMinutes={
                remainingMinutes !== null
                  ? remainingMinutes === 0
                    ? `< 1`
                    : remainingMinutes
                  : "∞"
              }
              onCancelPrepareCampaign={onCancelPrepareCampaign}
            />
          </BlankContainer>
        </Layout>
      ) : (
        <Layout>
          <Container>
            <HeaderTitleBlock>
              <ArrowBackIconBlock onClick={onClose}>
                <img
                  src="/images/arrow-campaign-description.svg"
                  alt="arrow-left-icon"
                />
              </ArrowBackIconBlock>
              <TitleBlock campaign={campaign} />
            </HeaderTitleBlock>
            {!campaign.isArchived && (
              <CampaignActionsButtons>
                <OutlineCampaignBtn
                  disabled={isArchiveStatusLoading}
                  onClick={() => setIsArchivePopupOpened(true)}
                >
                  Archive Campaign
                </OutlineCampaignBtn>
                {campaign.status !== CAMPAIGN_UPDATE_STATUSES.COMPLETED &&
                  campaign.status !==
                    CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH && (
                    <>
                      <OutlineCampaignBtn
                        disabled={isPauseStatusLoading}
                        onClick={togglePauseStatus}
                      >
                        {campaign.status === CAMPAIGN_UPDATE_STATUSES.PAUSED
                          ? "Resume Campaign"
                          : "Pause Campaign"}
                      </OutlineCampaignBtn>
                      <MarkCampaignCompletedBtn
                        disabled={isMarkCompletedLoading}
                        onClick={markCampaignCompleted}
                      >
                        Stop Campaign
                      </MarkCampaignCompletedBtn>
                    </>
                  )}

                {campaign.status ===
                  CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH && (
                  <>
                    <ReviewBtn
                      $disabled={
                        isCandidatesLoading ||
                        !!campaign.isNeedToGenerateMessagePreviews
                      }
                      onClick={fetchAllCandidatesForReview}
                      data-tooltip-id="review-tooltip"
                      data-tooltip-content="Please wait. The campaign is still being generated"
                    >
                      Review
                    </ReviewBtn>
                    {(isCandidatesLoading ||
                      !!campaign.isNeedToGenerateMessagePreviews) && (
                      <Tooltip
                        id="review-tooltip"
                        place="left"
                        className="custom-tooltip"
                      />
                    )}
                    <MarkCampaignCompletedBtn
                      disabled={isBeginCampaignLoading}
                      onClick={beginCampaign}
                    >
                      Launch Campaign
                    </MarkCampaignCompletedBtn>
                  </>
                )}
              </CampaignActionsButtons>
            )}
          </Container>
          <Block>
            <CardContainer>
              <RateCard
                disabled={
                  campaign.status === CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH
                }
                $width="19.3%"
                $padding="15px 18.8px 15px 18px"
                $withFit={true}
                title="Open rate"
                currentRate={campaignStatisticList?.openRate || 0}
                changeRate={campaignStatisticList?.changeOpenRate || 0}
                isLoading={isLoading}
              />
              <RateCard
                disabled={
                  campaign.status === CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH
                }
                $width="19.3%"
                $padding="15px 19px 14.5px 18px"
                $withFit={true}
                title="Engagement rate"
                currentRate={campaignStatisticList?.engagementRate || 0}
                changeRate={campaignStatisticList?.changeEngagementRate || 0}
                isLoading={isLoading}
              />
              <CampaignCard $width="27.4%" $padding="1.8% 2%">
                <Flex $alignItem="flex-start">
                  <Icon
                    $marginRight="14px"
                    src="/images/Goal-campaigns-icon.png"
                    alt="goal-icon"
                  />
                  <CampaignCardTitle>Campaign goal</CampaignCardTitle>
                </Flex>
                <CampaignCardContent $paddingLeft="31px">
                  {campaign.goal}
                </CampaignCardContent>
              </CampaignCard>
              <CampaignCard
                $width="30.8%"
                $noMargin={true}
                $padding="1.8% 3.2% 1.8% 2.2%"
              >
                <Flex $alignItem="flex-start">
                  <Icon
                    $marginRight="14px"
                    src="/images/Crown-icon.png"
                    alt="crown-icon"
                  />
                  <CampaignCardTitle>Campaign strategy</CampaignCardTitle>
                </Flex>
                <CampaignCardContent $paddingLeft="37px">
                  {campaign.strategy}
                </CampaignCardContent>
              </CampaignCard>
            </CardContainer>

            {!!campaignMessageStats && (
              <FilterBar>
                <FbTotal>
                  <img src="/images/sms-tracking.png" alt="" />
                  <span>
                    Total <strong>Emails</strong> SENT{" "}
                    <strong>{campaignMessageStats.sent.value}</strong>
                  </span>
                </FbTotal>
                {Object.entries(FilterBarStatuses).map(([k, v]) => {
                  const value = campaignMessageStats[k].value;
                  const msgProgress = campaignMessageStats[k].progress;
                  // const msgChange = campaignMessageStats[k].change;
                  return (
                    <FbItem
                      key={k}
                      $type={k}
                      $isActive={messageStatsFilters.includes(k)}
                      $progress={msgProgress}
                      $isChangeHigher={Number(msgProgress) > 0}
                      $disabled={!value && !messageStatsFilters.includes(k)}
                      onClick={(e) => handleClickFilterBarItem(e, k)}
                    >
                      <div className="fb-item-title">{v.label}</div>
                      <div className="fb-item-content">
                        <div className="fb-item-value">{value}</div>
                        {!!+msgProgress && (
                          <div className="fb-item-change">
                            <svg
                              width="15"
                              height="7"
                              viewBox="0 0 15 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.54223 0.137696L0.576645 6.1377L14.5078 6.1377L7.54223 0.137696Z"
                                fill="#DD3C3C"
                              />
                            </svg>
                            <span>{msgProgress}%</span>
                          </div>
                        )}
                      </div>
                      <div className="fb-item-progress" />
                    </FbItem>
                  );
                })}
              </FilterBar>
            )}

            <Table
              ref={parentRef}
              $height={
                isCandidatesLoading ||
                !!campaign.isNeedToGenerateMessagePreviews
                  ? "calc(100% - 229px)"
                  : undefined
              }
            >
              <FirstTimeCampaignOnboarding
                config={firstTimeCampaignOnboardingConfig}
              />
              <TableControls>
                <Flex>
                  {campaign.crmType === CRMS.HUBSPOT ? (
                    <Icon
                      $margin="0 19.5% 0 20px"
                      $width="125px"
                      $height="37px"
                      src="/images/hubspot-logo.svg"
                      alt="hubspot-logo"
                    />
                  ) : (
                    <Icon
                      $padding="2.8% 19.5% 7.8% 7.9%"
                      $width="125px"
                      $translateY="-5px"
                      src="/images/bullhorn-logo.png"
                      alt="bullhorn-logo"
                    />
                  )}
                  <CandidateCounterText>
                    <Text>
                      {campaign.contactCount || campaign.contacts.length}
                    </Text>
                    &nbsp;Recipients
                  </CandidateCounterText>
                </Flex>
                <ButtonBlock>
                  <TableFilters
                    fields={[
                      {
                        type: "text",
                        inputs: [
                          {
                            key: "contactName",
                            label: "Candidate name",
                          },
                          {
                            key: "email",
                            label: "Email address",
                          },
                        ],
                      },
                      {
                        type: "select",
                        title: "Status",
                        inputs: [
                          {
                            key: "status",
                            relevantValues: relevantStatuses,
                          },
                        ],
                      },
                    ]}
                    load={fetchCandidatesByFilters}
                  />
                  <Search>
                    <SearchInput
                      type="text"
                      onChange={handleSearch}
                      placeholder="Search"
                    />
                    <SearchIcon src="/images/search-icon.png" />
                  </Search>
                  <RefreshButton onClick={refreshCandidates}>
                    Refresh
                  </RefreshButton>
                </ButtonBlock>
              </TableControls>
              {isCandidatesLoading ? (
                <Loader parentSize={true} bgColor="white" padding="20px 0" />
              ) : (
                <InfiniteScroll
                  dataLength={dataLength}
                  next={fetchCandidatesByScroll}
                  hasMore={hasMore}
                  height="calc(100vh - 428px)"
                  loader={
                    <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                  }
                >
                  <TableContainer>
                    <CandidateTable
                      candidateList={candidatesList}
                      isFollowUpsAsReplies={campaign.isFollowUpsAsReplies}
                      sort={sort}
                      setSort={(sort) => fetchCandidatesBySort(sort)}
                      onStatusChanged={refreshCandidates}
                      fullSequenceCampaignStepStatuses={
                        fullSequenceCampaignStepStatuses
                      }
                    />
                  </TableContainer>
                </InfiniteScroll>
              )}
            </Table>
          </Block>
        </Layout>
      )}
    </>
  );
};

export default CampaignDescription;

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  height: calc(100vh - 70px);
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.background_color};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 20px 30px;
`;

const BlankContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.background_color};
  padding: 20px 30px;
`;

const Text = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.black};
`;

const CandidateCounterText = styled.div`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 300;
  &::before {
    content: "";
    position: absolute;
    left: -24.2%;
    top: 0;
    height: 113%;
    width: 1.5px;
    background-color: ${({ theme }) => theme.colors.divider_color};
  }
`;

const TableContainer = styled.div`
  position: relative;
`;

const Table = styled.div`
  margin-top: 15px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  border-radius: 5px;
  max-height: calc(100% - 145px);
  min-width: 626px;
  background-color: #fff;
  ${({ $height }) => $height && `height: ${$height};`}
  position: relative;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const RefreshButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  width: 75px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.btn_border_color};
  color: ${({ theme }) => theme.colors.gray};
`;

const TableControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 88px 0;
  height: calc(100vh - 178px);
`;

const Flex = styled.div`
  display: flex;
  align-items: ${({ $alignItem }) => ($alignItem ? $alignItem : "center")};
  font-size: 12px;
`;

const CardContainer = styled.div`
  display: flex;
  height: fit-content;
  min-height: 130px;
  min-width: 626px;
`;

const CampaignCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.rateCard_border_color};
  height: 130px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 30px 0px #0000001a;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  margin-right: ${({ $noMargin }) => ($noMargin ? "0" : "10px")};
  @media (min-width: 1210px) {
    min-width: 206px;
  }
`;

const CampaignCardTitle = styled.span`
  font-size: 12px;
  font-weight: 800;
  width: 30%;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const CampaignCardContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  ${({ $paddingLeft }) =>
    $paddingLeft ? `padding-left:${$paddingLeft}` : " "};

  &:hover {
    -webkit-line-clamp: initial;
    overflow: visible;
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.white};
    padding-bottom: 10px;
  }
`;

const ArrowBackIconBlock = styled.div`
  position: relative;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  margin-right: 40px;
  width: 20px;
  height: 17px;
  &::after {
    content: "";
    cursor: default;
    position: absolute;
    right: -90%;
    top: -50%;
    height: 219%;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.divider_color};
  }
`;

const Icon = styled.img`
  ${({ $translateY }) =>
    $translateY ? `transform: translateY(${$translateY})` : ""};
  ${({ $width }) => ($width ? `width:${$width}` : "")};
  ${({ $height }) => ($height ? `height:${$height}` : "")};
  ${({ $padding }) => ($padding ? `padding:${$padding}` : "")};
  ${({ $cursor }) => ($cursor ? `cursor:${$cursor}` : ` `)};
  ${({ $margin }) => ($margin ? `margin:${$margin}` : "")};
  ${({ $marginRight }) => ($marginRight ? `margin-right:${$marginRight}` : "")};
`;

const OutlineCampaignBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const ReviewBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ $disabled }) => ($disabled ? "0.5" : "1")};
`;

const MarkCampaignCompletedBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #ffffff;
  background-color: ${({ disabled }) =>
    disabled ? "rgb(71,17,104,0.75)" : "rgb(71,17,104)"};

  height: 40px;
  width: 138px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #471168;
`;

const Search = styled.div`
  position: relative;
  margin: 23px 20px 23px 40px;
`;

const SearchInput = styled.input`
  margin: 0;
  height: 38px;
  width: 256px;
  padding-left: 30px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  &:focus-visible {
    margin: 0;
    height: 38px;
    width: 256px;
    padding-left: 30px;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 13px;
  height: 13px;
`;

const CampaignActionsButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding-top: 9px;
`;

const HeaderTitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const FilterBar = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 10px 0px #0000001a;
  height: fit-content;
  min-height: 68px;
  min-width: 626px;
  overflow-x: auto;
  overflow-y: hidden;
  + div {
    margin-top: 0;
  }
`;

const FbTotal = styled.div`
  padding: 22px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 7px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  text-transform: uppercase;

  img {
    width: 24px;
    height: auto;
  }

  strong {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-weight: 700;
  }
`;

const FbItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100%;
  min-width: 122px;
  border-left: 1px solid #e5e5e5;
  background-color: ${({ $type }) => {
    if (
      $type === FilterBarStatuses.unsubscribed.value ||
      $type === FilterBarStatuses.bounced.value
    ) {
      return "#ffecec";
    }
    if ($type === FilterBarStatuses.answered.value) {
      return "#f0f0f0";
    }
    return "#fff";
  }};
  ${({ $disabled }) =>
    $disabled ? "pointer-events: none;" : "cursor: pointer;"};

  .fb-item-title {
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 22px;
    ${({ $isActive }) =>
      $isActive ? `font-family: "AlbertSansExtraBold", sans-serif;` : ""};
    font-weight: ${({ $isActive }) => ($isActive ? "700" : "400")};
    font-size: 12px;
    line-height: 14.4px;
    text-align: left;
    color: ${({ $isActive }) => ($isActive ? "#000" : "#696969")};
  }

  ${({ $isActive }) =>
    $isActive
      ? ""
      : `
          &:hover {
            .fb-item-title {
              text-shadow: 0 0 black;
            }
          }
  `};

  .fb-item-content {
    padding-left: 10px;
    padding-right: 22px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    .fb-item-value {
      font-family: "AlbertSansExtraBold", sans-serif;
      font-size: 16px;
      font-weight: 900;
      line-height: 19.2px;
      text-align: left;
      color: ${({ $isActive }) => ($isActive ? "#000" : "#838383")};
    }

    .fb-item-change {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      column-gap: 1px;

      svg {
        ${({ $type }) =>
          $type === FilterBarStatuses.answered.value ||
          $type === FilterBarStatuses.completed.value
            ? `display: none`
            : ""};
        width: 14px;
        height: 6px;
        ${({ $isChangeHigher }) =>
          $isChangeHigher ? "" : "transform: rotate(180deg);"}
        path {
          fill: ${({ $type }) => {
            if (
              $type === FilterBarStatuses.unsubscribed.value ||
              $type === FilterBarStatuses.bounced.value
            ) {
              return "#DD3C3C";
            }
            if ($type === FilterBarStatuses.answered.value) {
              return "#6C8A2C";
            }
            if ($type === FilterBarStatuses.read.value) {
              return "#EEEEEE";
            }
            return "#E6E6E6";
          }};
        }
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 13.44px;
        text-align: left;
        color: ${({ $type }) => {
          if (
            $type === FilterBarStatuses.unsubscribed.value ||
            $type === FilterBarStatuses.bounced.value
          ) {
            return "#DD3C3C";
          }
          if ($type === FilterBarStatuses.answered.value) {
            return "#6C8A2C";
          }
          return "#696969";
        }};
      }
    }
  }

  .fb-item-progress {
    height: 4.7px;
    width: ${({ $progress }) => `${$progress || 0}%`};
    background-color: ${({ $type }) => {
      if (
        $type === FilterBarStatuses.unsubscribed.value ||
        $type === FilterBarStatuses.bounced.value
      ) {
        return "#DD3C3C";
      }
      if ($type === FilterBarStatuses.answered.value) {
        return "#B5A0C3";
      }
      if ($type === FilterBarStatuses.completed.value) {
        return "#B566E6";
      }
      if ($type === FilterBarStatuses.multiRead.value) {
        return "#691999";
      }
      return "#471168";
    }};
  }
`;
