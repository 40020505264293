import React from "react";
import { Routes } from "react-router-dom";

import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { saveDataToLocalStorage } from "./api/localStorage";

const CustomRoutes = ({ children }) => {
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const { extensionVersion, extensionId } = parsedQueryString;

  if (extensionId) {
    saveDataToLocalStorage("extensionId", extensionId);
  }

  if (extensionVersion) {
    saveDataToLocalStorage("extensionVersion", extensionVersion);
  }

  return <Routes>{children}</Routes>;
};

export default CustomRoutes;
