import React from "react";
import { styled, keyframes } from "styled-components";

function StepLoader({ stepIndex, steps }) {
  return (
    <Container>
      {steps.map((step, index) => (
        <StepLoaderItem
          key={step.id}
          $isDoneStep={index < stepIndex}
          $isCurrentStep={stepIndex === index}
        >
          {stepIndex === index && <span></span>}
        </StepLoaderItem>
      ))}
    </Container>
  );
}

export default StepLoader;

const Container = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 13px;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const ProgressBarKeyframe = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
`;

const StepLoaderItem = styled.div`
  background: ${({ $isDoneStep }) =>
    $isDoneStep ? "#471168" : "rgba(71, 17, 104, 0.5)"};
  width: 100%;
  ${({ $isCurrentStep }) => $isCurrentStep && "position: relative;"}

  span {
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: -o-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    -webkit-background-size: 40px 40px;
    background-size: 40px 40px;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${ProgressBarKeyframe} 0.8s linear infinite;
  }
`;
