import { fetchGptCallsStats } from "./queries";

const getGptCallsStats = async (date) => {
  const result = await fetchGptCallsStats(date);

  if (result.message) {
    return result;
  }
  return { success: true, result };
};

export { getGptCallsStats };
