import React from "react";
import styled from "styled-components";

function SubTitle({ children }) {
  return <Container>{children}</Container>;
}

export default SubTitle;

const Container = styled.span`
  font-size: 12px;
  color: #516f90;
  line-height: 1.5;
`;
