import React, { useState } from "react";
import { styled } from "styled-components";

function FAQsContent({ questions }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      {questions.map((item, index) => (
        <QuestionItem
          key={index}
          className="list-item"
          onClick={() => handleItemClick(index)}
        >
          <QuestionTitleBlock>
            <QuestionTitle>
              <QuestionIndex>Q{index + 1}</QuestionIndex>
              <div>{item.title}</div>
            </QuestionTitle>
            <ExpandIconBlock>
              <ExpandIcon
                src={
                  index === activeIndex
                    ? "/images/contract-list-item.png"
                    : "/images/expand-list-item.png"
                }
              ></ExpandIcon>
            </ExpandIconBlock>
          </QuestionTitleBlock>

          {index === activeIndex && (
            <AnswerBlock>
              <AnswerText>
                {item.answer.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </AnswerText>
            </AnswerBlock>
          )}
        </QuestionItem>
      ))}
    </>
  );
}

export default FAQsContent;

const QuestionItem = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid #e4e4e3;
`;

const QuestionTitleBlock = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const QuestionIndex = styled.div`
  color: #dcdcdc;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 800;
  font-size: 24px;
  padding-right: 10px;
  padding-left: 10px;
  border-right: 1px solid #e4e4e3;
  margin-right: 10px;
  width: 55px;
`;

const QuestionTitle = styled.div`
  font-size: 13px;
  display: flex;
  align-items: center;
`;

const ExpandIcon = styled.img`
  width: 15px;
  height: 15px;
`;

const ExpandIconBlock = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  border-left: 1px solid #e4e4e3;
  margin-left: 10px;
  width: 55px;
`;

const AnswerBlock = styled.div`
  background-color: #f6f6f5;
  border-radius: 5px;
  font-weight: 300;
  line-height: 20.82px;
  padding: 15px;
  margin: 10px 65px 0 65px;
  display: flex;
  flex-direction: column;
`;

const AnswerText = styled.div`
  p {
    font-size: 12px;
    font-family: "AlbertSansThin", sans-serif;
    font-weight: 300;
  }
`;
