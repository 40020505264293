/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../../InfiniteScroll";
import Spinner from "../../../../Spinner";
import Loader from "../../../../Loader";
import theme from "../../../../../theme";
import { getCampaignDetails } from "../../../../../services/reports";
import { getFilters } from "../../../../../services/campaigns";
import { MultiSelect } from "react-multi-select-component";
import { sendMessageToExtension } from "../../../../../utils/postToExtension";
import { displayPercent } from "../../../../../utils/utils";
import { TONE_OPTIONS } from "../../../../CreateCampaign/constants";

function CampaignDetailsTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [usersForFilter, setUsersForFilter] = useState([]);
  const [filters, setFilters] = useState({
    user: [],
  });
  const [sort, setSort] = useState({ sort: "name", order: "asc" });

  const tableContRef = useRef(null);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const fetchCampaigns = async (params) => {
    const data = {
      limit: getLimit(),
      offset: params?.clearAfterSearch ? 0 : offset,
    };

    if (params?.newSort || sort.sort) {
      data.sort = (params?.newSort || sort).sort;
      data.order = (params?.newSort || sort).order;
    }

    if (Object.keys(params?.newFilters || filters).length > 0) {
      data.filters = params?.newFilters || filters;
    }

    setIsLoading(!data.offset);

    const result = await getCampaignDetails(data);

    setIsLoading(false);

    if (result.success) {
      setCampaigns(
        params?.clearAfterSearch
          ? result.result.campaigns
          : [...campaigns, ...result.result.campaigns]
      );
      setDataLength(
        params?.clearAfterSearch
          ? result.result.campaigns.length
          : dataLength + result.result.campaigns.length
      );
      setHasMore(result.result.meta.hasMore);
      if (result.result.meta.hasMore) {
        setOffset(params?.clearAfterSearch ? data.limit : offset + data.limit);
      }
    } else {
      setHasMore(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch campaigns",
        },
      });
    }
  };

  const handleClickSort = async (sortField) => {
    let newSort;
    if (sortField === sort.sort) {
      if (sort.order === "asc") {
        newSort = { ...sort, order: "desc" };
      } else {
        newSort = { sort: null, order: null };
      }
    } else {
      newSort = { sort: sortField, order: "asc" };
    }
    setSort(newSort);
    await fetchCampaigns({ clearAfterSearch: true, newSort });
  };

  const handleChangeFilter = async (newFilters) => {
    setFilters(newFilters);
    await fetchCampaigns({ clearAfterSearch: true, newFilters });
  };

  const handleSelectFilter = (filterName, selOptions) => {
    const newFilters = {
      ...filters,
      [filterName]: selOptions.map((o) => o.value),
    };
    handleChangeFilter(newFilters);
  };

  const fetchByScroll = async () => {
    await fetchCampaigns({ scrolling: true });
  };

  const fetchOnMount = async () => {
    await fetchCampaigns();
  };

  useEffect(() => {
    const loadFilters = async () => {
      const res = await getFilters({ filter: ["users_in_company"] });
      if (res.success) {
        setUsersForFilter(
          (res.result?.users || []).map((c) => ({
            value: c.id,
            label: c.name,
          }))
        );
      }
    };
    loadFilters();
    fetchOnMount();
  }, []);

  const withSort = dataLength > 1;

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {[
            { name: "name", label: "Name" },
            { name: "goal", label: "Goal" },
            { name: "strategy", label: "Strategy" },
            { name: "tone", label: "Tone" },
            {
              name: "details",
              label: "Details",
              width: "40%",
              padding: "15px 5px 15px 20px",
            },
            { name: "open_rate", label: "Open Rate" },
            { name: "engagement_rate", label: "Engagement Rate" },
          ].map(({ name, label, width, padding }) => (
            <TableHeadData
              key={name}
              {...(width ? { $width: width } : null)}
              {...(padding ? { $padding: padding } : null)}
              {...(withSort
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort(name),
                  }
                : {})}
            >
              <TableHeadDataSort>
                {label}
                {withSort && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === name ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>
                No campaigns to show
              </EmptyTableData>
            </TableRow>
          ) : (
            campaigns.map((campaign, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>{campaign.name}</TableData>
                <TableData>{campaign.goal}</TableData>
                <TableData>
                  <TableDataContent>{campaign.strategy}</TableDataContent>
                </TableData>
                <TableData>
                  {TONE_OPTIONS.find((o) => o.value === campaign.tone)?.label ||
                    campaign.tone}
                </TableData>
                <TableData $width="40%" $padding="15px 5px 15px 20px">
                  <TableDataContent
                    dangerouslySetInnerHTML={{
                      __html: campaign.details || "",
                    }}
                  />
                </TableData>
                <TableData>{displayPercent(campaign.open_rate)}</TableData>
                <TableData>
                  {displayPercent(campaign.engagement_rate)}
                </TableData>
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Container $isLoading={isLoading}>
      <Filters>
        <Filter>
          <FilterLabel>User</FilterLabel>
          <MultiSelect
            options={usersForFilter}
            value={usersForFilter.filter((c) => filters.user.includes(c.value))}
            onChange={(selOptions) => handleSelectFilter("user", selOptions)}
            labelledBy="User"
            hasSelectAll={false}
            disableSearch={true}
            closeOnChangedValue={true}
            display="chip"
          />
        </Filter>
      </Filters>

      <Content ref={tableContRef}>
        {isLoading ? (
          <Loader parentSize={true} bgColor="white" padding="0" />
        ) : (
          <OverflowContent id="table_layout">
            {hasMore ? (
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchByScroll}
                hasMore={hasMore}
                scrollableTarget={"table_layout"}
                style={{ overflowY: "hidden" }}
                loader={
                  <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                }
              >
                {table}
              </InfiniteScroll>
            ) : (
              table
            )}
          </OverflowContent>
        )}
      </Content>
    </Container>
  );
}

export default CampaignDetailsTable;

const Container = styled.div`
  height: calc(100% - 55px);
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           background: transparent;
           z-index: 1;
         }`
      : ""}
`;

const Content = styled.div`
  height: calc(100% - 90px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const TableDataContent = styled.div`
  max-width: 100%;
  max-height: 77px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Filters = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  .multi-select .dropdown-content {
    z-index: 11;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;
