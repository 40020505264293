/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useAccount } from "../context";
import { getObjectFromLocalStorage } from "../api/localStorage";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  getDataFromLocalStorage,
  removeLoginDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../api/localStorage";
import NotificationsPane from "./NotificationsPane";
import { getNotificationsCount } from "../services/notifications";
import { NOTIFICATION_STATUSES } from "../utils/constants";

const NTF_POLLING_FREQUENCY_IN_SECONDS = 20;

const user = getObjectFromLocalStorage("user");
const isCompanyAdmin = user?.role === "company_admin" || user?.role === "admin";

function Navbar({ campaignBeingPrepared }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accountName, accountAvatar } = useAccount();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isNotificationsPaneOpened, setIsNotificationsPaneOpened] =
    useState(false);
  const [notificationsCount, setNotificationsCount] = useState(
    getDataFromLocalStorage("notificationsCount")
  );

  const timeoutRef = useRef(null);
  const notificationIconRef = useRef(null);

  const closeIframe = () => {
    if (campaignBeingPrepared) {
      navigate("/min-campaigns-prep", {
        state: { campaignToMinimize: campaignBeingPrepared },
      });
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "min",
        },
      });
      return;
    }

    sendMessageToExtension({
      message: "close-iframe",
    });
  };

  const signOut = () => {
    sendMessageToExtension({
      message: "sign-out",
    });
    removeLoginDataFromLocalStorage();
  };

  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const clearTimer = () => {
    clearTimeout(timeoutRef.current);
  };

  const toggleNotificationsPane = () => {
    setIsNotificationsPaneOpened(!isNotificationsPaneOpened);
  };

  const fetchNotificationsCount = async () => {
    clearTimer();

    const response = await getNotificationsCount({
      status: NOTIFICATION_STATUSES.NEW,
    });

    if (!response.success) {
      console.log(response.message);
      return;
    }

    timeoutRef.current = setTimeout(
      fetchNotificationsCount,
      NTF_POLLING_FREQUENCY_IN_SECONDS * 1000
    );
    setNotificationsCount(response.result.count);
    saveDataToLocalStorage("notificationsCount", response.result.count);
  };

  const handleReadNotifications = () => {
    setNotificationsCount(0);
    saveDataToLocalStorage("notificationsCount", 0);
  };

  const ROUTES = Object.freeze({
    Campaigns: "campaigns",
    Reports: "reports",
    Settings: "settings",
    Support: "support",
  });

  useEffect(() => {
    fetchNotificationsCount();
    return () => {
      clearTimer();
    };
  }, []);

  return (
    <NavbarContainer>
      <Logo src="/images/logos/logo-purple.png" alt="Logo" />
      <Navigation>
        <TabList>
          {Object.keys(ROUTES)
            .filter((f) => f !== `Reports` || isCompanyAdmin)
            .map((key) => (
              <LinkWrapper key={key}>
                <StyledLink
                  $currentPage={pathname === `/${ROUTES[key]}`}
                  to={`/${ROUTES[key]}`}
                >
                  {key}
                </StyledLink>
              </LinkWrapper>
            ))}
        </TabList>
        <NavMenuActions>
          <AccountDisplay onClick={toggleMenu}>
            <Avatar
              $imageUrl={
                accountAvatar?.current
                  ? accountAvatar.current
                  : "/images/minimal-logo-white.png"
              }
            ></Avatar>
            <span>{accountName.current}</span>
            <Image src="/images/profile-arrow-icon.png" alt="btn" />
          </AccountDisplay>
          {isMenuOpened && (
            <AccountMenu>
              <MenuItem onClick={signOut}>Sign Out</MenuItem>
            </AccountMenu>
          )}
          <NotificationsBlock ref={notificationIconRef}>
            <img
              src={
                notificationsCount > 0
                  ? "/images/notifications-icon.png"
                  : "/images/notifications-icon-empty.png"
              }
              alt="notifications"
              width="20"
              height="22"
              onClick={toggleNotificationsPane}
            />
            {notificationsCount !== undefined && notificationsCount > 0 && (
              <NotificationsCountBlock>
                <NotificationsCount>{notificationsCount}</NotificationsCount>
              </NotificationsCountBlock>
            )}
          </NotificationsBlock>
          {isNotificationsPaneOpened && (
            <NotificationsPane
              closePane={toggleNotificationsPane}
              onRead={handleReadNotifications}
            />
          )}
          <CloseIcon
            src="/images/Close-btn-icon.png"
            alt="close"
            width="18"
            onClick={closeIframe}
          />
        </NavMenuActions>
      </Navigation>
    </NavbarContainer>
  );
}

export default Navbar;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-right: 20px;
`;

const TabList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  padding-right: 55px;
  list-style-type: none;
`;

const StyledLink = styled(Link)`
  position: relative;
  color: ${({ $currentPage, theme }) =>
    $currentPage ? theme.colors.saturated_purple : theme.colors.gray};
  font-weight: ${({ $currentPage }) => ($currentPage ? 700 : 400)};
  font-size: 14px;
  text-decoration: none;
  &::before {
    ${({ $currentPage }) =>
      $currentPage
        ? `content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2.2px;
    width: 12.6px;
    background-color: #471168;`
        : " "}
  }
`;

const AccountDisplay = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 69px;
  width: 150px;
  height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
`;

const Image = styled.img`
  ${({ $width }) => ($width ? `width:${$width}` : " ")};
  ${({ $height }) => ($height ? `height:${$height}` : " ")};
`;

const Navigation = styled.nav`
  display: flex;
  width: ${isCompanyAdmin ? 675 : 600}px;
  height: 23px;
  align-items: center;
  justify-content: space-between;
`;

const NavbarContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid #e6e6e6;
`;

const NotificationsBlock = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  padding: 0 26px 0 27px;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  height: 26px;
  margin: 0 15px 0 20px;
`;

const NotificationsCountBlock = styled.div`
  position: absolute;
  top: -9px;
  background-color: #f95959;
  border-radius: 50%;
  padding: 1px 3px;
  left: 36px;
`;

const NotificationsCount = styled.div`
  min-width: 6.8px;
  text-align: center;
  font-size: 9px;
  font-family: "AlbertSansExtraBold";
  color: white;
`;

const Logo = styled.img`
  margin: 20px 0 20px 50px;
  width: 103px;
`;

const NavMenuActions = styled.div`
  display: flex;
  height: 23px;
  justify-content: space-between;
  align-items: center;
`;

const Avatar = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-image: ${({ $imageUrl }) => `url(${$imageUrl});`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AccountMenu = styled.div`
  position: absolute;
  top: 50px;
  width: 150px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 9px;
  border: 1px solid #d6ddeb;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 100000;
`;

const MenuItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: #070707;
`;

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
`;
