/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Loader from "../../../Loader";
import { getCompany, updateCompany } from "../../../../services/company";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import { getObjectFromLocalStorage } from "../../../../api/localStorage";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import BusinessHours from "./BusinessHours";
import Toggle from "./Toggle";

function SchedulingWrapper() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingUnsubscribe, setIsUpdatingUnsubscribe] = useState(false);

  const [businessHours, setBusinessHours] = useState(null);
  const [company, setCompany] = useState(null);

  const user = getUserFromLocalStorage();
  const isCompanyAdmin =
    user?.role === "company_admin" || user?.role === "admin";

  const isUsedScheduling =
    !!businessHours && !!Object.keys(businessHours).length;

  const isDisabledUpdate = () => {
    return (
      JSON.stringify(company.businessHours || {}) ===
        JSON.stringify(businessHours) ||
      (isUsedScheduling &&
        (!businessHours.tz ||
          !businessHours.from ||
          !businessHours.to ||
          JSON.stringify(businessHours.from || {}) ===
            JSON.stringify(businessHours.to || {})))
    );
  };

  const handleUpdate = async (e) => {
    e?.preventDefault();
    setIsUpdatingUnsubscribe(true);

    const response = await updateCompany({
      businessHours,
    });

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });

      setBusinessHours(company.businessHours || {});
      setIsUpdatingUnsubscribe(false);

      return;
    }

    setCompany({ ...company, businessHours });
    setIsUpdatingUnsubscribe(false);
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const response = await getCompany();

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: response.message,
          },
        });

        navigate("/campaigns");
        setIsLoading(false);
        return;
      }

      setCompany(response.company);
      setBusinessHours(response.company.businessHours || {});
      setIsLoading(false);
    };

    const companyInfo = getObjectFromLocalStorage("companyInfo");

    if (companyInfo?.company?.businessHours) {
      setCompany(companyInfo.company);
      setBusinessHours(companyInfo.company.businessHours);
      setIsLoading(false);
      return;
    }

    fetchCompany();
  }, []);

  return (
    <Content>
      {isLoading && !businessHours ? (
        <Loader parentSize={true} />
      ) : (
        <Section $isLoading={isLoading}>
          {isLoading && <Loader parentSize={true} />}
          <Title>Scheduling</Title>
          <Inputs>
            <Toggle
              id="isUsedScheduling"
              label="Only email during my business hours"
              value={isUsedScheduling}
              onChange={(checked) =>
                setBusinessHours(checked ? { tz: null } : {})
              }
              withNoAdminTooltip={!isCompanyAdmin}
              marginBottom="20px"
            />
            <Collapse $opened={isUsedScheduling}>
              <BusinessHours {...{ businessHours, setBusinessHours }} />
            </Collapse>
          </Inputs>

          {isCompanyAdmin && (
            <Actions>
              <UpdateBtn
                onClick={handleUpdate}
                disabled={isDisabledUpdate() || isUpdatingUnsubscribe}
              >
                Update
              </UpdateBtn>
            </Actions>
          )}
        </Section>
      )}
    </Content>
  );
}

export default SchedulingWrapper;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Section = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           z-index: 1;
         }`
      : ""}
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 2px;
  background: #fff;
`;

const UpdateBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

const Collapse = styled.div`
  transition: height 0.5s, opacity 0s;
  ${({ $opened }) =>
    $opened
      ? `height: 170px; overflow: initial; opacity: 1; transition: height 0.5s, opacity 0.9s;`
      : `height: 0; overflow: hidden; opacity: 0;`}
`;
