import React, { useState, useEffect } from "react";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { styled } from "styled-components";
import { getCompany, updateCompany } from "../../../services/company";
import { updateUser, updatePassword } from "../../../services/user";
import { useAccount } from "../../../context";
import { fileToBase64 } from "../../../utils/utils";
import Loader from "../../Loader";
import theme from "../../../theme";
import ConnectBtn from "../../ConnectBtn";
import PhotoUploader from "../../PhotoUploader";
import { getObjectFromLocalStorage } from "../../../api/localStorage";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { Tooltip } from "react-tooltip";

function Account() {
  const { account, setAccount, accountName, accountAvatar } = useAccount();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [isUpdatedPassword, setIsUpdatedPassword] = useState(false);

  const [errors, setErrors] = useState([]);

  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const isOauthTypeApplicable = account.oauthType !== "";

  const handleChange = (value, fieldName) => {
    setErrors(errors.filter((el) => el !== "account"));
    setAccount({ ...account, [fieldName]: value });
  };

  const onLoadFile = (file) => {
    fileToBase64(file, (base64String) => {
      setAccount({ ...account, avatar: base64String });
    });
  };

  const handleChangePassword = (value, callback) => {
    setErrors(
      errors.filter((el) => el !== "password" && el !== "confirmPassword")
    );
    callback(value);
  };

  const handleSubmitButtonClick = (event, formType) => {
    event.preventDefault();
    if (formType === "account") {
      handleSubmitUpdateAccount();
    }
    if (formType === "password") {
      handleSubmitResetPassword();
    }
  };

  const handleSubmitUpdateAccount = async () => {
    setIsAccountLoading(true);
    const isCompanyNameNotChanged = isEqualCompanyName();
    const isUserInfoNotChanged = isEqualUsers();

    if (!isCompanyNameNotChanged) {
      const response = await updateCompany({
        name: companyName,
      });

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: response.message,
          },
        });
      }
    }

    if (isUserInfoNotChanged) {
      setIsAccountLoading(false);
      return;
    }

    try {
      const updatedData = {
        name: account.name,
        email: account.email,
        avatar: account.avatar,
      };
      await updateUser(updatedData);
      const user = getUserFromLocalStorage();
      setAccount({ ...user, ...updatedData });
      accountName.current = user.name;
      accountAvatar.current = account.avatar;
      setIsAccountLoading(false);
    } catch (error) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while updating account info",
        },
      });
      setIsAccountLoading(false);
    }
  };

  const handleSubmitResetPassword = async () => {
    if (confirmNewPassword !== newPassword) {
      setErrors([...errors, "confirmPassword"]);
      return;
    }
    try {
      setIsPasswordLoading(true);
      await updatePassword({ password, newPassword });
      setIsUpdatedPassword(true);
      setNewPassword("");
      setConfirmNewPassword("");
      setPassword("");
      setIsPasswordLoading(false);

      setTimeout(() => {
        setIsUpdatedPassword(false);
      }, [3000]);
    } catch (error) {
      setIsPasswordLoading(false);
      setErrors([...errors, "password"]);
    }
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  const isEqualUsers = () => {
    const user = getUserFromLocalStorage();
    return (
      account.name === user.name &&
      account.companyId === user.companyId &&
      account.email === user.email &&
      account.avatar === user.avatar
    );
  };

  const isEqualCompanyName = () => {
    const companyInfo = getObjectFromLocalStorage("companyInfo");
    return companyInfo && companyInfo.company
      ? companyInfo.company.name === companyName
      : true;
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const result = await getCompany();
      setCompanyName(result.company.name);
    };
    fetchCompany();
  }, []);

  return (
    <Content>
      <Block>
        <Form
          $bgColor={theme.colors.white}
          $padding="43px 85px 43px 63px"
          onSubmit={handleSubmitUpdateAccount}
        >
          {isAccountLoading ? (
            <Loader parentSize={true} minWidth="488px" />
          ) : (
            <>
              <Title>Account</Title>
              <Flex>
                <label htmlFor="name">Name</label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Name"
                  autoComplete="off"
                  $width="488px"
                  value={account.name}
                  onChange={(event) =>
                    handleChange(event.target.value, event.target.name)
                  }
                />
              </Flex>
              <Flex
                data-tooltip-id={!isCompanyAdmin() ? `no-admin-tooltip` : null}
                data-tooltip-content={
                  !isCompanyAdmin()
                    ? "Please contact your company admin to change this setting"
                    : null
                }
              >
                <label htmlFor="companyId">Company Name</label>
                <Input
                  type="text"
                  id="companyName"
                  name="companyName"
                  placeholder="Company Name"
                  autoComplete="off"
                  readOnly={!isCompanyAdmin()}
                  $width="488px"
                  value={companyName}
                  onChange={(event) => setCompanyName(event.target.value)}
                />
              </Flex>
              {!isCompanyAdmin() ? (
                <Tooltip
                  id={`no-admin-tooltip`}
                  className="custom-tooltip"
                  place="left"
                />
              ) : null}
              <Flex>
                <label htmlFor="email">Primary email address</label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email address"
                  autoComplete="off"
                  $width="488px"
                  value={account.email}
                  onChange={(event) =>
                    handleChange(event.target.value, event.target.name)
                  }
                  disabled={isOauthTypeApplicable}
                />
              </Flex>

              <PhotoUploader onLoadFile={onLoadFile} />

              <div>
                <ConnectBtn
                  label="Update Profile"
                  onClick={(e) => handleSubmitButtonClick(e, "account")}
                  disabled={isEqualUsers() && isEqualCompanyName()}
                />
              </div>
            </>
          )}
        </Form>
        <Form
          $bgColor="#fafafa"
          $padding="97px 71px 97px 35px"
          onSubmit={handleSubmitResetPassword}
        >
          {!isOauthTypeApplicable &&
            (isPasswordLoading ? (
              <Loader parentSize={true} />
            ) : (
              <>
                {isUpdatedPassword && (
                  <SuccessMessage>
                    User password was updated successfully.
                  </SuccessMessage>
                )}
                <Label htmlFor="campaign-title">Update Password</Label>
                <Flex>
                  <label htmlFor="password">Current Password</label>
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Current password"
                    autoComplete="off"
                    $width="204px"
                    value={password}
                    onChange={(event) =>
                      handleChangePassword(event.target.value, setPassword)
                    }
                    required={true}
                  />
                </Flex>
                <Flex>
                  <label htmlFor="newPassword">New Password</label>
                  <Input
                    type="password"
                    id="newPassword"
                    placeholder="New password"
                    autoComplete="off"
                    $width="204px"
                    value={newPassword}
                    onChange={(event) =>
                      handleChangePassword(event.target.value, setNewPassword)
                    }
                    required={true}
                  />
                </Flex>
                <Flex>
                  <label htmlFor="confirmPassword">Repeat New Password</label>
                  <Input
                    type="password"
                    id="confirmPassword"
                    placeholder="New password"
                    autoComplete="off"
                    $width="204px"
                    value={confirmNewPassword}
                    onChange={(event) =>
                      handleChangePassword(
                        event.target.value,
                        setConfirmNewPassword
                      )
                    }
                    required={true}
                  />
                </Flex>
                <div>
                  {errors.includes("password") && (
                    <ErrorMessage>Failed to update user password</ErrorMessage>
                  )}
                  {errors.includes("confirmPassword") && (
                    <ErrorMessage>Repeat new password correctly</ErrorMessage>
                  )}
                  {password !== "" &&
                    newPassword !== "" &&
                    password === newPassword && (
                      <ErrorMessage>
                        The new password must not match the old one.
                      </ErrorMessage>
                    )}

                  <ConnectBtn
                    label="Reset Password"
                    onClick={(e) => handleSubmitButtonClick(e, "password")}
                    disabled={
                      !password ||
                      !newPassword ||
                      password === newPassword ||
                      newPassword !== confirmNewPassword
                    }
                  />
                </div>
              </>
            ))}
        </Form>
      </Block>
    </Content>
  );
}

export default Account;

const Form = styled.form`
  background-color: ${({ $bgColor, theme }) =>
    $bgColor ? $bgColor : theme.colors.background_color};
  height: 100%;
  ${({ $padding }) => ($padding ? `padding:${$padding}` : "")};
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.colors.btn_border_color};
  }
`;

const SuccessMessage = styled.p`
  color: green;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.p`
  color: #f24726;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: 800;
  text-align: left;
  margin-bottom: 23px;
`;

const Input = styled.input`
  position: relative;
  margin-top: 4px;
  margin-bottom: 20px;
  border: 1px solid #d6ddeb;
  padding: 12px 45px 12px 16px;

  width: ${({ $width }) => ($width ? $width : "auto")};
  &:focus-visible {
    position: relative;
    margin-top: 4px;
    margin-bottom: 20px;
    border: 1px solid #d6ddeb;
    padding: 12px 0px 12px 16px;
    width: ${({ $width }) => ($width ? $width : "auto")};
    gap: 10px;
  }
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100%;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
