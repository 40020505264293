import React, { useState } from "react";
import { styled } from "styled-components";

import SuggestionPopup from "../../SuggestionPopup";

function ValidateGoalPopup({
  title,
  description,
  showLinkInput,
  onConfirm,
  onCancel,
}) {
  const [link, setLink] = useState("");
  const [linkErrorMessage, setLinkErrorMessage] = useState("");

  const isLinkValidUrl = () => {
    try {
      new URL(link);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleClickConfirm = (e) => {
    e.preventDefault();
    if (showLinkInput && !isLinkValidUrl()) {
      setLinkErrorMessage("Invalid URL");
      return;
    }

    onConfirm(link);
  };

  return (
    <SuggestionPopup
      title={title}
      description={description}
      biggerHeight={showLinkInput}
      onConfirm={handleClickConfirm}
      onCancel={onCancel}
    >
      {showLinkInput && (
        <InputContent>
          <Relative>
            <LinkInput
              type="text"
              placeholder="Enter the link"
              value={link}
              autoFocus={true}
              onInput={(event) => setLink(event.target.value)}
            />
            {linkErrorMessage !== "" && (
              <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
            )}
          </Relative>
          {linkErrorMessage !== "" && (
            <ErrorMessage>{linkErrorMessage}</ErrorMessage>
          )}
        </InputContent>
      )}
    </SuggestionPopup>
  );
}

export default ValidateGoalPopup;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const Relative = styled.div`
  position: relative;
`;

const LinkInput = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 10px;
  width: 100%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 11px;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;
