import React from "react";
import { styled } from "styled-components";

import WizardSteps from "./WizardSteps";

function SidePanel({ wizardSteps, title, stepIndex }) {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Description>
            We are automatically generating a key to help Reach interact with Office365.
        </Description>
        <WizardSteps wizardSteps={wizardSteps} stepIndex={stepIndex} />
      </div>
      <Logo src="/images/logos/logo-purple.png" alt="Logo" />
    </Container>
  );
}

export default SidePanel;

const Container = styled.div`
  width: 25%;
  background-color: white;
  border-right: 1px solid #cdcdcd;
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: 800;
  line-height: 0.9em;
  color: #808080;
  margin-bottom: 20px;
  font-family: "AlbertSansExtraBold", sans-serif;
  letter-spacing: 0rem;
`;

const Description = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 103px;
`;
