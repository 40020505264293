import React from "react";
import styled from "styled-components";

function Image({ preview }) {
  return <Container src={preview} alt="preview" />;
}

export default Image;

const Container = styled.img`
  background-color: white;
  border-radius: 6px;
  box-sizing: border-box;
  object-fit: cover;
  cursor: pointer;
  max-width: calc(100% - 60px);
  width: 100%;
  display: block;
  height: auto;

  @media (max-width: 768px) {
    max-width: 454px;
  }
`;
