import React from "react";
import styled from "styled-components";

function Section({ children }) {
  return <Container>{children}</Container>;
}

export default Section;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;
