import React from "react";

import TabItem from "./TabItem";
import { List } from "./styles";
import { OPTIONS_TABS } from "../../../constants";

function TabList({ selectedTabId, handleSelectTab }) {
  return (
    <List>
      {OPTIONS_TABS.map((tab) => (
        <TabItem
          key={tab.id}
          name={tab.name}
          onClick={() => handleSelectTab(tab.id)}
          activeItem={selectedTabId === tab.id}
        />
      ))}
    </List>
  );
}

export default TabList;
