import React, { useState } from "react";
import styled from "styled-components";
import { FAQ_QUESTIONS } from "../../utils/constants";
import FAQsContent from "./FAQsContent";

const FAQs = () => {
  const [activeTabKey, setActiveTabKey] = useState("all");

  const handleTabClick = (tabKey) => {
    setActiveTabKey(tabKey);
  };

  const getCurrentQuestions = () => {
    if (activeTabKey === "all") {
      let combinedQuestions = [];

      for (const category of Object.values(FAQ_QUESTIONS)) {
        combinedQuestions = combinedQuestions.concat(category.questions);
      }

      return combinedQuestions;
    }

    return FAQ_QUESTIONS[activeTabKey].questions;
  };

  return (
    <section>
      <Title className="main_title">Frequently-Asked Questions</Title>

      <Tabs>
        <Tab
          key="all"
          $activeTabKey={activeTabKey === "all"}
          className={activeTabKey === "all" ? "active" : ""}
          onClick={() => handleTabClick("all")}
        >
          All
        </Tab>
        {Object.keys(FAQ_QUESTIONS).map((key) => {
          return (
            <Tab
              key={key}
              $activeTabKey={activeTabKey === key}
              className={activeTabKey === key ? "active" : ""}
              onClick={() => handleTabClick(key)}
            >
              {FAQ_QUESTIONS[key].title}
            </Tab>
          );
        })}
      </Tabs>
      <FAQsContent questions={getCurrentQuestions()} />
    </section>
  );
};

export default FAQs;

const Title = styled.div`
  font-size: 25px;
  font-weight: 800;
  font-family: "AlbertSansExtraBold", sans-serif;
  margin-bottom: 20px;
  color: #5a5a5a;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.button`
  font-family: "AlbertSans", sans-serif;
  font-weight: ${({ $activeTabKey }) => ($activeTabKey ? "800" : "400")};
  height: 32px;
  padding: 0 13px;
  margin-right: 10px;
  border-radius: 5px;
  color: ${({ $activeTabKey }) => ($activeTabKey ? "white" : "#767675")};
  background-color: ${({ theme, $activeTabKey }) =>
    $activeTabKey ? theme.colors.saturated_purple : "#ebebea"};
  border: none;
  cursor: pointer;
`;
