import React from "react";
import { styled } from "styled-components";

export default function WizardStep({ index, stepIndex, title }) {
  return (
    <Container>
      <Step $tabValue={index} $currentStep={stepIndex}>
        {title}
      </Step>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
`;

const Step = styled.div`
  color: ${({ theme, $currentStep, $tabValue }) =>
    $currentStep === $tabValue
      ? theme.colors.saturated_purple
      : theme.colors.gray};
  font-weight: ${({ $currentStep, $tabValue }) =>
    $currentStep === $tabValue ? "800" : "normal"};
  border-left: 2px solid transparent;
  padding-left: 2%;
  ${({ theme, $currentStep, $tabValue }) =>
    $currentStep === $tabValue &&
    `
    border-color: ${theme.colors.saturated_purple};
  `}
  font-size: 14px;
`;
