import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import ConnectBtn from "../../../ConnectBtn";
import { apiUrl } from "../../../../utils/config";
import { getAccessToken } from "../../../../api/axios";
import ConfirmPopup from "../../../ConfirmPopup";

const POPUP_MODES = {
  WARNING: "warning",
  CONFIRM: "confirm",
};

function SimpleConnect({ label, disabled, onConnect, emailType }) {
  const [openedWindow, setOpenedWindow] = useState(null);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState(false);
  const [popupMode, setPopupMode] = useState(POPUP_MODES.CONFIRM);

  const closeOpenedWindow = useCallback(
    (isUnmount) => {
      if (openedWindow) {
        try {
          const { win, timer, closeWhenUnmount } = openedWindow;
          clearInterval(timer);
          if (!isUnmount || closeWhenUnmount) {
            win.close();
          }
        } catch {}
      }
    },
    [openedWindow]
  );

  useEffect(() => {
    return () => {
      closeOpenedWindow(true);
    };
  }, [closeOpenedWindow]);

  const handleClick = async (e) => {
    e?.preventDefault();
    closeOpenedWindow();

    setIsConfirmPopupOpened(true);
  };

  const handleOauthConnect = () => {
    setIsConfirmPopupOpened(false);
    setPopupMode(POPUP_MODES.CONFIRM);

    const oauthConnectUrl = new URL(
      `${apiUrl}/integrations/oauth-connect/${emailType}`
    );
    oauthConnectUrl.searchParams.set("token", getAccessToken());

    const parameters = `width=800,height=650,left=${
      (window.screen.width - 800) / 2
    },top=${(window.screen.height - 650) / 2}`;

    const win = window.open(oauthConnectUrl, "_blank", parameters);
    const timer = setInterval(() => {
      let wClosed, isSuccess, message;
      try {
        if (win.closed) {
          clearInterval(timer);
          wClosed = true;
        }
      } catch {}
      if (!wClosed) {
        try {
          if (
            win.origin &&
            ["/success", "/error"].includes(win.location.pathname)
          ) {
            isSuccess = win.location.pathname === "/success";
            const searchParams = new URLSearchParams(win.location.search);
            message = searchParams.get("message");

            clearInterval(timer);
            win.close();
            wClosed = true;
          }
        } catch {}
      }

      if (wClosed) {
        onConnect?.(isSuccess, message);
      }
    }, 1000);
    setOpenedWindow({ win, timer, closeWhenUnmount: !!onConnect });
  };

  const getPopupProps = () => {
    const props = {
      onCancel: () => {
        setIsConfirmPopupOpened(false);
        setPopupMode(POPUP_MODES.CONFIRM);
      },
    };

    if (popupMode === POPUP_MODES.WARNING) {
      return {
        ...props,
        title: "We're still waiting…",
        description:
          "Unfortunately, our app is still in review by Google. We promise, it's safe, but thought you should know!",
        confirmLabel: "That's ok!",
        cancelLabel: "I'd rather wait",
        iconSrc: "/images/google-icon.png",
        onConfirm: handleOauthConnect,
      };
    } else {
      return {
        ...props,
        title: "Create Email Integration",
        description:
          "Please note that you will need administrative privileges to create the integration",
        confirmLabel: "Sounds good",
        onConfirm: () => {
          if (emailType === "gsuite") {
            setPopupMode(POPUP_MODES.WARNING);
          } else {
            handleOauthConnect();
          }
        },
      };
    }
  };

  return (
    <>
      <ConnectBtn
        label={label || "Click to Connect"}
        disabled={disabled}
        onClick={handleClick}
      />
      {isConfirmPopupOpened && <ConfirmPopup {...getPopupProps()} />}
    </>
  );
}

SimpleConnect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onConnect: PropTypes.func,
  disabled: PropTypes.bool,
  emailType: PropTypes.string,
};

export default SimpleConnect;
