import React, { useState, useEffect } from "react";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../api/localStorage";
import { getMinExtensionVersion } from "../services/auth";
import { CHROME_STORE_EXTENSION_ADDRESS } from "../utils/constants";

import SuggestionPopup from "./SuggestionPopup";
import { sendMessageToExtension } from "../utils/postToExtension";

function CheckExtensionVersion() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const onConfirm = () => {
    sendMessageToExtension({
      message: "close-iframe",
    });
    window.open(CHROME_STORE_EXTENSION_ADDRESS, "_blank");
    setIsPopupOpen(false);
  };

  const onCancel = () => {
    setIsPopupOpen(false);
  };

  const compareVersions = (minVersion) => {
    const extensionVersion = getDataFromLocalStorage("extensionVersion");

    if (!extensionVersion || !minVersion) {
      return 0;
    }

    const extensionVersionParts = extensionVersion.split(".");
    const minVersionParts = minVersion.split(".");

    for (
      let i = 0;
      i < Math.max(extensionVersionParts.length, minVersionParts.length);
      i++
    ) {
      const num1 = parseInt(extensionVersionParts[i] || 0);
      const num2 = parseInt(minVersionParts[i] || 0);

      if (num1 < num2) {
        return -1;
      } else if (num1 > num2) {
        return 1;
      }
    }

    return 0;
  };

  useEffect(() => {
    const checkExtensionVersion = async () => {
      const cachedVersion = getObjectFromLocalStorage("MinExtensionVersion");

      if (cachedVersion && Date.now() < cachedVersion.timestamp) {
        const compareResult = compareVersions(cachedVersion.value);
        if (compareResult === -1) {
          setIsPopupOpen(true);
        }
        return;
      } else {
        const result = await getMinExtensionVersion();

        const newVersion = result.result.version;
        if (!newVersion) {
          return;
        }
        const expirationTime = Date.now() + 3600000;

        saveObjectToLocalStorage("MinExtensionVersion", {
          value: newVersion,
          timestamp: expirationTime,
        });

        const compareResult = compareVersions(newVersion);
        if (compareResult === -1) {
          setIsPopupOpen(true);
        }
      }
    };

    checkExtensionVersion();
  }, []);
  return (
    <>
      {isPopupOpen && (
        <SuggestionPopup
          title="Your extension needs an update"
          description="Please update the extension to the latest version to ensure that Reach works as intended."
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmBtnText="Update"
          cancelBtnText="Cancel"
        ></SuggestionPopup>
      )}
    </>
  );
}

export default CheckExtensionVersion;
