import React from "react";

import SubTitle from "./SubTitle";
import Description from "./Description";
import Title from "./Title";
import Section from "./Section";

function Requirements() {
  return (
    <>
      <Title>Requirements</Title>
      <Section>
        <SubTitle>SalesStream.ai Subscription</SubTitle>
        <Description>Compatible with Free plan</Description>
      </Section>
      <Section>
        <SubTitle>SalesStream.ai Permissions</SubTitle>
        <Description>
          Create, delete, or make changes to contacts.
          <br />
          View details about companies.
          <br />
          Create and view contact notes.
        </Description>
      </Section>
    </>
  );
}

export default Requirements;
