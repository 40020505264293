import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import StepLoaders from "./StepLoaders";
import CloseBtn from "../CloseBtn";
import SidePanel from "./SidePanel";
import ContentStep from "./ContentStep";
import { WIZARD_SCHEME } from "./constants";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { fetchAutoConfigureLog } from "../../services/queries";

function AutomateGsuiteKeyGen() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lastIndex = WIZARD_SCHEME.length - 1;
  const step = Number(searchParams.get("finishedStep")) || 0; // we use 'finishedStep' value after redirects.
  const serviceAccountUniqueId = searchParams.get("serviceAccountUniqueId");
  const [stepIndex, setStepIndex] = useState(step);

  const postMessageEventHandler = (event) => {
    if (event.data.result === "success") {
      setStepIndex((currentValue) => {
        const nextStepValue = currentValue + 1;
        if (lastIndex >= nextStepValue) {
          return nextStepValue;
        }
      });
    }

    if (event.data.result === "error") {
      const { message } = event.data;
      fetchAutoConfigureLog({
        logInfo: message
          ? `[GSuite] ${message}`
          : `Failed to auto-configure GSuite integration`,
      });
      navigateToSettingsPage();
    }
  };

  const onComplete = () => {
    navigateToSettingsPage();
  };

  const navigateToSettingsPage = () => {
    navigate("/settings", {
      state: {
        integrationData: {
          type: "email",
          connectionKeys: {
            type: "gsuite",
          },
        },
      },
    });

    sendMessageToExtension({
      message: "change-iframe-window-type",
      data: {
        iframeWindowType: "drawer",
      },
    });
  };

  useEffect(() => {
    if (lastIndex > stepIndex) {
      sendMessageToExtension({
        message: "run-automate-gsuite-key-gen-process",
        data: {
          step: stepIndex + 1,
          serviceAccountUniqueId: serviceAccountUniqueId,
        },
      });
    }
  }, [stepIndex, lastIndex, serviceAccountUniqueId]);

  useEffect(() => {
    window.addEventListener("message", postMessageEventHandler);
    return () => {
      window.removeEventListener("message", postMessageEventHandler);
    };
  }, []);

  return (
    <main className="app">
      <CloseBtn />
      <div className="layout">
        <SidePanel
          title="GSuite Key Generation"
          wizardSteps={WIZARD_SCHEME}
          stepIndex={stepIndex}
        />
        <Section>
          <ContentStep
            stepIndex={stepIndex}
            renderFinishButton={
              lastIndex === stepIndex ? (
                <FinishWizardButton onClick={onComplete} />
              ) : null
            }
          />
          {lastIndex !== stepIndex ? (
            <StepLoaders wizardSteps={WIZARD_SCHEME} stepIndex={stepIndex} />
          ) : null}
        </Section>
      </div>
    </main>
  );
}

export default AutomateGsuiteKeyGen;

const Section = styled.div`
  width: 75%;
  background-color: #f5f2ee;
  display: flex;
  align-items: center;
  position: relative;
`;

function FinishWizardButton({ onClick }) {
  return <FinishButton onClick={onClick}>Finish</FinishButton>;
}

const FinishButton = styled.button`
  cursor: pointer;
  color: #ffffff;
  background-color: #471168;
  height: 40px;
  width: 138px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #471168;
  margin: 15px 26px 0 auto;
`;
