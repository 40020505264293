import { useEffect, useState } from "react";
import styled from "styled-components";
import Payment from "../../Subscription/Payment";
import Loader from "../../Loader";
import { createSetupIntent } from "../../../services/subscription";
import { sendMessageToExtension } from "../../../utils/postToExtension";

const UpdateBillingDetails = ({ onReturn, onSuccess }) => {
  const [clientSecret, setClientSecret] = useState("");

  const createSetupIntentForUpdate = async () => {
    const result = await createSetupIntent();

    if (result.success) {
      setClientSecret(result.result.clientSecret);
    } else {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to update billing details",
        },
      });
      onReturn();
    }
  };

  useEffect(() => {
    createSetupIntentForUpdate();
  }, []);

  return (
    <>
      <BackToPlanSelection onClick={onReturn}>
        &lt; Back To Plan Selection
      </BackToPlanSelection>
      {clientSecret ? (
        <Payment
          clientSecret={clientSecret}
          updatePaymentMethod={true}
          onSuccess={onSuccess}
        />
      ) : (
        <Loader parentSize={true} height="calc(100% - 70px)" />
      )}
    </>
  );
};

export default UpdateBillingDetails;

const BackToPlanSelection = styled.div`
  margin: 20px 0 10px;
  padding: 3px 0;
  width: fit-content;
  font-size: 11px;
  color: #4640de;
  cursor: pointer;
`;
