import React from "react";
import { styled } from "styled-components";

function OverlayMessage({ message }) {
  return (
    <>
      <MessageContainer>
        <Message>{message}</Message>
      </MessageContainer>
    </>
  );
}

export default OverlayMessage;

const MessageContainer = styled.div`
  position: absolute;
  top: calc((100vh - 164px) / 2);
  left: calc((100vw - 487px) / 2);
  width: 487px;
  height: 164px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0px 4px 50px 0px #00000059;
`;

const Message = styled.div`
  font-size: 14px;
  font-weight: 400;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
