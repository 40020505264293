import React from "react";
import { styled } from "styled-components";
import { displayDate } from "../utils";
import {
  CAMPAIGN_STATUSES_COLOR,
  CAMPAIGN_UPDATE_STATUSES,
} from "../constants";
import { BEGIN_OPTION_IMMEDIATELY } from "../../CreateCampaign/constants";
import { Tooltip } from "react-tooltip";

function TitleBlock({ campaign }) {
  const campaignStatus =
    [
      CAMPAIGN_UPDATE_STATUSES.DRAFT,
      CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH,
    ].includes(campaign.status) && campaign.isNeedToGenerateMessagePreviews
      ? CAMPAIGN_UPDATE_STATUSES.GENERATING
      : campaign.status;
  return (
    <Block>
      <ShadowTitle>Campaign details</ShadowTitle>
      <TitleContainer>
        <Title>{campaign.name}</Title>
        <TitleStatus $bgColor={CAMPAIGN_STATUSES_COLOR[campaign.status]}>
          <div>{campaignStatus}</div>
        </TitleStatus>
        {campaign.status === CAMPAIGN_UPDATE_STATUSES.NOT_STARTED &&
          campaign.begin !== BEGIN_OPTION_IMMEDIATELY && (
            <>
              <img
                src="/images/info-icon.png"
                alt="info"
                width="18"
                height="18"
                style={{
                  marginTop: "5px",
                  opacity: 0.3,
                }}
                data-tooltip-id="starts-tooltip"
                data-tooltip-content={`Starts on ${displayDate(
                  campaign.begin
                )}`}
              />
              <Tooltip
                id="starts-tooltip"
                place="right"
                className="custom-tooltip"
              />
            </>
          )}
      </TitleContainer>

      <SubTitle>
        {campaign.createdAt && (
          <span>
            Created <Text>{displayDate(campaign.createdAt)}</Text>
          </span>
        )}
        <span>
          Recipients&nbsp;
          <Text>{campaign.contactCount || campaign.contacts.length}</Text>
        </span>
      </SubTitle>
    </Block>
  );
}

export default TitleBlock;

const ShadowTitle = styled.span`
  text-transform: uppercase;
  height: 13px;
  font-size: 12px;
  font-weight: 800;
  margin-left: 2px;
  color: ${({ theme }) => theme.colors.gray};
`;

const Title = styled.h3`
  font-weight: 400;
  font-size: 24px;
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 13px;
  font-weight: 400;
  gap: 12px;
  height: 13px;
  margin-top: 12px;
`;

const Text = styled.span`
  font-weight: 700;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleStatus = styled.div`
  white-space: nowrap;
  padding: 5px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#20b0ee")};
  font-size: 12px;
  color: #f5f2ee;
  font-weight: 800;
  border-radius: 3px;
  margin: 4px 10px;
  height: 22px;
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
`;
