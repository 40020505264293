import React, { useState } from "react";

import {
  CHROME_STORE_EXTENSION_ADDRESS,
  COOKIE_OPTIONS_STRING,
  POST_INSTALL_EXTENSION_SRC_COOKIE_NAME,
  POST_INSTALL_EXTENSION_SRC_COOKIE_VALUE,
  SOURCE_QUERY_PARAM,
  TEMP_LOCAL_STORAGE_DATA_COOKIE_NAME,
} from "../../../../utils/constants";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import { removeLoginDataFromLocalStorage, saveDataToLocalStorage } from "../../../../api/localStorage";
import Button from "./Button";
import {
  useExtensionInstalled,
  useGetStorageData,
  useUserCheck,
} from "./hooks";
import { getCookie, removeCookie } from "../../../../utils/utils";
import { getAccessToken } from "../../../../api/axios";
import { apiUrl } from "../../../../utils/config";

const LinkButton = () => {
  const postMessageEventHandler = (event) => {
    if (event?.data?.isExtensionInstalled !== undefined) {
      setIsExtensionInstalled(event.data.isExtensionInstalled);
    }

    if (event?.data?.iframeStorageData) {
      removeLoginDataFromLocalStorage();
      for (const key in event.data.iframeStorageData) {
        saveDataToLocalStorage(key, event.data.iframeStorageData[key]/* , true */);
      }
    }
  };

  syncLocalStorageWithCookies();

  const [isLoading, setIsLoading] = useState(true);
  const { isExtensionInstalled, setIsExtensionInstalled } =
    useExtensionInstalled(postMessageEventHandler, setIsLoading);
  useGetStorageData(isExtensionInstalled);
  const { isUserSignedIn } = useUserCheck(isExtensionInstalled);

  const handleClickInstallExtension = () => {
    document.cookie = `${POST_INSTALL_EXTENSION_SRC_COOKIE_NAME}=${POST_INSTALL_EXTENSION_SRC_COOKIE_VALUE}; ${COOKIE_OPTIONS_STRING}`;

    window.open(CHROME_STORE_EXTENSION_ADDRESS, "_blank");
  };

  /**
   * When we redirect from hubspot integration page(iframe), we should transfer local storage data
   * to /install-hubspot-app page.
   */
  function syncLocalStorageWithCookies() {
    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get(SOURCE_QUERY_PARAM);

    if (source) {
      const tempLocalStorageData = getCookie(
        TEMP_LOCAL_STORAGE_DATA_COOKIE_NAME
      );

      if (tempLocalStorageData) {
        const parsedData = JSON.parse(tempLocalStorageData);

        for (const key in parsedData) {
          saveDataToLocalStorage(key, parsedData[key], true);
        }

        removeCookie(TEMP_LOCAL_STORAGE_DATA_COOKIE_NAME);
      }

      window.history.replaceState(
        null,
        document.title,
        window.location.pathname
      );
    }
  }

  const handleClickSignIn = () => {
    sendMessageToExtension({
      message: "need-log-in",
    });
  };

  const handleClickInstallApp = async () => {
    // We pass accessToken to get a user data BE.
    window.location.href = `${apiUrl}/install-hubspot-app-step-2?accessToken=${getAccessToken()}`;
  };

  const CurrentButton = () => {
    if (isExtensionInstalled && !isUserSignedIn) {
      return (
        <Button
          onClick={handleClickSignIn}
          text="Sign In"
          isLoading={isLoading}
        />
      );
    }

    if (isUserSignedIn) {
      return (
        <Button
          onClick={handleClickInstallApp}
          text="Install App"
          isLoading={isLoading}
        />
      );
    }

    return (
      <Button
        onClick={handleClickInstallExtension}
        text="Install Extension"
        isLoading={isLoading}
      />
    );
  };

  return <CurrentButton />;
};

export default LinkButton;
