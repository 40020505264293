import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import CampaignWizard from "../components/CreateCampaign/CampaignWizard";
import Loader from "../components/Loader";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

import { getTempCampaign, resetTempCampaign } from "../services/campaigns";
import { getWizardTabs } from "../components/CreateCampaign/utils";
import {
  saveObjectToLocalStorage,
  removeDataFromLocalStorage,
  removeCreateCampaignDataFromLocalStorage,
} from "../api/localStorage";
import { loadUserData } from "../services/user";
import { POST_INSTALL_EXTENSION_SRC_COOKIE_NAME } from "../utils/constants";
import { getCookie } from "../utils/utils";

function CreateCampaign(props) {
  const { crm } = props;

  const location = useLocation();
  const { isRecreatingCampaignIframe } = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(!isRecreatingCampaignIframe);
  const [tempCampaign, setTempCampaign] = useState(null);

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const fixBeginDateIfNeeded = (beginDate) => {
    try {
      if (beginDate?.endsWith("T00:00:00.000Z")) {
        const [year, month, day] = beginDate.split(/[^0-9]/);
        const d = new Date();
        d.setFullYear(+year);
        d.setMonth(+month - 1);
        d.setDate(+day);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d.toISOString();
      }
    } catch {}
    return beginDate;
  };

  const fetchTempCampaign = async () => {
    if (!isLoading) {
      return;
    }

    removeCreateCampaignDataFromLocalStorage();

    const res = await getTempCampaign(crm);
    if (res.success && res.result.tempCampaign?.tempJson) {
      const {
        tempCampaign: { createdAt, tempJson, id },
        campaign,
      } = res.result;
      try {
        const parsedTempCampaign = JSON.parse(tempJson);
        const { name, crmType, activeTab } = parsedTempCampaign;
        if (
          name &&
          crmType === crm &&
          Object.values(
            getWizardTabs(crmType, parsedTempCampaign.type) || {}
          ).includes(activeTab)
        ) {
          if (parsedTempCampaign.beginDate) {
            parsedTempCampaign.beginDate = fixBeginDateIfNeeded(
              parsedTempCampaign.beginDate
            );
          }
          if (campaign?.beginDate) {
            campaign.beginDate = fixBeginDateIfNeeded(campaign.beginDate);
          }
          setTempCampaign({
            createdAt,
            tempJson: { ...parsedTempCampaign, tempCampaignId: id },
            campaign,
          });
        }
      } catch {}
    }

    removeDataFromLocalStorage("isCampaignChanged");
    removeDataFromLocalStorage("tempCampaignId");
    removeDataFromLocalStorage("currentTempCampaign");
    removeDataFromLocalStorage("currentDraftCampaign");

    setIsLoading(false);
  };

  useEffect(() => {
    const postInstallExtensionCookieValue = getCookie(
      POST_INSTALL_EXTENSION_SRC_COOKIE_NAME
    );
    const isNotForceToShowPostSignUpOnboarding = Boolean(
      postInstallExtensionCookieValue
    );

    loadUserData(
      null,
      (res) => {
        setIsLoadingUserData(false);

        if (
          res?.result?.postSignUpOnboarding?.completed !== false ||
          isNotForceToShowPostSignUpOnboarding
        ) {
          fetchTempCampaign();
        }
      },
      isNotForceToShowPostSignUpOnboarding
    );
  }, []);

  if (isLoading || isLoadingUserData) {
    return <Loader parentSize={true} />;
  }

  if (tempCampaign) {
    const handleStartOver = async () => {
      removeDataFromLocalStorage("currentTempCampaign");
      removeDataFromLocalStorage("currentDraftCampaign");
      setTempCampaign(null);
      await resetTempCampaign(crm);
    };
    const handleResume = () => {
      const { campaign, tempJson } = tempCampaign;
      if (campaign) {
        saveObjectToLocalStorage("currentDraftCampaign", campaign);
      } else {
        removeDataFromLocalStorage("currentDraftCampaign");
      }
      saveObjectToLocalStorage("currentTempCampaign", tempJson, true);
      setTempCampaign(null);
    };
    const { createdAt } = tempCampaign;

    const date = new Date(createdAt);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return (
      <>
        <CheckExtensionVersion />
        <CampaignWizard
          {...props}
          isResumeStep={true}
          date={formattedDate}
          handleResume={handleResume}
          handleStartOver={handleStartOver}
        />
      </>
    );
  }

  return (
    <>
      <CheckExtensionVersion />
      <CampaignWizard {...props} />
    </>
  );
}

export default CreateCampaign;
