import { o365OauthClientId, o365OauthTenantId } from "./config";
import { ENVs } from "./constants";

const currentEnvValue = () => {
  const envValue = Object.values(ENVs).find((value) => {
    return value === window.location.origin;
  });

  return envValue || null;
};

const currentEnvName = () => {
  const envName = Object.keys(ENVs).find(
    (key) => ENVs[key] === window.location.origin
  );
  return envName;
};

const authorityUrl = () => {
  return currentEnvName() === "prod"
    ? "https://login.microsoftonline.com/common"
    : `https://login.microsoftonline.com/${o365OauthTenantId}`;
};

export const msalConfig = {
  auth: {
    clientId: o365OauthClientId,
    authority: authorityUrl(),
    redirectUri: currentEnvValue(),
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
  prompt: "login",
};

export const AuthErrorCodes = {
  userCancelled: "user_cancelled",
};
