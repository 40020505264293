import React from "react";
import styled from "styled-components";

import Title from "./Title";
import Section from "./Section";

const supportList = [
  {
    id: 1,
    name: "help@salesstream.ai",
    url: "mailto:help@salesstream.ai",
  },
  {
    id: 2,
    name: "SalesStream.ai",
    url: "https://salesstream.ai",
  },
];

function Support() {
  return (
    <>
      <Title>SalesStream Support</Title>
      <Section>
        <List>
          {supportList.map((support) => {
            return (
              <ListItem key={support.id}>
                <Link href={support.url}>{support.name}</Link>
              </ListItem>
            );
          })}
        </List>
      </Section>
    </>
  );
}

export default Support;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  line-height: 24px;
  margin: 0;
  padding: 0;
`;

const Link = styled.a`
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.15s ease-out;
  color: #0091ae;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #007a8c;
    text-decoration: underline;
  }
`;
