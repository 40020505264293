import React from "react";
import styled from "styled-components";

import Details from "./Details";
import Requirements from "./Requirements";
import Support from "./Support";

function MainInfo() {
  return (
    <MainContainer>
      <Container>
        <Details />
      </Container>
      <Container>
        <Requirements />
      </Container>
      <Container>
        <Support />
      </Container>
    </MainContainer>
  );
}

export default MainInfo;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;
