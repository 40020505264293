import React from "react";
import styled from "styled-components";

function Separator() {
  return <Container>OR</Container>;
}

export default Separator;

const Container = styled.span`
  width: 90%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
  position: relative;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background-color: #757678;
  }

  &::before {
    margin-right: 10px;
  }

  &::after {
    margin-left: 10px;
  }
`;
