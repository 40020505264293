import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import styled from "styled-components";

import Login from "../components/Login";
import CloseBtn from "../components/CloseBtn";
import AskResetPassword from "../components/AskResetPassword";
import ResetPassword from "../components/ResetPassword";

import { removeLoginDataFromLocalStorage } from "../api/localStorage";
import { LOGIN_PAGE_CONTENT_LIST } from "../utils/constants";

const LoginPage = ({ loginPageContent }) => {
  const location = useLocation();
  const { resetToken, invited } = queryString.parse(location.search);
  const isInvited = invited === "true";

  useEffect(() => {
    removeLoginDataFromLocalStorage();
  }, []);

  return (
    <main className="app">
      <CloseBtn />
      <div className="layout">
        <SidePanel>
          <LogoBlock>
            <Logo src="/images/logos/full-logo-white.png" alt="Logo" />
          </LogoBlock>
          <SidePanelContent>
            <SidePanelContentTitle>
              AI superpowers for your sales team
            </SidePanelContentTitle>
            <SidePanelContentDescription>
              Seamless integration with your CRM platform empowering you to
              supercharge your outreach initiatives. Streamline your workflow
              and automatically carry out personalized outreach at scale
            </SidePanelContentDescription>
            {(loginPageContent !== LOGIN_PAGE_CONTENT_LIST.RESET_PASSWORD ||
              !isInvited) && (
              <>
                <SidePanelContentDescription>
                  Don’t have an account yet?
                </SidePanelContentDescription>
                <Link to="/register">
                  <SignUpBtn>Sign Up Now</SignUpBtn>
                </Link>
              </>
            )}
          </SidePanelContent>
        </SidePanel>
        <Content>
          {loginPageContent === LOGIN_PAGE_CONTENT_LIST.ASK_RESET_PASSWORD ? (
            <AskResetPassword />
          ) : loginPageContent === LOGIN_PAGE_CONTENT_LIST.RESET_PASSWORD ? (
            <ResetPassword resetToken={resetToken} isInvited={isInvited} />
          ) : (
            <Login />
          )}
        </Content>
      </div>
    </main>
  );
};

export default LoginPage;

const SidePanel = styled.div`
  width: 55%;
  background: no-repeat url(log_screens_bg.png);
  background-size: cover;
  border-right: 2px solid #e6e6e6;
  padding: 15px;
`;

const LogoBlock = styled.div`
  height: 20%;
`;

const Logo = styled.img`
  width: 161px;
  margin: 40px 0 0 40px;
`;

const SidePanelContent = styled.div`
  margin: 10% 20% 0 20%;
`;

const SidePanelContentTitle = styled.div`
  font-size: 25pt;
  font-weight: 800;
  color: white;
  margin-bottom: 20px;
`;

const SidePanelContentDescription = styled.div`
  color: white;
  margin-bottom: 20px;
  font-size: 12px;
`;

const SignUpBtn = styled.button`
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  height: 50px;
  width: 170px;
  background-color: transparent;
  cursor: pointer;
`;

const Content = styled.div`
  width: 45%;
  background-color: white;
  padding: 40px;
  display: flex;
  align-items: center;
`;
