import { useState, useEffect, useRef } from "react";
import { Tooltip } from "react-tooltip";

import { updateHubspotFields } from "../services/integrations";

import styled from "styled-components";
import theme from "../theme";
import { sendMessageToExtension } from "../utils/postToExtension";

const HubspotFieldsPopup = ({ fieldsConfig, onClose, isWizard }) => {
  const [fields, setFields] = useState(fieldsConfig);
  const [tableWidth, setTableWidth] = useState(null);
  const tableContRef = useRef(null);

  const changeProperty = (property) => {
    const newFields = { ...fields, [property]: !fields[property] };
    setFields(newFields);
  };

  const saveProperties = async () => {
    const response = await updateHubspotFields({ fieldsConfig: fields });

    if (!response) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while updating hubspot fields",
        },
      });
    }

    onClose();
  };

  useEffect(() => {
    document.body.classList.add("popup-open");

    const handleResize = () => {
      const tWidth = tableContRef?.current?.getBoundingClientRect()?.width;
      setTableWidth(tWidth ? tWidth - 6 /* width of scrollbar */ : tWidth);
    };

    const tableContResizeObserver = new ResizeObserver(handleResize);
    const tableContElm = tableContRef?.current;
    if (tableContElm) {
      tableContResizeObserver.observe(tableContElm);
    }

    handleResize();

    return () => {
      document.body.classList.remove("popup-open");

      tableContResizeObserver.disconnect();
    };
  }, []);

  return (
    <Container $isFullScreen={isWizard}>
      <SidePanel>
        <SidePanelContentTitle>Relevant Properties</SidePanelContentTitle>
        <SidePanelContentDescription>
          Reach has identified the properties relevant to creating outreach
          campaigns. If the AI has made a mistake, please select the appropriate
          properties
        </SidePanelContentDescription>
      </SidePanel>
      <Content>
        <Title>Relevant Properties</Title>

        <OverflowContent ref={tableContRef}>
          <Table>
            <TableHead>
              <TableRow $bgColor="#f3f2f2">
                <TableHeadData
                  $padding="15px 5px 15px 15px"
                  $maxWidth={`calc(${
                    tableWidth ? `${tableWidth}px` : "100%"
                  } - 70px)`}
                  $width={`calc(${
                    tableWidth ? `${tableWidth}px` : "100%"
                  } - 70px)`}
                >
                  Property
                </TableHeadData>
                <TableHeadData $padding="15px 15px 15px 10px" $width="70px">
                  Selected
                </TableHeadData>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(fields).map((field, index) => {
                return (
                  <TableRow
                    $cursor={"pointer"}
                    $borderBottom={`1px solid ${theme.colors.divider_color}`}
                    key={index}
                  >
                    <TableData
                      $padding="15px 5px 15px 15px"
                      $maxWidth={`calc(${
                        tableWidth ? `${tableWidth}px` : "100%"
                      } - 70px)`}
                      $width={`calc(${
                        tableWidth ? `${tableWidth}px` : "100%"
                      } - 70px)`}
                    >
                      <span
                        data-tooltip-id="field-tooltip"
                        data-tooltip-content={field}
                      >
                        {field}
                      </span>
                    </TableData>
                    <TableData $padding="15px 15px 15px 10px" $width="70px">
                      <CheckboxBlock>
                        <input
                          id={"fieldsCheckbox" + field}
                          type="checkbox"
                          checked={fields[field]}
                          value={fields[field]}
                          onChange={() => changeProperty(field)}
                        />
                        <label
                          htmlFor={"fieldsCheckbox" + field}
                          className="toggle"
                        ></label>
                      </CheckboxBlock>
                    </TableData>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </OverflowContent>
        <Tooltip
          id="field-tooltip"
          place="right"
          className="custom-tooltip"
          style={{
            maxWidth: 200,
            textTransform: "capitalize",
            wordBreak: "break-word",
          }}
        />
        <ButtonGroup>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <SaveButton onClick={saveProperties}>Save</SaveButton>
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default HubspotFieldsPopup;

const Container = styled.div`
  position: absolute;
  top: ${({ $isFullScreen }) => ($isFullScreen ? "0" : "70px")};
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${({ $isFullScreen }) =>
    $isFullScreen ? "100%" : "calc(100% - 70px)"};
`;
const SidePanel = styled.div`
  width: 30%;
  background-color: #fff;
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: column;
`;

const SidePanelContentTitle = styled.div`
  font-size: 2em;
  font-weight: 800;
  line-height: 0.9em;
  color: #808080;
  margin-bottom: 20px;
  font-family: "AlbertSansExtraBold", sans-serif;
  letter-spacing: 0rem;
`;

const SidePanelContentDescription = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 20px;
`;

const Content = styled.div`
  width: 70%;
  background-color: #f5f2ee;
  padding: 60px 45px 30px 45px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #cdcdcd;
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: calc(100% - 100px);
`;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const TableHeadData = styled.th`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 11px;
  color: #6d6d6d;
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "padding: 15px 5px 15px 5px"};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  font-weight: 800;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  height: 22px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "padding: 10px 5px 10px 5px"};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
  margin-top: 30px;
`;

const CancelButton = styled.button`
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.btn_border_color};
  color: ${({ theme }) => theme.colors.btn_border_color};
  border-radius: 5px;
  height: 40px;
  width: 120px;
  background-color: transparent;
  font-weight: 700;
`;

const SaveButton = styled.button`
  cursor: pointer;
  color: white;
  border-radius: 5px;
  height: 40px;
  width: 180px;
  margin-left: 20px;
  border: none;
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  font-weight: 700;
`;
