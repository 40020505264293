import { useState } from "react";
import { contactUs } from "../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import ConnectBtn from "./ConnectBtn";
import ContactUsSelect from "./ContactUsSelect";

import { REASON_OPTIONS } from "../utils/constants";

import styled from "styled-components";
import { sendMessageToExtension } from "../utils/postToExtension";
import { EMAIL_REGEX } from "../utils/constants";

const ContactUs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reasonFromUrl = searchParams.get("reason");

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState(
    reasonFromUrl && reasonFromUrl === "code" ? "Request an invite code" : null
  );
  const [details, setDetails] = useState("");

  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [reasonErrorMessage, setReasonErrorMessage] = useState("");
  const [detailsErrorMessage, setDetailsErrorMessage] = useState("");

  const handleReasonSelect = (value) => {
    setReason(value);
    setReasonErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setNameErrorMessage("");
    setEmailErrorMessage("");
    setReasonErrorMessage("");
    setDetailsErrorMessage("");

    setIsLoading(true);

    const result = await contactUs({ name, reason, email, details });

    setName("");
    setEmail("");
    setReason("");
    setDetails("");

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong with email sending",
        },
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    navigate("/login");
  };

  const isFormValid = () => {
    let isNameValid = true;
    let isEmailValid = true;
    let isReasonValid = true;
    let isDetailsValid = true;

    if (!name || name === "") {
      setNameErrorMessage("Please enter your name");
      isNameValid = false;
    }

    if (!email || !EMAIL_REGEX.test(email) || email === "") {
      setEmailErrorMessage("Please enter a valid email address");
      isEmailValid = false;
    }

    if (!reason || reason === "") {
      setReasonErrorMessage("Please enter a reason");
      isReasonValid = false;
    }

    if (!details || details === "") {
      setDetailsErrorMessage("Please enter a details");
      isDetailsValid = false;
    }

    if (!isNameValid || !isEmailValid || !isReasonValid || !isDetailsValid) {
      return false;
    }

    return true;
  };

  return (
    <section>
      <Title>Contact Us</Title>

      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="name">Your Name</Label>
            <Relative>
              <Input
                id="name"
                placeholder="Enter your name..."
                value={name}
                onChange={(e) => setName(e.target.value)}
                onInput={(e) => setNameErrorMessage("")}
                autoComplete="off"
              />
              {nameErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {nameErrorMessage && (
              <ErrorMessage>{nameErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="email">Your Email Address</Label>
            <Relative>
              <Input
                id="email"
                placeholder="Email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onInput={(e) => setEmailErrorMessage("")}
                autoComplete="off"
              />
              {emailErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {emailErrorMessage && (
              <ErrorMessage>{emailErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="reason">Reason</Label>
            <Relative>
              <ContactUsSelect
                options={REASON_OPTIONS}
                title="Reason"
                selected={reason}
                onSelect={(value) => handleReasonSelect(value)}
              />
              {reasonErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {reasonErrorMessage && (
              <ErrorMessage>{reasonErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="details">Details</Label>
            <Relative>
              <InputTextarea
                id="details"
                placeholder="Enter details..."
                value={details}
                onChange={(e) => setDetails(e.target.value)}
                onInput={(e) => setDetailsErrorMessage("")}
                autoComplete="off"
              />
              {detailsErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {detailsErrorMessage && (
              <ErrorMessage>{detailsErrorMessage}</ErrorMessage>
            )}
          </InputBlock>
        </Inputs>

        <Actions>
          <ConnectBtn type="submit" label="Send" disabled={isLoading} />
        </Actions>
      </Form>
    </section>
  );
};

export default ContactUs;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10%;
  height: 400px;
  overflow: auto;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const InputTextarea = styled.textarea`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 200px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 200px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;
