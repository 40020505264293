import React from "react";
import styled from "styled-components";

import WizardStep from "./WizardStep";

export default function WizardSteps({ wizardSteps, stepIndex }) {
  return (
    <>
      <Container>
        {wizardSteps.map((step, index) => (
          <WizardStep
            key={step.id}
            index={index}
            stepIndex={stepIndex}
            title={step.title}
          />
        ))}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  margin-top: 5%;
`;
