import styled from "styled-components";

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* row-gap: 15%; */
`;

export const TopTitle = styled.h3`
  margin-top: 40px;
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0em;
  line-height: 35.84px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  font-family: "AlbertSans", sans-serif;
`;

export const Message = styled.div`
  margin: 0 0 79px;
  max-width: 419px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18.2px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  font-family: "AlbertSans", sans-serif;
`;

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 58px;
  button {
    margin: 0;
  }
`;

export const TopBlock = styled.div`
  position: relative;
  padding-left: 70px;
  background-color: #fff;
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 0 70px;
  width: 100%;
`;
