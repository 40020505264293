import { useState } from "react";
import { askResetPassword } from "../services/auth";
import ConnectBtn from "./ConnectBtn";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { sendMessageToExtension } from "../utils/postToExtension";
import { EMAIL_REGEX } from "../utils/constants";
import { getDataFromLocalStorage } from "../api/localStorage";

const AskResetPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setEmailErrorMessage("");

    setIsLoading(true);

    const extensionId = getDataFromLocalStorage("extensionId");

    const result = await askResetPassword({ email, extensionId });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    sendMessageToExtension({
      message: "show-success-message",
      data: {
        message: "A password reset email has been sent to you",
        title: "",
      },
    });
    setEmail("");

    setIsLoading(false);
    navigate("/login");
  };

  const isFormValid = () => {
    let isEmailValid = true;

    if (!email || !EMAIL_REGEX.test(email) || email === "") {
      setEmailErrorMessage("Please enter a valid email address");
      isEmailValid = false;
    }

    return isEmailValid;
  };

  return (
    <section>
      <Title>Reset Password</Title>

      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="email">Your Email Address</Label>
            <Relative>
              <Input
                id="email"
                placeholder="Email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onInput={(e) => setEmailErrorMessage("")}
                autoComplete="off"
              />
              {emailErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {emailErrorMessage && (
              <ErrorMessage>{emailErrorMessage}</ErrorMessage>
            )}
          </InputBlock>
        </Inputs>

        <Actions>
          <ConnectBtn
            type="submit"
            label="Send Reset Email"
            disabled={isLoading}
          />
        </Actions>
      </Form>
    </section>
  );
};

export default AskResetPassword;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10%;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;
