import {
  fetchCompany,
  fetchUpdateCompany,
  fetchCompanies,
  fetchCreateInvite,
  fetchGetCompanyFilters,
  fetchInvoices,
} from "./queries.js";
import {
  saveObjectToLocalStorage,
  getObjectFromLocalStorage,
} from "../api/localStorage.js";

const getCompany = async () => {
  const result = await fetchCompany();

  if (result.message) {
    return {
      success: false,
      message: result.message,
    };
  }

  const company = result.company;

  saveObjectToLocalStorage("companyInfo", { company });

  return { success: true, company };
};

const updateCompany = async (data, forceUpdateLocalStorage = true) => {
  const result = await fetchUpdateCompany(data);

  if (result.message) {
    return {
      success: false,
      message: result.message,
    };
  }

  const company = result.company;

  const companyInfo = getObjectFromLocalStorage("companyInfo");

  const isCurrentCompany = companyInfo?.company?.id === company?.id;

  if (isCurrentCompany || forceUpdateLocalStorage) {
    saveObjectToLocalStorage("companyInfo", {
      company: isCurrentCompany
        ? { ...companyInfo.company, ...company }
        : company,
    });
  }

  return { success: true };
};

const getCompanies = async (data) => {
  try {
    const result = await fetchCompanies(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get companies failed" };
  }
};

const createInvite = async (data) => {
  if (!data.companyId && !data.companyName) {
    return { success: false, message: "Missed company id or name" };
  }

  if (!data.code) {
    return { success: false, message: "Missed code" };
  }

  if (!data.maxUses) {
    return { success: false, message: "Missed max uses" };
  }

  try {
    const result = await fetchCreateInvite(data);

    if (!result.invite) {
      return result;
    }

    return { success: true, invite: result.invite };
  } catch (error) {
    return { success: false, message: "Create invite failed" };
  }
};

const getCompanyFilters = async (data) => {
  try {
    const result = await fetchGetCompanyFilters(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to get 'Admin' filters" };
  }
};

const getInvoices = async () => {
  try {
    const result = await fetchInvoices();

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to get company invoices" };
  }
};

export {
  getCompany,
  updateCompany,
  getCompanies,
  createInvite,
  getCompanyFilters,
  getInvoices,
};
