import {
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../api/localStorage";
import {
  FIRST_TIME_CAMPAIGN_ONBOARDING_NAME_LIST,
  FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY,
  FSC_STEP_AUTOMATION_LEVEL,
  FSC_STEP_OFFSET_UNIT_LABEL,
} from "./constants";

const fileToBase64 = (file, callback) => {
  const reader = new FileReader();

  reader.onloadend = () => {
    const base64String = reader.result;
    callback(base64String);
  };

  reader.readAsDataURL(file);
};

const displayNumber = (val) => {
  if (typeof val === "number") {
    return val.toLocaleString("en-US");
  }
  return "";
};

const displayPercent = (val) => {
  if (typeof val === "number") {
    return `${Number(val.toFixed(2)).toLocaleString("en-US")}%`;
  }
  return "";
};

const displayUserRole = (role) => {
  return (
    role
      ?.replace(/_/g, " ")
      .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()) || ""
  );
};

const displayDate = (date) => {
  return date
    ? new Date(date).toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    : "";
};

const toSnakeCase = (string) => {
  return string.toLowerCase().replace(" ", "_");
};

const calcCampaignProgress = (campaign) => {
  const defaultProgress = 2;
  const formattedProgress = Math.round(campaign.progress) || defaultProgress;
  return formattedProgress < defaultProgress
    ? defaultProgress
    : formattedProgress;
};

const calcCampaignRemainingMinutes = (campaign) => {
  const { remainingTime } = campaign;
  if (typeof remainingTime === "number") {
    const formattedRemainingTime = Math.floor(remainingTime / 60000);
    return formattedRemainingTime === 0 ? "< 1" : formattedRemainingTime;
  }
  return campaign.contacts?.length < 50 ? "< 1" : "< 5";
};

const buildSequenceTemplateStepName = (step, useTab = false) => {
  const offsetUnitLabel = FSC_STEP_OFFSET_UNIT_LABEL[step.offsetUnit];
  const offsetText = step.offset
    ? ` - Offset ${step.offset}${
        offsetUnitLabel
          ? ` ${offsetUnitLabel}${step.offset > 1 ? "s" : ""}`
          : step.offsetUnit
      }`
    : "";

  const automationLevelLabel =
    step.automationLevel === FSC_STEP_AUTOMATION_LEVEL.AUTOMATIC
      ? "Automated"
      : step.automationLevel;

  return `Step ${step.orderNum}:${useTab ? "\t" : " "}${
    step.type
  } ${automationLevelLabel}${offsetText}`;
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function removeCookie(name) {
  document.cookie = `${name}=; SameSite=None; Secure; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}

const updateFirstTimeCampaignOnboardingUserList = ({
  userId,
  name,
  initializeWithDefaultNames = false,
}) => {
  const userList = getDataFromLocalStorage(
    FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY
  );
  const parsedList = JSON.parse(userList || "{}");

  if (!parsedList[userId]) {
    parsedList[userId] = initializeWithDefaultNames
      ? [...FIRST_TIME_CAMPAIGN_ONBOARDING_NAME_LIST]
      : [];
  }

  if (name && !parsedList[userId].includes(name)) {
    parsedList[userId].push(name);
  }

  saveDataToLocalStorage(
    FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY,
    JSON.stringify(parsedList)
  );
};

const isUserInFirstTimeCampaignOnboardingUserList = ({ userId, name }) => {
  const list = getDataFromLocalStorage(
    FIRST_TIME_CAMPAIGN_ONBOARDING_USER_LIST_LOCAL_STORAGE_KEY
  );
  const parsedList = JSON.parse(list || "{}");

  if (typeof name === "undefined") {
    return Object.keys(parsedList).includes(userId);
  }

  return parsedList[userId]?.includes(name) ?? false;
};

export {
  fileToBase64,
  displayNumber,
  displayPercent,
  displayUserRole,
  displayDate,
  toSnakeCase,
  calcCampaignProgress,
  calcCampaignRemainingMinutes,
  buildSequenceTemplateStepName,
  getCookie,
  removeCookie,
  updateFirstTimeCampaignOnboardingUserList,
  isUserInFirstTimeCampaignOnboardingUserList,
};
