import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BULLHORN_CAMPAIGN_TYPES, CAMPAIGN_TYPES } from "../constants";
import StepButton from "../../StepButton";
import { Tooltip } from "react-tooltip";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import { isBullhornMarketingCampaignType } from "../utils";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

const LABELS = {
  [BULLHORN_CAMPAIGN_TYPES.recruitForJob]: "Recruit for a job",
  [BULLHORN_CAMPAIGN_TYPES.marketToCandidates]: "Market to candidates",
  [BULLHORN_CAMPAIGN_TYPES.marketToClients]: "Market to clients",
};

function CampaignType({
  setCampaignType,
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
}) {
  const campaignTypeList = CAMPAIGN_TYPES[campaign.crmType];
  const parentRef = useRef(null);
  const [isCustomTypeInputOpened, setIsCustomTypeInputOpened] = useState(false);
  const [selectedType, setType] = useState(campaign.type);
  const [customType, setCustomType] = useState("");
  const [customTypeErrorMessage, setCustomTypeErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignType,
    parentRef,
  };

  const handleChange = (strategy) => {
    setErrorMessage("");
    setCustomTypeErrorMessage("");

    setType(strategy);
    setIsCustomTypeInputOpened(false);
    setCustomType("");

    if (campaign.crmType === CRMS.BULLHORN) {
      setCampaignType(strategy);
    }
  };

  const handleDefineNewStrategy = () => {
    setIsCustomTypeInputOpened(true);

    setErrorMessage("");
    setCustomTypeErrorMessage("");

    setType("");
  };

  const handleClickNext = (event) => {
    event.preventDefault();
    if (selectedType === "" && !isCustomTypeInputOpened) {
      setErrorMessage("Please choose a campaign type");
      return;
    }
    if (selectedType === "" && isCustomTypeInputOpened) {
      if (customType.length < 1) {
        setCustomTypeErrorMessage("This field is required");
        return;
      }
      if (customType.length > 0) {
        setCustomTypeErrorMessage("");
        setCampaign((campaign) => ({ ...campaign, type: customType }));
        handleIncreaseStep();
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      type: selectedType,
    }));
    handleIncreaseStep();
  };

  const handleClickBack = (event) => {
    event.preventDefault();
    if (selectedType === "" && isCustomTypeInputOpened && customType !== "") {
      setCampaign((campaign) => ({
        ...campaign,
        type: customType,
      }));
      handleDecreaseStep();
      return;
    }
    if (selectedType !== "") {
      setCampaign((campaign) => ({
        ...campaign,
        type: selectedType,
      }));
      handleDecreaseStep();
      return;
    }
    setCampaign((campaign) => ({
      ...campaign,
      type: "",
    }));
    handleDecreaseStep();
  };

  useEffect(() => {
    if (!campaignTypeList.includes(campaign.type) && campaign.type !== "") {
      setIsCustomTypeInputOpened(true);
      setCustomType(campaign.type);
      return;
    }

    setIsCustomTypeInputOpened(false);
  }, [campaign, campaignTypeList]);

  return (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <Title>Campaign Type</Title>
        <SelectBlock>
          <Label fontWeight="bold">What is the purpose of this campaign?</Label>
        </SelectBlock>
        <RadioButtonGroup>
          {campaignTypeList.map((type, index) => {
            const isDisabled =
              !!campaign.localBullhornData?.isMarketingCampaign &&
              !isBullhornMarketingCampaignType(type);
            const isChecked = type === selectedType;
            return (
              <React.Fragment key={index}>
                <RadioBlock
                  data-tooltip-id={isDisabled ? `no-job-tooltip` : null}
                  data-tooltip-content={
                    isDisabled
                      ? "You must navigate to a Job before starting a Recruitment campaign"
                      : null
                  }
                >
                  <div>
                    <RadioInput
                      id={type}
                      value={type}
                      name="campaign-type"
                      type="radio"
                      disabled={isDisabled}
                      checked={isChecked}
                      onChange={
                        isDisabled ? undefined : () => handleChange(type)
                      }
                    />
                    <RadioInputCustomChecked
                      $disabled={isDisabled}
                      $checked={isChecked}
                    />
                  </div>
                  <RadioInputLabel
                    htmlFor={type}
                    $disabled={isDisabled}
                    $checked={isChecked}
                    onClick={isDisabled ? undefined : () => handleChange(type)}
                  >
                    {LABELS[type] || type}
                  </RadioInputLabel>
                </RadioBlock>
                {isDisabled ? (
                  <Tooltip
                    id={`no-job-tooltip`}
                    className="custom-tooltip"
                    place="right"
                  />
                ) : null}
              </React.Fragment>
            );
          })}
        </RadioButtonGroup>

        {campaign.crmType !== CRMS.BULLHORN ? (
          <>
            <Divider />

            <CustomTypeLabel onClick={handleDefineNewStrategy}>
              + Define a new Campaign Type
            </CustomTypeLabel>
            {isCustomTypeInputOpened && (
              <InputContent>
                <Relative>
                  <CustomTypeInput
                    type="text"
                    placeholder="Enter the type"
                    value={customType}
                    onInput={(event) => setCustomType(event.target.value)}
                  />
                  {customTypeErrorMessage !== "" && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {customTypeErrorMessage !== "" && (
                  <ErrorMessage>{customTypeErrorMessage}</ErrorMessage>
                )}
              </InputContent>
            )}
            {errorMessage !== "" && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </>
        ) : null}
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleClickBack} isNext={false} />
        <StepButton onClick={handleClickNext} isNext={true} />
      </ButtonGroup>
    </>
  );
}

export default CampaignType;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const SelectBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 600;
  font-size: 15px;
`;

const RadioButtonGroup = styled.div``;

const RadioBlock = styled.div`
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-size: 13px;
  align-items: center;
  padding: 5px 0;
  width: fit-content;
`;

const RadioInput = styled.input.attrs({ type: "radio" })`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 20px;
  height: 20px;
  margin: 0 10px;
  display: ${({ checked }) => (checked ? "none" : "block")};
`;

const RadioInputCustomChecked = styled.div`
  display: ${({ $checked }) => ($checked ? "block" : "none")};
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  background-image: url("images/checked-radio-btn-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin: 0 10px;
`;

const RadioInputLabel = styled.label`
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  padding: 0 12px;
  font-weight: ${({ $checked }) => ($checked ? "700" : "400")};
  color: ${({ theme, $checked }) =>
    $checked ? theme.colors.saturated_purple : "#5A5A5A"};
`;

const CustomTypeLabel = styled.div`
  color: #4640de;
  font-size: 13px;
  margin-left: 50px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 15px;
  height: 2px;
  background-color: #858585;
  margin: 10px 50px 20px 50px;
`;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const Relative = styled.div`
  position: relative;
`;

const CustomTypeInput = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin: 0;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin: 0;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
`;
