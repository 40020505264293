import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "styled-components";

function CustomSelect({
  options,
  title,
  onSelect,
  selected,
  handleSelectOpen,
  width,
  minWidth,
  disabled,
  isClearable,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClearClick = (event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setSelectedOption(null);
    setIsOpen(false);
    onSelect(null);
  };

  const handleOptionClick = (option) => {
    if (option.disabled) return;
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option.value);
  };

  const handleSelectClick = () => {
    handleSelectOpen && handleSelectOpen();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSelectedOption(
      options.find((option) => option.value === selected) || null
    );
    setIsOpen(false);
  }, [selected, options]);

  return (
    <CustomSelectContainer
      $width={width}
      $minWidth={minWidth}
      $disabled={disabled}
    >
      <SelectHeader
        $width={width}
        $minWidth={minWidth}
        onClick={handleSelectClick}
      >
        <SelectedOption>
          {selectedOption !== undefined && selectedOption !== null
            ? selectedOption.label
            : title}
        </SelectedOption>
        <SelectedOptionButtons>
          {!!isClearable && !!selectedOption && (
            <img
              src="/close-icon.png"
              width="12"
              alt="clear"
              onClick={handleClearClick}
            />
          )}
          <img src="/images/arrow-down.png" width="8" alt="arrow-down" />
        </SelectedOptionButtons>
      </SelectHeader>
      {isOpen && (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              key={option.value}
              className="option"
              $disabled={option.disabled}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </CustomSelectContainer>
  );
}

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isClearable: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  onSelect: PropTypes.func.isRequired,
};

export default CustomSelect;

const Option = styled.div`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  background-color: ${({ $disabled }) => ($disabled ? "#d3d3d3" : "#fff")};
  padding: 8px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CustomSelectContainer = styled.div`
  position: relative;
  font-size: 12px;
  color: #000000;
  box-shadow: 0 0 10px #dfdfdf;
  border-radius: 3px;
  height: 38px;
  width: ${({ $width }) => ($width ? $width : "fit-content")};
  min-width: ${({ $minWidth }) => $minWidth || "208px"};
  margin-bottom: 8px;
  background-color: white;
  ${({ $disabled }) =>
    $disabled ? "pointer-events: none; opacity: 0.65" : ""};
`;

const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #d1d1d1;
  cursor: pointer;
  height: 38px;
  width: ${({ $width }) => ($width ? $width : "fit-content")};
  min-width: ${({ $minWidth }) => $minWidth || "208px"};
  border-radius: 3px;
`;

const SelectedOption = styled.div`
  flex: 1;
  padding-right: 3px;
`;

const SelectedOptionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 5px;
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #d1d1d1;
  border-radius: 3px;
  z-index: 1;
`;
