import React from "react";
import styled from "styled-components";

function Description({ children }) {
  return <Container>{children}</Container>;
}

export default Description;

const Container = styled.span`
  font-size: 14px;
  line-height: 24px;
`;
