import React from "react";
import styled from "styled-components";
import StepButton from "../StepButton";

export default function WizardStepControls({ stepConfig, goToTab }) {
  return (
    <>
      {stepConfig.prevTab && (
        <StepButton
          onClick={(event) => goToTab(event, stepConfig.prevTab)}
          isNext={false}
        />
      )}

      {stepConfig.nextTab && (
        <StepButton
          onClick={(event) => goToTab(event, stepConfig.nextTab)}
          isNext={true}
        />
      )}

      {!stepConfig.nextTab && (
        <FinishBtn onClick={(event) => goToTab(event, "finish")}>
          Finish
        </FinishBtn>
      )}
    </>
  );
}

const FinishBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: white;
  border-radius: 5px;
  height: 40px;
  width: ${({ $width }) => ($width ? $width : "120px")};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  background-color: ${({ disabled, theme }) =>
    disabled ? "rgb(71,17,104,0.75)" : theme.colors.saturated_purple};
  font-weight: 700;
  font-size: 12px;
  font-family: "AlbertSansExtraBold", sans-serif;
`;
