const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const DAYS_OF_WEEK_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const CAMPAIGN_UPDATE_STATUSES = {
  COMPLETED: "Completed",
  PAUSED: "Paused",
  RESUME: "Resume",
  DRAFT: "Draft",
  READY_TO_LAUNCH: "Ready to launch",
  GENERATING: "Generating",
  NOT_STARTED: "Not started",
  START: "Start",
  ARCHIVED: "Archived",
};

const CANDIDATES_STATUSES = {
  DRAFT: "Draft",
  NOT_STARTED: "Not started",
  SCHEDULED: "Scheduled",
  EMAIL_SENT: "Email Sent",
  READ: "Read",
  BOUNCE: "Bounce",
  UNSUBSCRIBE: "Unsubscribe",
  REPLIED: "Replied",
  SUCCESS: "Success",
  COMPLETED: "Completed",
  PAUSED: "Paused",
  ERROR: "Error",
  PENDING: "Pending",
  FAIL: "Fail",
};

const CAMPAIGN_STATUSES_COLOR = {
  "Ready to launch": "#cc5a36",
  Generating: "#cc5a36",
  "Not started": "#a1a1a1",
  Active: "#20b0ee",
  Completed: "#6c8a2c",
  Paused: "#ffc700",
  Error: "#dd3c3c",
  Fail: "#dd3c3c",
};

const CAMPAIGN_STATUSES = {
  DRAFT: "Draft",
  READY_TO_LAUNCH: "Ready to launch",
  NOT_STARTED: "Not started",
  PAUSED: "Paused",
  ACTIVE: "Active",
  COMPLETED: "Completed",
  ERROR: "Error",
  CREDITS_ERROR: "Credits error",
};

const MESSAGE_STATUSES = {
  PREVIEW: "Preview",
  NOT_SENT: "Not sent",
  SENT: "Sent",
  USER_REPLY: "User reply",
};

export {
  MONTH_NAMES,
  DAYS_OF_WEEK_NAMES,
  CAMPAIGN_UPDATE_STATUSES,
  CANDIDATES_STATUSES,
  CAMPAIGN_STATUSES_COLOR,
  CAMPAIGN_STATUSES,
  MESSAGE_STATUSES,
};
