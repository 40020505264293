import { getObjectFromLocalStorage } from "../api/localStorage";
import { sendMessageToExtension } from "./postToExtension";

const getUserFromLocalStorage = () => {
  const user = getObjectFromLocalStorage("user");

  if (!user) {
    console.log("Need to log in.  Unable to find user in local storage (inside routine).")
    sendMessageToExtension({
      message: "need-log-in",
    });
    return null;
  }
  return user;
};

export default getUserFromLocalStorage;
