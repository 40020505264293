import {
  fetchGetNotifications,
  fetchReadNotifications,
  fetchGetNotificationsCount,
} from "./queries";

const getNotifications = async (data) => {
  try {
    const result = await fetchGetNotifications(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to get notifications" };
  }
};

const setAsReadNotifications = async (data) => {
  try {
    const result = await fetchReadNotifications(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to mark notifications as read" };
  }
};

const getNotificationsCount = async (data) => {
  try {
    const result = await fetchGetNotificationsCount(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Failed to get notifications" };
  }
};
export { getNotifications, setAsReadNotifications, getNotificationsCount };
