/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import Loader from "../../Loader";
import { getCompany, updateCompany } from "../../../services/company";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { getObjectFromLocalStorage } from "../../../api/localStorage";
import { sendEmail } from "../../../services/integrations";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

function SimpleConnect({ onReloadIntegrations }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [isAllowed, setIsAllowed] = useState(null);
  const [company, setCompany] = useState(null);

  const handleUpdate = async (e) => {
    e?.preventDefault();

    setIsLoading(true);

    const response = await updateCompany({
      allowSimpleConnect: isAllowed,
    });

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      setIsAllowed(company.allowSimpleConnect);
      setIsLoading(false);
      return;
    }

    setCompany({ ...company, allowSimpleConnect: isAllowed });

    setIsLoading(false);

    onReloadIntegrations && onReloadIntegrations();
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const response = await getCompany();

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: response.message,
          },
        });

        navigate("/campaigns");
        setIsLoading(false);
        return;
      }

      setCompany(response.company);
      setIsAllowed(response.company.allowSimpleConnect);

      setIsLoading(false);
    };

    const companyInfo = getObjectFromLocalStorage("companyInfo");

    if (typeof companyInfo?.company?.allowSimpleConnect === "boolean") {
      setCompany(companyInfo.company);
      setIsAllowed(companyInfo.company.allowSimpleConnect);
      setIsLoading(false);
      return;
    }

    fetchCompany();
  }, []);

  const handleSendEmailViaSmtp = async (e) => {
    e?.preventDefault();

    setIsLoading(true);

    const result = await sendEmail({ checkSmtp: true });

    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message || "Failed to send email",
        },
      });
    }
  };

  const currentUser = getUserFromLocalStorage();

  return (
    <Content>
      {isLoading && isAllowed === null ? (
        <Loader parentSize={true} />
      ) : (
        <Section $isLoading={isLoading}>
          {isLoading && <Loader parentSize={true} />}
          <Title>Simple Connect</Title>
          <Inputs>
            <InputBlock>
              <CheckboxBlock>
                <input
                  id="isAllowedCheckbox"
                  type="checkbox"
                  checked={isAllowed}
                  value={isAllowed}
                  onChange={(event) => setIsAllowed(event.target.checked)}
                />
                <label htmlFor="isAllowedCheckbox" className="toggle"></label>
                <CheckboxLabel $checked={isAllowed}>
                  Allow OAuth-based integration
                </CheckboxLabel>
              </CheckboxBlock>
            </InputBlock>
          </Inputs>

          <Actions>
            <UpdateBtn
              onClick={handleUpdate}
              disabled={isAllowed === company.allowSimpleConnect}
            >
              Update
            </UpdateBtn>
          </Actions>

          {!!currentUser?.email?.includes("raph") && (
            <>
              <br />
              <hr />
              <Actions>
                <UpdateBtn onClick={handleSendEmailViaSmtp}>
                  Send Email (SMTP)
                </UpdateBtn>
              </Actions>
            </>
          )}
        </Section>
      )}
    </Content>
  );
}

export default SimpleConnect;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Section = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           z-index: 1;
         }`
      : ""}
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const UpdateBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
