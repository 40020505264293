/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  getCandidateMessages,
  generateReplyForMessage,
  sendReplyMessage,
  changeCandidateStatus,
} from "../../../services/candidates";
import styled, { keyframes } from "styled-components";
import { convertDateToEmailSentFormat } from "../utils";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEnvelopeCircleCheck,
  faCircleCheck,
  faReply,
  faChevronDown,
  faMessage,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { groupBy, sortBy } from "lodash";
import theme from "../../../theme";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { MESSAGE_STATUSES, CANDIDATES_STATUSES } from "../constants";
import { Tooltip } from "react-tooltip";

const CONTENT_TYPES = {
  EMAIL: "email",
  PROMPT: "prompt",
};

const buildPromptSequence = (promptSequence) => {
  if (!promptSequence || !promptSequence?.length) {
    return "No Prompt";
  }

  return promptSequence.map((prompt, index) => {
    return (
      <PromptItem key={index}>
        <PromptRole>
          <PromptRoleLabel>Role:</PromptRoleLabel>
          <PromptRoleValue>{prompt.role}</PromptRoleValue>
        </PromptRole>
        <PromptContent>
          <PromptContentLabel>Content:</PromptContentLabel>
          <PromptContentValue>
            {prompt.content.replace(/\\n/g, "\n")}
          </PromptContentValue>
        </PromptContent>
      </PromptItem>
    );
  });
};

const CandidatesPopup = ({
  candidate,
  isFollowUpsAsReplies,
  isAdmin,
  handleClose,
  onStatusChanged,
}) => {
  const user = getUserFromLocalStorage();

  const [activeMessageIndex, setActiveMessageIndex] = useState({
    index: 0,
    subIndex: 0,
  });
  const [activeContent, setActiveContent] = useState(CONTENT_TYPES.EMAIL);
  const [isShowNoPhone, setIsShowNoPhone] = useState(false);
  const [messages, setMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userReply, setUserReply] = useState(null);
  const [isReplyLoading, setIsReplyLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isChangeStatusDropdownOpen, setIsChangeStatusDropdownOpen] =
    useState(false);
  const [changedStatus, setChangedStatus] = useState(null);

  const activeMessage =
    messages[activeMessageIndex.index]?.[activeMessageIndex.subIndex];

  const parentActiveMessage = activeMessage?.replyFor
    ? messages[activeMessageIndex.index].find(
        (m) =>
          m[
            activeMessage.status === MESSAGE_STATUSES.USER_REPLY
              ? "conversationId"
              : "externalId"
          ] === activeMessage.replyFor
      )
    : null;

  const buildContentBody = () => {
    return (
      (activeMessage.text || "[n/a]") +
      (parentActiveMessage
        ? '<div class="parentMessage">' + parentActiveMessage.text + "</div>"
        : "")
    );
  };

  const changeStatus = async (status) => {
    const res = await changeCandidateStatus({
      campaignId: candidate.campaignId,
      candidateId: candidate.id,
      status,
    });

    if (res.success === false) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.errorMessage,
        },
      });
    } else {
      setChangedStatus(
        status === CANDIDATES_STATUSES.SUCCESS ? "Goal Met" : status
      );
      onStatusChanged();
    }
  };

  const fetchCandidateMessages = async (afterReply = false) => {
    setIsLoading(true);
    const data = await getCandidateMessages({
      campaignId: candidate.campaignId,
      candidateId: candidate.id,
    });

    if (data.success !== undefined && !data.success) {
      setErrorMessage(data.message);
      setIsLoading(false);
      return;
    }

    const allMessages = data.result.messages.map((m) =>
      m.conversationId
        ? m
        : { ...m, conversationId: m.attempt, externalId: m.attempt }
    );

    const groupedMessages = sortBy(
      Object.values(groupBy(allMessages, "conversationId")).map((messageList) =>
        sortBy(messageList, ["createdAt"])
      ),
      (messageList) => {
        return messageList[0].createdAt;
      }
    );

    setMessages(groupedMessages);
    setIsShowNoPhone(
      !candidate.phoneNumber && allMessages.some((m) => m.type === "sms")
    );

    const lastIndexOfGroupWithConversation = groupedMessages.findLastIndex(
      (g) => g[0]?.conversationId !== g[0]?.attempt
    );

    if (allMessages.length) {
      const index =
        afterReply && groupedMessages[activeMessageIndex.index]
          ? activeMessageIndex.index
          : lastIndexOfGroupWithConversation !== -1
          ? lastIndexOfGroupWithConversation
          : 0;
      setActiveMessageIndex({
        index,
        subIndex: groupedMessages[index].length - 1,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchCandidateMessages();
  }, [candidate]);

  const toggleActiveContent = () => {
    if (activeContent === CONTENT_TYPES.EMAIL) {
      setActiveContent(CONTENT_TYPES.PROMPT);
    } else {
      setActiveContent(CONTENT_TYPES.EMAIL);
    }
  };

  const setActiveMessage = (index, subIndex) => {
    setActiveMessageIndex({
      index,
      subIndex,
    });
    setActiveContent(CONTENT_TYPES.EMAIL);
    handleCancelReply();
  };

  const handleClickReply = () => {
    const replyText = "<p></p>";
    setUserReply({ html: replyText, message: { text: replyText } });

    setTimeout(() => {
      try {
        const replyEmailBox = document.getElementById("replyEmailBox");
        if (replyEmailBox) {
          replyEmailBox.focus();
          const s = window.getSelection();
          const r = document.createRange();
          r.setStart(replyEmailBox, 0);
          r.setEnd(replyEmailBox, 0);
          s.removeAllRanges();
          s.addRange(r);
        }
      } catch (e) {
        console.error("replyEmailBox error: ", e);
      }
    }, 100);
  };

  const handleCancelReply = (event) => {
    event?.preventDefault();

    setUserReply(null);
  };

  const handleClickSendReply = async (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    try {
      const replyEmailBox = document.getElementById("replyEmailBox");
      if (!replyEmailBox.textContent.trim()) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: "Please enter an answer",
          },
        });
        return;
      }
    } catch (e) {
      console.error("replyEmailBox error: ", e);
    }

    const reply = {
      text: "<div>" + userReply.message.text.replace("<p></p>", "") + "</div>",
      subject:
        userReply.message.subject || "Re: " + (activeMessage.subject || ""),
      prompt: userReply.message.prompt,
    };

    setIsReplyLoading(true);

    const res = await sendReplyMessage(activeMessage, reply);

    setIsReplyLoading(false);

    handleCancelReply();

    if (res.success === false) {
      console.log(res);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Could not send reply message",
        },
      });
    } else {
      fetchCandidateMessages(true);
    }
  };

  const handleClickGenerateReply = async (event) => {
    event?.preventDefault();

    setIsReplyLoading(true);

    const res = await generateReplyForMessage(activeMessage);

    setIsReplyLoading(false);

    if (res.success === false) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.errorMessage,
        },
      });
    } else {
      const { message } = res;
      setUserReply({ html: message.text, message });
    }
  };

  const handleInputReply = (event) => {
    setUserReply({
      ...userReply,
      message: { ...userReply.message, text: event.target.innerHTML },
    });
  };

  const displayEmailDate = (date) => {
    return new Date(date)
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", "");
  };

  const allowToChangeStatus =
    !changedStatus &&
    !candidate.isUnsubscribe &&
    ![
      CANDIDATES_STATUSES.BOUNCE,
      CANDIDATES_STATUSES.COMPLETED,
      CANDIDATES_STATUSES.SUCCESS,
      CANDIDATES_STATUSES.DRAFT,
      CANDIDATES_STATUSES.NOT_STARTED,
      CANDIDATES_STATUSES.PENDING,
    ].includes(candidate.status);

  return (
    <PopupContainer>
      <Content>
        <PopupHeader>
          <div>
            <PopupHeaderTitle
              $cursor={allowToChangeStatus ? "pointer" : "default"}
            >
              <p>
                {candidate.full_name ||
                  [candidate.first_name, candidate.last_name]
                    .filter(Boolean)
                    .join(" ") ||
                  candidate.email ||
                  "-"}
              </p>
              <span
                onClick={() =>
                  allowToChangeStatus
                    ? setIsChangeStatusDropdownOpen(!isChangeStatusDropdownOpen)
                    : null
                }
              >
                {changedStatus
                  ? changedStatus
                  : candidate.status === CANDIDATES_STATUSES.SUCCESS
                  ? "Goal Met"
                  : candidate.status === CANDIDATES_STATUSES.EMAIL_SENT &&
                    candidate.lastSentMessageType === "sms"
                  ? "SMS Sent"
                  : candidate.status}
                {isChangeStatusDropdownOpen && (
                  <ChangeStatusDropdown>
                    <DropdownOption $isDefaultOption={true} $isBg={false}>
                      <DropdownIcon icon={faChevronDown} />
                    </DropdownOption>
                    <DropdownOption
                      $isBg={true}
                      onClick={() =>
                        changeStatus(CANDIDATES_STATUSES.COMPLETED)
                      }
                    >
                      {CANDIDATES_STATUSES.COMPLETED}
                    </DropdownOption>
                    <DropdownOption
                      $isBg={true}
                      onClick={() => changeStatus(CANDIDATES_STATUSES.SUCCESS)}
                    >
                      Goal Met
                    </DropdownOption>
                  </ChangeStatusDropdown>
                )}
              </span>
            </PopupHeaderTitle>

            <PopupHeaderSubTitle>
              {candidate.email}{" "}
              {isShowNoPhone && (
                <>
                  <FontAwesomeIcon
                    icon={faPhoneSlash}
                    color="#bbb"
                    data-tooltip-id="no-phone-number"
                    data-tooltip-content="No phone number"
                  />
                  <Tooltip
                    id="no-phone-number"
                    className="custom-tooltip"
                    place="right"
                  />
                </>
              )}
            </PopupHeaderSubTitle>
          </div>
          <CloseIcon
            src="/images/email-details-back-icon.png"
            onClick={handleClose}
          />
        </PopupHeader>

        {isLoading ? (
          <Loader parentSize={true} bgColor="#f5f2ee" />
        ) : errorMessage ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : messages.length === 0 ? (
          <NoMessagesText>No messages</NoMessagesText>
        ) : (
          <PopupContent>
            <LeftSidePanel>
              {messages.map((messageList, index) => {
                const firstMessageInThread = messageList[0];
                const isEmailMessage = firstMessageInThread.type === "email";
                const isActiveMessageThread =
                  index === activeMessageIndex.index;
                const isActiveFirstMessage =
                  isActiveMessageThread && activeMessageIndex.subIndex === 0;
                return (
                  <React.Fragment key={index}>
                    <MessageTabTitle onClick={() => setActiveMessage(index, 0)}>
                      <MessageEmailTypeIcon
                        icon={isEmailMessage ? faEnvelope : faMessage}
                      />
                      <MessageDate $active={isActiveMessageThread}>
                        <span>
                          {`${isEmailMessage ? "Email" : "SMS"} ${
                            firstMessageInThread.sent ? "sent " : "preview"
                          } ${
                            firstMessageInThread.sent
                              ? displayEmailDate(
                                  firstMessageInThread.sentAt ||
                                    firstMessageInThread.createdAt
                                )
                              : ""
                          }`}
                        </span>
                        {isActiveMessageThread && (
                          <ActiveTabArrow src="/images/active-email-tab-arrow.png" />
                        )}
                      </MessageDate>
                    </MessageTabTitle>
                    {!!firstMessageInThread.read && (
                      <MessageTabTitle
                        $subTitle={true}
                        onClick={() => setActiveMessage(index, 0)}
                      >
                        <MessageEmailTypeIcon icon={faEnvelopeCircleCheck} />
                        <MessageDate $active={isActiveFirstMessage}>
                          <span>
                            {`Read ${
                              firstMessageInThread.readCount > 1
                                ? `${
                                    firstMessageInThread.readCount
                                  } times (last ${displayEmailDate(
                                    firstMessageInThread.lastReadAt ||
                                      firstMessageInThread.createdAt
                                  )})`
                                : displayEmailDate(
                                    firstMessageInThread.lastReadAt ||
                                      firstMessageInThread.createdAt
                                  )
                            }`}
                          </span>
                        </MessageDate>
                      </MessageTabTitle>
                    )}
                    {(!!firstMessageInThread.answered ||
                      isFollowUpsAsReplies) &&
                      messageList.map((message, subIndex) => {
                        if (subIndex === 0) {
                          return null;
                        }
                        const isActiveReplyMessage =
                          isActiveMessageThread &&
                          subIndex === activeMessageIndex.subIndex;
                        return (
                          <MessageTabTitle
                            key={`${index}-${subIndex}`}
                            $subTitle={true}
                            onClick={() => setActiveMessage(index, subIndex)}
                          >
                            {message.followUpFor ? (
                              <>
                                <MessageEmailTypeIcon
                                  $activeReply={isActiveReplyMessage}
                                  icon={faCircleCheck}
                                />
                                <MessageDate
                                  $activeReply={isActiveReplyMessage}
                                >
                                  <span>
                                    {`Followed-up ${displayEmailDate(
                                      message.createdAt
                                    )}`}
                                  </span>
                                </MessageDate>
                              </>
                            ) : (
                              <>
                                <MessageEmailTypeIcon
                                  icon={
                                    message.replyFor &&
                                    message.status !==
                                      MESSAGE_STATUSES.USER_REPLY
                                      ? faReply
                                      : faCircleCheck
                                  }
                                  $activeReply={isActiveReplyMessage}
                                />
                                <MessageDate
                                  $activeReply={isActiveReplyMessage}
                                >
                                  <span>
                                    {message.replyFor &&
                                    message.status !==
                                      MESSAGE_STATUSES.USER_REPLY
                                      ? `You replied ${displayEmailDate(
                                          message.createdAt
                                        )}${
                                          message.status ===
                                          MESSAGE_STATUSES.NOT_SENT
                                            ? " (Not sent)"
                                            : ""
                                        }`
                                      : `Answered ${displayEmailDate(
                                          message.answeredAt ||
                                            message.lastReadAt ||
                                            message.createdAt
                                        )}`}
                                  </span>
                                </MessageDate>
                              </>
                            )}
                          </MessageTabTitle>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </LeftSidePanel>
            <RightSidePanel>
              <ContentHeader>
                <ContentHeaderTitleBlock>
                  <ContentHeaderEmailTypeIcon
                    icon={
                      activeMessage.type === "email" ? faEnvelope : faMessage
                    }
                  />
                  <div>
                    <ContentHeaderTitle>
                      {`${activeMessage.type === "email" ? "Email " : "SMS "} ${
                        activeMessage.replyFor ? "Reply" : "Message"
                      }${
                        activeContent === CONTENT_TYPES.EMAIL ? "" : " Prompt"
                      }`}
                    </ContentHeaderTitle>
                    <ContentHeaderSubTitle>
                      <span>
                        {convertDateToEmailSentFormat(
                          activeMessage.sentAt !== ""
                            ? activeMessage.sentAt
                            : activeMessage.createdAt
                        )}
                        {activeMessage.replyFor ? (
                          <>
                            {" "}
                            from{" "}
                            <span className="email">
                              {activeMessage.replyFor &&
                              activeMessage.status !==
                                MESSAGE_STATUSES.USER_REPLY
                                ? user?.email || ""
                                : candidate.email}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </ContentHeaderSubTitle>
                  </div>
                </ContentHeaderTitleBlock>
                {userReply !== null ? (
                  <BtnBar>
                    {isAdmin && (
                      <ChangeContentTypeBtn
                        disabled={isReplyLoading}
                        onClick={handleClickGenerateReply}
                      >
                        Generate
                      </ChangeContentTypeBtn>
                    )}
                    <ChangeContentTypeBtn
                      disabled={
                        isReplyLoading ||
                        !userReply.message.text?.replace("<p></p>", "").trim()
                      }
                      onClick={handleClickSendReply}
                    >
                      Send
                    </ChangeContentTypeBtn>
                    <ChangeContentTypeBtn
                      disabled={isReplyLoading}
                      onClick={handleCancelReply}
                    >
                      Cancel
                    </ChangeContentTypeBtn>
                  </BtnBar>
                ) : activeMessage.replyFor ? (
                  activeMessage.status !== MESSAGE_STATUSES.USER_REPLY ||
                  candidate.isUnsubscribe ||
                  candidate.status === CANDIDATES_STATUSES.BOUNCE ? null : (
                    <ChangeContentTypeBtn onClick={handleClickReply}>
                      Reply
                    </ChangeContentTypeBtn>
                  )
                ) : (
                  isAdmin && (
                    <ChangeContentTypeBtn onClick={toggleActiveContent}>
                      {activeContent === CONTENT_TYPES.EMAIL
                        ? "View Prompt"
                        : `View ${
                            activeMessage?.type === "email" ? "Email" : "SMS"
                          }`}
                    </ChangeContentTypeBtn>
                  )
                )}
              </ContentHeader>

              <ContentBodyWrap>
                {activeContent === CONTENT_TYPES.EMAIL && (
                  <>
                    {activeMessage?.type !== "sms" && (
                      <ContentBodySubject>
                        {activeMessage.subject || "[n/a]"}
                      </ContentBodySubject>
                    )}
                    <ContentBody
                      $withSubject={activeMessage?.type !== "sms"}
                      $withReplyEmailBox={!isReplyLoading && userReply !== null}
                    >
                      {isReplyLoading ? (
                        <Loader parentSize={true} height="fit-content" />
                      ) : (
                        userReply !== null && (
                          <ReplyEmailBox
                            id="replyEmailBox"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            $disabled={isReplyLoading}
                            onInput={handleInputReply}
                            dangerouslySetInnerHTML={{
                              __html: userReply.html,
                            }}
                          />
                        )
                      )}
                      <ContentBodyText
                        className={
                          userReply !== null ? "parentMessage" : undefined
                        }
                        dangerouslySetInnerHTML={{
                          __html: buildContentBody(),
                        }}
                      ></ContentBodyText>
                    </ContentBody>
                  </>
                )}
                {activeContent === CONTENT_TYPES.PROMPT && (
                  <ContentBody>
                    <ContentBodyText>
                      {buildPromptSequence(activeMessage.prompt)}
                    </ContentBodyText>
                  </ContentBody>
                )}
              </ContentBodyWrap>
            </RightSidePanel>
          </PopupContent>
        )}
      </Content>
    </PopupContainer>
  );
};

export default CandidatesPopup;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 97%;
  margin: 0 0 0 auto;
  height: 100%;
  background: #fff;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #999;
  overflow: hidden;
  animation: ${slideInFromRight} 0.5s ease-in-out;
`;

const PopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  padding: 20px 40px 20px 40px;
  justify-content: space-between;
`;

const CloseIcon = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const PopupHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  p {
    font-size: 24px;
    font-weight: 800;
    font-family: "AlbertSansExtraBold", sans-serif;
    color: #888888;
    margin-bottom: 0;
  }
  span {
    position: relative;
    font-size: 11px;
    font-weight: 400;
    font-family: "AlbertSansThin", sans-serif;
    color: #000000;
    margin-left: 20px;
    cursor: ${({ $cursor }) => ($cursor ? $cursor : "default")};
  }
`;

const ChangeStatusDropdown = styled.div`
  position: absolute;
  top: -11px;
  display: flex;
  flex-direction: column;
  left: -11px;
  border-top: 1px solid #888888;
`;

const DropdownOption = styled.div`
  background-color: ${({ $isBg }) => ($isBg ? "#ffffff" : "transparent")};
  display: flex;
  align-items: center;
  border-left: 1px solid #888888;
  border-right: 1px solid #888888;
  border-bottom: 1px solid #888888;
  padding: 10px;
  ${({ $isDefaultOption }) => $isDefaultOption && "justify-content: flex-end;"}
  &:hover {
    background-color: ${({ $isBg }) => ($isBg ? "#f5f2ee" : "transparent")};
  }
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: #000;
  opacity: 0.5;
`;

const PopupHeaderSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "AlbertSansThin", sans-serif;
  color: #000000;
  span {
    margin-right: 10px;
  }
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  padding: 40px;
  color: red;
`;

const PopupContent = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
`;

const LeftSidePanel = styled.div`
  width: 30%;
  height: 100%;
  background-color: #f8f8f8;
  border: 1px solid #e1e1e1;
  overflow: auto;
`;

const NoMessagesText = styled.div`
  font-size: 16px;
  padding: 40px;
`;

const MessageTabTitle = styled.div`
  ${({ $subTitle }) => ($subTitle ? "margin-left: 22px" : "")};
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MessageTypeIcon = styled.img`
  width: 17px;
  margin: 10px;
`;

const MessageEmailTypeIcon = styled(FontAwesomeIcon)`
  margin: 10px 9px 10px 13px;
  font-size: 14px;
  color: #000;
  opacity: 0.5;
  min-width: 18px;
  ${({ $activeReply }) => ($activeReply ? `color: ${theme.colors.red}` : "")};
`;

const MessageDate = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ $active, $activeReply }) =>
    $active || $activeReply ? "700" : "400"};
  font-family: "AlbertSansThin", sans-serif;
  font-size: 12px;
  border-bottom: 1px solid #e3e3e3;
  ${({ $activeReply }) => ($activeReply ? `color: ${theme.colors.red}` : "")};
`;

const ActiveTabArrow = styled.img`
  width: 7px;
  margin-right: 10px;
`;

const RightSidePanel = styled.div`
  width: 70%;
  height: 100%;
  background-color: #f5f2ee;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const ContentHeaderTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentHeaderTypeIcon = styled.img`
  width: 34px;
  margin: 20px 15px 20px 20px;
`;

const ContentHeaderEmailTypeIcon = styled(FontAwesomeIcon)`
  margin: 15px 19px 16px 20px;
  font-size: 30px;
  color: #000;
  opacity: 0.5;
`;

const ContentHeaderTitle = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

const ContentHeaderSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "AlbertSansThin", sans-serif;
  span {
    &.email {
      color: ${theme.colors.purple};
    }
  }
`;

const ChangeContentTypeBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: black;
  background-color: transparent;
  height: 40px;
  width: 158px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  ${({ disabled }) => (disabled ? "opacity: 0.75" : "")};
`;

const BtnBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  button {
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const ContentBodyWrap = styled.div`
  height: calc(100% - 120px);
`;

const ContentBody = styled.div`
  background-color: #ffffff;
  margin-left: 90px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: auto;
  height: ${({ $withSubject }) =>
    $withSubject ? "calc(100% - 60px)" : "100%"};
  overflow-y: ${({ $withReplyEmailBox }) =>
    $withReplyEmailBox ? "auto" : "hidden"};
  border: 1px solid #dcdbd9;
  padding: 30px 0 30px 30px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

const ContentBodySubject = styled.div`
  background-color: #ffffff;
  margin-left: 90px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: auto;
  padding: 10px 10px 10px 30px;
  font-size: 14px;
  border: 1px solid #dcdbd9;
`;

const ContentBodyText = styled.div`
  overflow: auto;
  flex-grow: 1;
  padding-right: 30px;
  p {
    padding-bottom: 10px;
  }
  &.parentMessage,
  .parentMessage {
    margin: 20px 0 0 10px;
    padding: 20px 15px 15px 10px;
    border-left: 3px solid #ccc;
    background-color: #f9f9f9;
  }
  &.parentMessage {
    margin-right: 30px;
  }
  ul {
    padding-inline-start: 40px;
  }
`;

const ReplyEmailBox = styled.div`
  margin-right: 30px;
  padding: 0 0 10px;
  border: none;
  outline: none;
  box-shadow: none;
  ${({ $disabled }) => ($disabled ? "pointer-events: none" : "")};
`;

const PromptItem = styled.div`
  border-bottom: 1px solid #dcdbd9;
  margin: 5px 0;
`;

const PromptRole = styled.div``;

const PromptRoleLabel = styled.span`
  font-weight: bold;
  font-style: italic;
  margin-right: 5px;
`;

const PromptRoleValue = styled.span``;

const PromptContent = styled.div``;

const PromptContentLabel = styled.div`
  font-weight: bold;
  font-style: italic;
`;

const PromptContentValue = styled.div`
  margin: 5px 0;
`;
