import { removeClearCacheDataFromLocalStorage } from "../api/localStorage";

export const clearCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach(async (name) => {
        await caches.delete(name);
      });
    });
  }
  removeClearCacheDataFromLocalStorage();
};
