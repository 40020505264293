import React from "react";
import styled from "styled-components";

import Video from "./Video";
import Image from "./Image";

function Preview({ previewItem, movePreviewItem }) {
  const handlePrevPreviewItem = () => movePreviewItem("prev");
  const handleNextPreviewItem = () => movePreviewItem("next");

  return (
    <Container>
      <MoveButton onClick={handlePrevPreviewItem}>&lt;</MoveButton>
      <CarouselContent previewItem={previewItem} />
      <MoveButton onClick={handleNextPreviewItem}>&gt;</MoveButton>
    </Container>
  );
}

function CarouselContent({ previewItem }) {
  if (previewItem.type === "video") {
    return (
      <Video
        preview={previewItem.preview}
        altPreview={previewItem.altPreview}
      />
    );
  }

  if (previewItem.type === "image") {
    return <Image preview={previewItem.preview} />;
  }
}

export default Preview;

const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 4px;
  justify-content: center;
  margin-bottom: 24px;
  width: 100%;
`;

const MoveButton = styled.button`
  height: 48px;
  width: 48px;
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
  flex-shrink: 0;
  color: #454746;

  &:hover {
    border-radius: 50%;
    background-color: #f0f1f1;
  }
`;
