import React, { useState, useEffect } from "react";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { WIZARD_STEPS } from "../constants";
import AutomateIntegrationKeyGen from "../../AutomateIntegrationKeyGen/UI/AutomateIntegrationKeyGenWizard";
import {
  createIntegration,
  getHubspotFields,
} from "../../../services/integrations";
import { fetchAutoConfigureLog } from "../../../services/queries";
import { useNavigate } from "react-router-dom";

function AutomateHubspotAPI() {
  const navigate = useNavigate();

  const [stepIndex, setStepIndex] = useState(0);
  const [apiKey, setApiKey] = useState(null);

  const postMessageEventHandler = (event) => {
    if (!event.data || event.data.finishedStepIndex === undefined) {
      return;
    }
    if (event.data.code) {
      setApiKey(event.data.code);
    } else {
      setStepIndex(event.data.finishedStepIndex + 1);
    }
  };

  useEffect(() => {
    const createHubspotIntegration = async () => {
      const connectionKeys = {
        accessToken: apiKey,
      };

      const data = {
        type: "crm",
        connectionKeys: {
          type: "hubspot",
          ...connectionKeys,
        },
      };

      const result = await createIntegration(data);

      if (!result.success) {
        fetchAutoConfigureLog({
          logInfo: `Failed to create integration for HubSpot; apiKey: ${apiKey}. Error message: ${result.message}`,
        });
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: result.message,
          },
        });
        navigate("/settings", {
          state: { integrationData: data },
        });
        sendMessageToExtension({
          message: "change-iframe-window-type",
          data: {
            iframeWindowType: "drawer",
          },
        });

        return;
      }

      await getHubspotFields();

      sendMessageToExtension({
        message: "open-crm-settings",
      });
    };
    if (apiKey) {
      createHubspotIntegration();
    }
  }, [apiKey]);

  useEffect(() => {
    window.addEventListener("message", postMessageEventHandler);
    sendMessageToExtension({
      message: "start-automate-hubspot-api-process",
    });

    return () => {
      window.removeEventListener("message", postMessageEventHandler);
    };
  }, []);

  return (
    <AutomateIntegrationKeyGen
      title="HubSpot API Key"
      description="We are automatically generating a Private App and API Key to help Reach interact with Hubspot."
      stepIndex={stepIndex}
      steps={WIZARD_STEPS}
      messageTypeForContinue="continue-automate-hubspot-api"
    />
  );
}

export default AutomateHubspotAPI;
