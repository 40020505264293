import React from "react";
import styled from "styled-components";

const ToggleSwitch = ({ leftLabel, rightLabel, position, onChange }) => {
  const isChecked = position === "left";

  return (
    <MainContainer>
      <LeftLabel htmlFor="toggleSwitch">{leftLabel}</LeftLabel>
      <Input
        id="toggleSwitch"
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <ToggleButton htmlFor="toggleSwitch" $checked={isChecked} />
      <RightLabelContainer>
        <RightLabel htmlFor="toggleSwitch">{rightLabel}</RightLabel>
        <Discount>Save 33%</Discount>
      </RightLabelContainer>
    </MainContainer>
  );
};

export default ToggleSwitch;

const MainContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 34px;
  padding-top: 25px;
  justify-content: center;
`;

const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleButton = styled.label`
  display: inline-block;
  position: relative;
  height: 18px;
  padding: 0px 17px;
  background-color: #d8635f;
  border-radius: 13px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: ${({ $checked }) => ($checked ? "-6px" : "calc(100% - 15px)")};
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 0.3s;
    border: 3px solid #d8635f;
  }
`;

const Label = styled.label`
  cursor: pointer;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 12.66px;
  user-select: none;
`;

const RightLabelContainer = styled.div`
  position: relative;

  width: max-content;
  display: flex;
`;

const Discount = styled.span`
  font-family: "ArchitectsDaughter";
  position: absolute;
  top: -33px;
  right: -51px;
  font-size: 15px;
  color: #d8635f;
`;

const LeftLabel = styled(Label)``;

const RightLabel = styled(Label)`
  font-weight: 600;
  position: relative;
  color: #4d4d4d;

  &::before {
    content: "";
    background-image: url("/images/curvedarrow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    display: block;
    position: absolute;
    top: -28px;
    right: 15px;
  }
`;
