import React from "react";
import ThumbnailItem from "./ThumbnailItem";
import styled from "styled-components";

function ThumbnailList({ listData, previewItem, setPreview }) {
  return (
    <List>
      {listData.map((listItem) => (
        <ThumbnailItem
          key={listItem.id}
          activeItem={previewItem.id === listItem.id}
          src={listItem.carousel}
          isVideoThumbnail={listItem.type === "video"}
          onClick={() => setPreview(listItem)}
        />
      ))}
    </List>
  );
}

export default ThumbnailList;

const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 24px;
  justify-content: center;

  @media (max-width: 900px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
`;
