import React from "react";

import LabelsList from "../LabelsList";
import LabelItem from "../LabelItem";

const featuresList = [
  {
    id: 1,
    name: "Subscribe/unsubscribe",
    url: "https://ecosystem.hubspot.com/marketplace/apps/marketing/email?eco_categoryFeatures=SUBSCRIBE_UNSUBSCRIBE",
  },
  {
    id: 2,
    name: "Easy integrations",
    url: "https://ecosystem.hubspot.com/marketplace/apps/marketing/email?eco_categoryFeatures=EASY_INTEGRATIONS",
  },
  {
    id: 3,
    name: "Automation tools",
    url: "https://ecosystem.hubspot.com/marketplace/apps/marketing/email?eco_categoryFeatures=AUTOMATION_TOOLS",
  },
];

function FeaturesComponent() {
  return (
    <LabelsList>
      {featuresList.map((feature) => (
        <LabelItem key={feature.id} name={feature.name} url={feature.url} />
      ))}
    </LabelsList>
  );
}

export default FeaturesComponent;
