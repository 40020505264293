import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

function ContactUsSelect({ options, title, onSelect, selected, width }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option.value);
  };

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSelectedOption(
      options.find((option) => option.value === selected) || null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Container $width={width}>
      <SelectHeader onClick={handleSelectClick}>
        <SelectedOption $isSelected={!!selectedOption}>
          {selectedOption ? selectedOption.label : title}
        </SelectedOption>
        <img src="/images/arrow-down.png" width="8" alt="arrow-down" />
      </SelectHeader>
      {isOpen && (
        <OptionsContainer>
          {options.map((option) => (
            <Option
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </Option>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
}

ContactUsSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.bool,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  onSelect: PropTypes.func.isRequired,
};

export default ContactUsSelect;

const Container = styled.div`
  position: relative;
  font-size: 12px;
  color: #000000;
  height: 40px;
  width: ${({ $width }) => ($width ? $width : "90%")};
  margin-bottom: 5px;
  background-color: white;
`;

const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
  box-shadow: inset 0 0 6px #dfdfdf;
  border: 1px solid #c3cad6;
  cursor: pointer;
  height: 40px;
`;

const SelectedOption = styled.div`
  flex: 1;
  color: ${(props) => (props.$isSelected ? "#000000" : "#757575")};
  font-size: 13px;
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 39px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  border-radius: 3px;
  box-shadow: inset 0 0 6px #dfdfdf;
  border: 1px solid #c3cad6;
`;

const Option = styled.div`
  padding: 8px;
  cursor: pointer;
  font-size: 13px;
`;
