import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import {
  getSamples,
  createSample,
  updateSample,
  removeSample,
} from "../../../services/prompts";
import SamplesContent from "./SamplesContent";
import Loader from "../../Loader";

function Samples() {
  const [isLoading, setIsLoading] = useState(true);

  const [samples, setSamples] = useState([]);
  const [promptsTokens, setPromptsTokens] = useState([]);

  useEffect(() => {
    fetchSamples();
  }, []);

  const fetchSamples = async () => {
    const result = await getSamples();
    if (!result.success) {
      setIsLoading(false);
      return;
    }

    setSamples(result.samples.samples);
    setPromptsTokens(result.samples.tokens);
    setIsLoading(false);
  };

  const handleUpdateSample = async (data) => {
    setIsLoading(true);
    const result = await updateSample(data);

    if (!result.success) {
      setIsLoading(false);
      return;
    }

    await fetchSamples();
  };

  const handleCreateSample = async (data) => {
    setIsLoading(true);
    const result = await createSample(data);
    if (!result.success) {
      setIsLoading(false);
      return;
    }

    await fetchSamples();
  };

  const handleRemoveSample = async (data) => {
    setIsLoading(true);
    const result = await removeSample(data);

    if (!result.success) {
      setIsLoading(false);
      return;
    }

    await fetchSamples();
  };

  return (
    <Container>
      {isLoading ? (
        <Loader parentSize={true} padding="0 20px 30px 0" />
      ) : (
        <Content>
          <Title>Samples</Title>
          <SamplesContent
            samples={samples}
            promptsTokens={promptsTokens}
            createSample={handleCreateSample}
            updateSample={handleUpdateSample}
            removeSample={handleRemoveSample}
          />
        </Content>
      )}
    </Container>
  );
}

export default Samples;

const Container = styled.div`
  padding: 0 20px 30px 0;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  height: 100%;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
