import {
  fetchCampaignsSummary,
  fetchCampaignDetails,
  fetchUserActivity,
  fetchUndeliverableEmails,
} from "./queries.js";

const getUserActivity = async (data) => {
  try {
    const result = await fetchUserActivity(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get user activity failed" };
  }
};

const getCampaignsSummary = async (data) => {
  try {
    const result = await fetchCampaignsSummary(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get campaigns summary failed" };
  }
};

const getCampaignDetails = async (data) => {
  try {
    const result = await fetchCampaignDetails(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get campaign details failed" };
  }
};

const getUndeliverableEmails = async (data) => {
  try {
    const result = await fetchUndeliverableEmails(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get campaigns summary failed" };
  }
};

export {
  getUserActivity,
  getCampaignsSummary,
  getCampaignDetails,
  getUndeliverableEmails,
};
