import React from "react";
import styled from "styled-components";

function Button({ title, styles, onClick }) {
  const {
    backgroundColor,
    backgroundColorActive,
    backgroundColorHover,
    logoUrl,
  } = styles || {};

  return (
    <Container
      onClick={onClick}
      $backgroundColor={backgroundColor}
      $backgroundColorActive={backgroundColorActive}
      $backgroundColorHover={backgroundColorHover}
    >
      <Logo $logoUrl={logoUrl} />
      {title}
    </Container>
  );
}

export default Button;

const RestStyleButton = styled.button`
  font-family: inherit;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: none;
`;

const Container = styled(RestStyleButton)`
  position: relative;
  width: 90%;
  height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 3px;
  padding: 3px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ $backgroundColorHover }) => $backgroundColorHover};
  }

  &:active {
    background-color: ${({ $backgroundColorActive }) => $backgroundColorActive};
  }
`;

const Logo = styled.div`
  position: absolute;
  background-image: ${({ $logoUrl }) => `url(${$logoUrl});`};
  width: 34px;
  height: 34px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  top: 50%;
  transform: translateY(-50%);
`;
