import React from "react";
import styled from "styled-components";

function ThumbnailItem({ activeItem, src, isVideoThumbnail, onClick }) {
  return (
    <Item $activeItem={activeItem}>
      <Image src={src} alt="carousel preview" $activeItem={activeItem} />
      {isVideoThumbnail && (
        <PlayButton src="images/hupspot-app/play-icon.png" />
      )}
      <ImageButton onClick={onClick} />
    </Item>
  );
}

export default ThumbnailItem;

const Item = styled.li`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  max-width: 96px;
  min-width: 14%;

  ${({ $activeItem }) =>
    $activeItem &&
    `
      border-radius: 6px;
      outline: 3px solid #0b57d0;
  `};
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  height: auto;

  aspect-ratio: 1.6;
  display: block;
  width: 100%;

  ${({ $activeItem }) =>
    $activeItem &&
    `
      border-radius: 6px;
  `}
`;

const ButtonResetStyle = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const ImageButton = styled(ButtonResetStyle)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const PlayButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  display: block;
`;
