const theme = Object.freeze({
  colors: {
    gray: "#5A5A5A",
    purple: "#4640DE",
    black: "#000000",
    white: "#ffffff",
    red: "#F95959",
    green: "#6C8A2C",

    saturated_purple: "#471168",

    btn_border_color: "#757678",

    background_color: "#F5F2EE",

    candidate_table_head_color: "#F0F0EFC2",

    divider_color: "#CDCDCD",
    rateCard_border_color: "#E2E2E2",
  },
  fonts: {
    primary: "'AlbertSans', sans-serif",
  },
});

export default theme;
