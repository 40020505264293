import React, { useState, useCallback } from "react";
import { styled } from "styled-components";
import PromptListItem from "./PromptListItem";

const DEFAULT_ITEM = {
  role: "user",
  content: "",
};

const PromptList = ({ items, type, crm, promptsTypes, onChange }) => {
  const templateWarningMessage = () => {
    const tokens = [];
    crm.forEach((currentCrm) => {
      tokens.push([...promptsTypes[type]?.templateKeys[currentCrm] || []]);
    });
    return `This template can include tokens: ${tokens.join(", ")}`;
  };

  const [listItems, setListItems] = useState(items);

  const onAddItem = useCallback(() => {
    setListItems((listItems) => [...listItems, DEFAULT_ITEM]);
  }, []);

  const onRemoveItem = (index) => {
    listItems.splice(index, 1);
    onChange([...listItems]);
  };

  const onChangeItem = (index, item) => {
    if (item.role === "conditional" && item.content === "") {
      item.content = [""];
    }
    listItems[index] = item;
    onChange([...listItems]);
  };

  return (
    <PromptListComponent>
      <WarningMessage>{templateWarningMessage()}</WarningMessage>
      {listItems.map((i, index) => {
        return (
          <Item key={index}>
            <StepNumber>{index + 1} Step</StepNumber>
            <PromptListItem
              item={i}
              index={index}
              onDelete={onRemoveItem}
              onChange={onChangeItem}
              listItems={listItems}
            />
          </Item>
        );
      })}
      <AddNewItem onClick={onAddItem} type="button">
        Add
      </AddNewItem>
    </PromptListComponent>
  );
};

const PromptListComponent = styled.div``;

const Item = styled.div``;

const AddNewItem = styled.button`
  width: 90%;
  border: ${({ theme }) => `1px dotted ${theme.colors.gray}`};

  color: ${({ theme }) => `${theme.colors.gray}`};
  height: 15px;
  font-size: 10px;
  background: none;
  cursor: pointer;
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
    color: ${({ theme }) => `${theme.colors.saturated_purple}`};
  }
`;

const StepNumber = styled.div`
  font-size: 12px;
  color: ${({ theme }) => `${theme.colors.gray}`};
  margin: 4px;
`;

const WarningMessage = styled.span`
  color: #7969a5;
  font-size: 11px;
  width: 90%;
`;

export default PromptList;
