import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const BackButton = () => {
  return (
    <Link to="/register">
      <SignUpButton>Back to sign up</SignUpButton>
    </Link>
  );
};

export default BackButton;

const SignUpButton = styled.button`
  cursor: pointer;
  color: #ffffff;
  background-color: rgb(71, 17, 104);
  height: 40px;
  padding: 0 25px;
  border-radius: 6px;
  border: none;
  margin-top: 20px;
`;
