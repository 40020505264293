import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { GOALS, VALIDATE_GOAL_GPT_ANSWERS } from "../constants";
import StepButton from "../../StepButton";
import ValidateGoalPopup from "./ValidateGoalPopup";
import { validateCampaignGoal } from "../../../services/campaigns";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
} from "../../../utils/constants";
import { isBullhornMarketingCampaignType } from "../utils";
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
} from "../../../api/localStorage";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";

function CampaignOverview({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
  crm,
  isEmailIntegration,
}) {
  const parentRef = useRef(null);
  const [isLinkInputOpened, setIsLinkInputOpened] = useState(false);
  const [isCustomGoalInputOpened, setIsCustomGoalInputOpened] = useState(false);
  const [selectedGoal, setGoal] = useState(campaign.goal);
  const [link, setLink] = useState("");
  const [customGoal, setCustomGoal] = useState("");
  const [title, setTitle] = useState("");
  const [linkErrorMessage, setLinkErrorMessage] = useState("");
  const [customGoalErrorMessage, setCustomGoalErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [validateGoalGPTAnswer, setValidateGoalGPTAnswer] = useState("");
  const [isValidateGoalPopupOpened, setIsValidateGoalPopupOpened] =
    useState(false);
  const [isValidationLoading, setIsValidationLoading] = useState(false);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.campaignOverview,
    parentRef,
  };

  const handleChange = (goal) => {
    if (goal === GOALS.emailReply && !isEmailIntegration) {
      setErrorMessage(
        "We cannot monitor email goals unless you set up an email provider"
      );
      return;
    }
    setErrorMessage("");
    setLinkErrorMessage("");
    setCustomGoalErrorMessage("");

    setGoal(goal);
    setIsCustomGoalInputOpened(false);
    setCustomGoal("");
    if (goal !== GOALS.visitLink) {
      setIsLinkInputOpened(false);
      setLink("");
      return;
    }
    setIsLinkInputOpened(true);
  };

  const handleDefineNewGoal = () => {
    setIsCustomGoalInputOpened(true);
    setIsLinkInputOpened(false);

    setErrorMessage("");
    setLinkErrorMessage("");
    setCustomGoalErrorMessage("");

    setGoal("");
    setLink("");
  };

  const isLinkValidUrl = () => {
    try {
      new URL(link);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateGoal = async () => {
    setIsValidationLoading(true);
    const data = {
      crm: campaign.crmType,
      goal: customGoal,
    };
    const response = await validateCampaignGoal(data);

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      handleIncreaseStep();
      return;
    }

    if (
      Object.values(VALIDATE_GOAL_GPT_ANSWERS).includes(response.result.result)
    ) {
      if (response.result.result === VALIDATE_GOAL_GPT_ANSWERS.custom) {
        handleIncreaseStep();
        return;
      }
      setValidateGoalGPTAnswer(response.result.result);
      setIsValidateGoalPopupOpened(true);
      setIsValidationLoading(false);
    } else {
      handleIncreaseStep();
    }
  };

  const changeGoalDescription = () => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      return 'It looks like you\'d like your recipients to simply reply to your message.  Changing your campaign goal to "Any email reply" will allow Reach to better track goal completions.';
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      return 'It looks like you\'d like your recipients to click on a link.  Changing your campaign goal to "Get contact to visit a link" will allow Reach to automatically track successful clicks.';
    }

    return "";
  };

  const confirmChangeGoal = (link) => {
    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.visitLink,
        link,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }

    if (validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.emailReply) {
      setCampaign((campaign) => ({
        ...campaign,
        goal: GOALS.emailReply,
        isCustomGoal: false,
      }));

      handleIncreaseStep();
      return;
    }
  };

  const cancelChangeGoal = () => {
    handleIncreaseStep();
  };

  const handleClickNext = (event) => {
    event.preventDefault();

    if (title === "") {
      return;
    }

    if (selectedGoal === "" && !isCustomGoalInputOpened) {
      setErrorMessage("Please choose a campaign goal");
      return;
    }
    if (selectedGoal === "" && isCustomGoalInputOpened) {
      if (customGoal.length < 1) {
        setCustomGoalErrorMessage("This field is required");
        return;
      }
      if (customGoal.length > 0) {
        setCustomGoalErrorMessage("");
        setCampaign((campaign) => ({
          ...campaign,
          goal: customGoal,
          name: title,
          isCustomGoal: true,
        }));
        if (
          crm === CRMS.BULLHORN &&
          !isBullhornMarketingCampaignType(campaign.type)
        ) {
          validateGoal();
        } else {
          handleIncreaseStep();
        }
        return;
      }
    }
    if (selectedGoal === GOALS.visitLink) {
      if (link.length < 1) {
        setLinkErrorMessage("This field is required");
        return;
      }

      const isLinkValid = isLinkValidUrl();

      if (!isLinkValid) {
        setLinkErrorMessage("Please enter a valid link");
        return;
      }
      if (isLinkValid && link.length > 0) {
        setLinkErrorMessage("");
        setCampaign((campaign) => ({
          ...campaign,
          goal: selectedGoal,
          link,
          name: title,
          isCustomGoal: false,
        }));
        handleIncreaseStep();
        return;
      }
    }

    setCampaign((campaign) => ({
      ...campaign,
      goal: selectedGoal,
      name: title,
      isCustomGoal: false,
    }));
    handleIncreaseStep();

    removeDataFromLocalStorage(PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY, true);
  };

  const setCampaignName = () => {
    setTitle(() => {
      const preparedCampaignName = getDataFromLocalStorage(
        PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY
      );

      if (preparedCampaignName) {
        return preparedCampaignName;
      }

      return campaign.name;
    });
  };

  useEffect(() => {
    setCampaignName();

    if (campaign.goal === GOALS.visitLink) {
      setIsLinkInputOpened(true);
      setIsCustomGoalInputOpened(false);
      setLink(campaign.link);
      return;
    }

    if (
      campaign.goal !== GOALS.emailReply &&
      campaign.goal !== GOALS.schedulePhoneCall &&
      campaign.goal !== ""
    ) {
      setIsCustomGoalInputOpened(true);
      setIsLinkInputOpened(false);
      setCustomGoal(campaign.goal);
      setGoal("");

      return;
    }

    setGoal(campaign.goal);
    setIsLinkInputOpened(false);
    setIsCustomGoalInputOpened(false);
  }, [campaign]);

  return (
    <>
      <Section ref={parentRef}>
        <FirstTimeCampaignOnboarding
          config={firstTimeCampaignOnboardingConfig}
        />
        <Title>Campaign Overview</Title>
        <InputBlock>
          <Label fontWeight="bold" htmlFor="campaign-title">
            Give your campaign a name
          </Label>
          <InputContent>
            <Relative>
              <CampaignTitleInput
                type="text"
                id="campaign-title"
                placeholder="Campaign Title"
                autoComplete="off"
                value={title}
                onInput={(event) => setTitle(event.target.value)}
              />
              {title === "" && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {title === "" && (
              <ErrorMessage>Please enter campaign title</ErrorMessage>
            )}
          </InputContent>
        </InputBlock>
        <SelectBlock>
          <Label fontWeight="bold">What's your goal for this campaign?</Label>
        </SelectBlock>
        <RadioButtonGroup>
          {Object.values(GOALS).map((goal) => (
            <RadioBlock key={goal}>
              <RadioInput
                tabIndex="0"
                id={goal}
                value={goal}
                name="goal"
                type="radio"
                checked={goal === selectedGoal}
                onChange={() => handleChange(goal)}
              />
              <RadioInputCustomChecked
                $checked={goal === selectedGoal}
                tabIndex="-1"
              />
              <RadioInputLabel
                htmlFor={goal}
                $checked={goal === selectedGoal}
                onClick={() => handleChange(goal)}
              >
                {goal}
              </RadioInputLabel>
              {isLinkInputOpened && goal === GOALS.visitLink && (
                <InputContent>
                  <Relative>
                    <LinkInput
                      type="text"
                      placeholder="Enter the link"
                      value={link}
                      autoFocus={true}
                      onInput={(event) => setLink(event.target.value)}
                    />
                    {linkErrorMessage !== "" && (
                      <ErrorIcon
                        src="/images/input-error-icon.svg"
                        alt="error"
                      />
                    )}
                  </Relative>
                  {linkErrorMessage !== "" && (
                    <ErrorMessage>{linkErrorMessage}</ErrorMessage>
                  )}
                </InputContent>
              )}
            </RadioBlock>
          ))}
        </RadioButtonGroup>

        <Divider />

        <CustomGoalLabel onClick={handleDefineNewGoal}>
          + Define a New Goal
        </CustomGoalLabel>
        {isCustomGoalInputOpened && (
          <InputContent>
            <Relative>
              <CustomGoalInput
                type="text"
                placeholder="Enter the goal"
                value={customGoal}
                onInput={(event) => setCustomGoal(event.target.value)}
              />
              {customGoalErrorMessage !== "" && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {customGoalErrorMessage !== "" && (
              <ErrorMessage>{customGoalErrorMessage}</ErrorMessage>
            )}
          </InputContent>
        )}
        {errorMessage !== "" && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Section>
      <ButtonGroup>
        <StepButton onClick={handleDecreaseStep} disabled isNext={false} />
        <StepButton
          onClick={handleClickNext}
          disabled={isValidationLoading}
          isNext={true}
        />
      </ButtonGroup>

      {isValidateGoalPopupOpened && (
        <ValidateGoalPopup
          title="There’s something else you can do here!"
          description={changeGoalDescription()}
          showLinkInput={
            validateGoalGPTAnswer === VALIDATE_GOAL_GPT_ANSWERS.link
          }
          onConfirm={confirmChangeGoal}
          onCancel={cancelChangeGoal}
        />
      )}
    </>
  );
}

export default CampaignOverview;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const Label = styled.label`
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 600;
  font-size: 15px;
`;

const CampaignTitleInput = styled.input`
  border: 2px solid #e8e8e8;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  &:focus-visible {
    border: 2px solid #e8e8e8;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 10px;
  }
`;

const RadioButtonGroup = styled.div``;

const RadioBlock = styled.div`
  border: none;
  width: 100%;
  display: flex;
  align-items: center;
  color: #2f2f2f;
  font-size: 13px;
`;

const RadioInput = styled.input.attrs({ type: "radio" })`
  cursor: pointer;
  width: 20px !important;
  margin: 0 10px !important;

  display: ${({ checked }) => (checked ? "none" : "block")};
`;

const RadioInputCustomChecked = styled.div`
  display: ${({ $checked }) => ($checked ? "block" : "none")};
  cursor: pointer;
  background-image: url("images/checked-radio-btn-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 20px;
  height: 40px;
  margin: 0 10px;
`;

const RadioInputLabel = styled.label`
  cursor: pointer;
  padding: 0 12px;
  font-weight: ${({ $checked }) => ($checked ? "700" : "400")};
  color: ${({ theme, $checked }) =>
    $checked ? theme.colors.saturated_purple : "#5A5A5A"};
`;

const CustomGoalLabel = styled.div`
  color: #4640de;
  font-size: 13px;
  margin-left: 50px;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Divider = styled.div`
  width: 15px;
  height: 2px;
  background-color: #858585;
  margin: 10px 50px 20px 50px;
`;

const InputContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
`;

const Relative = styled.div`
  position: relative;
`;

const LinkInput = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 10px;
  width: 100%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 10px;
    width: 100%;
  }
`;

const CustomGoalInput = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin: 0;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin: 0;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 11px;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const ButtonGroup = styled.div`
  grid-row-start: 2;
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: end;
`;
