import { createContext, useContext, useRef, useState } from "react";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import { sendMessageToExtension } from "../utils/postToExtension";

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(getUserFromLocalStorage());
  const user = getUserFromLocalStorage();

  if (!user) {
    console.log("Need to log in.  Unable to find user in local storage.")
    sendMessageToExtension({
      message: "need-log-in",
    });
  }
  const accountName = useRef(getUserFromLocalStorage()?.name || "");
  const accountAvatar = useRef(
    getUserFromLocalStorage()?.avatar || "/images/minimal-logo-white.png"
  );

  return (
    <AccountContext.Provider
      value={{ account, setAccount, accountName, accountAvatar }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const useAccount = () => useContext(AccountContext);

export { useAccount, AccountProvider };
