import React from "react";
import ConfirmPopup from "../../ConfirmPopup";

function CampaignMayExceedCreditsPopup({ values, onConfirm, onCancel }) {
  const { estimatedTokenCount, creditsRemaining } = values;
  const description = `This campaign is estimated at ${estimatedTokenCount} credits and you have ${creditsRemaining} credits remaining.  As a result, the campaign will be partially executed.  Would you like to continue?`;
  return (
    <ConfirmPopup
      title="Campaign may exceed available credits"
      description={description}
      confirmLabel="Yes"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
}

export default CampaignMayExceedCreditsPopup;
