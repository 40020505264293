import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { getSamples, testPrompt } from "../../../services/prompts";
import ConnectBtn from "../../ConnectBtn";
import Loader from "../../Loader";
import ContactUsSelect from "../../ContactUsSelect";
import { sendMessageToExtension } from "../../../utils/postToExtension";

const PromptTestFormPopup = ({ prompt, handleClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [samples, setSamples] = useState([]);
  const [samplesOptions, setSamplesOptions] = useState([]);

  const [sampleId, setSampleId] = useState("");
  const [currentSample, setCurrentSample] = useState(null);
  const [topP, setTopP] = useState("");
  const [temperature, setTemperature] = useState("");
  const [bestOf, setBestOf] = useState("");
  const [samplesCount, setSamplesCount] = useState("");
  const [withCompliance, setWithCompliance] = useState(false);

  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTestPrompt = async (e) => {
    e.preventDefault();

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setErrorMessage("");

    const testParams = {};

    if (topP !== "") {
      testParams.top_p = topP;
    }

    if (temperature !== "") {
      testParams.temperature = temperature;
    }

    if (bestOf !== "") {
      testParams.best_of = bestOf;
    }

    if (samplesCount !== "") {
      testParams.samplesCount = samplesCount;
    }

    if (withCompliance) {
      testParams.withCompliance = withCompliance;
    }

    await fetchTestPrompt({
      promptId: prompt.id ? prompt.id : prompt.type,
      sampleId,
      testParams,
    });
  };

  const fetchTestPrompt = async (data) => {
    setIsLoading(true);
    const result = await testPrompt(data);
    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      handleClose();
      return;
    }

    let formattedSamples = [];

    for (let i = 0; i < result.samples.length; i++) {
      const sample = result.samples[i];
      delete sample.rawPrompts;
      formattedSamples = formattedSamples.concat(Object.values(sample));
    }

    console.log(
      `Returned ${result.length} results.  Values: ${JSON.stringify(
        formattedSamples
      )}`
    );
    setResults(formattedSamples);

    setIsLoading(false);
  };

  const isFormValid = () => {
    if (!sampleId || sampleId === "") {
      setErrorMessage("Please choose sample");
      return false;
    }

    return true;
  };

  const fetchSamples = async () => {
    const result = await getSamples();
    if (!result.success) {
      setIsLoading(false);
      return;
    }

    const samples = result.samples?.samples;

    if (!samples?.length) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "No samples to test",
        },
      });
      handleClose();
      return;
    }
    const samplesOptions = samples.map((sample) => ({
      label: sample.name,
      value: sample.id,
    }));

    setSamples(samples);
    setSamplesOptions(samplesOptions);
    setIsLoading(false);
  };

  const handleSetSample = (value) => {
    setSampleId(value);
    const currentSample = samples.find((sample) => sample.id === value);
    setCurrentSample(currentSample);
    setErrorMessage("");
  };

  useEffect(() => {
    fetchSamples();
  }, []);

  return (
    <UpdatePromptContainer>
      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          x
        </CloseIcon>

        {isLoading ? (
          <Loader parentSize={true} />
        ) : (
          <Form onSubmit={handleTestPrompt}>
            <Inputs>
              <InputBlock>
                <Label htmlFor="email">Current Prompt: </Label>
                <Relative>
                  {prompt.sequence.map((sequence, index) => (
                    <Template key={index}>
                      <span>{sequence.role}:</span> {sequence.content}
                    </Template>
                  ))}
                </Relative>
              </InputBlock>

              <InputBlock>
                <Label htmlFor="email">Sample: </Label>
                <Relative>
                  <ContactUsSelect
                    width="50%"
                    options={samplesOptions}
                    title="Samples"
                    selected={sampleId}
                    onSelect={(value) => handleSetSample(value)}
                  />
                  {currentSample &&
                    currentSample.fields.map((field, index) => (
                      <Template key={index}>
                        <span>{field.title}:</span> {field.text}
                      </Template>
                    ))}
                </Relative>
              </InputBlock>

              <InputBlock>
                <Label>Test parameters (optional)</Label>
                <Relative>
                  <Input
                    id="top_p"
                    placeholder="top_p..."
                    value={topP}
                    onChange={(e) => setTopP(e.target.value)}
                    autoComplete="off"
                  />
                </Relative>
                <Relative>
                  <Input
                    id="temperature"
                    placeholder="temperature..."
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                    autoComplete="off"
                  />
                </Relative>
                <Relative>
                  <Input
                    id="best_of"
                    placeholder="best_of..."
                    value={bestOf}
                    onChange={(e) => setBestOf(e.target.value)}
                    autoComplete="off"
                  />
                </Relative>
                <Relative>
                  <Input
                    id="samples_count"
                    placeholder="# of samples..."
                    value={samplesCount}
                    onChange={(e) => setSamplesCount(e.target.value)}
                    autoComplete="off"
                  />
                </Relative>
                <CheckboxBlock>
                  <input
                    id="autoReplyCheckbox"
                    type="checkbox"
                    checked={withCompliance}
                    value={withCompliance}
                    onChange={(e) => setWithCompliance(e.target.checked)}
                  />
                  <label htmlFor="autoReplyCheckbox" className="toggle"></label>
                  <CheckboxLabel $checked={withCompliance}>
                    Run with compliance prompt
                  </CheckboxLabel>
                </CheckboxBlock>
              </InputBlock>

              {results &&
                results.length !== 0 &&
                results.map((result, index) => (
                  <ResultBlock key={index}>
                    <Label>Result {index + 1}: </Label>
                    <InputBlock>
                      <Label>Prompts: </Label>
                      <Relative>
                        {result.prompts.map((prompt, indexP) => (
                          <Template key={`${index}-${indexP}`}>
                            <span>{prompt.role}:</span> {prompt.content}
                          </Template>
                        ))}
                      </Relative>
                      <Label>Answer: </Label>
                      <Relative>
                        <Template>
                          <span>{result.answer}</span>
                        </Template>
                      </Relative>
                      <Label>Used Tokens: </Label>
                      <Relative>
                        {result.usages.map((usage, indexU) => {
                          const usageItem = Array.isArray(usage)
                            ? usage[0]
                            : usage;
                          return (
                            !!usageItem && (
                              <React.Fragment key={`${index}-${indexU}`}>
                                {result.usages.length > 1 && (
                                  <Label style={{ marginLeft: 5 }}>
                                    Used Tokens {indexU + 1}:
                                  </Label>
                                )}
                                {Object.entries(usageItem).map(
                                  ([key, value], index) => (
                                    <Template key={index}>
                                      <span>{key}:</span> {value}
                                    </Template>
                                  )
                                )}
                              </React.Fragment>
                            )
                          );
                        })}
                      </Relative>
                    </InputBlock>
                  </ResultBlock>
                ))}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Inputs>

            <Actions>
              <ConnectBtn type="submit" label="Test" />
            </Actions>
          </Form>
        )}
      </Content>
    </UpdatePromptContainer>
  );
};

export default PromptTestFormPopup;

const UpdatePromptContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;
const Content = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
`;

const CloseIcon = styled.span`
  content: "x";
  cursor: pointer;
  position: fixed;
  right: 20px;
  top: 20px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 45px;
`;

const ResultBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: inset 0 0 6px #dfdfdf;
  h3 {
    font-size: 18px;
    font-family: "AlbertSansExtraBold", sans-serif;
    text-align: center;
  }
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const Template = styled.div`
  font-size: 12px;
  font-family: "AlbertSansThin", sans-serif;
  margin-left: 10px;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: ${({ $width }) => $width || "50%"};
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: ${({ $width }) => $width || "50%"};
  }
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const CheckboxBlock = styled.div`
  margin-bottom: 20px;
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;
