import React from "react";

import { Container } from "./styles";

const Button = ({ onClick, text, isLoading }) => {
  return (
    <Container onClick={onClick} $isLoading={isLoading}>
      {isLoading ? "..." : text}
    </Container>
  );
};

export default Button;
