import React from "react";

import LabelsList from "../LabelsList";
import LabelItem from "../LabelItem";

const categoryList = [
  {
    id: 1,
    name: "Email",
    url: "https://ecosystem.hubspot.com/marketplace/apps/marketing/email",
  },
  {
    id: 2,
    name: "SMS",
    url: "https://ecosystem.hubspot.com/marketplace/apps/marketing/sms",
  },
];

function CategoriesComponent() {
  return (
    <LabelsList>
      {categoryList.map((category) => (
        <LabelItem key={category.id} name={category.name} url={category.url} />
      ))}
    </LabelsList>
  );
}

export default CategoriesComponent;
