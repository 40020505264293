import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import Loader from "../../../Loader";
import { getGptCallsStats } from "../../../../services/usage";
import StatCard from "./StatCard";
import UsageTable from "./UsageTable";

const UsageWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchGptCallsStats = async (date) => {
    const result = await getGptCallsStats(date);
    if (!result.success) {
      setData({});
      setIsLoading(false);
      return;
    }

    setData(result.result);
    setIsLoading(false);
  };

  useEffect(() => {
    const today = new Date();
    today.setHours(0, -today.getTimezoneOffset(), 0, 0);
    fetchGptCallsStats(today.toISOString());
  }, []);

  const handleLoad = (date) => {
    setIsLoading(true);
    fetchGptCallsStats(date);
  };

  const getCreditsValue = (key) => {
    const value = data?.[key] ? Math.ceil(data?.[key] / 1000) : data?.[key];
    return key === "monthlyTotal"
      ? [value, getCreditsValue("creditsPerMonth")]
      : value;
  };

  return (
    <Container>
      {isLoading && (
        <LoaderWrapper>
          <Loader parentSize={true} padding="0" />
        </LoaderWrapper>
      )}
      {!!data && (
        <Content>
          <Title>AI Credits Usage Statistics</Title>
          <CardContainer>
            {[
              { key: "dailyAverage", title: ["daily", "average"] },
              { key: "dailyTotal", title: ["current day", "total"] },
              { key: "monthlyAverage", title: ["monthly", "average"] },
              { key: "monthlyTotal", title: ["current month", "total"] },
            ].map(({ title, key }) => (
              <StatCard
                key={key}
                title={
                  <>
                    {title[0]}
                    <br />
                    {title[1]}
                  </>
                }
                value={getCreditsValue(key)}
              />
            ))}
          </CardContainer>
          <UsageTable
            data={data.logs || []}
            isLoading={isLoading}
            onLoad={handleLoad}
          />
        </Content>
      )}
    </Container>
  );
};

export default UsageWrapper;

const Container = styled.div`
  padding: 43px 0 23px 43px;
  height: 100%;
  width: 100%;
  position: relative;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: #ffffff47; */
  z-index: 99;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 20px;
  height: 100%;
  /* overflow: hidden; */
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

const CardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns:
    minmax(auto, 245px) minmax(auto, 245px) minmax(auto, 245px)
    minmax(auto, 245px);
  column-gap: 30px;
  margin-bottom: 20px;
`;
