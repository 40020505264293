/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import Loader from "../../Loader";
import theme from "../../../theme";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { getInvoices } from "../../../services/company";

function BillingsTable() {
  const [isLoading, setIsLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const fetchBillings = async () => {
    setIsLoading(true);

    const result = await getInvoices();

    setIsLoading(false);

    if (result.success) {
      setInvoices(result.result.invoices);
    } else {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch invoices",
        },
      });
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "PAID":
        return "#6C8A2C";
      case "UNPAID":
        return "#DD3C3C";
      default:
        return "#E6E6E6";
    }
  };

  const fetchOnMount = async () => {
    await fetchBillings();
  };

  useEffect(() => {
    fetchOnMount();
  }, []);

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {[
            { name: "date", label: "Invoice Date" },
            { name: "amount", label: "Amount" },
            { name: "status", label: "Status" },
          ].map(({ name, label }) => (
            <TableHeadData key={name}>
              <TableHeadDataSort>{label}</TableHeadDataSort>
            </TableHeadData>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!invoices.length ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No invoices to show</EmptyTableData>
            </TableRow>
          ) : (
            invoices.map((invoice, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>
                  {invoice.createdAt
                    ? new Date(invoice.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "-"}
                </TableData>
                <TableData>${invoice.amountPaid / 100}</TableData>
                <TableData color={getStatusColor(invoice.status.toUpperCase())}>
                  {invoice.status.toUpperCase()}
                </TableData>
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Container>
      <Content>
        {isLoading ? (
          <Loader parentSize={true} bgColor="white" padding="0" />
        ) : (
          <OverflowContent id="table_layout">{table}</OverflowContent>
        )}
      </Content>
    </Container>
  );
}

export default BillingsTable;

const Container = styled.div`
  height: calc(100% - 55px);
`;

const Content = styled.div`
  height: 100%;
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;
