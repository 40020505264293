import styled from "styled-components";

export const Container = styled.div`
  margin-left: 30px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
`;

export const List = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  cursor: pointer;
  padding: 8px 15px;
  position: relative;

  ${({ $activeItem }) =>
    $activeItem &&
    `
    &::before {
      content: '';
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 4px;
      background-color: #E4E4E4;
      bottom: 0;
      border-radius: 10px
    }
  `};

  &:first-child {
    padding-left: 0;
  }

  &:not(:first-child) {
    ${({ $activeItem }) =>
      $activeItem &&
      `
      &::before {
        left: 0;
      }
    `}
  }
`;

export const Name = styled.span`
  cursor: pointer;
  color: #757678;
  font-size: 12px;
  font-weight: 400;
  line-height: 19.2px;

  ${({ $activeItem }) =>
    $activeItem &&
    `
    font-weight: 700;
    color: #000
  `}
`;
