import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useGetIntegrationOrder() {
  const location = useLocation();
  const { isCreatedHubspotIntegrationViaPublicApp } = queryString.parse(
    location.search
  );
  const [integrationOrder, setIntegrationOrder] = useState([
    "emailService",
    "crm",
    "smsService",
    "aiModel",
  ]);

  useEffect(() => {
    if (isCreatedHubspotIntegrationViaPublicApp === "true") {
      setIntegrationOrder(["crm", "emailService", "smsService", "aiModel"]);
    }
  }, [isCreatedHubspotIntegrationViaPublicApp]);

  return integrationOrder;
}

export function useGetActiveTab(tabName) {
  const [activeTab, setActiveTab] = useState(tabName);

  useEffect(() => {
    setActiveTab(tabName);
  }, [tabName]);

  return {
    activeTab,
    setActiveTab,
  };
}
