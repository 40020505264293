import React from "react";
import ContentStep from "./ContentStep";
import SidePanel from "./SidePanel";
import { styled } from "styled-components";
import CloseBtn from "../../CloseBtn";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import StepLoader from "./StepLoader";

function AutomateIntegrationKeyGen({
  title,
  description,
  stepIndex,
  steps,
  messageTypeForContinue,
}) {
  const onClose = () => {
    sendMessageToExtension({
      message: "close-iframe",
    });
  };

  return (
    <main className="app">
      <CloseBtn onClose={onClose} />
      <div className="layout">
        <SidePanel
          stepIndex={stepIndex}
          steps={steps}
          title={title}
          description={description}
        />
        <Section>
          <ContentStep
            stepIndex={stepIndex}
            steps={steps}
            messageTypeForContinue={messageTypeForContinue}
          />
          <StepLoader stepIndex={stepIndex} steps={steps} />
        </Section>
      </div>
    </main>
  );
}

export default AutomateIntegrationKeyGen;

const Section = styled.div`
  width: 75%;
  background-color: #f5f2ee;
  display: flex;
  align-items: center;
  position: relative;
`;
