import React, { useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import {
  ButtonWrapper,
  CampaignTitleInput,
  Container,
  CreateCampaignButton,
  Description,
  ErrorIcon,
  ErrorMessage,
  Header,
  InputWrapper,
  QuestionBlock,
  QuestionIcon,
} from "./styles";
import { saveDataToLocalStorage } from "../../../../api/localStorage";
import {
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY,
} from "../../../../utils/constants";
import Avatars from "../../../Avatars";
import FirstTimeCampaignOnboarding from "../../../FirstTimeCampaignOnboarding";

const avatarsListConfig = [
  {
    src: "/images/prepare-screen-sm/avatar-1.png",
    width: "26px",
    top: "13%",
    left: "5%",
    blinkTime: 8,
  },
  {
    src: "/images/prepare-screen-sm/avatar-2.png",
    width: "15px",
    top: "32%",
    left: "10%",
    blinkTime: 6,
  },
  {
    src: "/images/prepare-screen-sm/avatar-3.png",
    width: "21px",
    top: "49%",
    left: "13%",
    blinkTime: 5,
  },
  {
    src: "/images/prepare-screen-sm/avatar-4.png",
    width: "18px",
    top: "15%",
    left: "77%",
    blinkTime: 7,
  },
  {
    src: "/images/prepare-screen-sm/avatar-5.png",
    width: "37px",
    top: "25%",
    left: "85%",
    blinkTime: 4,
  },
  {
    src: "/images/prepare-screen-sm/avatar-6.png",
    width: "38px",
    top: "43%",
    left: "78%",
    blinkTime: 9,
  },
];

function CreateCampaignBlock({ attemptToCreateCampaign }) {
  const parentRef = useRef(null);
  const [title, setTitle] = useState("");
  const [isTitleError, setIsTitleError] = useState(false);
  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.createCampaign,
    parentRef,
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setTitle(value);

    if (value !== "") {
      setIsTitleError(false);
    }
  };

  const handleClick = () => {
    if (title.trim() === "") {
      setIsTitleError(true);
    } else {
      attemptToCreateCampaign();
      saveDataToLocalStorage(PREPARED_CAMPAIGN_NAME_LOCAL_STORAGE_KEY, title);
    }
  };
  return (
    <Container ref={parentRef}>
      <FirstTimeCampaignOnboarding config={firstTimeCampaignOnboardingConfig} />
      <Avatars configList={avatarsListConfig} />
      <Header>Let’s get started on your first campaign</Header>
      <Description>
        Creating a campaign is simple. Enter a title, add some sales goals, give
        Reach some campaign details and select your contacts. It’s really that
        simple!
      </Description>
      <QuestionBlock
        data-tooltip-id="no-campaigns-question-tooltip"
        data-tooltip-content="-"
      >
        {/*<QuestionIcon src="/images/question-circle-solid-icon.png" />*/}
        {/*How does it work?*/}
      </QuestionBlock>
      <Tooltip
        id="no-campaigns-question-tooltip"
        place="bottom"
        className="custom-tooltip"
      />
      <InputWrapper>
        <CampaignTitleInput
          type="text"
          placeholder="Campaign Title"
          autoComplete="off"
          value={title}
          onChange={handleInputChange}
        />
        {isTitleError && (
          <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
        )}
        {isTitleError && (
          <ErrorMessage>Please enter campaign title</ErrorMessage>
        )}
      </InputWrapper>
      <ButtonWrapper>
        <CreateCampaignButton onClick={handleClick}>
          Create Campaign
        </CreateCampaignButton>
      </ButtonWrapper>
    </Container>
  );
}

export default CreateCampaignBlock;
