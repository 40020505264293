import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function CheckoutForm({
  onConfirm,
  onUpdate,
  updatePaymentMethod,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isElementsLoading, setIsElementsLoading] = useState(true);
  const [isDisabledPayBtn, setIsDisabledPayBtn] = useState(false);

  const submitButtonText = updatePaymentMethod ? "Save" : "Pay now";

  const handleConfirmFromStripe = (intent) => {
    const intentName = updatePaymentMethod ? "Update" : "Payment";
    const emitConfirm = (isSuccess) =>
      updatePaymentMethod
        ? onUpdate(intent.payment_method, isSuccess)
        : onConfirm(intent.id);

    switch (intent.status) {
      case "succeeded":
        setIsDisabledPayBtn(true);
        setMessage(`${intentName} succeeded!`);
        emitConfirm(true);
        break;
      case "canceled":
        setIsDisabledPayBtn(true);
        setMessage(`${intentName} canceled.`);
        emitConfirm(false);
        break;
      case "processing":
        setIsDisabledPayBtn(true);
        setMessage(`${intentName} is processing.`);
        break;
      case "requires_payment_method":
        setMessage(`${intentName} was not successful, please try again.`);
        break;
      default:
        setMessage(`Something went wrong.`);
        break;
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      handleConfirmFromStripe(paymentIntent);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);

  useEffect(() => {
    if (!elements) {
      return;
    }

    const element = elements.getElement("payment");
    element.on("ready", () => {
      setIsElementsLoading(false);
    });
  }, [elements]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let intent;
    let error;

    if (updatePaymentMethod) {
      const { error: setupError, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.origin + "/subscription-plans",
        },
        redirect: "if_required",
      });

      error = setupError;
      intent = setupIntent;
    } else {
      const { paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: window.location.origin + "/subscription-plans",
        },
        redirect: "if_required",
      });

      error = paymentError;
      intent = paymentIntent;
    }

    setIsLoading(false);

    if (!intent) {
      intent = error?.payment_intent || error?.setup_intent;
    }

    if (intent) {
      handleConfirmFromStripe(intent);
      return;
    }

    console.log("payment error:", error);

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button
        disabled={
          isLoading ||
          isElementsLoading ||
          !stripe ||
          !elements ||
          isDisabledPayBtn
        }
        id="submit"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            submitButtonText
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
