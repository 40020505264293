import React, { useState, useCallback } from "react";
import { styled } from "styled-components";
import SampleListItem from "./SampleListItem";

const DEFAULT_ITEM = {
  role: "user",
  content: "",
};

const SampleList = ({ items, onChange, promptsTokens }) => {
  const [listItems, setListItems] = useState(items);

  const onAddItem = useCallback(() => {
    setListItems((listItems) => [...listItems, DEFAULT_ITEM]);
  }, []);

  const onRemoveItem = (index) => {
    listItems.splice(index, 1);
    onChange([...listItems]);
  };

  const onChangeItem = (index, item) => {
    listItems[index] = item;
    onChange([...listItems]);
  };

  return (
    <SampleListComponent>
      {listItems.map((i, index) => {
        return (
          <Item key={index}>
            <Title>Sample tokens:</Title>
            <SampleListItem
              item={i}
              promptsTokens={promptsTokens}
              index={index}
              onDelete={onRemoveItem}
              onChange={onChangeItem}
            />
          </Item>
        );
      })}
      <AddNewItem onClick={onAddItem} type="button">
        Add token
      </AddNewItem>
    </SampleListComponent>
  );
};

const SampleListComponent = styled.div``;

const Item = styled.div``;

const AddNewItem = styled.button`
  cursor: pointer;
  color: #5a5a5a;
  background-color: white;
  height: 40px;
  width: 158px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  margin-top: 15px;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: "AlbertSansExtraBold", sans-serif;
  color: ${({ theme }) => `${theme.colors.gray}`};
  margin-bottom: 15px;
`;

export default SampleList;
