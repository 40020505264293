const WIZARD_STEPS = [
  {
    id: 1,
    title: "Registering App",
    description:
      "First, reach will create a new App Registration within your Azure instance.",
    imageSrc: "/images/automate-o365-key-gen/step1.png",
  },
  {
    id: 2,
    title: "Setting API Permissions",
    description:
      "Now, Reach is configuring the application to grant specific permissions required to interact with your mailbox",
    imageSrc: "/images/automate-o365-key-gen/step2.png",
  },
  {
    id: 3,
    title: "Creating client credentials",
    description:
      "Now, Reach is generating a secret key that can be used to interact with your application.  Please note that this key will expire in 180 days.",
    imageSrc: "/images/automate-o365-key-gen/step3.png",
  },
  {
    id: 4,
    title: "Updating integration settings",
    description:
      "Finally, we can use the newly-generated app credentials within Reach.  You're almost done!",
    imageSrc: "/images/automate-o365-key-gen/step4.png",
  },
];

export { WIZARD_STEPS };
