import React from "react";
import styled from "styled-components";

import { scrollAnchors } from "../constants";

const data = [
  {
    id: 1,
    title: "Personalize at scale",
    description:
      "Unlock the power of personalized communication at scale with Reach. Our AI-driven platform seamlessly integrates with your CRM to automatically craft tailored email and SMS campaigns. Every message is personalized based on the recipient's unique data, ensuring that even large-scale outreach feels one-on-one and relevant. With Reach, you can connect with thousands of contacts while maintaining the personal touch that drives engagement and builds relationships, all without the manual effort typically required for personalization at scale.",
    imgUrl: "images/hupspot-app/2.png",
  },
  {
    id: 2,
    title: "Effortless Integration",
    description:
      "Reach integrates seamlessly with your existing CRM, making it easy to deploy without disrupting your current workflows. With just a few clicks, the Chrome Extension connects to your CRM, bringing the full power of AI-driven email and SMS personalization directly into your workspace. No need for complex setups or extensive training—Reach fits right into your toolkit, allowing your team to start crafting personalized campaigns immediately. Say goodbye to complicated integrations and hello to a streamlined, efficient workflow.",
    imgUrl: "images/hupspot-app/3.png",
  },
  {
    id: 3,
    title: "Personalize Based on Custom CRM Fields",
    description:
      "With Reach, you can dive deep into personalization by using custom CRM fields like client likes, past interactions, and previous meeting notes. Whether it’s referencing a product they love or a detail from your last conversation, Reach ensures every message feels uniquely relevant. This level of customization not only enhances engagement but also builds stronger, more personalized connections with your audience, making each interaction meaningful and memorable.",
    imgUrl: "images/hupspot-app/4.png",
  },
];

function Features() {
  return (
    <>
      <Title id={scrollAnchors.Features}>Features</Title>
      <List>
        {data.map((d1) => {
          return (
            <ListItem key={d1.id}>
              <Info>
                <ListItemTitle>{d1.title}</ListItemTitle>
                <Description>{d1.description}</Description>
              </Info>
              <ImageContainer>
                <Image src={d1.imgUrl} alt="feature" />
              </ImageContainer>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

export default Features;

const Title = styled.h3`
  font-weight: 600;
  font-size: 22px;
  color: #33475b;
  margin-bottom: 24px;
  padding-top: 142px; // 142px - header height.
  margin-top: -142px; // 142px - header height.

  @media (max-width: 768px) {
    padding-top: 164px; // 164px - header height.
    margin-top: -164px; // 164px - header height.
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
`;

const Info = styled.div`
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 544px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ListItemTitle = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
  white-space: pre-line;
`;

const ImageContainer = styled.div`
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 544px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const Image = styled.img`
  max-width: 280px;
  width: 100%;
  display: block;
  height: auto;
`;
