/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { WIZARD_STEPS } from "../constants";
import AutomateIntegrationKeyGen from "../../AutomateIntegrationKeyGen/UI/AutomateIntegrationKeyGenWizard";
import { createIntegration } from "../../../services/integrations";
import { fetchAutoConfigureLog } from "../../../services/queries";

function AutomateO365KeyGen() {
  const navigate = useNavigate();

  const [stepIndex, setStepIndex] = useState(0);
  const [secretKey, setSecretKey] = useState(null);
  const [activeDirectoryId, setActiveDirectoryId] = useState(null);
  const [appRegistrationId, setAppRegistrationId] = useState(null);

  const postMessageEventHandler = (event) => {
    if (!event.data) {
      return;
    }

    if (event.data.finishedStepIndex === undefined) {
      return;
    }

    if (event.data.appId) {
      setAppRegistrationId(event.data.appId);
    }

    if (event.data.tenantId) {
      setActiveDirectoryId(event.data.tenantId);
    }

    if (event.data.secretKey) {
      setSecretKey(event.data.secretKey);
    }

    setStepIndex(event.data.finishedStepIndex + 1);
  };

  useEffect(() => {
    if (
      secretKey &&
      activeDirectoryId &&
      appRegistrationId &&
      stepIndex === WIZARD_STEPS.length - 1
    ) {
      const createOffice365Integration = async () => {
        const connectionKeys = {
          activeDirectoryId,
          appRegistrationId,
          secret: secretKey,
        };

        const data = {
          type: "email",
          connectionKeys: {
            type: "office365",
            ...connectionKeys,
          },
        };

        const result = await createIntegration(data);

        if (!result.success) {
          fetchAutoConfigureLog({
            logInfo: `Failed to create integration for office365; activeDirectoryId: ${activeDirectoryId}; appRegistrationId: ${appRegistrationId}; secretKey: ${secretKey}. Error message: ${result.message}`,
          });
          sendMessageToExtension({
            message: "show-error-message",
            data: {
              message: result.message,
            },
          });
          navigate("/settings", {
            state: { integrationData: data },
          });
          sendMessageToExtension({
            message: "change-iframe-window-type",
            data: {
              iframeWindowType: "drawer",
            },
          });

          return;
        }

        navigate("/settings");
        sendMessageToExtension({
          message: "change-iframe-window-type",
          data: {
            iframeWindowType: "drawer",
          },
        });
      };

      createOffice365Integration();
    }
  }, [secretKey, activeDirectoryId, appRegistrationId, stepIndex]);

  useEffect(() => {
    window.addEventListener("message", postMessageEventHandler);
    sendMessageToExtension({
      message: "automate-o365-key-gen-process-start",
    });

    return () => {
      window.removeEventListener("message", postMessageEventHandler);
    };
  }, []);

  return (
    <AutomateIntegrationKeyGen
      title="Office365 Key Generation"
      description="We are automatically generating a key to help Reach interact with Office365."
      stepIndex={stepIndex}
      steps={WIZARD_STEPS}
      messageTypeForContinue="automate-o365-key-gen-process-continue"
    />
  );
}

export default AutomateO365KeyGen;
