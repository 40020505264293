import React, { useEffect } from "react";
import { styled } from "styled-components";
import { sendMessageToExtension } from "../../../utils/postToExtension";

function ContentStep({ stepIndex, steps, messageTypeForContinue }) {
  useEffect(() => {
    if (stepIndex > 0 && stepIndex < steps.length) {
      sendMessageToExtension({
        message: messageTypeForContinue,
        data: {
          step: stepIndex,
        },
      });
    }
  }, [stepIndex, steps, messageTypeForContinue]);
  return (
    <Content>
      <Section>
        <Title>
          {steps[stepIndex].id}. {steps[stepIndex].title}
        </Title>
        <Description>{steps[stepIndex].description}</Description>
        <ContentImage>
          <img src={steps[stepIndex].imageSrc} alt={stepIndex + " image"} />
        </ContentImage>
      </Section>
    </Content>
  );
}

export default ContentStep;

const Content = styled.div`
  width: 100%;
  margin: ${({ $margin }) => ($margin ? $margin : "60px 30px 30px 60px")};
  height: ${({ $height }) => ($height ? $height : "calc(100vh - 100px)")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
  margin-left: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
  color: #5a5a5a;
  width: 385px;
`;

const ContentImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
  max-height: calc(100% - 100px);
  flex-grow: 1;
  img {
    width: auto;
    max-width: 80%;
    height: auto;
    max-height: 100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  }
`;
