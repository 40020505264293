import React from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  0% {
    opacity:1;
  }
  50% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const Avatar = styled.img`
  position: absolute;
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 9;
  animation: ${blink} normal ${({ $blinkTime }) => $blinkTime}s infinite
    ease-in-out;
`;

const BgImageLeft = styled.img`
  position: absolute;
  width: 47px;
  top: 30%;
  left: 12%;
  z-index: 99;
`;

const BgImageRight = styled.img`
  position: absolute;
  width: 68px;
  right: 14%;
  top: 47%;
  z-index: 99;
`;

const avatarsListConfig = [
  {
    src: "/images/prepare-screen/avatar-1.png",
    width: "45px",
    top: "19%",
    left: "20%",
    blinkTime: 8,
  },
  {
    src: "/images/prepare-screen/avatar-2.png",
    width: "33px",
    top: "34%",
    left: "17%",
    blinkTime: 6,
  },
  {
    src: "/images/prepare-screen/avatar-3.png",
    width: "41px",
    top: "47%",
    left: "24%",
    blinkTime: 5,
  },
  {
    src: "/images/prepare-screen/avatar-4.png",
    width: "34px",
    top: "19%",
    left: "72%",
    blinkTime: 7,
  },
  {
    src: "/images/prepare-screen/avatar-5.png",
    width: "61px",
    top: "22%",
    left: "81%",
    blinkTime: 4,
  },
  {
    src: "/images/prepare-screen/avatar-6.png",
    width: "65px",
    top: "40%",
    left: "76%",
    blinkTime: 9,
  },
];

const Avatars = ({ configList }) => {
  const data = configList || avatarsListConfig;

  return (
    <>
      <BgImageLeft
        src="/images/prepare-screen/bg-left.png"
        alt="prepare-bg-left"
      ></BgImageLeft>
      <BgImageRight
        src="/images/prepare-screen/bg-right.png"
        alt="prepare-bg-right"
      ></BgImageRight>
      {data.map((avatar, index) => (
        <Avatar
          src={avatar.src}
          alt="avatar"
          key={index}
          $top={avatar.top}
          $left={avatar.left}
          $blinkTime={avatar.blinkTime}
          width={avatar.width}
        />
      ))}
    </>
  );
};

export default Avatars;
