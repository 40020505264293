import React from "react";

import AutomateGsuiteKeyGenWizard from "../components/AutomateGsuiteKeyGen";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

function AutomateGsuiteKeyGen() {
  return (
    <>
      <CheckExtensionVersion />
      <AutomateGsuiteKeyGenWizard />
    </>
  );
}

export default AutomateGsuiteKeyGen;
