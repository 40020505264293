import { MONTH_NAMES, DAYS_OF_WEEK_NAMES } from "./constants";

const displayDate = (data) => {
  const date = new Date(data);
  const month = MONTH_NAMES[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const dateString = month + " " + day + ", " + year;
  return dateString;
};

const convertDateToEmailSentFormat = (data) => {
  const date = new Date(data);
  const dayOfWeek = DAYS_OF_WEEK_NAMES[date.getDay()];
  const month = MONTH_NAMES[date.getMonth()];
  const dayOfMonth = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours() % 12 || 12;
  const minute = date.getMinutes();
  const amPm = date.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}, ${year} - ${hour}:${minute
    .toString()
    .padStart(2, "0")} ${amPm}`;
  return formattedDate;
};

export { displayDate, convertDateToEmailSentFormat };
