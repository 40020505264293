import React from "react";
import styled from "styled-components";

import { scrollAnchors } from "../constants";

function Overview() {
  return (
    <>
      <Title id={scrollAnchors.Overview}>Overview</Title>
      <Description>
        <DescriptionSection>
          Generate thousands of unique and personalized email and SMS messages
          in seconds with Reach by SalesStream.ai. Our email and SMS marketing
          automation tool is a ChatGPT wrapper that sits on top of Hubspot,
          Bullhorn and other CRM and ATS solutions, to help you quickly write
          and send personalized messages, drawing data from your history of
          interactions with each contact.
        </DescriptionSection>
        <DescriptionSection>
          Reach integrates seamlessly with ATS like Bullhorn and CRMs like
          HubSpot, fitting right into your workflow. Whether you’re emailing
          clients, customers, candidates, or prospects, Reach makes it easy to
          personalize emails and SMS with AI.
        </DescriptionSection>
        <p>What Reach Can Do For You:</p>
        <DescriptionSection>
          <DescriptionItemList>
            <li>
              Effortless Personalization: Instantly craft thousands of
              personalized emails and SMS using your CRM or ATS data.
            </li>
            <li>
              Seamless CRM + ATS Integration: Works smoothly with Bullhorn and
              HubSpot through our Google Chrome extension.
            </li>
            <li>
              AI-Powered Writing: Automatically generates compelling messages
              for clients, prospects, and team members using our ChatGPT
              wrapper.
            </li>
            <li>
              Boosted Engagement: Ensures your emails reach inboxes, not junk
              folders, increasing open and response rates.
            </li>
            <li>
              Real-Time Tracking: Monitor your email performance and improve
              your strategy with real-time analytics.
            </li>
            <li>
              No Learning Curve: Easy to use with no complex sequences to
              master.
            </li>
          </DescriptionItemList>
        </DescriptionSection>
        <DescriptionSection>
          Try our ChatGPT email tool and simplify your workflow with our
          powerful AI-powered email automation.
        </DescriptionSection>
      </Description>
    </>
  );
}

export default Overview;

const Title = styled.h2`
  font-weight: 400;
  font-size: 24px;
  color: #33475b;
  margin-bottom: 16px;
  padding-top: 142px; // 142px - header height.
  margin-top: -142px; // 142px - header height.

  @media (max-width: 768px) {
    padding-top: 164px; // 164px - header height.
    margin-top: -164px; // 164px - header height.
  }
`;

const Description = styled.div`
  color: #33475b;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
`;

const DescriptionSection = styled.div`
  margin-top: 20px;
`;

const DescriptionItemList = styled.ul`
  margin-left: 6px;
`;
