import React, { useRef, useState } from "react";
import styled from "styled-components";

function Video({ preview, altPreview }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
      videoRef.current.muted = false;
    }
  };

  return (
    <VideoContainer>
      <StyledVideo ref={videoRef}>
        <source src={preview} type="video/webm" />
        <source src={altPreview} type="video/mp4" />
        Your browser does not support the video tag.
      </StyledVideo>
      <PlayButton onClick={togglePlay} $isPlaying={isPlaying} />
    </VideoContainer>
  );
}

export default Video;

const VideoContainer = styled.div`
  position: relative;
  max-width: calc(100% - 60px);
  width: 100%;

  @media (max-width: 768px) {
    max-width: 454px;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-image: ${({ $isPlaying }) =>
    !$isPlaying ? 'url("images/hupspot-app/play-icon.png")' : ""};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 48px 48px;
  width: 100%;
  height: 100%;
`;
