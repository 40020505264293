import React, { useState } from "react";
import { styled } from "styled-components";
import ConnectBtn from "../../../ConnectBtn";
import CustomSelect from "../../../CustomSelect";
import { EMAIL_REGEX, USER_ROLES } from "../../../../utils/constants";
import { displayUserRole } from "../../../../utils/utils";
import { Tooltip } from "react-tooltip";

const USER_ROLE_FIELD_NAME = "role";

const FIELDS = [
  { label: "Name", name: "name", type: "text" },
  { label: "Email", name: "email", type: "email", pattern: EMAIL_REGEX },
];

const UserFormPopup = ({ data, onClose, onSave, isSaving }) => {
  const isEditMode = !!data.id;

  const isDeactivated = data.role === USER_ROLES.DEACTIVATED;
  const isCompanyAdmin = data.role === USER_ROLES.COMPANY_ADMIN;

  const userRoleOptions = [
    ...Object.values(USER_ROLES).filter(
      (r) =>
        r === data.role ||
        (r !== USER_ROLES.ADMIN &&
          r !== USER_ROLES.UNPAID &&
          (r !== USER_ROLES.INVITED || !isEditMode))
    ),
    ...(data.role && !Object.values(USER_ROLES).includes(data.role)
      ? [data.role]
      : []),
  ].map((value) => ({ value, label: displayUserRole(value) }));

  const prepareData = (d) => {
    if (!d.hasOwnProperty("id")) {
      return {
        id: "",
        name: "",
        email: "",
        role: USER_ROLES.INVITED,
      };
    }
    return d;
  };

  const dataJson = JSON.stringify(prepareData(data));
  const [values, setValues] = useState(JSON.parse(dataJson));
  const [errorMessages, setErrorMessages] = useState({});

  const handleClose = (event) => {
    event?.preventDefault();
    if (!isSaving) {
      onClose();
    }
  };

  const prepareInputValue = (event, field) => {
    return event.target.value.trim();
  };

  const handleToggleActive = (event) => {
    event?.preventDefault();
    const role = isDeactivated ? USER_ROLES.USER : USER_ROLES.DEACTIVATED;
    onSave({ id: data.id, role });
  };

  const handleSubmit = (event) => {
    event?.preventDefault();

    const errors = {};

    FIELDS.forEach((f) => {
      if (!values[f.name]) {
        errors[f.name] = `Missed ${[f.label]}`;
      } else if (f.pattern && !f.pattern.test(values[f.name])) {
        errors[f.name] = `Invalid ${[f.label]}`;
      }
    });

    if (Object.keys(errors).length) {
      setErrorMessages(errors);
    } else {
      const updData = { id: data.id };
      FIELDS.forEach((f) => {
        const val = values[f.name];
        if (val !== data[f.name]) {
          updData[f.name] = val;
        }
      });
      onSave(updData);
    }
  };

  const renderFieldCtrl = (field) => {
    const setValue = (value) => {
      setValues({ ...values, [field.name]: value });
      setErrorMessages((m) => ({
        ...m,
        [field.name]: "",
      }));
    };

    if (field.type === "select") {
      const isCompanyAdminTooltip =
        field.name === USER_ROLE_FIELD_NAME && isCompanyAdmin;
      return (
        <>
          <SelectWrap
            {...(isCompanyAdminTooltip
              ? {
                  "data-tooltip-id": `tooltip-user-${field.name}`,
                  "data-tooltip-content":
                    "Please first assign another user as a company admin",
                }
              : null)}
            $disabled={
              isCompanyAdminTooltip ||
              (!!field.isDisabled && field.isDisabled(values))
            }
          >
            <CustomSelect
              options={
                field.name === USER_ROLE_FIELD_NAME ? userRoleOptions : []
              }
              title=""
              width="100%"
              selected={values[field.name]}
              onSelect={setValue}
            />
          </SelectWrap>
          {isCompanyAdminTooltip && (
            <Tooltip
              id={`tooltip-user-${field.name}`}
              className="custom-tooltip"
              place="bottom"
            />
          )}
        </>
      );
    }

    return (
      <Input
        id={field.name}
        placeholder={field.placeholder || `Enter ${field.label}...`}
        value={values[field.name]}
        onBlur={(e) => {
          setValue(prepareInputValue(e, field));
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        autoComplete="off"
        type={field.type}
        disabled={!!field.isDisabled && field.isDisabled(values)}
        {...field.props}
      />
    );
  };

  return (
    <InviteContainer>
      <Content>
        <CloseIcon className="close-icon" onClick={handleClose}>
          <svg
            width="11"
            height="11"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          >
            <line x1="11" y1="0" x2="0" y2="11" />
            <line x1="0" y1="0" x2="11" y2="11" />
          </svg>
        </CloseIcon>

        <Form onSubmit={handleSubmit}>
          {FIELDS.map((field) => {
            return (
              <Inputs key={field.name}>
                <InputBlock>
                  {field.type !== "boolean" && (
                    <Label htmlFor={field.name}>{field.label}</Label>
                  )}
                  <Relative>
                    {renderFieldCtrl(field)}
                    {!!errorMessages[field.name] && (
                      <ErrorIcon
                        src="/images/input-error-icon.svg"
                        alt="error"
                      />
                    )}
                  </Relative>
                  {!!errorMessages[field.name] && (
                    <ErrorMessage>{errorMessages[field.name]}</ErrorMessage>
                  )}
                </InputBlock>
              </Inputs>
            );
          })}

          <Actions>
            <ConnectBtn
              label={isEditMode ? "Save" : "Send Invite"}
              disabled={dataJson === JSON.stringify(values) || isSaving}
            />
            {isEditMode &&
              data.role !== USER_ROLES.UNPAID &&
              data.role !== USER_ROLES.INVITED && (
                <>
                  <ConnectBtn
                    type="button"
                    label={`${isDeactivated ? "R" : "D"}eactivate`}
                    onClick={handleToggleActive}
                    disabled={isCompanyAdmin || isSaving}
                    {...(isCompanyAdmin
                      ? {
                          props: {
                            "data-tooltip-id": `tooltip-user-active`,
                            "data-tooltip-content":
                              "Please first assign another user as a company admin",
                          },
                        }
                      : null)}
                  />
                  {isCompanyAdmin && (
                    <Tooltip
                      id={`tooltip-user-active`}
                      className="custom-tooltip"
                      place="top"
                    />
                  )}
                </>
              )}
          </Actions>
        </Form>
      </Content>
    </InviteContainer>
  );
};

export default UserFormPopup;

const InviteContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 70%;
  max-width: 500px;
  margin: 0 auto;
  height: fit-content;
  max-height: 70vh;
  margin-top: 14vh;
  background: #fff;
  border-radius: 4px;
  padding: 20px 20px 20px 10px;
  border: 1px solid #999;
`;

const CloseIcon = styled.span`
  cursor: pointer;
  position: absolute;
  right: -30px;
  top: -13px;
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(70vh - 80px);
  overflow: auto;
`;

const Inputs = styled.div`
  margin-left: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .custom-business-hours-cont {
    margin-bottom: -8px;
    > div > label,
    > div > div > label {
      font-size: 12px;
      height: 20px;
    }
    .multi-select {
      .dropdown-heading-value {
        span.gray {
          display: none !important;
        }
      }
    }
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  box-shadow: 0 0 10px #dfdfdf;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
  background: #fff;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-left: 10px;
  padding-top: 20px;
  position: sticky;
  bottom: 0;
  background: #fff;
`;

const SelectWrap = styled.div`
  width: 90%;
  margin-bottom: 5px;
  > div:first-child {
    ${({ $disabled }) =>
      $disabled ? "opacity: 0.65; pointer-events: none;" : ""}
    margin-bottom: 0;
    height: 40px;
    > div:first-child {
      height: 40px;
      > div:first-child {
        font-size: 13px;
        ${({ $noSelected }) => ($noSelected ? "color: #757678;" : "")}
      }
      ${({ $hasNoneOption }) =>
        $hasNoneOption
          ? `
              + div {
                > div:first-child {
                  color: #757678;
                }
              }
            `
          : ""}
    }
  }
`;
