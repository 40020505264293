import { CHROME_STORE_EXTENSION_ID } from "./constants";

let extensionIdFromLocalStorage = localStorage.getItem("extensionId");
let extensionIdFromURL = new URLSearchParams(window.location.search).get(
  "extensionId"
);

const sendMessageToExtension = (message) => {
  let extensionId =
    extensionIdFromLocalStorage ||
    extensionIdFromURL ||
    CHROME_STORE_EXTENSION_ID;

  // eslint-disable-next-line no-undef
  let chromeRuntime = chrome.runtime;

  // try to send a message to the extensionId from LocalStorage
  if (extensionId) {
    try {
      chromeRuntime.sendMessage(extensionId, message);
    } catch (error) {
      console.log(`Error: ${error.message}; stack: ${error.stack}`);
      // if we didn't try the one from the Url, let's try that one
      if (extensionIdFromURL && extensionId !== extensionIdFromURL) {
        localStorage.setItem("extensionId", extensionIdFromURL);
        chromeRuntime.sendMessage(extensionIdFromURL, message);
      }
    }
  }
};

export { sendMessageToExtension };
