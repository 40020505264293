import React from "react";
import styled from "styled-components";

function Title({ children }) {
  return <Container>{children}</Container>;
}

export default Title;

const Container = styled.h3`
  font-size: 22px;
  color: #33475b;
  margin-bottom: 16px;
  line-height: normal;
`;
