import React from "react";

import TabsList from "./TabsList";
import { Container } from "./styles";

function Tabs({ selectedOptionId, handleSelectOption }) {
  return (
    <Container>
      <TabsList
        selectedTabId={selectedOptionId}
        handleSelectTab={handleSelectOption}
      />
    </Container>
  );
}

export default Tabs;
