/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../InfiniteScroll";
import Spinner from "../../../Spinner";
import Loader from "../../../Loader";
import theme from "../../../../theme";
import { getUsers } from "../../../../services/users";
import { getFilters } from "../../../../services/campaigns";
import { CRM_OPTIONS } from "../../../../utils/constants";
import { MultiSelect } from "react-multi-select-component";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { displayDate, displayNumber, displayPercent, displayUserRole } from "../../../../utils/utils";

function UsersTable({ openCampaignsWithFilter }) {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState({
    crm: [],
    company: [],
    dateRange: {},
  });
  const [sort, setSort] = useState({ sort: null, order: null });

  const tableContRef = useRef(null);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const fetchUsers = async (params) => {
    const data = {
      limit: getLimit(),
      offset: params?.clearAfterSearch ? 0 : offset,
    };

    if (params?.newSort || sort.sort) {
      data.sort = (params?.newSort || sort).sort;
      data.order = (params?.newSort || sort).order;
    }

    if (Object.keys(params?.newFilters || filters).length > 0) {
      data.filters = params?.newFilters || filters;
    }

    setIsLoading(!data.offset);

    const result = await getUsers(data);

    setIsLoading(false);

    if (result.success) {
      setUsers(
        params?.clearAfterSearch
          ? result.result.users
          : [...users, ...result.result.users]
      );
      setDataLength(
        params?.clearAfterSearch
          ? result.result.users.length
          : dataLength + result.result.users.length
      );
      setHasMore(result.result.meta.hasMore);
      if (result.result.meta.hasMore) {
        setOffset(params?.clearAfterSearch ? data.limit : offset + data.limit);
      }
    } else {
      setHasMore(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch users",
        },
      });
    }
  };

  const handleClickSort = async (sortField) => {
    let newSort;
    if (sortField === sort.sort) {
      if (sort.order === "asc") {
        newSort = { ...sort, order: "desc" };
      } else {
        newSort = { sort: null, order: null };
      }
    } else {
      newSort = { sort: sortField, order: "asc" };
    }
    setSort(newSort);
    await fetchUsers({ clearAfterSearch: true, newSort });
  };

  const handleChangeFilter = async (newFilters) => {
    setFilters(newFilters);
    await fetchUsers({ clearAfterSearch: true, newFilters });
  };

  const handleSelectFilter = (filterName, selOptions) => {
    const newFilters = {
      ...filters,
      [filterName]: selOptions.map((o) => o.value),
    };
    handleChangeFilter(newFilters);
  };

  const handleSelectDate = (name, value) => {
    const dateRange = { ...filters.dateRange };
    if (value) {
      if (name === "endDate") {
        value.setHours(23);
        value.setMinutes(59);
        value.setSeconds(59);
        value.setMilliseconds(999);
      }
      dateRange[name] = value;
    } else {
      delete dateRange[name];
    }
    const newFilters = {
      ...filters,
      dateRange,
    };
    handleChangeFilter(newFilters);
  };

  const fetchByScroll = async () => {
    await fetchUsers({ scrolling: true });
  };

  const fetchOnMount = async () => {
    await fetchUsers();
  };

  useEffect(() => {
    const loadCompanyFilter = async () => {
      const res = await getFilters({ filter: ["companies"] });
      if (res.success) {
        setCompanies(
          (res.result?.companies || []).map((c) => ({
            value: c.id,
            label: c.name,
          }))
        );
      }
    };
    loadCompanyFilter();
    fetchOnMount();
  }, []);

  const withSort = dataLength > 1;

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {[
            { name: "name", label: "Name" },
            { name: "email", label: "Email" },
            { name: "role", label: "Type" },
            { name: "companyName", label: "Company" },
            { name: "inviteCode", label: "Invite code" },
            { name: "lastLoginAt", label: "Last Login" },
          ].map(({ name, label }) => (
            <TableHeadData
              key={name}
              {...(withSort
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort(name),
                  }
                : {})}
            >
              <TableHeadDataSort>
                {label}
                {withSort && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === name ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
          ))}
          {[
            { name: "totalCampaigns", label: "Total Campaigns" },
            { name: "sent", label: "Emails Sent" },
            { name: "openRate", label: "Open Rate" },
            { name: "engagementRate", label: "Engagement Rate" },
          ].map(({ name, label }) => (
            <TableHeadData key={name}>
              <TableHeadDataSort>{label}</TableHeadDataSort>
            </TableHeadData>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No users to show</EmptyTableData>
            </TableRow>
          ) : (
            users.map((user, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>{user.name}</TableData>
                <TableData>{user.email}</TableData>
                <TableData>{displayUserRole(user.role)}</TableData>
                <TableData>{user.companyName}</TableData>
                <TableData>{user.inviteCode}</TableData>
                <TableData>{displayDate(user.lastLoginAt)}</TableData>
                <TableData
                  $cursor="pointer"
                  onClick={() => openCampaignsWithFilter({ userId: user.id })}
                >
                  {user.totalCampaigns}
                </TableData>
                <TableData>{displayNumber(user.sent)}</TableData>
                <TableData>{displayPercent(user.openRate)}</TableData>
                <TableData>{displayPercent(user.engagementRate)}</TableData>
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Container $isLoading={isLoading}>
      <Filters>
        <Filter>
          <FilterLabel>Company</FilterLabel>
          <MultiSelect
            options={companies}
            value={companies.filter((c) => filters.company.includes(c.value))}
            onChange={(selOptions) => handleSelectFilter("company", selOptions)}
            labelledBy="Company"
            hasSelectAll={false}
            disableSearch={true}
            closeOnChangedValue={true}
            display="chip"
          />
        </Filter>
        <Filter>
          <FilterLabel>CRM</FilterLabel>
          <MultiSelect
            options={CRM_OPTIONS}
            value={CRM_OPTIONS.filter((c) => filters.crm.includes(c.value))}
            onChange={(selOptions) => handleSelectFilter("crm", selOptions)}
            labelledBy="CRM"
            hasSelectAll={false}
            disableSearch={true}
            closeOnChangedValue={true}
            display="chip"
          />
        </Filter>
        <Filter>
          <FilterLabel>Date</FilterLabel>
          <DateRange>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={filters.dateRange.startDate}
              onChange={(date) => handleSelectDate("startDate", date)}
              selectsStart
              startDate={filters.dateRange.startDate}
              endDate={filters.dateRange.endDate}
            />
            <span>-</span>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              selected={filters.dateRange.endDate}
              onChange={(date) => handleSelectDate("endDate", date)}
              selectsEnd
              startDate={filters.dateRange.startDate}
              endDate={filters.dateRange.endDate}
              minDate={filters.dateRange.startDate}
            />
          </DateRange>
        </Filter>
      </Filters>

      <Content ref={tableContRef}>
        {isLoading ? (
          <Loader parentSize={true} bgColor="white" padding="0" />
        ) : (
          <OverflowContent id="table_layout">
            {hasMore ? (
              <InfiniteScroll
                dataLength={dataLength}
                next={fetchByScroll}
                hasMore={hasMore}
                scrollableTarget={"table_layout"}
                style={{ overflowY: "hidden" }}
                loader={
                  <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                }
              >
                {table}
              </InfiniteScroll>
            ) : (
              table
            )}
          </OverflowContent>
        )}
      </Content>
    </Container>
  );
}

export default UsersTable;

const Container = styled.div`
  height: calc(100% - 55px);
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           background: transparent;
           z-index: 1;
         }`
      : ""}
`;

const Content = styled.div`
  height: calc(100% - 90px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Filters = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  .multi-select .dropdown-content {
    z-index: 11;
  }
`;

const FilterLabel = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const DateRange = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  > span {
    margin: 0 5px;
  }
  > .react-datepicker-wrapper {
    > .react-datepicker__input-container > input {
      margin: 0;
      width: 97px;
      border-radius: 4px;
      border-color: #ccc;
      box-shadow: none;
      font-size: 12px;
    }
  }
  .react-datepicker-popper {
    z-index: 11;
    .react-datepicker__month-container {
      font-size: 12px;
      .react-datepicker__current-month {
        font-size: 12px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        margin: 3px;
        width: 21px;
        line-height: 21px;
      }
    }
  }
`;
