import { useEffect, useState } from "react";

import { getSubscriptionPlans } from "../../services/subscription";

export const useGetPlansData = () => {
  const [plansData, setPlansData] = useState({
    plans: null,
    isLoading: false,
    errorMessage: null,
  });

  useEffect(() => {
    const fetchSubscriptionPlans = async () => {
      setPlansData((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const response = await getSubscriptionPlans();

        if (!response.success || !response.result) {
          throw new Error(response.message);
        }

        setPlansData({
          plans: response.result,
          isLoading: false,
          errorMessage: null,
        });
      } catch (error) {
        setPlansData({
          plans: null,
          isLoading: false,
          errorMessage: error.message,
        });
      }
    };

    fetchSubscriptionPlans();
  }, []);

  return plansData;
};

export const useSelectDefaultPlanId = (plans, currentPlanId) => {
  const [selectedPlanId, setSelectedPlanId] = useState(currentPlanId || null);

  useEffect(() => {
    if (currentPlanId) {
      return;
    }

    setSelectedPlanId(() => {
      if (plans.length > 0) {
        return plans[Math.floor(plans.length / 2)].id;
      } else {
        return null;
      }
    });
  }, [plans, currentPlanId]);

  return {
    selectedPlanId,
    setSelectedPlanId,
  };
};
