import React from "react";
import styled from "styled-components";

function LabelItem({ name, url }) {
  return (
    <Link href={url}>
      <Name>{name}</Name>
    </Link>
  );
}

export default LabelItem;

const Link = styled.a`
  text-decoration: none;
`;

const Name = styled.span`
  font-size: 12px;
  background-color: #f5f8fa;
  border: 1px solid #7c98b6;
  color: #33475b;
  line-height: 22px;
  padding: 0px 8px;
  position: relative;
  cursor: inherit;
  display: inline-flex;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  user-select: none;
  vertical-align: 8px;

  &:hover {
    text-decoration: underline;
  }
`;
