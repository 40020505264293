import React from "react";
import styled from "styled-components";

function TabItem({ activeItem, href, onClick, name }) {
  return (
    <Item $activeItem={activeItem}>
      <Link href={href} onClick={onClick} $activeItem={activeItem}>
        {name}
      </Link>
    </Item>
  );
}

export default TabItem;

const Item = styled.li`
  transition-property: color;
  white-space: nowrap;

  ${({ $activeItem }) =>
    $activeItem &&
    `
    border-bottom: 2.5px solid #33475b;
    font-weight: 600;
  `}
`;

const Link = styled.a`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #33475b;
  padding: 12px 28px;

  ${({ $activeItem }) =>
    $activeItem &&
    `
    font-weight: 600;
  `}
`;
