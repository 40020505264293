import { styled } from "styled-components";

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${({ $plusWidth }) =>
    $plusWidth ? `calc(40% + ${$plusWidth})` : "40%"};
  margin-left: 30px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

export const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
  margin-bottom: 8px;
`;

export const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
`;

export const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;

export const DateSelect = styled.div`
  margin-bottom: 8px;
  width: 100%;
  > .react-datepicker-wrapper {
    width: 100%;
    > .react-datepicker__input-container {
      > input {
        margin: 0;
        width: 100%;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #ccc;
        box-shadow: 0 0 10px #dfdfdf;
        background-color: white;
        color: black;
        font-size: 12px;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
          font-style: italic;
          color: #a8adb7;
        }
      }
      .react-datepicker__close-icon {
        &::after {
          font-size: 18px;
          background: transparent;
          color: #adadad;
        }
        &:hover {
          &::after {
            color: #999;
          }
        }
      }
    }
  }
  .react-datepicker-popper {
    z-index: 11;
    .react-datepicker__triangle {
      transform: translate(25px, 0) !important;
    }
    .react-datepicker__month-container {
      font-size: 12px;
      .react-datepicker__current-month {
        font-size: 12px;
      }
      .react-datepicker__day-name,
      .react-datepicker__day {
        margin: 3px;
        width: 21px;
        line-height: 21px;
      }
    }
    .react-datepicker__today-button {
      font-size: 12px;
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const InputTextarea = styled.textarea`
  &,
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    resize: vertical;
    min-height: 70px;
    height: 150px;
    padding: 10px;
    font-size: 12px;
    color: #000000;
    box-shadow: 0 0 10px #dfdfdf;
    border-radius: 3px;
    margin-bottom: 8px;
    width: 100%;

    &::placeholder {
      font-size: 12px;
      font-weight: 400;
      font-style: italic;
      color: #a8adb7;
    }
  }
`;

export const SequenceTemplateField = styled.div`
  display: grid;
  grid-template-columns: 1fr 18px;
  column-gap: 12px;
  align-items: start;
  justify-content: start;
  width: 100%;
  img[alt="info"] {
    margin-top: 8px;
  }
`;
