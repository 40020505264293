/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ConfirmPopup from "../ConfirmPopup";
import Loader from "../Loader";
import { sendMessageToExtension } from "../../utils/postToExtension";

export default function ConfirmKeyGen({
  provider,
  titleForGenerate,
  descForGenerate,
  providerLabelForLogin,
  messageTypeForOpenKeyGenWizard,
  onClose,
}) {
  const [isConfirmKeyGen, setIsConfirmKeyGen] = useState(false);

  const [isConfirmKeyGenAfterLogin, setIsConfirmKeyGenAfterLogin] =
    useState(false);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isCheckingIsAuth, setIsCheckingIsAuth] = useState(true);

  const [isConfirmLoginForKeyGen, setIsConfirmLoginForKeyGen] = useState(false);

  const openAutomateProviderKeyWizard = () => {
    sendMessageToExtension({
      message: messageTypeForOpenKeyGenWizard,
    });
    onClose();
  };

  const postMessageEventHandlerForLogin = async (event) => {
    if (!event?.data) {
      return;
    }

    console.log("!!! postMessageEventHandlerForLogin ", event.data);

    if (provider === event.data.provider) {
      setIsLoggingIn(false);
      window.removeEventListener("message", postMessageEventHandlerForLogin);
      if (!event.data.isAuth) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: "Something went wrong when trying to log in",
          },
        });
        onClose();
        return;
      }
      setIsConfirmKeyGenAfterLogin(true);
    }
  };

  const openLoginProviderForKeyGen = () => {
    setIsConfirmLoginForKeyGen(false);
    setIsLoggingIn(true);
    window.addEventListener("message", postMessageEventHandlerForLogin);
    sendMessageToExtension({
      message: "automate-provider-key-gen-login",
      data: { provider },
    });
  };

  useEffect(() => {
    const postMessageEventHandlerForCheckIsAuth = async (event) => {
      if (!event?.data) {
        return;
      }

      console.log("!!! postMessageEventHandlerForCheckIsAuth ", event.data);

      if (provider === event.data.provider) {
        setIsCheckingIsAuth(false);
        window.removeEventListener(
          "message",
          postMessageEventHandlerForCheckIsAuth
        );
        if (event.data.isAuth) {
          setIsConfirmKeyGen(true);
          return;
        }
        setIsConfirmLoginForKeyGen(true);
      }
    };

    window.addEventListener("message", postMessageEventHandlerForCheckIsAuth);
    sendMessageToExtension({
      message: "automate-provider-key-gen-check-is-auth",
      data: { provider },
    });

    return () => {
      window.removeEventListener(
        "message",
        postMessageEventHandlerForCheckIsAuth
      );
    };
  }, []);

  return (
    <>
      {(isCheckingIsAuth || isLoggingIn) && <Loader />}

      {isConfirmKeyGen && (
        <ConfirmPopup
          title={titleForGenerate}
          description={descForGenerate}
          confirmLabel="Let’s get started"
          cancelLabel="Cancel"
          onConfirm={openAutomateProviderKeyWizard}
          onCancel={onClose}
        />
      )}

      {isConfirmLoginForKeyGen && (
        <ConfirmPopup
          title="Please log in first"
          description={`To continue, please log into ${providerLabelForLogin}`}
          confirmLabel="Sure, I’ll log in"
          cancelLabel="Maybe later"
          onConfirm={openLoginProviderForKeyGen}
          onCancel={onClose}
        />
      )}

      {isConfirmKeyGenAfterLogin && (
        <ConfirmPopup
          title="Let’s go!"
          description={`Now that you’re logged in, Reach can generate an API key`}
          confirmLabel="Continue"
          cancelLabel="Maybe later"
          onConfirm={openAutomateProviderKeyWizard}
          onCancel={onClose}
          iconSrc="/images/thank-you.png"
        />
      )}

      {(isCheckingIsAuth || isLoggingIn) && <Loader />}
    </>
  );
}
