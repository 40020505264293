import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import {
  getNotifications,
  setAsReadNotifications,
} from "../services/notifications";
import { NOTIFICATION_STATUSES, NOTIFICATION_TYPES } from "../utils/constants";
import { sendMessageToExtension } from "../utils/postToExtension";

function NotificationsPane({ closePane, onRead }) {
  const [notifications, setNotifications] = useState([]);

  const buildNotificationText = (notification) => {
    switch (notification.type) {
      case NOTIFICATION_TYPES.EMAIL_REPLY:
        return {
          title: `${notification.contactName} replied to a message`,
          description: `${notification.contactName} replied to a message on campaign "${notification.campaignName}"`,
        };
      case NOTIFICATION_TYPES.GOAL_COMPLETION:
        return {
          title: `${notification.contactName} completed a goal`,
          description: `${notification.contactName} completed the goal on campaign "${notification.campaignName}"`,
        };
      case NOTIFICATION_TYPES.OAUTH_TOKEN_EXPIRED:
        return {
          title: "Warning!",
          description:
            "Unable to send email. Your token has expired. Please go to settings to re-login",
        };
      default:
        return {
          title: "Unknown",
          description: "",
        };
    }
  };

  useEffect(() => {
    const getData = async () => {
      const response = await getNotifications({
        status: NOTIFICATION_STATUSES.NEW,
      });

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: "Something wrong while trying to fetch notifications",
          },
        });

        closePane();
        return;
      }
      setNotifications(response.result.notifications);

      onRead();

      if (response.result.notifications.length) {
        const ids = response.result.notifications.map(({ id }) => id);
        await setAsReadNotifications({ ids });
      }
    };
    getData();
  }, []);

  return (
    <NotificationsPaneContainer id="notifications-pane">
      <Container>
        <Header>
          <HeaderTitle>All Notifications</HeaderTitle>
          <CloseIconBlock>
            <CloseIcon
              src="/images/notifications-close-icon.png"
              alt="info"
              width="18"
              onClick={closePane}
            />
          </CloseIconBlock>
        </Header>
        <Content>
          {notifications.map((item, index) => (
            <NotificationContainer key={index}>
              <NotificationTitleBlock>
                <img
                  src="/images/reach-black-small.png"
                  alt="reach"
                  width="18"
                />
                <NotificationTitle>
                  {buildNotificationText(item).title}
                </NotificationTitle>
              </NotificationTitleBlock>
              <NotificationDefinitionBlock>
                <NotificationDefinition>
                  {buildNotificationText(item).description}
                </NotificationDefinition>
              </NotificationDefinitionBlock>
            </NotificationContainer>
          ))}
        </Content>
      </Container>
    </NotificationsPaneContainer>
  );
}

export default NotificationsPane;

const NotificationsPaneContainer = styled.div`
  width: 374px;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  position: absolute;
  right: 0;
  top: 70px;
  display: flex;
  padding-left: 18px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
`;

const CloseIconBlock = styled.div`
  padding-left: 15px;
  height: 26px;
  border-left: 1px solid #e6e6e6;
`;

const HeaderTitle = styled.div`
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 700;
  font-family: "AlbertSansExtraBold", sans-serif;
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

const Header = styled.div`
  padding-top: 18px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 28px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 10px;
  height: calc(100% - 100px);
`;

const NotificationContainer = styled.div`
  margin-bottom: 15px;
`;

const NotificationTitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationTitle = styled.div`
  color: black;
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
  font-family: "AlbertSansExtraBold", sans-serif;
  margin-left: 10px;
`;

const NotificationDefinitionBlock = styled.div`
  margin-left: 28px;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 13px;
`;

const NotificationDefinition = styled.div`
  color: #646464;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  padding-right: 20px;
`;

const Container = styled.div`
  height: calc(100% - 10px);
`;
