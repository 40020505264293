import React, { useRef, useState, useEffect } from "react";
import { styled } from "styled-components";
import CustomSelect from "../../../CustomSelect";

const GPT_ROLES = [
  { label: "System", value: "system" },
  { label: "User", value: "user" },
  { label: "Assistant", value: "assistant" },
  { label: "Conditional", value: "conditional" },
];

const PromptListItem = ({ index, item, onDelete, onChange, listItems }) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!item) {
      return;
    }
    setContent(item.content);
    setRole(item.role);
    setCondition(item.condition || "");
  }, [item]);

  const [content, setContent] = useState("");
  const [role, setRole] = useState("");
  const [condition, setCondition] = useState("");

  const isIncludeConditional = () => {
    let isConditionalExists = false;
    for (let i = 0; i < index; i++) {
      if (listItems[i].role === "conditional") {
        isConditionalExists = true;
      }
    }
    return isConditionalExists;
  };

  const onSelect = (role) => {
    setRole(role);
    onChange(index, {
      role,
      content,
    });
  };

  const onSelectCondition = (condition) => {
    setCondition(condition);
    onChange(index, {
      role,
      condition,
      content,
    });
  };

  const handleSelectOpen = () => {
    const conditionalRole = GPT_ROLES.find(
      (role) => role.value === "conditional"
    );
    if (
      index === 0 ||
      listItems[index - 1].role !== "assistant" ||
      listItems.find((item) => item.role === "conditional")
    ) {
      conditionalRole.disabled = true;
      return;
    }
    conditionalRole.disabled = false;
  };

  const addCondition = (conditionIndex, condition) => {
    const newConditions = item.content.map((item, i) =>
      i === conditionIndex ? condition : item
    );

    onChange(index, {
      role: "conditional",
      content: newConditions,
    });
  };

  const onAddInput = () => {
    const newConditions = [...item.content, ""];
    onChange(index, {
      role: "conditional",
      content: newConditions,
    });
  };

  const deleteConditionInput = (conditionIndex) => {
    const newConditions = item.content.filter((_, i) => i !== conditionIndex);
    onChange(index, {
      role: "conditional",
      content: newConditions,
    });
  };

  const conditionsOptions = () => {
    const conditionsStep = listItems.find(
      (item) => item.role === "conditional"
    );
    const conditionsBlock = conditionsStep.content || conditionsStep.options;
    const options = conditionsBlock?.map((condition) => ({
      label: condition,
      value: condition,
    }));
    return options;
  };

  const onTextareaChange = (e) => {
    const content = e.target.value;
    setContent(content);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      onChange(index, {
        ...item,
        content,
      });
    }, 100);
  };

  return (
    <Relative>
      <DeleteButton onClick={() => onDelete(index)} type="button">
        -
      </DeleteButton>
      <CustomSelect
        options={GPT_ROLES}
        title="Role"
        selected={role}
        onSelect={onSelect}
        handleSelectOpen={handleSelectOpen}
      />
      {role === "conditional" ? (
        <InputBlock>
          <Label htmlFor="password">Conditions</Label>
          {Array.isArray(content) &&
            content.map((condition, conditionIndex) => (
              <div key={conditionIndex}>
                <Input
                  placeholder="Condition"
                  value={condition}
                  onChange={(e) => addCondition(conditionIndex, e.target.value)}
                  autoComplete="off"
                />
                <DeleteButton
                  onClick={() => deleteConditionInput(conditionIndex)}
                  type="button"
                >
                  -
                </DeleteButton>
              </div>
            ))}
          <AddNewItem onClick={onAddInput} type="button">
            Add condition
          </AddNewItem>
        </InputBlock>
      ) : (
        <InputBlock>
          <Label htmlFor="password">Prompts</Label>
          <InputTextarea
            disabled={role === "assistant"}
            placeholder="Prompt template..."
            value={content}
            onChange={onTextareaChange}
            autoComplete="off"
          />
          {isIncludeConditional() && (
            <>
              <Label htmlFor="password">Condition</Label>
              <CustomSelect
                options={conditionsOptions()}
                title="Condition"
                selected={condition}
                onSelect={onSelectCondition}
              />
            </>
          )}
        </InputBlock>
      )}
    </Relative>
  );
};

const DeleteButton = styled.button`
  color: ${({ theme }) => `${theme.colors.red}`};
  background: none;
  border: ${({ theme }) => `1px solid ${theme.colors.red}`};
  /* padding: 2px; */
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 6px;
  left: calc(90% - 20px);
  cursor: pointer;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const InputTextarea = styled.textarea`
  border: 1px solid #d1d1d1;
  outline: none;
  padding: 10px;
  height: 150px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 150px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 80%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 80%;
  }
`;

const AddNewItem = styled.button`
  width: 30%;
  border: ${({ theme }) => `1px dotted ${theme.colors.gray}`};

  color: ${({ theme }) => `${theme.colors.gray}`};
  height: 15px;
  font-size: 10px;
  background: none;
  cursor: pointer;
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
    color: ${({ theme }) => `${theme.colors.saturated_purple}`};
  }
`;

export default PromptListItem;
