import React from "react";
import styled from "styled-components";

function LabelsList({ children }) {
  return <Container>{children}</Container>;
}

export default LabelsList;

const Container = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
  flex-wrap: wrap;
`;
