import React from "react";
import styled, { keyframes } from "styled-components";

const Loading = () => (
  <Container>
    <Spinner width="60" height="60" />
  </Container>
);

export default Loading;

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: white;
`;

const Spinner = styled.img`
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;
