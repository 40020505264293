import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { getCompany, updateCompany } from "../../../services/company";
import Loader from "../../Loader";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { useNavigate } from "react-router-dom";
import { getObjectFromLocalStorage } from "../../../api/localStorage";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

function Deliverability() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingDeliverability, setIsUpdatingDeliverability] =
    useState(false);

  const [maxEmailsPerMinute, setMaxEmailsPerMinute] = useState("");
  const [company, setCompany] = useState(null);

  const fetchUpdateCompany = async (e) => {
    e.preventDefault();

    if (!isCompanyAdmin()) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Available only for company admins",
        },
      });
      setMaxEmailsPerMinute(company.maxEmailsPerMinute);
      return;
    }

    setIsUpdatingDeliverability(true);

    const response = await updateCompany({
      maxEmailsPerMinute,
    });

    if (!response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: response.message,
        },
      });
      setMaxEmailsPerMinute(company.maxEmailsPerMinute);
      setIsUpdatingDeliverability(false);
      return;
    }

    setCompany({ ...company, maxEmailsPerMinute });

    setIsUpdatingDeliverability(false);
  };

  const isDataNotChanged = () => {
    return company.maxEmailsPerMinute === maxEmailsPerMinute;
  };

  const isCompanyAdmin = () => {
    const user = getUserFromLocalStorage();
    return user?.role === "company_admin" || user?.role === "admin";
  };

  useEffect(() => {
    const fetchCompany = async () => {
      const response = await getCompany();

      if (!response.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: response.message,
          },
        });

        navigate("/campaigns");
        setIsLoading(false);
        return;
      }

      setCompany(response.company);
      setMaxEmailsPerMinute(response.company.maxEmailsPerMinute);

      setIsLoading(false);
    };

    const companyInfo = getObjectFromLocalStorage("companyInfo");

    if (companyInfo?.company?.maxEmailsPerMinute) {
      setCompany(companyInfo.company);
      setMaxEmailsPerMinute(companyInfo.company.maxEmailsPerMinute);
      setIsLoading(false);
      return;
    }

    fetchCompany();
  }, []);

  return (
    <Content>
      {isLoading ? (
        <Loader parentSize={true} />
      ) : (
        <Section>
          <Title>Deliverability</Title>
          <Flex>
            <label htmlFor="maxEmailsPerMinute">Max Emails Per Minute</label>
            <Input
              type="number"
              id="maxEmailsPerMinute"
              name="maxEmailsPerMinute"
              placeholder="Max Emails Per Minute"
              autoComplete="off"
              $width="488px"
              value={maxEmailsPerMinute}
              onChange={(event) =>
                setMaxEmailsPerMinute(parseInt(event.target.value, 10))
              }
            />
          </Flex>

          <Actions>
            <UpdateBtn
              onClick={(e) => fetchUpdateCompany(e)}
              $disabled={isDataNotChanged() || isUpdatingDeliverability}
            >
              Update
            </UpdateBtn>
          </Actions>
        </Section>
      )}
    </Content>
  );
}

export default Deliverability;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  label {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.colors.btn_border_color};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const Section = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const Input = styled.input`
  position: relative;
  margin-top: 4px;
  margin-bottom: 20px;
  border: 1px solid #d6ddeb;
  padding: 12px 45px 12px 16px;

  width: ${({ $width }) => ($width ? $width : "auto")};

  &:focus-visible {
    position: relative;
    margin-top: 4px;
    margin-bottom: 20px;
    border: 1px solid #d6ddeb;
    padding: 12px 0px 12px 16px;
    width: ${({ $width }) => ($width ? $width : "auto")};
    gap: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const UpdateBtn = styled.button`
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  height: 40px;
  width: 122px;
  font-size: 12px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  opacity: ${({ $disabled }) => ($disabled ? "0.5" : "1")};
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;
