/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../InfiniteScroll";
import Spinner from "../../../Spinner";
import Loader from "../../../Loader";
import theme from "../../../../theme";
import {
  getPriceForRegisterUser,
  getUsers,
  saveUser,
} from "../../../../services/users";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import UserFormPopup from "./UserFormPopup";
import { displayDate, displayUserRole } from "../../../../utils/utils";
import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
  saveObjectToLocalStorage,
} from "../../../../api/localStorage";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import ConfirmPopup from "../../../ConfirmPopup";
import { useAccount } from "../../../../context";

function UsersTable() {
  const { setAccount } = useAccount();

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [users, setUsers] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState({ sort: null, order: null });
  const [popupData, setPopupData] = useState(null);
  const [warningPopupData, setWarningPopupData] = useState(null);

  const tableContRef = useRef(null);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const fetchUsers = async (params) => {
    const data = {
      limit: getLimit(),
      offset: params?.clearAfterSearch ? 0 : offset,
    };

    if (params?.newSort || sort.sort) {
      data.sort = (params?.newSort || sort).sort;
      data.order = (params?.newSort || sort).order;
    }

    setIsLoading(!data.offset);

    const userInfo = getUserFromLocalStorage();
    let companyId = userInfo?.companyId;
    if (!companyId) {
      const companyInfo = getObjectFromLocalStorage("companyInfo");
      companyId = companyInfo?.company?.id;
    }
    if (companyId) {
      data.filters = { company: [companyId] };
    }

    const result = await getUsers(data);

    setIsLoading(false);

    if (result.success) {
      setUsers(
        params?.clearAfterSearch
          ? result.result.users
          : [...users, ...result.result.users]
      );
      setDataLength(
        params?.clearAfterSearch
          ? result.result.users.length
          : dataLength + result.result.users.length
      );
      setHasMore(result.result.meta.hasMore);
      if (result.result.meta.hasMore) {
        setOffset(params?.clearAfterSearch ? data.limit : offset + data.limit);
      }
    } else {
      setHasMore(false);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch users",
        },
      });
    }
  };

  const handleClickSort = async (sortField) => {
    let newSort;
    if (sortField === sort.sort) {
      if (sort.order === "asc") {
        newSort = { ...sort, order: "desc" };
      } else {
        newSort = { sort: null, order: null };
      }
    } else {
      newSort = { sort: sortField, order: "asc" };
    }
    setSort(newSort);
    await fetchUsers({ clearAfterSearch: true, newSort });
  };

  const fetchByScroll = async () => {
    await fetchUsers({ scrolling: true });
  };

  const fetchOnMount = async () => {
    await fetchUsers();
  };

  const handleEdit = (event, user) => {
    event?.preventDefault();
    setPopupData(user);
  };

  const handleAdd = (event) => {
    event?.preventDefault();
    setPopupData({});
  };

  const save = async (data) => {
    setIsSaving(true);
    setPopupData(null);
    setWarningPopupData(null);

    const extensionId = getDataFromLocalStorage("extensionId");

    const result = await saveUser({ ...data, extensionId });

    setIsSaving(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            result.message || `User was not ${data.id ? "updated" : "created"}`,
        },
      });
      return;
    }

    const userInfo = getUserFromLocalStorage();
    if (userInfo?.id && userInfo.id === data.id) {
      saveObjectToLocalStorage("user", {
        ...userInfo,
        ...data,
      });
      if (data.role && data.role !== userInfo.role) {
        window.location.reload();
        return;
      }
      setAccount({ ...userInfo, ...data });
    }

    fetchUsers({
      clearAfterSearch: true,
    });
  };

  const handleSave = async (data) => {
    const companyInfo = getObjectFromLocalStorage("companyInfo");
    if (data.id || companyInfo?.company?.isFreeSubscription !== false) {
      await save(data);
      return;
    }

    setIsSaving(true);
    const pricesRes = await getPriceForRegisterUser();
    setIsSaving(false);
    if (!pricesRes.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: pricesRes.message,
        },
      });
      return;
    }

    const period = companyInfo?.company?.subscriptionPlanPeriod || "month";
    const { currentPrice, newPrice } = pricesRes.result;
    setWarningPopupData({
      data,
      text: `Please note that this will increase your ${period}ly fee from $${currentPrice} to $${newPrice}.  Are you sure?`,
    });
  };

  useEffect(() => {
    fetchOnMount();
  }, []);

  const withSort = dataLength > 1;

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          {[
            { name: "name", label: "Name" },
            { name: "email", label: "Email" },
            { name: "role", label: "Type" },
            { name: "lastLoginAt", label: "Last Login" },
          ].map(({ name, label }) => (
            <TableHeadData
              key={name}
              {...(withSort
                ? {
                    $cursorPointer: true,
                    onClick: () => handleClickSort(name),
                  }
                : {})}
            >
              <TableHeadDataSort>
                {label}
                {withSort && (
                  <SortIcon
                    src={`/images/sort-${
                      sort.sort === name ? `${sort.order}-` : ""
                    }icon.png`}
                    alt="sort"
                  />
                )}
              </TableHeadDataSort>
            </TableHeadData>
          ))}
          <TableHeadData>&nbsp;</TableHeadData>
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>No users to show</EmptyTableData>
            </TableRow>
          ) : (
            users.map((user, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>{user.name}</TableData>
                <TableData>{user.email}</TableData>
                <TableData>{displayUserRole(user.role)}</TableData>
                <TableData>{displayDate(user.lastLoginAt)}</TableData>
                <TableData>
                  <EditBtn onClick={(e) => handleEdit(e, user)}>Edit</EditBtn>
                </TableData>
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <>
      {!!warningPopupData && (
        <ConfirmPopup
          title="Are you sure?"
          description={warningPopupData.text}
          confirmLabel="Yes, I’m sure"
          cancelLabel="Nevermind"
          onConfirm={async () => {
            await save(warningPopupData.data);
          }}
          onCancel={() => setWarningPopupData(null)}
        />
      )}

      <Container $isLoading={isSaving}>
        {isSaving && <Loader />}

        {!!popupData && (
          <UserFormPopup
            data={popupData}
            onClose={() => setPopupData(null)}
            onSave={handleSave}
            isSaving={isSaving}
          />
        )}

        <Buttons>
          <AddBtn onClick={handleAdd} disabled={isLoading || isSaving}>
            Add user
          </AddBtn>
        </Buttons>

        <Content ref={tableContRef}>
          {isLoading ? (
            <Loader parentSize={true} bgColor="white" padding="0" />
          ) : (
            <OverflowContent id="table_layout">
              {hasMore ? (
                <InfiniteScroll
                  dataLength={dataLength}
                  next={fetchByScroll}
                  hasMore={hasMore}
                  scrollableTarget={"table_layout"}
                  style={{ overflowY: "hidden" }}
                  loader={
                    <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                  }
                >
                  {table}
                </InfiniteScroll>
              ) : (
                table
              )}
            </OverflowContent>
          )}
        </Content>
      </Container>
    </>
  );
}

export default UsersTable;

const Container = styled.div`
  height: calc(100% - 55px);
  ${({ $isLoading }) =>
    $isLoading
      ? `position: relative;
         > div:first-child {
           position: absolute;
           background: transparent;
           z-index: 1;
         }`
      : ""}
`;

const Content = styled.div`
  height: calc(100% - 40px - 25px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
            width: 115px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
            width: 115px;
            text-align: right;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const TableHeadDataSort = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const SortIcon = styled.img`
  width: 11px;
`;

const Buttons = styled.div`
  margin-bottom: 25px;
  display: flex;
  column-gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
`;

const EditBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.saturated_purple};
  border-radius: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 0 25px;
  cursor: pointer;
`;

const AddBtn = styled.button`
  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  color: ${({ theme }) => theme.colors.white};
  height: 40px;
  padding: 0 25px;
  font-weight: 800;
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    opacity: 0.75;
  }
`;
