/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { styled } from "styled-components";
import InfiniteScroll from "../../../InfiniteScroll";
import Spinner from "../../../Spinner";
import Loader from "../../../Loader";
import theme from "../../../../theme";
import { getCompanies } from "../../../../services/company";
import { displayNumber, displayPercent } from "../../../../utils/utils";

function CompaniesTable({
  openCampaignsWithFilter,
  onEdit,
  isReloadNeeded,
  onSetIsReloadNeeded,
  newInviteForCompany,
  setNewInviteForCompany,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const tableContRef = useRef(null);

  const getLimit = () => {
    const minHeadRowHeight = 43;
    const minBodyRowHeight = 46;
    return Math.max(
      Math.floor(
        (tableContRef?.current?.getBoundingClientRect()?.height -
          minHeadRowHeight) /
          minBodyRowHeight
      ),
      10
    );
  };

  const fetchCompanies = async (data) => {
    const result = await getCompanies(data);

    setIsLoading(false);
    if (result.success) {
      setCompanies(
        result.result.meta.offset === 0
          ? result.result.companies
          : [...companies, ...result.result.companies]
      );
      setHasMore(result.result.meta.hasMore);
      setOffset(result.result.meta.offset + result.result.meta.limit);
    } else {
      setHasMore(false);
    }
    onSetIsReloadNeeded(false);
  };

  useEffect(() => {
    fetchCompanies({ limit: getLimit() });
  }, []);

  useEffect(() => {
    if (isReloadNeeded) {
      const data = {
        offset: 0,
        limit: getLimit(),
      };
      fetchCompanies(data);
    }
  }, [isReloadNeeded]);

  useEffect(() => {
    if (newInviteForCompany) {
      const { companyId } = newInviteForCompany;
      const tableCompanies = [...companies];
      const company = tableCompanies.find((c) => c.id === companyId);
      if (company) {
        company.inviteCodes.push(newInviteForCompany);
        setCompanies(tableCompanies);
      }
      setNewInviteForCompany(null);
    }
  }, [newInviteForCompany]);

  const fetchCompaniesByScroll = () => {
    const data = {
      offset,
      limit: getLimit(),
    };
    fetchCompanies(data);
  };

  const handleEdit = (event, company) => {
    event?.preventDefault();
    onEdit(company);
  };

  const dataLength = companies.length;

  const table = (
    <Table>
      <TableHead>
        <TableRow $bgColor="#f3f2f2">
          <TableHeadData>Company</TableHeadData>
          <TableHeadData>Admin</TableHeadData>
          <TableHeadData>Invite Code</TableHeadData>
          <TableHeadData># of Users</TableHeadData>
          <TableHeadData># of Campaigns</TableHeadData>
          <TableHeadData>Open Rate</TableHeadData>
          <TableHeadData>Engagement Rate</TableHeadData>
          <TableHeadData>Credit Limit</TableHeadData>
          <TableHeadData>Credits Remaining</TableHeadData>
          {!!onEdit && <TableHeadData>&nbsp;</TableHeadData>}
        </TableRow>
      </TableHead>
      <TableBody>
        <React.Fragment>
          {!dataLength ? (
            <TableRow>
              <EmptyTableData colSpan={100}>
                No companies to show
              </EmptyTableData>
            </TableRow>
          ) : (
            companies.map((company, index) => (
              <TableRow
                key={index}
                $borderBottom={`1px solid ${theme.colors.divider_color}`}
              >
                <TableData>{company.name}</TableData>
                <TableData>{company.adminNames.join(", ")}</TableData>
                <TableData>
                  {company.inviteCodes
                    .map((inviteCode) => inviteCode.code)
                    .join(", ")}
                </TableData>
                <TableData>{company.totalUsers}</TableData>
                <TableData
                  $cursor="pointer"
                  onClick={() =>
                    openCampaignsWithFilter({ companyId: company.id })
                  }
                >
                  {company.totalCampaigns}
                </TableData>
                <TableData>{displayPercent(company.openRate)}</TableData>
                <TableData>{displayPercent(company.engagementRate)}</TableData>
                <TableData>{displayNumber(company.creditsPerMonth)}</TableData>
                <TableData>{displayNumber(company.creditsRemaining)}</TableData>
                {!!onEdit && (
                  <TableData>
                    {company.unpaidSubscription ? (
                      "Subscription not paid"
                    ) : (
                      <EditBtn onClick={(e) => handleEdit(e, company)}>
                        Edit
                      </EditBtn>
                    )}
                  </TableData>
                )}
              </TableRow>
            ))
          )}
        </React.Fragment>
      </TableBody>
    </Table>
  );

  return (
    <Content ref={tableContRef}>
      {isLoading ? (
        <Loader parentSize={true} bgColor="white" padding="0" />
      ) : (
        <OverflowContent id="table_layout">
          {hasMore ? (
            <InfiniteScroll
              dataLength={dataLength}
              next={fetchCompaniesByScroll}
              hasMore={hasMore}
              scrollableTarget={"table_layout"}
              style={{ overflowY: "hidden" }}
              loader={<Spinner width="60" height="60" margin="20px 0 0 40vw" />}
            >
              {table}
            </InfiniteScroll>
          ) : (
            table
          )}
        </OverflowContent>
      )}
    </Content>
  );
}

export default CompaniesTable;

const Content = styled.div`
  height: calc(100% - 55px - 60px);
`;

const OverflowContent = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const TableHeadData = styled.th`
  &,
  * {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-size: 11px;
    color: #6d6d6d;
  }
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  font-weight: 800;
  ${({ $cursorPointer }) => ($cursorPointer ? "cursor: pointer" : "")};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const TableRow = styled.tr`
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")}
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $padding }) =>
    $padding
      ? `padding: ${$padding}`
      : `padding: 15px 5px;
          &:first-of-type {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
          }
    `};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  font-size: 13px;
  font-weight: 400;
  word-wrap: break-word;
  cursor: ${({ $cursor }) => $cursor || "default"};
`;

const EmptyTableData = styled(TableData)`
  text-align: center;
  opacity: 0.75;
`;

const EditBtn = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.saturated_purple};
  color: ${({ theme }) => theme.colors.saturated_purple};
  border-radius: 5px;
  height: 40px;
  padding: 0 25px;
  background-color: transparent;
  cursor: pointer;
`;
