import React from "react";
import { styled } from "styled-components";

function OptionsDefinitionPopup({
  isOpened,
  handleClose,
  setIsOpened,
  definitions,
}) {
  return isOpened ? (
    <OptionsDefinitionPopupContainer>
      <Container>
        <Header>
          <TitleBlock onClick={() => setIsOpened(false)}>
            <img src="/images/info-icon.png" alt="info" width="18" />
            <ButtonTitle>What do these options mean?</ButtonTitle>
          </TitleBlock>
          <CloseIcon
            src="/images/close-icon-small.png"
            alt="info"
            width="8"
            onClick={handleClose}
          />
        </Header>
        <Description>
          Define the parameters of your campaign by selecting from the options
          below.
        </Description>
        <Content>
          {definitions.map((item, index) => (
            <OptionContainer key={index}>
              <OptionTitle>{item.title}</OptionTitle>
              <OptionDefinition>{item.definition}</OptionDefinition>
            </OptionContainer>
          ))}
        </Content>
      </Container>
      <AcceptBtn onClick={() => setIsOpened(false)}>I got this!</AcceptBtn>
    </OptionsDefinitionPopupContainer>
  ) : (
    <OptionsDefinitionPopupButton>
      <TitleBlock onClick={() => setIsOpened(true)}>
        <img src="/images/info-icon.png" alt="info" width="18" />
        <ButtonTitle>What do these options mean?</ButtonTitle>
      </TitleBlock>
      <CloseIcon
        src="/images/close-icon-small.png"
        alt="info"
        width="8"
        onClick={handleClose}
      />
    </OptionsDefinitionPopupButton>
  );
}

export default OptionsDefinitionPopup;

const OptionsDefinitionPopupContainer = styled.div`
  width: 300px;
  background-color: #fff7ea;
  border-radius: 6px;
  position: absolute;
  right: 20px;
  top: 60px;
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  justify-content: space-between;
`;

const OptionsDefinitionPopupButton = styled.div`
  width: 300px;
  height: 43px;
  background-color: #fff7ea;
  border-radius: 6px;
  position: absolute;
  right: 20px;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 10px;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ButtonTitle = styled.div`
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 700;
  font-family: "AlbertSansExtraBold", sans-serif;
  padding-left: 10px;
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

const Header = styled.div`
  height: 43px;
  background-color: #fff7ea;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`;

const Description = styled.div`
  color: #4a4a4a;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  width: 217px;
  padding-left: 28px;
`;

const Content = styled.div`
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 10px;
  height: calc(100% - 100px);
`;

const OptionContainer = styled.div`
  margin-bottom: 10px;
  padding-bottom: 3px;
  border-bottom: 1px solid #bfb8b1;
`;

const OptionTitle = styled.div`
  color: black;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
`;

const OptionDefinition = styled.div`
  color: #4a4a4a;
  font-size: 9px;
  font-weight: 400;
  line-height: 10px;
  padding-right: 30px;
`;

const AcceptBtn = styled.div`
  color: #4640de;
  font-size: 9px;
  font-weight: 400;
  border-bottom: 1px dashed #4640de;
  width: fit-content;
  cursor: pointer;
  margin-left: 28px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  height: calc(100% - 33px);
`;
