import React, { useState } from "react";
import { styled } from "styled-components";
import CompaniesTable from "./CompaniesTable";
import InviteFormPopup from "./InviteFormPopup";
import CompanyFormPopup from "./CompanyFormPopup";
import Loader from "../../../Loader";
import getUserFromLocalStorage from "../../../../utils/getUserFromLocalStorage";
import { sendMessageToExtension } from "../../../../utils/postToExtension";
import { getFilters } from "../../../../services/campaigns";
import { createInvite, updateCompany } from "../../../../services/company";
import { saveObjectToLocalStorage } from "../../../../api/localStorage";

function CompaniesWrapper({ openCampaignsWithFilter }) {
  const [isLoading, setIsLoading] = useState(false);
  const [invitePopupData, setInvitePopupData] = useState(null);
  const [companyPopupData, setCompanyPopupData] = useState(null);
  const [isTableReloadNeeded, setIsTableReloadNeeded] = useState(false);
  const [newInviteForCompany, setNewInviteForCompany] = useState(null);

  const handleOpenInvitePopup = (event) => {
    event?.preventDefault();
    if (!invitePopupData) {
      setInvitePopupData([]);
    }
    const loadCompanyFilter = async () => {
      const res = await getFilters({ filter: ["companies"] });
      setInvitePopupData(
        (res.result?.companies || []).map((c) => ({
          value: c.id,
          label: c.name,
        }))
      );
    };
    loadCompanyFilter();
  };

  const handleCloseInvitePopup = (event) => {
    event?.preventDefault();
    setInvitePopupData(null);
  };

  const handleCreateInvite = async (data) => {
    setInvitePopupData(null);
    setIsLoading(true);

    const result = await createInvite(data);

    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message || "Invite was not created",
        },
      });
      return;
    }

    setNewInviteForCompany(result.invite);
  };

  const handleOpenCompanyPopup = (companyData = {}) => {
    setCompanyPopupData(companyData);
  };

  const handleCloseCompanyPopup = () => {
    setCompanyPopupData(null);
  };

  const handleSaveCompany = async (data) => {
    const oldCompanyAdminId = companyPopupData.adminId;

    setCompanyPopupData(null);
    setIsLoading(true);

    const result = await updateCompany(data, false);

    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message:
            result.message ||
            `Company was not ${data.isNew ? "created" : "updated"}`,
        },
      });
      return;
    }

    if (invitePopupData) {
      handleOpenInvitePopup(); // reload companies for invite popup
    } else if (
      data.id &&
      data.adminId &&
      oldCompanyAdminId &&
      oldCompanyAdminId !== data.adminId
    ) {
      const userInfo = getUserFromLocalStorage();
      if (userInfo?.id && userInfo.id === data.adminId) {
        saveObjectToLocalStorage("user", {
          ...userInfo,
          role: "company_admin",
        });
        window.location.reload();
        return;
      }
    }

    setIsTableReloadNeeded(true);
  };

  return (
    <Container>
      {isLoading && (
        <LoaderWrapper>
          <Loader parentSize={true} padding="0" />
        </LoaderWrapper>
      )}

      <Content>
        <Title>Companies</Title>

        <div>
          <CreateInviteBtn onClick={handleOpenInvitePopup}>
            Create Invite
          </CreateInviteBtn>
        </div>

        {!!invitePopupData && (
          <InviteFormPopup
            companies={invitePopupData}
            onClose={handleCloseInvitePopup}
            onCreateInvite={handleCreateInvite}
            onCreateCompany={handleOpenCompanyPopup}
          />
        )}

        {!!companyPopupData && (
          <CompanyFormPopup
            data={companyPopupData}
            onClose={handleCloseCompanyPopup}
            onSave={handleSaveCompany}
          />
        )}

        <CompaniesTable
          openCampaignsWithFilter={openCampaignsWithFilter}
          onEdit={handleOpenCompanyPopup}
          isReloadNeeded={isTableReloadNeeded}
          onSetIsReloadNeeded={setIsTableReloadNeeded}
          {...{ newInviteForCompany, setNewInviteForCompany }}
        />
      </Content>
    </Container>
  );
}

export default CompaniesWrapper;

const Container = styled.div`
  padding: 0 20px 0 0;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Content = styled.div`
  height: 100%;
`;

const Title = styled.div`
  font-family: "AlbertSansExtraBold";
  width: 30%;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.colors.gray};
`;

const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-right: 20px;
  /* background-color: #ffffff47; */
  z-index: 100;
`;

const CreateInviteBtn = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.saturated_purple};
  height: 40px;
  padding: 0 25px;
  border-radius: 5px;
  border: none;
  font-weight: 800;
  margin-bottom: 20px;
`;
