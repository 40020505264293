import React from "react";
import styled from "styled-components";

import GsuiteButton from "./GsuiteButton";
import Separator from "./Separator";
import O365Button from "./O365Button";

function ThirdPartyOauthButtons({ handleGsuiteOauth, handleO365Oauth }) {
  return (
    <>
      <Container>
        <GsuiteButton onClick={handleGsuiteOauth} />
        <O365Button onClick={handleO365Oauth} />
      </Container>
      <Separator>OR</Separator>
    </>
  );
}

export default ThirdPartyOauthButtons;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
