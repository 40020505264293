import React, { useEffect } from "react";
import styled from "styled-components";

import Header from "./Header";
import AppDetails from "./AppDetails";
import MainInfo from "./MainInfo";

import ReactGA from "react-ga4";

function InstallHubspotApp() {
  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "Install Hubspot App",
      });
    }
  }, []);

  return (
    <>
      <HeaderContainer>
        <HeaderInnerContainer>
          <Header />
        </HeaderInnerContainer>
      </HeaderContainer>
      <BodyContainer>
        <BodyInnerContainer>
          <Row>
            <AppDetailsInnerContainer>
              <AppDetails />
            </AppDetailsInnerContainer>
            <MainInfoInnerContainer>
              <MainInfo />
            </MainInfoInnerContainer>
          </Row>
        </BodyInnerContainer>
      </BodyContainer>
    </>
  );
}

export default InstallHubspotApp;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: #f5f8fa;
  padding-left: 48px;
  padding-right: 48px;
  position: fixed;
  z-index: 10;
  border-bottom: 1px solid #cbd6e2;
`;

const BodyContainer = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 182px 48px 40px 48px; // (142px+40px) - header height.

  @media (max-width: 768px) {
    padding-top: 204px; // (164px+40px) - header height.
  }
`;

const HeaderInnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
  border-top: none;
`;

const BodyInnerContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
  border-top: none;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -16px;
  margin-right: -16px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AppDetailsInnerContainer = styled.div`
  padding-left: 16px;
  padding-right: 60px;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;

  @media (max-width: 768px) {
    padding-right: 16px;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const MainInfoInnerContainer = styled.div`
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-left: 16px;
  padding-right: 16px;

  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
