import React from "react";
import { styled } from "styled-components";

function SidePanel({ stepIndex, steps, title, description }) {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <GridStepContainer>
          {steps.map((step, index) => (
            <GridItem key={step.id}>
              <Step $tabValue={index} $currentStep={stepIndex}>
                {step.title}
              </Step>
            </GridItem>
          ))}
        </GridStepContainer>
      </div>
      <Logo src="/images/logos/logo-purple.png" alt="Logo" />
    </Container>
  );
}

export default SidePanel;

const Container = styled.div`
  width: 25%;
  background-color: white;
  border-right: 1px solid #cdcdcd;
  padding: 60px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: 800;
  line-height: 0.9em;
  color: #808080;
  margin-bottom: 20px;
  font-family: "AlbertSansExtraBold", sans-serif;
  letter-spacing: 0rem;
`;

const Description = styled.div`
  font-size: 14px;
  color: #808080;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 103px;
`;

const GridStepContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, 1fr);
  margin-top: 5%;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 22px;
`;

const Step = styled.div`
  color: ${({ theme, $currentStep, $tabValue }) =>
    $currentStep === $tabValue
      ? theme.colors.saturated_purple
      : theme.colors.gray};
  font-weight: ${({ $currentStep, $tabValue }) =>
    $currentStep === $tabValue ? "800" : "normal"};
  border-left: 2px solid transparent;
  padding-left: 2%;
  ${({ theme, $currentStep, $tabValue }) =>
    $currentStep === $tabValue &&
    `
    border-color: ${theme.colors.saturated_purple};
  `}
  font-size: 14px;
`;
