/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { displayDate } from "./utils";
import { styled } from "styled-components";
import theme from "../../theme";
import { getCampaigns } from "../../services/campaigns";
import InfiniteScroll from "../InfiniteScroll";
import Loader from "../Loader";
import OverlayMessage from "./OverlayMessage";
import Spinner from "../Spinner";
import { debounce } from "lodash";
import { sendMessageToExtension } from "../../utils/postToExtension";
import TableFilters from "./TableFilter";
import { CAMPAIGN_STATUSES } from "./constants";
import { displayNumber, displayPercent } from "../../utils/utils";

const CampaignsTable = ({
  selectCampaign,
  tableHeight,
  onScroll,
  isTableFullScreen,
  campaignsFilters,
}) => {
  const [campaignList, setCampaignList] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState({
    filters: campaignsFilters?.filters || {},
    searchString: campaignsFilters?.searchString || "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [relevantStatuses, setRelevantStatuses] = useState([]);
  const [lastError, setLastError] = useState(null);
  const [isErrorOverlayShown, setIsErrorOverlayShown] = useState(false);
  const [errorTooltipMessage, setErrorTooltipMessage] = useState(null);
  const [errorCampaignId, setErrorCampaignId] = useState(null);

  const getLimit = () => {
    const tableMargins = isTableFullScreen ? 205 : 425;
    const campaignsTableHeight = window.innerHeight - tableMargins;

    const limit = Math.floor(campaignsTableHeight / 50);
    return limit;
  };

  const fetchCampaigns = async (params) => {
    console.log("fetchCampaigns", params, selectedFilters);

    const limit = getLimit();

    const data = {
      offset: params?.clearAfterSearch ? 0 : dataLength,
      limit,
    };

    const { filters, searchString } = selectedFilters;

    if (searchString && searchString !== "") {
      data.query = searchString;
    }

    if (filters && Object.keys(filters).length > 0) {
      data.filters = filters;
    }

    const result = await getCampaigns(data);

    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch campaigns",
        },
      });
      sendMessageToExtension({
        message: "close-iframe",
      });
      return;
    }

    setRelevantStatuses(result.result.meta.statuses);

    setCampaignList(
      params?.clearAfterSearch
        ? result.result.campaigns
        : [...campaignList, ...result.result.campaigns]
    );

    setDataLength(dataLength + result.result.campaigns.length);
    setHasMore(result.result.meta.hasMore);

    setIsLoading(false);
  };

  const handleSearch = debounce((event) => {
    setSelectedFilters({
      ...selectedFilters,
      searchString: event.target.value,
    });
  }, 500);

  const showErrorOverlay = (campaign) => {
    if (
      campaign.status === CAMPAIGN_STATUSES.ERROR &&
      !isErrorOverlayShown &&
      campaign.lastError
    ) {
      setLastError(campaign.lastError);
      setErrorTooltipMessage("Click to copy");
      setErrorCampaignId(campaign.id);
      setIsErrorOverlayShown(true);
    }
  };

  const hideErrorOverlay = () => {
    if (isErrorOverlayShown) {
      setLastError(null);
      setErrorTooltipMessage("Click to copy");
      setErrorCampaignId(null);
      setIsErrorOverlayShown(false);
    }
  };

  const handleCopy = () => {
    setErrorTooltipMessage("Copied!");
  };

  useEffect(() => {
    if (campaignList.length === 0) {
      return;
    }
    const scrollComponent = document.getElementsByClassName(
      "infinite-scroll-component"
    )[0];
    if (scrollComponent) {
      scrollComponent.addEventListener("wheel", onScroll);
    }
  }, [campaignList]);

  useEffect(() => {
    fetchCampaigns({ clearAfterSearch: true });
  }, [selectedFilters]);

  return (
    <>
      {isLoading ? (
        <LoaderWrapper $height={tableHeight}>
          <Loader parentSize={true} />
        </LoaderWrapper>
      ) : (
        <TableLayout>
          {isErrorOverlayShown && <OverlayMessage message={lastError} />}
          <TableTitleBlock>
            <TableTitle>All Campaigns</TableTitle>
            <ButtonBlock>
              <TableFilters
                fields={[
                  {
                    type: "select",
                    title: "Status",
                    inputs: [
                      {
                        key: "statuses",
                        isMultiSelect: true,
                        relevantValues: relevantStatuses,
                      },
                    ],
                  },
                ]}
                values={selectedFilters.filters}
                load={(newFilters) =>
                  setSelectedFilters({
                    ...selectedFilters,
                    filters: newFilters,
                  })
                }
              />
              <Search>
                <SearchInput
                  type="text"
                  defaultValue={selectedFilters.searchString}
                  onChange={handleSearch}
                  placeholder="Search"
                />
                <SearchIcon src="/images/search-icon.png" />
              </Search>
              <RefreshButton
                onClick={() => fetchCampaigns({ clearAfterSearch: true })}
              >
                Refresh
              </RefreshButton>
            </ButtonBlock>
          </TableTitleBlock>
          <InfiniteScroll
            dataLength={dataLength}
            next={fetchCampaigns}
            hasMore={hasMore}
            scrollableTarget={"table_layout"}
            style={{ overflow: "hidden" }}
            loader={<Spinner width="60" height="60" margin="20px 0 0 40vw" />}
          >
            <Table>
              <TableHead>
                <TableRow $bgColor="#f3f2f2">
                  <TableHeadData
                    $padding="15px 5px 15px 15px"
                    $maxWidth="200px"
                  >
                    Campaign
                  </TableHeadData>
                  <TableHeadData>Created</TableHeadData>
                  <TableHeadData>Goal</TableHeadData>
                  <TableHeadData $padding="0 5px 0 0">Recipients</TableHeadData>
                  <TableHeadData $padding="0 5px 0 0">
                    Emails Sent
                  </TableHeadData>
                  <TableHeadData $padding="0 5px 0 0">Open Rate</TableHeadData>
                  <TableHeadData $padding="0 5px 0 0">
                    Engagement Rate
                  </TableHeadData>
                  <TableHeadData $padding="0 5px 0 0">Status</TableHeadData>
                </TableRow>
              </TableHead>
              {campaignList && campaignList.length !== 0 ? (
                <TableBody>
                  {campaignList.map((campaign, index) => {
                    return (
                      <TableRow
                        $borderBottom={`1px solid ${theme.colors.divider_color}`}
                        key={index}
                      >
                        <TableData
                          onClick={() =>
                            selectCampaign(campaign, selectedFilters)
                          }
                          $cursor={"pointer"}
                          $maxWidth="200px"
                          $padding="15px 5px 15px 15px"
                          color={theme.colors.purple}
                        >
                          {campaign.name}
                        </TableData>
                        <TableData $padding="0 5px 0 0">
                          {displayDate(campaign.createdAt)}
                        </TableData>
                        <TableData $padding="0 5px 0 0">
                          {campaign.goal}
                        </TableData>
                        <TableData>{campaign.contactCount}</TableData>
                        <TableData>{displayNumber(campaign.sent)}</TableData>
                        <TableData>
                          {displayPercent(campaign.openRate)}
                        </TableData>
                        <TableData>
                          {displayPercent(campaign.engagementRate)}
                        </TableData>
                        <TableData
                          $cursor={
                            campaign.status === CAMPAIGN_STATUSES.ERROR
                              ? "pointer"
                              : "default"
                          }
                        >
                          <CopyToClipboard
                            text={lastError ? lastError : null}
                            onCopy={handleCopy}
                          >
                            <CampaignStatus
                              onMouseOver={() => showErrorOverlay(campaign)}
                              onMouseLeave={() => hideErrorOverlay()}
                            >
                              {isErrorOverlayShown &&
                              lastError &&
                              campaign.id === errorCampaignId
                                ? errorTooltipMessage
                                : campaign.status}
                            </CampaignStatus>
                          </CopyToClipboard>
                        </TableData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableData>
                      <NoCampaignsMessage>
                        No campaigns to show
                      </NoCampaignsMessage>
                    </TableData>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </InfiniteScroll>
        </TableLayout>
      )}
    </>
  );
};

export default CampaignsTable;

const TableHeadData = styled.th`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 11px;
  ${({ $padding }) =>
    $padding ? `padding: ${$padding}` : "15px 5px 15px 5px"};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  font-weight: 800;
  color: #494949;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

const TableBody = styled.tbody`
  background-color: #fbfbfb;
`;

const TableHead = styled.thead`
  text-align: left;
  position: sticky;
  top: -1px;
  z-index: 10;
`;

const TableRow = styled.tr`
  height: 22px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "10px 5px 10px 0")};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
  font-size: 13px;
  font-weight: 400;
`;

const NoCampaignsMessage = styled.span`
  font-size: 11px;
  padding: 20px;
`;

const LoaderWrapper = styled.div`
  height: ${({ $height }) => $height};
`;

const TableLayout = styled.div`
  margin-left: 40px;
  margin-top: 20px;
  border: 1px solid #e2e2e2;
  border-top-left-radius: 5px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  background-color: #fbfbfb;
  max-width: 1440px;
`;

const TableTitleBlock = styled.div`
  position: sticky;
  top: 0px;
  z-index: 11;
  display: flex;
  align-items: center;
  padding: 25px 10px 25px 15px;
  background-color: white;
  border-top-left-radius: 5px;
  justify-content: space-between;
`;

const TableTitle = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 16px;
  font-weight: 800;
  color: #5a5a5a;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

const RefreshButton = styled.button`
  font-size: 12px;
  font-weight: 700;
  width: 75px;
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.btn_border_color};
  color: ${({ theme }) => theme.colors.gray};
`;

const Search = styled.div`
  position: relative;
  margin: 0 20px 0 40px;
`;

const SearchInput = styled.input`
  height: 38px;
  width: 256px;
  padding-left: 30px;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  margin-bottom: 0;

  &:focus-visible {
    height: 38px;
    width: 256px;
    padding-left: 30px;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    margin-bottom: 0;
  }
`;

const SearchIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 13px;
  height: 13px;
`;

const CampaignStatus = styled.div`
  height: 100%;
  min-width: 100px;
`;
