import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

function ConnectBtn({ label, onClick, disabled, type = "submit", props }) {
  return (
    <Btn type={type} disabled={disabled} onClick={onClick} {...props}>
      <Content>{label}</Content>
    </Btn>
  );
}

ConnectBtn.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ConnectBtn;

const Btn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ disabled }) =>
    disabled ? "rgb(71,17,104,0.75)" : "rgb(71,17,104)"};
  height: 40px;
  padding: 0 25px;
  border-radius: 6px;
  border: none;
  + button {
    margin-left: 20px;
  }
`;

const Content = styled.span`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "AlbertSansExtraBold", sans-serif;
  font-weight: 700;
  font-size: 12px;
`;
