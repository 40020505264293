import React from "react";
import styled from "styled-components";

function ResumeCampaignStep({ date, handleResume, handleStartOver }) {
  return (
    <Form>
      <Section>
        <Title>Looks like you’ve got a draft campaign</Title>
        <SelectBlock>
          <Label fontWeight="bold">
            You started creating a campaign, but never finished. Please choose
            if you want to continue where you left off or start a new campaign
          </Label>
        </SelectBlock>

        <OptionsBlock>
          <Option>
            <IconContainer>
              <img src="/images/resume.png" alt="resume" width="17" />
            </IconContainer>
            <OptionContent>
              <OptionTitle>Resume creating previous campaign</OptionTitle>
              <OptionDescription>Draft Last Saved on {date}</OptionDescription>
              <div className="button" onClick={handleResume}>
                <div className="resume-icon"></div>
                Resume Campaign Creation
              </div>
            </OptionContent>
          </Option>
          <DividerText>OR</DividerText>
          <Option>
            <IconContainer>
              <img
                src="/images/start-new-campaign.png"
                alt="start new campaign"
                width="20"
              />
            </IconContainer>
            <OptionContent>
              <OptionTitle>Create a New Campaign</OptionTitle>
              <OptionDescription>
                You will lose the saved draft if you create a new campaign. Now,
                don’t say we didn’t warn you :)
              </OptionDescription>
              <div className="button" onClick={handleStartOver}>
                <div className="resume-icon"></div>
                Create a New Campaign
              </div>
            </OptionContent>
          </Option>
        </OptionsBlock>
      </Section>
    </Form>
  );
}

export default ResumeCampaignStep;

const Form = styled.form`
  width: 100%;
  margin: ${({ $margin }) => ($margin ? $margin : "60px 30px 30px 60px")};
  height: ${({ $height }) => ($height ? $height : "calc(100vh - 100px)")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const SelectBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin: 8px 120px 8px 0;
  color: ${({ theme }) => theme.colors.btn_border_color};
  font-weight: 600;
  font-size: 15px;
`;

const OptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
`;

const DividerText = styled.div`
  font-weight: 700;
  font-size: 12px;
  opacity: 0.3;
  align-self: center;
`;

const Option = styled.div`
  display: flex;
  background-color: white;
  opacity: 0.5;
  border-radius: 10px;
  margin: 20px 0;
  box-shadow: 0 0 10px #dfdfdf;
  border: none;
  padding: 20px 0;
  .button {
    cursor: pointer;
    height: 32px;
    background-color: #fafafa;
    color: #5a5a5a;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
    font-size: 10px;
    font-weight: 700;
    width: fit-content;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .resume-icon {
      margin-right: 5px;
      background: url(/images/resume-arrow-grey.svg) no-repeat center;
      width: 10px;
      height: 12px;
    }
  }
  &:hover {
    opacity: 1;
    border: 1px solid #d2cdcd;
    .button {
      cursor: pointer;
      height: 32px;
      background-color: #471168;
      color: white;
      border-radius: 6px;
      border: none;
      font-size: 10px;
      font-weight: 700;
      width: fit-content;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .resume-icon {
        margin-right: 5px;
        color: white;
        background: url("/images/resume-arrow.svg") no-repeat center;
        width: 10px;
        height: 12px;
      }
    }
  }
`;

const IconContainer = styled.div`
  width: 50px;
  text-align: right;
`;

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const OptionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const OptionDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 16px;
  width: 80%;
`;
