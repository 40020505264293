import React from "react";
import { styled } from "styled-components";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function BusinessHours({ businessHours, setBusinessHours, allowEmpty }) {
  const allZones = Intl.supportedValuesOf("timeZone").map((zoneName) => ({
    label: zoneName,
    value: zoneName,
  }));
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleSelectTimeZone = (arr) => {
    const value = (
      arr.length > 1
        ? arr.find(({ value }) => value !== businessHours.tz)
        : arr[0]
    )?.value;
    let newBusinessHours = {
      ...businessHours,
      tz: value || (allowEmpty ? null : currentTz),
    };
    if (allowEmpty && !newBusinessHours.tz) {
      newBusinessHours = {};
    }
    setBusinessHours(newBusinessHours);
  };

  const handleSelectTime = (field, date) => {
    let value;
    if (date) {
      value = { hours: date.getHours(), minutes: date.getMinutes() };
    }
    let newBusinessHours = {
      ...businessHours,
      ...(!businessHours.tz && value ? { tz: currentTz } : {}),
      [field]: value,
    };
    if (allowEmpty && !newBusinessHours.to && !newBusinessHours.from) {
      newBusinessHours = {};
    }
    setBusinessHours(newBusinessHours);
  };

  const prepareSelectedTime = (field) => {
    const value = businessHours[field];
    if (value) {
      const res = new Date();
      res.setHours(value.hours);
      res.setMinutes(value.minutes);
      res.setSeconds(0);
      return res;
    }
  };

  return (
    <Content className="custom-business-hours-cont">
      <HorFlex>
        {["from", "to"].map((field) => (
          <InputBlock key={field}>
            <Label>{field}:</Label>
            <StyledDatePicker>
              <DatePicker
                selected={prepareSelectedTime(field)}
                onChange={(d) => handleSelectTime(field, d)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="h:mm aa"
                isClearable={!!allowEmpty}
              />
            </StyledDatePicker>
          </InputBlock>
        ))}
      </HorFlex>
      <InputBlock>
        <Label>Time Zone:</Label>
        <MultiSelect
          options={allZones}
          value={
            allowEmpty && !businessHours.tz
              ? []
              : allZones.filter(
                  ({ value }) => value === (businessHours.tz || currentTz)
                )
          }
          onChange={handleSelectTimeZone}
          labelledBy="Time Zone"
          hasSelectAll={false}
          closeOnChangedValue={true}
          ClearIcon={<span />}
          ClearSelectedIcon={
            allowEmpty ? (
              <img src="/close-icon.png" width="12" alt="close" />
            ) : (
              <span />
            )
          }
          ArrowRenderer={() => (
            <img src="/images/arrow-down.png" width="8" alt="arrow-down" />
          )}
          display="chip"
        />
      </InputBlock>
    </Content>
  );
}

export default BusinessHours;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .multi-select {
    width: 208px;
    .dropdown-heading {
      box-shadow: 0 0 10px #dfdfdf;
      button.clear-selected-button {
        margin: 0 5px;
      }
    }
  }
`;

const HorFlex = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 14px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
  text-transform: capitalize;
`;

const StyledDatePicker = styled.div`
  > .react-datepicker-wrapper {
    > .react-datepicker__input-container > input {
      margin: 0;
      width: 97px;
      border-radius: 4px;
      border-color: #ccc;
      box-shadow: 0 0 10px #dfdfdf;
      font-size: 12px;
    }
    .react-datepicker__close-icon {
      &::after {
        height: 12px;
        width: 12px;
        color: transparent;
        background-color: transparent;
        background-image: url("/close-icon.png");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        &::after {
          opacity: 0.9;
        }
      }
    }
  }
  .react-datepicker-popper {
    .react-datepicker__time-container {
      font-size: 12px;
      .react-datepicker__header {
        .react-datepicker-time__header {
          font-size: 12px;
        }
      }
      .react-datepicker__time {
        .react-datepicker__time-box {
          text-align: right;
        }
      }
    }
  }
`;
