import React from "react";
import styled from "styled-components";

import Carousel from "./Carousel";
import Overview from "./Overview";
import Features from "./Features";

function AppDetails() {
  return (
    <>
      <Container>
        <Carousel />
      </Container>
      <Container>
        <Overview />
      </Container>
      <Container>
        <Features />
      </Container>
    </>
  );
}

export default AppDetails;

const Container = styled.div`
  margin-bottom: 48px;
`;
