import React from "react";

import { Item, Name } from "./styles";

function TabItem({ activeItem, onClick, name }) {
  return (
    <Item $activeItem={activeItem} onClick={onClick}>
      <Name $activeItem={activeItem}>{name}</Name>
    </Item>
  );
}

export default TabItem;
