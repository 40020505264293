import React from "react";

import AutomateO365KeyGenWizard from "../components/AutomateO365KeyGen";
import CheckExtensionVersion from "../components/CheckExtensionVersion";

function AutomateO365KeyGen() {
  return (
    <>
      <CheckExtensionVersion />
      <AutomateO365KeyGenWizard />
    </>
  );
}

export default AutomateO365KeyGen;
