/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  changeContactSequenceStatus,
  getContact,
  getCandidateMessages,
  generateReplyForMessage,
  sendReplyMessage,
} from "../../../services/candidates";
import styled, { keyframes } from "styled-components";
import { convertDateToEmailSentFormat } from "../utils";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faEnvelope,
  faEnvelopeCircleCheck,
  faMessage,
  faPhoneSlash,
  faPhoneSquare,
  faReply,
  faShareAltSquare,
} from "@fortawesome/free-solid-svg-icons";
import { groupBy, sortBy } from "lodash";
import theme from "../../../theme";
import { buildSequenceTemplateStepName } from "../../../utils/utils";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import {
  CAMPAIGN_STATUSES_COLOR,
  CANDIDATES_STATUSES,
  MESSAGE_STATUSES,
} from "../constants";
import { Tooltip } from "react-tooltip";
import {
  FSC_STEP_AUTOMATION_LEVEL,
  FSC_STEP_STATUS,
  FSC_STEP_TYPE,
} from "../../../utils/constants";
import CustomSelect from "../../CustomSelect";

const CONTENT_TYPES = {
  EMAIL: "email",
  PROMPT: "prompt",
};

const buildPromptSequence = (promptSequence) => {
  if (!promptSequence || !promptSequence?.length) {
    return "No Prompt";
  }

  return promptSequence.map((prompt, index) => {
    return (
      <PromptItem key={index}>
        <PromptRole>
          <PromptRoleLabel>Role:</PromptRoleLabel>
          <PromptRoleValue>{prompt.role}</PromptRoleValue>
        </PromptRole>
        <PromptContent>
          <PromptContentLabel>Content:</PromptContentLabel>
          <PromptContentValue>
            {prompt.content.replace(/\\n/g, "\n")}
          </PromptContentValue>
        </PromptContent>
      </PromptItem>
    );
  });
};

const ContactSequenceStepPopup = (props) => {
  const { isAdmin, handleClose, stepsStatuses } = props;

  const user = getUserFromLocalStorage();

  const [contact, setContact] = useState({ ...props.candidate });

  const getCurrentStepIndex = (contactData) => {
    if (!contactData) {
      contactData = contact;
    }

    return Math.min(
      contactData.executionCount,
      contactData.sequenceTemplateSteps.length - 1
    );
  };

  const [activeStepIndex, setActiveStepIndex] = useState(getCurrentStepIndex());
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);
  const [activeContent, setActiveContent] = useState(null);
  const [isShowNoPhone, setIsShowNoPhone] = useState(false);
  const [messages, setMessages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userReply, setUserReply] = useState(null);
  const [isReplyLoading, setIsReplyLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const selectedStep = contact.sequenceTemplateSteps[activeStepIndex];

  const [changedActiveStepStatus, setChangedActiveStepStatus] = useState(
    selectedStep.status
  );

  const allowedStatusesOptions = (
    stepsStatuses.find(
      (item) =>
        item.type === selectedStep.type &&
        item.automationLevels.includes(selectedStep.automationLevel)
    )?.statuses || Object.values(FSC_STEP_STATUS)
  )
    .filter(
      (s) =>
        s === selectedStep.status ||
        (![FSC_STEP_STATUS.PENDING, FSC_STEP_STATUS.ASSIGNED].includes(s) &&
          (s === FSC_STEP_STATUS.SKIPPED ||
            (selectedStep.orderNum === getCurrentStepIndex() + 1 &&
              ((selectedStep.status === FSC_STEP_STATUS.ASSIGNED &&
                selectedStep.automationLevel ===
                  FSC_STEP_AUTOMATION_LEVEL.MANUAL) ||
                ![
                  FSC_STEP_STATUS.COMPLETED,
                  FSC_STEP_STATUS.SUCCESS,
                  FSC_STEP_STATUS.OPT_OUT,
                ].includes(s)))))
    )
    .map((value) => ({
      value,
      label: value,
    }));

  const getStepMessages = (step, messageList) => {
    if (!step?.messageId) {
      return null;
    }
    return (messageList || messages).find((g) => g?.[0]?.id === step.messageId);
  };

  const activeMessageGroup = getStepMessages(selectedStep);

  const activeMessage = activeMessageGroup?.[activeMessageIndex];

  const parentActiveMessage = activeMessage?.replyFor
    ? activeMessageGroup.find(
        (m) =>
          m[
            activeMessage.status === MESSAGE_STATUSES.USER_REPLY
              ? "conversationId"
              : "externalId"
          ] === activeMessage.replyFor
      )
    : null;

  const buildContentBody = () => {
    return (
      (activeMessage?.text || "[n/a]") +
      (parentActiveMessage
        ? '<div class="parentMessage">' + parentActiveMessage.text + "</div>"
        : "")
    );
  };

  const fetchCandidateMessages = async (afterReply = false, contactData) => {
    setIsLoading(true);
    const data = await getCandidateMessages({
      campaignId: contact.campaignId,
      candidateId: contact.id,
    });

    if (data.success !== undefined && !data.success) {
      setErrorMessage(data.message);
      setIsLoading(false);
      return;
    }

    if (!contactData) {
      contactData = contact;
    }

    const allMessages = data.result.messages.map((m) =>
      m.conversationId
        ? m
        : { ...m, conversationId: m.attempt, externalId: m.attempt }
    );

    const groupedMessages = sortBy(
      Object.values(groupBy(allMessages, "conversationId")).map((messageList) =>
        sortBy(messageList, ["createdAt"])
      ),
      (messageList) => {
        return messageList[0].createdAt;
      }
    );

    setMessages(groupedMessages);

    setIsShowNoPhone(
      !contact.phoneNumber &&
        contact.sequenceTemplateSteps.some((s) => s.type === FSC_STEP_TYPE.SMS)
    );

    const stepIndex = afterReply
      ? activeStepIndex
      : getCurrentStepIndex(contactData);
    const curStep = contactData.sequenceTemplateSteps[stepIndex];

    if (!afterReply) {
      setActiveStepIndex(stepIndex);
      setChangedActiveStepStatus(curStep.status);
    }

    const curStepMessages = getStepMessages(curStep, groupedMessages);
    if (curStepMessages?.length) {
      setActiveMessageIndex(curStepMessages.length - 1);
      setActiveContent(CONTENT_TYPES.EMAIL);
    } else {
      setActiveMessageIndex(0);
      setActiveContent(null);
    }

    setIsLoading(false);
  };

  const getUpdContact = (contactData) => {
    return {
      ...contact,
      isUnsubscribe: contactData.isUnsubscribe,
      status: contactData.status,
      lastSentMessageType: contactData.lastSentMessageType,
      lastCommunication: contactData.lastCommunication,
      sequenceTemplateSteps: contactData.meta.sequenceTemplateSteps,
      executionCount: contactData.meta.executionCount,
    };
  };

  useEffect(() => {
    const fetchContact = async () => {
      const res = await getContact(contact.id);

      let updContact = contact;
      if (res.contact) {
        updContact = getUpdContact(res.contact);

        setContact(updContact);

        const stepIndex = getCurrentStepIndex(updContact);
        setActiveStepIndex(stepIndex);

        const step = updContact.sequenceTemplateSteps[stepIndex];
        setChangedActiveStepStatus(step.status);
      }

      fetchCandidateMessages(false, updContact);
    };

    fetchContact();
  }, []);

  const toggleActiveContent = () => {
    if (activeContent === CONTENT_TYPES.EMAIL) {
      setActiveContent(CONTENT_TYPES.PROMPT);
    } else {
      setActiveContent(CONTENT_TYPES.EMAIL);
    }
  };

  const setSelectedStep = (stepIndex) => {
    setActiveStepIndex(stepIndex);

    const step = contact.sequenceTemplateSteps[stepIndex];
    setChangedActiveStepStatus(step.status);

    let content = null;
    let msgIndex = 0;
    if (stepIndex <= getCurrentStepIndex()) {
      const stepMessages = getStepMessages(step);
      if (stepMessages?.length) {
        msgIndex = stepMessages.length - 1;
        content = CONTENT_TYPES.EMAIL;
      }
    }
    setActiveMessageIndex(msgIndex);
    setActiveContent(content);
    handleCancelReply();
  };

  const handleChangeStepStatus = async () => {
    if (activeContent === CONTENT_TYPES.PROMPT) {
      setActiveContent(CONTENT_TYPES.EMAIL);
    }
    handleCancelReply();

    const res = await changeContactSequenceStatus({
      contactId: contact.id,
      stepNum: selectedStep.orderNum,
      status: changedActiveStepStatus,
    });

    if (res.errorMessage) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.errorMessage,
        },
      });
      return;
    }

    const updContact = getUpdContact(res.contact);

    const stepIndex = getCurrentStepIndex(updContact);
    if (stepIndex !== activeStepIndex) {
      const step = updContact.sequenceTemplateSteps[stepIndex];
      setChangedActiveStepStatus(step.status);
      setActiveStepIndex(stepIndex);
      setActiveMessageIndex(0);
    }

    setContact(updContact);

    fetchCandidateMessages(false, updContact);
  };

  const setActiveMessage = (msgIndex) => {
    setActiveMessageIndex(msgIndex);
    setActiveContent(CONTENT_TYPES.EMAIL);
    handleCancelReply();
  };

  const handleClickReply = () => {
    const replyText = "<p></p>";
    setUserReply({ html: replyText, message: { text: replyText } });

    setTimeout(() => {
      try {
        const replyEmailBox = document.getElementById("replyEmailBox");
        if (replyEmailBox) {
          replyEmailBox.focus();
          const s = window.getSelection();
          const r = document.createRange();
          r.setStart(replyEmailBox, 0);
          r.setEnd(replyEmailBox, 0);
          s.removeAllRanges();
          s.addRange(r);
        }
      } catch (e) {
        console.error("replyEmailBox error: ", e);
      }
    }, 100);
  };

  const handleCancelReply = (event) => {
    event?.preventDefault();

    setUserReply(null);
  };

  const handleClickSendReply = async (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    try {
      const replyEmailBox = document.getElementById("replyEmailBox");
      if (!replyEmailBox.textContent.trim()) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: "Please enter an answer",
          },
        });
        return;
      }
    } catch (e) {
      console.error("replyEmailBox error: ", e);
    }

    const reply = {
      text: "<div>" + userReply.message.text.replace("<p></p>", "") + "</div>",
      subject:
        userReply.message.subject || "Re: " + (activeMessage.subject || ""),
      prompt: userReply.message.prompt,
    };

    setIsReplyLoading(true);

    const res = await sendReplyMessage(activeMessage, reply);

    setIsReplyLoading(false);

    handleCancelReply();

    if (res.success === false) {
      console.log(res);
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Could not send reply message",
        },
      });
    } else {
      fetchCandidateMessages(true);
    }
  };

  const handleClickGenerateReply = async (event) => {
    event?.preventDefault();

    setIsReplyLoading(true);

    const res = await generateReplyForMessage(activeMessage);

    setIsReplyLoading(false);

    if (res.success === false) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: res.errorMessage,
        },
      });
    } else {
      const { message } = res;
      setUserReply({ html: message.text, message });
    }
  };

  const handleInputReply = (event) => {
    setUserReply({
      ...userReply,
      message: { ...userReply.message, text: event.target.innerHTML },
    });
  };

  const displayEmailDate = (date) => {
    return new Date(date)
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
      .replace(",", "");
  };

  const allowToChangeStepStatus =
    ![FSC_STEP_STATUS.COMPLETED, FSC_STEP_STATUS.SKIPPED].includes(
      selectedStep.status
    ) &&
    !contact.sequenceTemplateSteps.some((s) =>
      [FSC_STEP_STATUS.SUCCESS, FSC_STEP_STATUS.OPT_OUT].includes(s.status)
    ) &&
    !contact.isUnsubscribe &&
    ![
      CANDIDATES_STATUSES.BOUNCE,
      CANDIDATES_STATUSES.COMPLETED,
      CANDIDATES_STATUSES.SUCCESS,
    ].includes(contact.status);

  const getStepIcon = (step) => {
    return [FSC_STEP_TYPE.EMAIL, FSC_STEP_TYPE.MARKETING_EMAIL].includes(
      step.type
    )
      ? faEnvelope
      : step.type === FSC_STEP_TYPE.SMS
      ? faMessage
      : step.type === FSC_STEP_TYPE.PHONE_CALL
      ? faPhoneSquare
      : faShareAltSquare;
  };

  return (
    <PopupContainer>
      <Content>
        <PopupHeader>
          <div>
            <PopupHeaderTitle $cursor={"default"} $status={contact.status}>
              <p>
                {contact.full_name ||
                  [contact.first_name, contact.last_name]
                    .filter(Boolean)
                    .join(" ") ||
                  contact.email ||
                  "-"}
              </p>
              <span>
                {contact.status === CANDIDATES_STATUSES.SUCCESS
                  ? "Goal Met"
                  : contact.status === CANDIDATES_STATUSES.FAIL
                  ? "Goal Not Met"
                  : contact.status === CANDIDATES_STATUSES.EMAIL_SENT &&
                    contact.lastSentMessageType === "sms"
                  ? "SMS Sent"
                  : contact.status}
                {contact.sequenceTemplateSteps.some((s) =>
                  [FSC_STEP_STATUS.SUCCESS, FSC_STEP_STATUS.OPT_OUT].includes(
                    s.status
                  )
                ) && (
                  <small>
                    (step{" "}
                    {
                      contact.sequenceTemplateSteps.find((s) =>
                        [
                          FSC_STEP_STATUS.SUCCESS,
                          FSC_STEP_STATUS.OPT_OUT,
                        ].includes(s.status)
                      ).orderNum
                    }
                    )
                  </small>
                )}
              </span>
            </PopupHeaderTitle>

            <PopupHeaderSubTitle>
              {contact.email}{" "}
              {isShowNoPhone && (
                <>
                  <FontAwesomeIcon
                    icon={faPhoneSlash}
                    color="#bbb"
                    data-tooltip-id="no-phone-number"
                    data-tooltip-content="No phone number"
                  />
                  <Tooltip
                    id="no-phone-number"
                    className="custom-tooltip"
                    place="right"
                  />
                </>
              )}
            </PopupHeaderSubTitle>
          </div>
          <CloseIcon
            src="/images/email-details-back-icon.png"
            onClick={() => handleClose(contact)}
          />
        </PopupHeader>

        {isLoading ? (
          <Loader parentSize={true} bgColor="#f5f2ee" />
        ) : errorMessage ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : (
          <PopupContent>
            <LeftSidePanel>
              {contact.sequenceTemplateSteps.map((step, index) => {
                const isCurrentStep = index === getCurrentStepIndex();
                const isSelectedStep = index === activeStepIndex;
                const firstMessageInThread = isSelectedStep
                  ? activeMessageGroup?.[0]
                  : null;
                return (
                  <React.Fragment key={index}>
                    <MessageTabTitle onClick={() => setSelectedStep(index)}>
                      <MessageEmailTypeIcon
                        icon={getStepIcon(step)}
                        $active={isSelectedStep}
                        $isCurrentStep={isCurrentStep}
                      />
                      <MessageDate
                        $active={isSelectedStep}
                        $isCurrentStep={isCurrentStep}
                      >
                        <span>{buildSequenceTemplateStepName(step)}</span>
                        {isSelectedStep && (
                          <ActiveTabArrow src="/images/active-email-tab-arrow.png" />
                        )}
                      </MessageDate>
                    </MessageTabTitle>
                    {!!firstMessageInThread?.sent && (
                      <MessageTabTitle
                        $subTitle={true}
                        onClick={() => setActiveMessage(0)}
                      >
                        <MessageEmailTypeIcon icon={faEnvelopeCircleCheck} />
                        <MessageDate $active={!activeMessageIndex}>
                          <span>
                            {`Sent ${displayEmailDate(
                              firstMessageInThread?.sentAt ||
                                firstMessageInThread?.createdAt
                            )}`}
                          </span>
                        </MessageDate>
                      </MessageTabTitle>
                    )}
                    {!!firstMessageInThread?.read && (
                      <MessageTabTitle
                        $subTitle={true}
                        onClick={() => setActiveMessage(0)}
                      >
                        <MessageEmailTypeIcon icon={faEnvelopeCircleCheck} />
                        <MessageDate $active={!activeMessageIndex}>
                          <span>
                            {`Read ${
                              firstMessageInThread?.readCount > 1
                                ? `${
                                    firstMessageInThread?.readCount
                                  } times (last ${displayEmailDate(
                                    firstMessageInThread?.lastReadAt ||
                                      firstMessageInThread?.createdAt
                                  )})`
                                : displayEmailDate(
                                    firstMessageInThread?.lastReadAt ||
                                      firstMessageInThread?.createdAt
                                  )
                            }`}
                          </span>
                        </MessageDate>
                      </MessageTabTitle>
                    )}
                    {!!firstMessageInThread?.answered &&
                      activeMessageGroup.map((message, subIndex) => {
                        if (subIndex === 0) {
                          return null;
                        }
                        const isActiveReplyMessage =
                          subIndex === activeMessageIndex;
                        return (
                          <MessageTabTitle
                            key={`${index}-${subIndex}`}
                            $subTitle={true}
                            onClick={() => setActiveMessage(subIndex)}
                          >
                            {message.followUpFor ? (
                              <>
                                <MessageEmailTypeIcon
                                  $activeReply={isActiveReplyMessage}
                                  icon={faCircleCheck}
                                />
                                <MessageDate
                                  $activeReply={isActiveReplyMessage}
                                >
                                  <span>
                                    {`Followed-up ${displayEmailDate(
                                      message.createdAt
                                    )}`}
                                  </span>
                                </MessageDate>
                              </>
                            ) : (
                              <>
                                <MessageEmailTypeIcon
                                  icon={
                                    message.replyFor &&
                                    message.status !==
                                      MESSAGE_STATUSES.USER_REPLY
                                      ? faReply
                                      : faCircleCheck
                                  }
                                  $activeReply={isActiveReplyMessage}
                                />
                                <MessageDate
                                  $activeReply={isActiveReplyMessage}
                                >
                                  <span>
                                    {message.replyFor &&
                                    message.status !==
                                      MESSAGE_STATUSES.USER_REPLY
                                      ? `You replied ${displayEmailDate(
                                          message.createdAt
                                        )}${
                                          message.status ===
                                          MESSAGE_STATUSES.NOT_SENT
                                            ? " (Not sent)"
                                            : ""
                                        }`
                                      : `Answered ${displayEmailDate(
                                          message.answeredAt ||
                                            message.lastReadAt ||
                                            message.createdAt
                                        )}`}
                                  </span>
                                </MessageDate>
                              </>
                            )}
                          </MessageTabTitle>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </LeftSidePanel>
            <RightSidePanel>
              <ContentHeader>
                <ContentHeaderTitleBlock>
                  <ContentHeaderEmailTypeIcon
                    icon={getStepIcon(selectedStep)}
                  />
                  <div>
                    <ContentHeaderTitle>{`${selectedStep.type} ${
                      ![
                        FSC_STEP_TYPE.EMAIL,
                        FSC_STEP_TYPE.MARKETING_EMAIL,
                        FSC_STEP_TYPE.SMS,
                      ].includes(selectedStep.type)
                        ? ""
                        : activeMessage?.replyFor
                        ? "Reply"
                        : "Message"
                    }${
                      activeContent !== CONTENT_TYPES.PROMPT ? "" : " Prompt"
                    }`}</ContentHeaderTitle>
                    <ContentHeaderSubTitle>
                      <span>
                        {!!activeMessage &&
                          convertDateToEmailSentFormat(activeMessage.createdAt)}
                        {activeMessage?.replyFor ? (
                          <>
                            {" "}
                            from{" "}
                            <span className="email">
                              {activeMessage?.replyFor &&
                              activeMessage?.status !==
                                MESSAGE_STATUSES.USER_REPLY
                                ? user?.email || ""
                                : contact.email}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </ContentHeaderSubTitle>
                  </div>
                  <ContentStatus>
                    <CustomSelect
                      disabled={!allowToChangeStepStatus}
                      options={allowedStatusesOptions}
                      minWidth="100px"
                      title=""
                      selected={changedActiveStepStatus}
                      onSelect={setChangedActiveStepStatus}
                    />
                    <UpdateStatusBtn
                      disabled={changedActiveStepStatus === selectedStep.status}
                      onClick={handleChangeStepStatus}
                    >
                      Update
                    </UpdateStatusBtn>
                  </ContentStatus>
                </ContentHeaderTitleBlock>
                {userReply !== null ? (
                  <BtnBar>
                    {isAdmin && (
                      <ChangeContentTypeBtn
                        disabled={isReplyLoading}
                        onClick={handleClickGenerateReply}
                      >
                        Generate
                      </ChangeContentTypeBtn>
                    )}
                    <ChangeContentTypeBtn
                      disabled={
                        isReplyLoading ||
                        !userReply.message.text?.replace("<p></p>", "").trim()
                      }
                      onClick={handleClickSendReply}
                    >
                      Send
                    </ChangeContentTypeBtn>
                    <ChangeContentTypeBtn
                      disabled={isReplyLoading}
                      onClick={handleCancelReply}
                    >
                      Cancel
                    </ChangeContentTypeBtn>
                  </BtnBar>
                ) : activeMessage?.replyFor ? (
                  activeMessage?.status !== MESSAGE_STATUSES.USER_REPLY ||
                  contact.isUnsubscribe ||
                  contact.status === CANDIDATES_STATUSES.BOUNCE ? null : (
                    <ChangeContentTypeBtn onClick={handleClickReply}>
                      Reply
                    </ChangeContentTypeBtn>
                  )
                ) : (
                  isAdmin &&
                  !!activeMessage && (
                    <ChangeContentTypeBtn onClick={toggleActiveContent}>
                      {activeContent === CONTENT_TYPES.EMAIL
                        ? "View Prompt"
                        : `View ${
                            activeMessage?.type === "email" ? "Email" : "SMS"
                          }`}
                    </ChangeContentTypeBtn>
                  )
                )}
              </ContentHeader>

              <ContentBodyWrap>
                {activeContent === CONTENT_TYPES.EMAIL && (
                  <>
                    {activeMessage?.type !== "sms" && (
                      <ContentBodySubject>
                        {activeMessage?.subject || "[n/a]"}
                      </ContentBodySubject>
                    )}
                    <ContentBody
                      $withSubject={activeMessage?.type !== "sms"}
                      $withReplyEmailBox={!isReplyLoading && userReply !== null}
                    >
                      {isReplyLoading ? (
                        <Loader parentSize={true} height="fit-content" />
                      ) : (
                        userReply !== null && (
                          <ReplyEmailBox
                            id="replyEmailBox"
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            $disabled={isReplyLoading}
                            onInput={handleInputReply}
                            dangerouslySetInnerHTML={{
                              __html: userReply.html,
                            }}
                          />
                        )
                      )}
                      <ContentBodyText
                        className={
                          userReply !== null ? "parentMessage" : undefined
                        }
                        dangerouslySetInnerHTML={{
                          __html: buildContentBody(),
                        }}
                      ></ContentBodyText>
                    </ContentBody>
                  </>
                )}
                {activeContent === CONTENT_TYPES.PROMPT && (
                  <ContentBody>
                    <ContentBodyText>
                      {buildPromptSequence(activeMessage?.prompt)}
                    </ContentBodyText>
                  </ContentBody>
                )}
              </ContentBodyWrap>
            </RightSidePanel>
          </PopupContent>
        )}
      </Content>
    </PopupContainer>
  );
};

export default ContactSequenceStepPopup;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 99;
`;

const Content = styled.div`
  position: relative;
  width: 97%;
  margin: 0 0 0 auto;
  height: 100%;
  background: #fff;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #999;
  overflow: hidden;
  animation: ${slideInFromRight} 0.5s ease-in-out;
`;

const PopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100px;
  padding: 20px 40px 20px 40px;
  justify-content: space-between;
`;

const CloseIcon = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
`;

const PopupHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  p {
    font-size: 24px;
    font-weight: 800;
    font-family: "AlbertSansExtraBold", sans-serif;
    color: #888888;
    margin-bottom: 0;
  }
  span {
    position: relative;
    font-size: 11px;
    font-weight: 400;
    ${({ $status }) => {
      if (
        [CANDIDATES_STATUSES.COMPLETED, CANDIDATES_STATUSES.SUCCESS].includes(
          $status
        )
      ) {
        return `color: ${CAMPAIGN_STATUSES_COLOR.Completed};`;
      }
      if (
        [
          CANDIDATES_STATUSES.BOUNCE,
          CANDIDATES_STATUSES.ERROR,
          CANDIDATES_STATUSES.FAIL,
        ].includes($status)
      ) {
        return `color: ${CAMPAIGN_STATUSES_COLOR.Fail};`;
      }
      return `font-family: "AlbertSansThin", sans-serif; color: #000000;`;
    }};
    margin-left: 20px;
    cursor: ${({ $cursor }) => ($cursor ? $cursor : "default")};
    small {
      margin-left: 5px;
    }
  }
`;

const ChangeStatusDropdown = styled.div`
  position: absolute;
  top: -11px;
  display: flex;
  flex-direction: column;
  left: -11px;
  border-top: 1px solid #888888;
`;

const DropdownOption = styled.div`
  background-color: ${({ $isBg }) => ($isBg ? "#ffffff" : "transparent")};
  display: flex;
  align-items: center;
  border-left: 1px solid #888888;
  border-right: 1px solid #888888;
  border-bottom: 1px solid #888888;
  padding: 10px;
  ${({ $isDefaultOption }) => $isDefaultOption && "justify-content: flex-end;"}
  &:hover {
    background-color: ${({ $isBg }) => ($isBg ? "#f5f2ee" : "transparent")};
  }
`;

const DropdownIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  color: #000;
  opacity: 0.5;
`;

const PopupHeaderSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "AlbertSansThin", sans-serif;
  color: #000000;
  span {
    margin-right: 10px;
  }
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  padding: 40px;
  color: red;
`;

const PopupContent = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: row;
`;

const LeftSidePanel = styled.div`
  width: 30%;
  height: 100%;
  background-color: #f8f8f8;
  border: 1px solid #e1e1e1;
  overflow: auto;
`;

const NoMessagesText = styled.div`
  font-size: 16px;
  padding: 40px;
`;

const MessageTabTitle = styled.div`
  ${({ $subTitle }) => ($subTitle ? "margin-left: 22px" : "")};
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MessageTypeIcon = styled.img`
  width: 17px;
  margin: 10px;
`;

const MessageEmailTypeIcon = styled(FontAwesomeIcon)`
  margin: 10px 9px 10px 13px;
  font-size: 14px;
  color: #000;
  opacity: 0.5;
  min-width: 18px;
  ${({ $activeReply }) => ($activeReply ? `color: ${theme.colors.red};` : "")};
  ${({ $isCurrentStep, $active }) =>
    $isCurrentStep
      ? `color: ${CAMPAIGN_STATUSES_COLOR.Active};${
          $active ? "opacity: 0.6;" : ""
        }`
      : ""};
`;

const MessageDate = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ $active, $activeReply, $isCurrentStep }) =>
    $active || $activeReply || $isCurrentStep ? "700" : "400"};
  font-family: "AlbertSansThin", sans-serif;
  font-size: 12px;
  border-bottom: 1px solid #e3e3e3;
  ${({ $activeReply }) => ($activeReply ? `color: ${theme.colors.red};` : "")};
  ${({ $isCurrentStep, $active }) =>
    $isCurrentStep
      ? `color: ${CAMPAIGN_STATUSES_COLOR.Active};${
          $active ? "" : "opacity: 0.75;"
        }`
      : ""};
`;

const ActiveTabArrow = styled.img`
  width: 7px;
  margin-right: 10px;
`;

const RightSidePanel = styled.div`
  width: 70%;
  height: 100%;
  background-color: #f5f2ee;
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  padding: 20px;
`;

const ContentHeaderTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContentHeaderTypeIcon = styled.img`
  width: 34px;
  margin: 20px 15px 20px 20px;
`;

const ContentHeaderEmailTypeIcon = styled(FontAwesomeIcon)`
  margin: 15px 19px 16px 20px;
  font-size: 30px;
  color: #000;
  opacity: 0.5;
`;

const ContentHeaderTitle = styled.div`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 16px;
  font-weight: 700;
`;

const ContentHeaderSubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "AlbertSansThin", sans-serif;
  span {
    &.email {
      color: ${theme.colors.purple};
    }
  }
`;

const ChangeContentTypeBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: black;
  background-color: transparent;
  height: 40px;
  width: 158px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  ${({ disabled }) => (disabled ? "opacity: 0.75" : "")};
`;

const BtnBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  overflow-x: auto;
  button {
    width: auto;
    padding-left: 25px;
    padding-right: 25px;
  }
`;

const ContentBodyWrap = styled.div`
  height: calc(100% - 120px);
`;

const ContentBody = styled.div`
  background-color: #ffffff;
  margin-left: 90px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: auto;
  height: ${({ $withSubject }) =>
    $withSubject ? "calc(100% - 60px)" : "100%"};
  overflow-y: ${({ $withReplyEmailBox }) =>
    $withReplyEmailBox ? "auto" : "hidden"};
  border: 1px solid #dcdbd9;
  padding: 30px 0 30px 30px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;

const ContentStatus = styled.div`
  font-size: 14px;
  padding-left: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  > div {
    margin: 0;
  }
`;

const UpdateStatusBtn = styled.button`
  cursor: pointer;
  color: black;
  background-color: transparent;
  height: 36px;
  width: auto;
  padding: 0 20px;
  font-weight: 800;
  border-radius: 6px;
  border: 1px solid #5a5a5a;
  ${({ disabled }) => (disabled ? "display: none;" : "")};
`;

const ContentBodySubject = styled.div`
  background-color: #ffffff;
  margin-left: 90px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: auto;
  padding: 10px 10px 10px 30px;
  font-size: 14px;
  border: 1px solid #dcdbd9;
`;

const ContentBodyText = styled.div`
  overflow: auto;
  flex-grow: 1;
  padding-right: 30px;
  p {
    padding-bottom: 10px;
  }
  &.parentMessage,
  .parentMessage {
    margin: 20px 0 0 10px;
    padding: 20px 15px 15px 10px;
    border-left: 3px solid #ccc;
    background-color: #f9f9f9;
  }
  &.parentMessage {
    margin-right: 30px;
  }
  ul {
    padding-inline-start: 40px;
  }
`;

const ReplyEmailBox = styled.div`
  margin-right: 30px;
  padding: 0 0 10px;
  border: none;
  outline: none;
  box-shadow: none;
  ${({ $disabled }) => ($disabled ? "pointer-events: none" : "")};
`;

const PromptItem = styled.div`
  border-bottom: 1px solid #dcdbd9;
  margin: 5px 0;
`;

const PromptRole = styled.div``;

const PromptRoleLabel = styled.span`
  font-weight: bold;
  font-style: italic;
  margin-right: 5px;
`;

const PromptRoleValue = styled.span``;

const PromptContent = styled.div``;

const PromptContentLabel = styled.div`
  font-weight: bold;
  font-style: italic;
`;

const PromptContentValue = styled.div`
  margin: 5px 0;
`;
