import React, { useState } from "react";
import { styled } from "styled-components";
import CandidatesPopup from "./CandidatesPopup";
import ContactSequenceStepPopup from "./ContactSequenceStepPopup";
import theme from "../../../theme";
import { CANDIDATES_STATUSES } from "../constants";
import { displayDate } from "../utils";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

function CandidateTable({
  candidateList,
  isFollowUpsAsReplies,
  setSort,
  sort,
  onStatusChanged,
  fullSequenceCampaignStepStatuses = [],
}) {
  const [isCandidatesPopupOpen, setIsCandidatesPopupOpen] = useState(false);
  const [activeCandidate, setActiveCandidate] = useState(null);

  const openPopup = (event, candidate) => {
    event.preventDefault();
    setIsCandidatesPopupOpen(true);
    setActiveCandidate(candidate);
  };

  const closePopup = (event) => {
    if (event?.id === activeCandidate.id) {
      const idx = candidateList.findIndex((c) => c.id === activeCandidate.id);
      candidateList[idx] = event;
    } else {
      event.preventDefault();
    }
    setIsCandidatesPopupOpen(false);
    setActiveCandidate(null);
  };

  const handleClickSort = (newSort) => {
    setSort({ newSort, newOrder: sort.order === "asc" ? "desc" : "asc" });
  };

  const isAdmin = () => {
    const userInfo = getUserFromLocalStorage();
    if (!userInfo) {
      return false;
    }
    return userInfo.role === "admin";
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow $backgroundColor={theme.colors.candidate_table_head_color}>
            <TableHeadData
              $padding="0 8px 0 18px"
              $cursorPointer={true}
              onClick={() => handleClickSort("full_name")}
            >
              Candidate Name
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "full_name" ? sort.order : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("email")}
            >
              Email
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "email" ? sort.order : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("status")}
            >
              Status
              <SortIcon
                src={`/images/sort-${
                  sort.sort === "status" ? sort.order : "icon"
                }.png`}
                alt="sort asc"
              />
            </TableHeadData>
            <TableHeadData>Last Communication</TableHeadData>
            <TableHeadData $padding="0 144px 0 0">History</TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          {candidateList.length !== 0 ? (
            candidateList.map((recipient, index) => {
              return (
                <TableRow
                  $borderBottom={`1px solid ${theme.colors.divider_color}`}
                  key={index}
                >
                  <TableData $padding="0 0 0 8px" color={theme.colors.purple}>
                    {recipient.full_name ||
                      [recipient.first_name, recipient.last_name]
                        .filter(Boolean)
                        .join(" ") ||
                      recipient.email ||
                      "-"}
                  </TableData>
                  <TableData>{recipient.email}</TableData>
                  <TableData>
                    {recipient.status === CANDIDATES_STATUSES.SUCCESS
                      ? "Goal Met"
                      : recipient.status === CANDIDATES_STATUSES.FAIL
                      ? "Goal Not Met"
                      : recipient.status === CANDIDATES_STATUSES.EMAIL_SENT &&
                        recipient.lastSentMessageType === "sms"
                      ? "SMS Sent"
                      : recipient.status}
                  </TableData>
                  <TableData>
                    {recipient.lastCommunication &&
                    recipient.lastCommunication !== ""
                      ? displayDate(recipient.lastCommunication)
                      : ""}
                  </TableData>
                  <TableData
                    color={theme.colors.purple}
                    $cursor="pointer"
                    onClick={(event) => openPopup(event, recipient)}
                  >
                    Details...
                  </TableData>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableData $padding="0 0 0 8px">No recipients to show</TableData>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isCandidatesPopupOpen &&
        (activeCandidate.sequenceTemplateSteps ? (
          <ContactSequenceStepPopup
            candidate={activeCandidate}
            isAdmin={isAdmin()}
            handleClose={(event) => closePopup(event)}
            stepsStatuses={fullSequenceCampaignStepStatuses}
          />
        ) : (
          <CandidatesPopup
            candidate={activeCandidate}
            isFollowUpsAsReplies={isFollowUpsAsReplies}
            isAdmin={isAdmin()}
            handleClose={(event) => closePopup(event)}
            onStatusChanged={onStatusChanged}
          />
        ))}
    </>
  );
}

export default CandidateTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.tbody``;

const TableHead = styled.thead`
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  position: sticky;
  top: 0px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.candidate_table_head_color};
`;

const TableHeadData = styled.th`
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? "pointer" : "")};
  font-size: 11px;
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "")};
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.candidate_table_head_color};
`;

const TableRow = styled.tr`
  height: 46px;
  ${({ $backgroundColor, theme }) =>
    $backgroundColor ? `background-color:${$backgroundColor}` : ""};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom}` : ""};
`;

const TableData = styled.td`
  ${({ $width }) => ($width ? `width: ${$width}` : "")};
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "")};
  ${({ color, theme }) => (color ? `color: ${color}` : theme.colors.black)};
  ${({ color, theme }) => (color ? `font-size: 12px` : "font-size:11px")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor}` : "")};
`;

const SortIcon = styled.img`
  width: 15px;
  padding-left: 5px;
`;
