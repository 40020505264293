import {
  fetchUsers,
  fetchGetPriceForRegisterUser,
  fetchSaveUser,
} from "./queries.js";

const getUsers = async (data) => {
  try {
    const result = await fetchUsers(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get users failed" };
  }
};

const getPriceForRegisterUser = async () => {
  try {
    const result = await fetchGetPriceForRegisterUser();

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get price failed" };
  }
};

const saveUser = async (data) => {
  try {
    const result = await fetchSaveUser(data);

    if (result.message) {
      return result;
    }

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Save user failed" };
  }
};

export { getUsers, getPriceForRegisterUser, saveUser };
