import React from "react";
import styled from "styled-components";

import Features from "./Features";
import Categories from "./Categories";
import SubTitle from "../SubTitle";
import Description from "../Description";
import Title from "../Title";
import Section from "../Section";

function Details() {
  return (
    <>
      <Title>Details</Title>
      <Section>
        <SubTitle>Provider</SubTitle>
        <AppName href="https://salesstream.ai/" target="_blank">
          SalesStream.ai
        </AppName>
      </Section>
      <Section>
        <SubTitle>Total installs</SubTitle>
        <Description>100+</Description>
      </Section>
      <Section>
        <SubTitle>Category</SubTitle>
        <Categories />
      </Section>
      <Section>
        <SubTitle>Features</SubTitle>
        <Features />
      </Section>
      <Section>
        <SubTitle>Languages</SubTitle>
        <Description>English</Description>
      </Section>
    </>
  );
}

export default Details;

const AppName = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.15s ease-out;
  color: #0091ae;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #007a8c;
    text-decoration: underline;
  }
`;
